<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_codeManage" :checkRole="manageRole" />
      <div class="list_wrap">
        <div class="list_filter_wrap float_box">
          <div class="filter_list_box">
            <table class="mando_table code_search_table">
              <colgroup></colgroup>
              <tbody>
                <tr>
                  <th>코드 대분류 명</th>
                  <td>
                    <input
                      type="text"
                      ref="codeSearhNm"
                      v-model="codeSearhNm"
                      placeholder="코드 대분류 명 입력"
                    />
                  </td>
                  <th>사용여부</th>
                  <td>
                    <select ref="codeSearchUseYn" v-model="codeSearchUseYn">
                      <option value="">전체</option>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  </td>
                  <td>
                    <button type="button" class="btn_code" @click="doSearch">
                      조회
                    </button>

                    <button
                      type="button"
                      class="btn_code btn_reset"
                      ref="btnReset"
                      style="display: none"
                      @click="doReset"
                    >
                      초기화
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="table_list_wrap">
          <div class="table_list">
            <div class="title_wrap">
              <h3>코드 대분류 목록</h3>
              <div class="title_btn">
                <button
                  type="button"
                  class="add_project bgicon"
                  @click="showModal()"
                >
                  추가
                </button>
              </div>
            </div>
            <div class="mando_table_wrap">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="col_name" style="cursor: not-allowed">No</div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('code')"
                        style="cursor: pointer"
                      >
                        코드
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeNm')"
                        style="cursor: pointer"
                      >
                        코드명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeENm')"
                        style="cursor: pointer"
                      >
                        코드 영어명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDesc')"
                        style="cursor: pointer"
                      >
                        코드 설명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeUseYn')"
                        style="cursor: pointer"
                      >
                        사용여부
                      </div>
                    </th>
                    <th>
                      <div class="col_name" style="cursor: not-allowed">
                        편집
                      </div>
                    </th>
                    <!-- <th>
                      <div class="col_name">삭제</div>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="m_pointer"
                    v-for="(item, index) in codeList"
                    :key="index"
                    @click="codeDetailActive($event, item.code, true)"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.codeNm }}</td>
                    <td>{{ item.codeENm }}</td>
                    <td>{{ item.codeDesc }}</td>
                    <td>{{ item.useYn }}</td>
                    <td>
                      <button
                        @click="modifyCode(item.code)"
                        type="button"
                        class="btn_code"
                      >
                        수정
                      </button>
                    </td>
                    <!-- <td>
                      <button
                        @click="showModal('deleteCode', item.code)"
                        type="button"
                        class="btn_code btn_color_red"
                      >
                        삭제
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="table_list_wrap">
          <div class="table_list">
            <div class="title_wrap">
              <h3>코드 상세 목록</h3>
              <div class="title_btn">
                <button
                  type="button"
                  class="add_project bgicon"
                  v-show="codeDetailBtn"
                  @click="showModal('detail', code)"
                >
                  추가
                </button>
              </div>
            </div>
            <div class="mando_table_wrap">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="col_name" style="cursor: not-allowed">No</div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDetail')"
                        style="cursor: pointer"
                      >
                        상세 코드
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDetailNm')"
                        style="cursor: pointer"
                      >
                        상세 코드명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDetailENm')"
                        style="cursor: pointer"
                      >
                        상세 코드 영어명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDetailDesc')"
                        style="cursor: pointer"
                      >
                        상세 코드 설명
                      </div>
                    </th>
                    <th>
                      <div
                        class="col_name"
                        @click="sort('codeDetailUseYn')"
                        style="cursor: pointer"
                      >
                        사용여부
                      </div>
                    </th>
                    <th>
                      <div class="col_name" style="cursor: not-allowed">
                        편집
                      </div>
                    </th>
                    <!-- <th>
                      <div class="col_name">삭제</div>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="m_pointer"
                    v-for="(item, index) in codeDetailList"
                    :key="index"
                    @click="codeDetailActive($event, item.code, false)"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.dtlCode }}</td>
                    <td>{{ item.dtlNm }}</td>
                    <td>{{ item.dtlENm }}</td>
                    <td>{{ item.dtlDesc }}</td>
                    <td>{{ item.useYn }}</td>
                    <td>
                      <button
                        @click="modifyDetailCode(item.code, item.dtlCode)"
                        type="button"
                        class="btn_code"
                      >
                        수정
                      </button>
                    </td>
                    <!-- <td>
                      <button
                        @click="showModal('deleteDetailCode', item.dtlCode)"
                        type="button"
                        class="btn_code btn_color_red"
                      >
                        삭제
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modalWrap"
        class="modalWrap"
        ref="modalWrap_01"
        style="display: none"
      >
        <div class="modal_contents radiusbox">
          <strong class="modal_title">{{
            created && created != null && created != ""
              ? "코드 대분류 수정"
              : "코드 대분류 추가"
          }}</strong>
          <div id="management">
            <div
              class="row_box two_item"
              v-if="!created || created == null || created == ''"
            >
              <div>
                <div class="data_key">코드</div>
                <div
                  class="data_val"
                  :class="{ failure: isEmptyCode || isDuplicateCode }"
                >
                  <input
                    type="text"
                    name="code"
                    ref="code"
                    maxlength="20"
                    v-model="code"
                    placeholder="20자 이내로 입력해주세요."
                  />
                  <div class="text_message" v-if="isEmptyCode">
                    코드를 입력해 주세요.
                  </div>
                  <div class="text_message" v-if="isDuplicateCode">
                    {{ duplicateText }}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div class="row_box two_item">
              <div>
                <div class="data_key">코드명</div>
                <div class="data_val" :class="{ failure: isEmptyCodeNm }">
                  <input
                    type="text"
                    name="codeNm"
                    ref="codeNm"
                    maxlength="50"
                    v-model="codeNm"
                    placeholder="입력해주세요."
                  />
                  <div class="text_message" v-if="isEmptyCodeNm">
                    코드명을 입력해 주세요.
                  </div>
                </div>
              </div>
              <div>
                <div class="data_key">코드 영어명</div>
                <div class="data_val" :class="{ failure: isEmptyCodeENm }">
                  <input
                    type="text"
                    name="codeENm"
                    ref="codeENm"
                    maxlength="50"
                    v-model="codeENm"
                    placeholder="입력해주세요."
                  />
                  <div class="text_message" v-if="isEmptyCodeENm">
                    코드 영어명을 입력해 주세요.
                  </div>
                </div>
              </div>
            </div>
            <div class="row_box two_item">
              <div>
                <div class="data_key">코드 설명</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="codeDesc"
                    ref="codeDesc"
                    maxlength="50"
                    v-model="codeDesc"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
              <div>
                <div class="data_key">사용 여부</div>
                <div class="data_val">
                  <select
                    name="codeUseYn"
                    ref="codeUseYn"
                    :value="codeUseYn"
                    @change="chgCodeUseYn($event)"
                  >
                    <option value="Y">Y</option>
                    <option value="N" selected>N</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc1</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="codeEtc1"
                    ref="codeEtc1"
                    maxlength="200"
                    v-model="codeEtc1"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc2</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="codeEtc2"
                    ref="codeEtc2"
                    maxlength="200"
                    v-model="codeEtc2"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc3</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="codeEtc3"
                    ref="codeEtc3"
                    maxlength="200"
                    v-model="codeEtc3"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>

            <div class="save_box">
              <button type="button" @click="closeModal">취소</button
              ><button type="button" class="mcbtn" @click="clickRegist('code')">
                저장
              </button>
            </div>
          </div>

          <button
            type="button"
            class="btn_close"
            @click="closeModal()"
          ></button>
        </div>
      </div>

      <div
        id="modalWrap"
        class="modalWrap"
        ref="modalWrap_02"
        style="display: none"
      >
        <div class="modal_contents radiusbox">
          <strong class="modal_title">{{
            detailCodeCreated &&
            detailCodeCreated != null &&
            detailCodeCreated != ""
              ? "코드 상세 수정"
              : "코드 상세 추가"
          }}</strong>
          <div id="management">
            <template
              v-if="
                !detailCodeCreated ||
                detailCodeCreated == null ||
                detailCodeCreated == ''
              "
            >
              <div class="row_box two_item">
                <div>
                  <div class="data_key">상세 코드</div>
                  <div class="data_val" :class="{ failure: isEmptyDetailCode }">
                    <input
                      type="text"
                      name="detailCode"
                      ref="detailCode"
                      maxlength="20"
                      v-model="detailCode"
                      placeholder="20자 이내로 입력해주세요."
                    />
                    <div class="text_message" v-if="isEmptyDetailCode">
                      상세코드를 입력해 주세요.
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </template>
            <div class="row_box two_item">
              <div>
                <div class="data_key">상세 코드명</div>
                <div class="data_val" :class="{ failure: isEmptyDetailCodeNm }">
                  <input
                    type="text"
                    name="detailCodeNm"
                    ref="detailCodeNm"
                    maxlength="50"
                    v-model="detailCodeNm"
                    placeholder="입력해주세요."
                  />
                  <div class="text_message" v-if="isEmptyDetailCodeNm">
                    상세 코드명을 입력해 주세요.
                  </div>
                </div>
              </div>
              <div>
                <div class="data_key">상세 코드 영어명</div>
                <div
                  class="data_val"
                  :class="{ failure: isEmptyDetailCodeENm }"
                >
                  <input
                    type="text"
                    name="detailCodeENm"
                    ref="detailCodeENm"
                    maxlength="50"
                    v-model="detailCodeENm"
                    placeholder="입력해주세요."
                  />
                  <div class="text_message" v-if="isEmptyDetailCodeENm">
                    상세 코드 영어명을 입력해 주세요.
                  </div>
                </div>
              </div>
            </div>
            <div class="row_box two_item">
              <div>
                <div class="data_key">상세 코드 설명</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="detailCodeDesc"
                    ref="detailCodeDesc"
                    maxlength="50"
                    v-model="detailCodeDesc"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
              <div>
                <div class="data_key">사용 여부</div>
                <div class="data_val">
                  <select
                    name="detailCodeUseYn"
                    ref="detailCodeUseYn"
                    :value="detailCodeUseYn"
                    @change="chgDetailCodeUseYn($event)"
                  >
                    <option value="Y">Y</option>
                    <option value="N" selected>N</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc1</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="detailCodeEtc1"
                    ref="detailCodeEtc1"
                    maxlength="200"
                    v-model="detailCodeEtc1"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc2</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="detailCodeEtc2"
                    ref="detailCodeEtc2"
                    maxlength="200"
                    v-model="detailCodeEtc2"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div class="row_box">
              <div>
                <div class="data_key">etc3</div>
                <div class="data_val">
                  <input
                    type="text"
                    name="detailCodeEtc3"
                    ref="detailCodeEtc3"
                    maxlength="200"
                    v-model="detailCodeEtc3"
                    placeholder="입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div class="save_box">
              <button type="button" @click="closeModal">취소</button
              ><button
                type="button"
                class="mcbtn"
                @click="clickRegist('detail')"
              >
                저장
              </button>
            </div>
          </div>

          <button
            type="button"
            class="btn_close"
            @click="closeModal()"
          ></button>
        </div>
      </div>
    </div>
    <!-- 삭제 Modal -->
    <!-- <div id="modalWrap" ref="modalWrap_03" style="display: none">
      <div
        class="radiusbox modal_contents message_alert message_modal"
        ref="modalAlert_01"
      >
        <div class="message_box">해당 대분류 코드를 삭제 하시겠습니까?</div>
        <button type="button" class="btn_check mcbtn" @click="deleteCode()">
          확인
        </button>
        <button type="button" class="btn_close" @click="closeModal()"></button>
      </div>
    </div> -->

    <!-- 삭제 Modal -->
    <!-- <div id="modalWrap" ref="modalWrap_04" style="display: none">
      <div
        class="radiusbox modal_contents message_alert message_modal"
        ref="modalAlert_02"
      >
        <div class="message_box">해당 상세코드를 삭제 하시겠습니까?</div>
        <button
          type="button"
          class="btn_check mcbtn"
          @click="deleteDetailCode()"
        >
          확인
        </button>
        <button type="button" class="btn_close" @click="closeModal()"></button>
      </div>
    </div> -->
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import "@/assets/js/filter";
import common from "@/assets/js/common";

export default {
  name: "codeManage",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
  },
  data() {
    return {
      //sampleJsonData: sampleJsonData,

      filterList: [],
      filterRowData: [],

      pCode: "",
      code: "",
      codeNm: "",
      codeENm: "",
      clCode: "",
      codeDesc: "",
      codeUseYn: "N",
      created: "",
      codeEtc1: "",
      codeEtc2: "",
      codeEtc3: "",

      codeSort: "",
      codeNmSort: "",
      codeENmSort: "",
      codeDescSort: "",
      codeUseYnSort: "",

      codeDetailSort: "",
      codeDetailNmSort: "",
      codeDetailENmSort: "",
      codeDetailDescSort: "",
      codeDetailUseYnSort: "",

      codeFormData: {},
      codeList: [],
      codeDetailList: [],

      //orgCodeList: [],

      detailCode: "",
      detailCodeNm: "",
      detailCodeENm: "",
      detailCodeDesc: "",
      detailCodeUseYn: "N",
      detailCodeCreated: "",

      detailCodeEtc1: "",
      detailCodeEtc2: "",
      detailCodeEtc3: "",

      isEmptyCode: false,
      isEmptyCodeNm: false,
      isEmptyCodeENm: false,
      isEmptyDetailCode: false,
      isEmptyDetailCodeNm: false,
      isEmptyDetailCodeENm: false,

      codeDetailBtn: false,

      // 검색
      codeSearhNm: "",
      codeSearchUseYn: "",
      isDuplicateCode: false,
      duplicateText: "",
    };
  },
  created() {},
  mounted() {
    this.setRole();
    this.getCodeList();
  },
  computed: {},
  methods: {
    // 정렬
    sort(column) {
      // 대분류 코드
      if (column == "code") {
        if (this.codeSort == "" || this.codeSort == "asc") {
          this.codeSort = "desc";

          this.codeList.sort(function (a, b) {
            return b.code.localeCompare(a.code);
          });
        } else if (this.codeSort == "desc") {
          this.codeSort = "asc";
          this.codeList.sort(function (a, b) {
            return a.code.localeCompare(b.code);
          });
        }
        // 대분류 코드명
      } else if (column == "codeNm") {
        if (this.codeNmSort == "" || this.codeNmSort == "asc") {
          this.codeNmSort = "desc";

          this.codeList.sort(function (a, b) {
            return b.codeNm.localeCompare(a.codeNm);
          });
        } else if (this.codeNmSort == "desc") {
          this.codeNmSort = "asc";
          this.codeList.sort(function (a, b) {
            return a.codeNm.localeCompare(b.codeNm);
          });
        }
        // 대분류 코드 영어명
      } else if (column == "codeENm") {
        if (this.codeENmSort == "" || this.codeENmSort == "asc") {
          this.codeENmSort = "desc";

          this.codeList.sort(function (a, b) {
            return b.codeENm.localeCompare(a.codeENm);
          });
        } else if (this.codeENmSort == "desc") {
          this.codeENmSort = "asc";
          this.codeList.sort(function (a, b) {
            return a.codeENm.localeCompare(b.codeENm);
          });
        }
        // 대분류 코드 설명
      } else if (column == "codeDesc") {
        if (this.codeDescSort == "" || this.codeDescSort == "asc") {
          this.codeDescSort = "desc";

          this.codeList.sort(function (a, b) {
            return a.codeDesc.localeCompare(b.codeDesc);
          });
        } else if (this.codeDescSort == "desc") {
          this.codeDescSort = "asc";
          this.codeList.sort(function (a, b) {
            return b.codeDesc.localeCompare(a.codeDesc);
          });
        }
      } else if (column == "codeUseYn") {
        if (this.codeUseYnSort == "" || this.codeUseYnSort == "asc") {
          this.codeUseYnSort = "desc";
          this.codeList.sort(function (a, b) {
            return a.useYn.localeCompare(b.useYn);
          });
        } else if (this.codeUseYnSort == "desc") {
          this.codeUseYnSort = "asc";

          this.codeList.sort(function (a, b) {
            return b.useYn.localeCompare(a.useYn);
          });
        }
      } else if (column == "codeDetail") {
        if (this.codeDetailSort == "" || this.codeDetailSort == "asc") {
          this.codeDetailSort = "desc";

          this.codeDetailList.sort(function (a, b) {
            return b.dtlCode.localeCompare(a.dtlCode);
          });
        } else if (this.codeDetailSort == "desc") {
          this.codeDetailSort = "asc";
          this.codeDetailList.sort(function (a, b) {
            return a.dtlCode.localeCompare(b.dtlCode);
          });
        }
        // 상세 코드명
      } else if (column == "codeDetailNm") {
        if (this.codeDetailNmSort == "" || this.codeDetailNmSort == "asc") {
          this.codeDetailNmSort = "desc";

          this.codeDetailList.sort(function (a, b) {
            return b.dtlNm.localeCompare(a.dtlNm);
          });
        } else if (this.codeDetailNmSort == "desc") {
          this.codeDetailNmSort = "asc";
          this.codeDetailList.sort(function (a, b) {
            return a.dtlNm.localeCompare(b.dtlNm);
          });
        }
        // 상세 코드 영어명
      } else if (column == "codeDetailENm") {
        if (this.codeDetailENmSort == "" || this.codeDetailENmSort == "asc") {
          this.codeDetailENmSort = "desc";

          this.codeDetailList.sort(function (a, b) {
            return b.dtlENm.localeCompare(a.dtlENm);
          });
        } else if (this.codeDetailENmSort == "desc") {
          this.codeDetailENmSort = "asc";
          this.codeDetailList.sort(function (a, b) {
            return a.dtlENm.localeCompare(b.dtlENm);
          });
        }
        // 상세 코드 설명
      } else if (column == "codeDetailDesc") {
        if (this.codeDetailDescSort == "" || this.codeDetailDescSort == "asc") {
          this.codeDetailDescSort = "desc";

          this.codeDetailList.sort(function (a, b) {
            return b.dtlDesc.localeCompare(a.dtlDesc);
          });
        } else if (this.codeDetailDescSort == "desc") {
          this.codeDetailDescSort = "asc";
          this.codeDetailList.sort(function (a, b) {
            return a.dtlDesc.localeCompare(b.dtlDesc);
          });
        }
      } else if (column == "codeDetailUseYn") {
        if (
          this.codeDetailUseYnSort == "" ||
          this.codeDetailUseYnSort == "asc"
        ) {
          this.codeDetailUseYnSort = "desc";

          this.codeDetailList.sort(function (a, b) {
            return b.useYn.localeCompare(a.useYn);
          });
        } else if (this.codeDetailUseYnSort == "desc") {
          this.codeDetailUseYnSort = "asc";
          this.codeDetailList.sort(function (a, b) {
            return a.useYn.localeCompare(b.useYn);
          });
        }
      }
    },

    // 조회
    doSearch() {
      let codeSearhNm = this.codeSearhNm;
      let codeSearchUseYn = this.codeSearchUseYn;

      if (
        (codeSearhNm && codeSearhNm != null && codeSearhNm != "") ||
        (codeSearchUseYn && codeSearchUseYn != null && codeSearchUseYn != "")
      ) {
        const searchList = [];
        this.codeList = this.orgCodeList;
        this.codeList.forEach(function (item) {
          if (
            codeSearhNm &&
            item.code.includes(codeSearhNm) &&
            codeSearchUseYn &&
            item.useYn == codeSearchUseYn
          ) {
            console.log(item);
            searchList.push(item);
          } else if (
            codeSearhNm &&
            !codeSearchUseYn &&
            item.code.includes(codeSearhNm)
          ) {
            searchList.push(item);
          } else if (
            !codeSearhNm &&
            codeSearchUseYn &&
            item.useYn == codeSearchUseYn
          ) {
            searchList.push(item);
          }
        });

        this.codeList = searchList;
        console.log(this.codeList);
        this.$refs.btnReset.style.display = "inline-block";
      } else {
        this.codeList = this.orgCodeList;
        this.$refs.btnReset.style.display = "none";
      }
    },
    // 초기화
    doReset() {
      this.codeSearhNm = "";
      this.codeSearchUseYn = "";
      this.$refs.btnReset.style.display = "none";
      this.codeList = this.orgCodeList;
    },
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },

    async getCodeList() {
      try {
        let url = "/cmm-code/cmmcode";
        const response = await common.apiGet(url);
        console.log(response.data.data);
        this.codeList = response.data.data;
        this.orgCodeList = this.codeList;
      } catch (error) {
        console.error("error", error);
      }
    },
    chgCodeUseYn(e) {
      this.codeUseYn = e.target.value;
    },
    chgDetailCodeUseYn(e) {
      this.detailCodeUseYn = e.target.value;
    },
    async codeDetailActive(e, code, isPCode) {
      this.pCode = code;
      this.codeDetailBtn = true;
      document.querySelector(".m_pointer.active")?.classList.remove("active");

      if (e.target.tagName == "TD") {
        e.target.parentElement.classList.add("active");
      } else if (e.target.tagName == "BUTTON") {
        e.target.parentElement.parentElement.classList.add("active");
      }
      if (isPCode == true) this.getDetailCodeList(code);
    },
    async getDetailCodeList(code) {
      try {
        let url = "/cmm-code/dtlcode";
        let param = {
          code: code,
        };
        const response = await common.apiGet(url, param);

        this.codeDetailList = response.data.data;
      } catch (error) {
        console.error("error", error);
      }
    },
    async modifyCode(code) {
      if (code) {
        try {
          let url = "/cmm-code/cmmcode/" + code;

          const response = await common.apiGet(url);
          const data = response.data.data;

          this.code = data.code;
          this.codeNm = data.codeNm;
          this.codeENm = data.codeENm;
          this.codeDesc = data.codeDesc;
          this.clCode = "";
          this.codeUseYn = data.useYn;
          this.created = data.created;
          this.codeEtc1 = data.etc1;
          this.codeEtc2 = data.etc2;
          this.codeEtc3 = data.etc3;
        } catch (error) {
          console.error("error", error);
        }
      }
      this.showModal("modify");
    },

    async modifyDetailCode(code, detailCode) {
      if (code && detailCode) {
        try {
          let url = "/cmm-code/dtlcode/" + detailCode;
          let param = {
            code: code,
          };

          const response = await common.apiGet(url, param);
          const data = response.data.data;
          this.detailCode = data.dtlCode;
          this.detailCodeNm = data.dtlNm;
          this.detailCodeENm = data.dtlENm;
          this.detailCodeDesc = data.dtlDesc;
          this.detailCodeCreated = data.created;
          this.detailCodeUseYn = data.useYn;

          this.detailCodeEtc1 = data.etc1;
          this.detailCodeEtc2 = data.etc2;
          this.detailCodeEtc3 = data.etc3;
          console.log(data);
        } catch (error) {
          console.error("error", error);
        }
      }
      this.showModal("modifyDetail");
    },
    // 코드 대분류 등록 / 수정
    async clickRegist(kind) {
      if (kind == "code") {
        if (!this.code || this.code == null || this.code == "") {
          this.isEmptyCode = true;
          return false;
        } else {
          this.isEmptyCode = false;
        }

        if (!this.codeNm || this.codeNm == null || this.codeNm == "") {
          this.isEmptyCodeNm = true;
          return false;
        } else {
          this.isEmptyCodeNm = false;
        }

        if (!this.codeENm || this.codeENm == null || this.codeENm == "") {
          this.isEmptyCodeENm = true;
          return false;
        } else {
          this.isEmptyCodeENm = false;
        }

        this.codeFormData = {
          code: this.code,
          codeNm: this.codeNm,
          codeENm: this.codeENm,
          codeDesc: this.codeDesc,
          clCode: "",
          useYn: this.codeUseYn,
          etc1: this.codeEtc1,
          etc2: this.codeEtc2,
          etc3: this.codeEtc3,
        };

        try {
          let response;
          if (this.created && this.created != null && this.created != "") {
            response = await common.apiPatch(
              "/cmm-code/cmmcode/" + this.codeFormData.code,
              this.codeFormData
            );
          } else {
            response = await common.apiPost(
              "/cmm-code/cmmcode",
              this.codeFormData
            );
          }

          if (response.status === 200 || response.status === "200") {
            this.closeModal();
            this.getCodeList();
            this.created = "";
            this.isDuplicateCode = false;
          }
        } catch (error) {
          console.error("error", error);
          // 중복된 코드 유효성 검사
          this.isDuplicateCode = true;
          this.duplicateText = error.response.data.message;
        }
      } else if (kind == "detail") {
        if (
          !this.detailCode ||
          this.detailCode == null ||
          this.detailCode == ""
        ) {
          this.isEmptyDetailCode = true;
          return false;
        } else {
          this.isEmptyDetailCode = false;
        }

        if (
          !this.detailCodeNm ||
          this.detailCodeNm == null ||
          this.detailCodeNm == ""
        ) {
          this.isEmptyDetailCodeNm = true;
          return false;
        } else {
          this.isEmptyDetailCodeNm = false;
        }

        if (
          !this.detailCodeENm ||
          this.detailCodeENm == null ||
          this.detailCodeENm == ""
        ) {
          this.isEmptyDetailCodeENm = true;
          return false;
        } else {
          this.isEmptyDetailCodeENm = false;
        }

        this.codeFormData = {
          code: this.pCode,
          dtlCode: this.detailCode,
          dtlNm: this.detailCodeNm,
          dtlENm: this.detailCodeENm,
          dtlDesc: this.detailCodeDesc,
          useYn: this.detailCodeUseYn,
          etc1: this.detailCodeEtc1,
          etc2: this.detailCodeEtc2,
          etc3: this.detailCodeEtc3,
        };
        try {
          let response;
          if (
            this.detailCodeCreated &&
            this.detailCodeCreated != null &&
            this.detailCodeCreated != ""
          ) {
            response = await common.apiPatch(
              "/cmm-code/dtlcode/" + this.codeFormData.dtlCode,
              this.codeFormData
            );
          } else {
            response = await common.apiPost(
              "/cmm-code/dtlcode",
              this.codeFormData
            );
          }

          if (response.status === 200 || response.status === "200") {
            this.closeModal();
            this.getDetailCodeList(this.pCode);
            this.detailCodeCreated = "";
          }
        } catch (error) {
          console.error("error", error);
        }
      }
    },

    async deleteCode() {
      const response = await common.apiDelete("/cmm-code/cmmcode/" + this.code);
      if (response.status === 200 || response.status === "200") {
        this.closeModal();
        this.getCodeList();
        this.code = "";
      }
    },
    async deleteDetailCode() {
      const response = await common.apiDelete(
        "/cmm-code/dtlcode/" + this.detailCode
      );
      if (response.status === 200 || response.status === "200") {
        this.closeModal();
        this.getDetailCodeList(this.pCode);
      }
    },

    showModal(kind, code) {
      console.log("open modal");
      let $modal = null;
      if (kind == "detail" || kind == "modifyDetail") {
        $modal = this.$refs.modalWrap_02;
        /*
        else if (kind == "deleteCode") {
          this.code = code;
        $modal = this.$refs.modalWrap_03;
        this.$refs.modalAlert_01.style.display = "block";
        }

          */
      } else if (kind == "deleteDetailCode") {
        this.detailCode = code;
        $modal = this.$refs.modalWrap_04;
        this.$refs.modalAlert_02.style.display = "block";
      } else {
        if (kind && kind != "modify") {
          this.codeUseYn = "N";
          this.created = "";
        }

        $modal = this.$refs.modalWrap_01;
      }

      $modal.style.display = "block";
      //this.defaultRpa();
    },
    closeModal() {
      this.$refs.modalWrap_01.style.display = "none";
      this.$refs.modalWrap_02.style.display = "none";
      //this.$refs.modalWrap_03.style.display = "none";
      //this.$refs.modalWrap_04.style.display = "none";
      //this.$refs.modalAlert_01.style.display = "none";
      //this.$refs.modalAlert_02.style.display = "none";
      this.code = "";
      this.codeNm = "";
      this.codeENm = "";
      this.clCode = "";
      this.codeDesc = "";
      this.codeUseYn = "N";
      this.created = "";
      this.codeEtc1 = "";
      this.codeEtc2 = "";
      this.codeEtc3 = "";

      this.detailCode = "";
      this.detailCodeNm = "";
      this.detailCodeENm = "";
      this.detailCodeDesc = "";
      this.detailCodeUseYn = "N";
      this.detailCodeCreated = "";
      this.detailCodeEtc1 = "";
      this.detailCodeEtc2 = "";
      this.detailCodeEtc3 = "";
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  display: flex;
  gap: 20px;
  margin-top: 110px;
  .table_list_wrap {
    width: 50%;
    flex: 1 1 50%;
  }
  position: relative;
  .table_list {
    height: 600px;
  }
  .list_filter_wrap {
    position: absolute;
    top: -90px;
  }
  .mando_table_wrap {
    overflow: scroll !important;
    height: calc(100% - 80px) !important;
    .mando_table {
      position: relative;
      thead {
        th {
          padding-left: 10px;
          padding-right: 10px;
          .col_name {
            min-width: 80px;
          }
        }
      }
      tbody {
        tr {
          &:last-child {
            border-bottom: 1px solid #e5e9ef;
          }
          th {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      .active {
        background-color: #edfbff;
      }
      .m_pointer {
        td {
          padding: 5px 10px;
          height: 42px;
        }
      }
    }
  }
  .code_search_table {
    tr {
      &:hover {
        background: none;
      }
      th,
      td {
        vertical-align: middle;
        input {
          width: 100%;
          height: 100%;
          line-height: 45px;
          box-sizing: border-box;
          border: 1px solid #d5dae3;
          border-radius: 10px;
          background-color: #fff;
          font-size: 15px;
          padding: 10px 15px;
        }
        select {
          width: 100%;
          height: 45px;
          background-position: 96%;
        }
      }
      td {
        &:last-child {
          padding: 0;
        }
      }
    }
  }
}

#contents .table_list .title_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 25px;
  border-bottom: 1px solid #e5e9ef;
  .title_btn {
    position: static;
  }
}
.modalWrap {
  .float_box {
    height: auto;
  }
  .modal_contents {
    width: 100%;
    max-width: 1200px;
    padding: 25px;
    box-sizing: border-box;
    #recentFilter {
      width: 100%;
      min-height: 130px;
      border: 1px solid $line01Color;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 15px 20px;
      .date_filter {
        padding-left: 20px;
      }
      .filter_list_box {
        width: 100%;
        height: 100%;
        position: relative;
        .float_box {
          width: 100%;
          box-sizing: border-box;
          position: relative;
          top: -10px;
          li {
            display: inline-block;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            border-radius: 10px;
            padding: 10px 30px 10px 15px;
            position: relative;
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
              margin-right: 0;
            }
            span {
              &:last-child {
                color: #4791ff;
              }
            }
          }
          .btn_close_item {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 9px;
            right: 4px;
            &::before {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(45deg);
            }
            &::after {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .btn_all_filter {
        height: 45px;
        padding: 0 20px 0 10px;
        font-size: $fontSub;
        color: $placeHolderColor;
        position: absolute;
        top: 55px;
        right: 0;
        text-align: left;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url($baseURL + "/common/selectdropdown_small.svg");
        display: none;
        &.active {
          background-image: url($baseURL + "/common/selectdropup_small.svg");
        }
      }
    }
    .list_wrap {
      width: 100%;
      height: 570px;

      //스크롤 기능 추가
      // overflow: auto;
      .table_list {
        box-shadow: none;
        border: 1px solid $line01Color;
        .total_num {
          box-sizing: border-box;
          padding: 20px;
          padding-bottom: 0;
          font-size: $fontSub;
          span {
            color: $mainColor;
            font-weight: $bold;
            font-size: $fontSub;
          }
        }
      }
      .mando_table_wrap {
        .mando_table {
          position: relative;
        }
      }
    }
  }
}
.btn_code {
  padding: 13px 15px;
  background-color: #00b4ed;
  color: #fff;
  border-radius: 10px;
  &.btn_color_red {
    background-color: #dc7171;
  }
  &.btn_reset {
    position: absolute;
    margin-left: 10px;
  }
}
</style>
