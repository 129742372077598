<template>
<div class="table_wrap">
           <select style="width: 200px;background-position: 178px center;" name="prjType" ref="prjType">
               <option value="">{{labelText}}</option>
           </select>
</div>
</template>

<script>
export default {
     props: {
      interface: {
        type: Object,
        required: true,
      },
      otherProp: {
        type: Object,
        required: true,
      },
      readOnlyProp:{
        type: Boolean,
        require:true,
      },
      handlerList:{
        type:Object,
        require:true
      },
      componentIndex:{
        type:Number
      },
      
    },
    data() {
        return {
            labelText:'',
            handlerKey :'',
            color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
            picStartDate: null,
            picEndDate: null,
            start_cal_key:0,
            end_cal_key:0,
            picStartDateCalendar: null,
            picEndDateCalendar: null,
            picColor: {
                startStatus: false,
                endStatus: false,
                active: "#00B4ED",
                default: "#020A0A",
            },
            onlyRead : false,
            remove : false,
            DataMapCateGory:'Data',
            parentHandlerKey : '',
            parameter:''
        };
    },
    mounted(){
        const vm = this;
        const prop = this.otherProp;
        vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
        vm.handlerKey = prop.handlerKey; //handlerKey
        if(vm.parentHandlerKey!==undefined){
          vm.parentHandlerKey = prop.parentHandlerKey; // 부모키 
        }
        vm.labelText = prop.labelText;
        vm.parameter = prop.parameter

  
    },
    methods:{
    },
    watch:{
    }
}
</script>

<style scoped lang="scss">
        .table_wrap{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
                justify-content: center;
    align-items: center;
    text-align: center;
            .title_box{
                height: 45px;
                position: relative;    
                margin-bottom: 20px;
                .search_from{
                    position: absolute !important;
                    padding-right: 40px !important;
                    .btn_search{
                        width: 40px;
                    }
                }
            }
        }
</style>


