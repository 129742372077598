<template>
  <div class="port_wrap">
    <div class="top_control">
      <select>
        <option>Template type</option>
        <option>Template type</option>
        <option>Template type</option>
        <option>Template type</option>
      </select>

      <select>
        <option>Layout 개인화 가능여부</option>
        <option>Layout 개인화 가능</option>
        <option>Layout 개인화 불가능</option>
      </select>
      <button @click="layout_change" id="layout_button">레이아웃 변경</button>
    </div>
    <div class="component_pannel">
      <button @click="start">Component1</button>
      <button>Component2</button>
      <button>Component3</button>
      <button>Component4</button>
    </div>
    <div class="rightCell">
      <div class="batch_close" style="display: none" @click="close">X</div>

      <div class="grid_box_wrap real">
        <div class="grid_box_wrap batch_grid" style="display: none">
          <div class="grid_cell x1 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y1" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y1" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y2" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y2" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y3" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y3" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y4" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y4" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y5" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y5" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y6" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y6" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y7" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y7" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y8" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y8" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y9" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y9" @mouseover="getInfo"></div>

          <div class="grid_cell x1 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x2 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x3 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x4 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x5 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x6 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x7 y10" @mouseover="getInfo"></div>
          <div class="grid_cell x8 y10" @mouseover="getInfo"></div>
          <div
            class="real_grid_cell"
            :style="{
              display: 'none',
              gridColumnStart: start_x,
              gridColumnEnd: end_x,
              gridRowStart: start_y,
              gridRowEnd: end_y,
            }"
          >
            <h4>
              컴포넌트2<button @click="removeElement">제거</button
              ><button @click="optionOpen">설정</button>
            </h4>
            <div class="moving_pannel">
              <div class="left_ar" @click="leftMove"></div>
              <div class="right_ar" @click="rightMove"></div>
              <div class="top_ar" @click="upMove"></div>
              <div class="down_ar" @click="downMove"></div>
            </div>
            <div class="right_wid_pannel">
              <div class="left_ar" @click="widDown"></div>
              <div class="right_ar" @click="widUp"></div>
            </div>
            <div class="bottom_hei_pannel">
              <div class="up_ar" @click="heiUp"></div>
              <div class="down_ar" @click="heiDown"></div>
            </div>
          </div>
        </div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>

        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div class="grid_cell"></div>
        <div
          class="real_grid_cell"
          style="display: block; grid-area: 3 / 3 / 6 / 7"
        >
          <h4>
            컴포넌트2<button @click="removeElement">제거</button
            ><button @click="optionOpen">설정</button>
          </h4>
          <div class="moving_pannel">
            <div class="left_ar" @click="leftMove"></div>
            <div class="right_ar" @click="rightMove"></div>
            <div class="top_ar" @click="upMove"></div>
            <div class="down_ar" @click="downMove"></div>
          </div>
          <div class="right_wid_pannel">
            <div class="left_ar" @click="widDown"></div>
            <div class="right_ar" @click="widUp"></div>
          </div>
          <div class="bottom_hei_pannel">
            <div class="up_ar" @click="heiUp"></div>
            <div class="down_ar" @click="heiDown"></div>
          </div>
        </div>
        <div
          class="real_grid_cell"
          style="display: block; grid-area: 2 / 1 / 4 / 3"
        >
          <h4>
            컴포넌트1<button @click="removeElement">제거</button
            ><button @click="optionOpen">설정</button>
          </h4>
          <div class="moving_pannel">
            <div class="left_ar" @click="leftMove"></div>
            <div class="right_ar" @click="rightMove"></div>
            <div class="top_ar" @click="upMove"></div>
            <div class="down_ar" @click="downMove"></div>
          </div>
          <div class="right_wid_pannel">
            <div class="left_ar" @click="widDown"></div>
            <div class="right_ar" @click="widUp"></div>
          </div>
          <div class="bottom_hei_pannel">
            <div class="up_ar" @click="heiUp"></div>
            <div class="down_ar" @click="heiDown"></div>
          </div>
        </div>

        <div
          class="real_grid_cell"
          id="imsi"
          style="display: none"
          v-for="(item, index) in divEle"
          :key="item.id"
        >
          <h4>
            {{ item.title }} {{ index
            }}<button @click="removeElement">제거</button
            ><button @click="optionOpen">설정</button>
          </h4>
          <div class="moving_pannel">
            <div class="left_ar" @click="leftMove"></div>
            <div class="right_ar" @click="rightMove"></div>
            <div class="top_ar" @click="upMove"></div>
            <div class="down_ar" @click="downMove"></div>
          </div>
          <div class="right_wid_pannel">
            <div class="left_ar" @click="widDown"></div>
            <div class="right_ar" @click="widUp"></div>
          </div>
          <div class="bottom_hei_pannel">
            <div class="up_ar" @click="heiUp"></div>
            <div class="down_ar" @click="heiDown"></div>
          </div>
        </div>
      </div>
      <div class="control_pannel">
        <button class="batch_comp" style="display: none" @click="comp">
          영역설정 완료
        </button>
      </div>
    </div>
  </div>
  <div class="setting_pop">
    <div class="setting_pop_cell">
      <div class="setting_pop_cell_x" @click="optionClose">X</div>
      <h4>컴포넌트 이름</h4>
      <div class="setting_pop_cell_contents_wrap">
        <table>
          <tbody>
            <tr>
              <th>컴포넌트 유형</th>
              <td>
                <select>
                  <option>Type</option>
                  <option>Type</option>
                  <option>Type</option>
                  <option>Type</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>컴포넌트 크기</th>
              <td></td>
            </tr>
            <tr>
              <th>컴포넌트 연계</th>
              <td>
                <select>
                  <option>NO</option>
                  <option>YES</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>제어 컴포넌트</th>
              <td>
                <div class="checks">
                  <input type="checkbox" id="ch1" /><label for="ch1"></label>
                  <span>컴포넌트1</span>
                </div>
                <div class="checks">
                  <input type="checkbox" id="ch2" /><label for="ch2"></label
                  ><span>컴포넌트2</span>
                </div>
                <div class="checks">
                  <input type="checkbox" id="ch3" /><label for="ch3"></label
                  ><span>컴포넌트3</span>
                </div>
                <div class="checks">
                  <input type="checkbox" id="ch4" /><label for="ch4"></label
                  ><span>컴포넌트4</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>호출 URL</th>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <th>Action Type</th>
              <td>
                <select>
                  <option>Action</option>
                  <option>Action</option>
                  <option>Action</option>
                  <option>Action</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>상세 메모</th>
              <td>
                <textarea></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="setting_pop_cell_bottom_button_box">
        <button>수정</button><button @click="optionClose">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PortletTest",
  components: {},
  computed: {},
  data() {
    return {
      before: "",
      currentClass: [],
      startPoint: [],
      start_x: "",
      start_y: "",
      end_x: "",
      end_y: "",
      divEle: [{ title: "컴포넌트 new" }],
    };
  },
  watch: {},
  mounted() {
    let isDrag = false;
    let isActive = false;
    let isLayout = false;
    let grid_boxes = document.querySelector(".batch_grid").children;

    if (isLayout === false) {
      if (grid_boxes.length > 0) {
        for (let i = 0; i < grid_boxes.length; i++) {
          grid_boxes[i].onmousedown = () => {
            isDrag = true;
            isActive = true;
            let got = grid_boxes[i].getAttribute("class");
            let got2 = got.substring(10);
            let got3 = got2.replace(" ", ",");
            let got4 = got3.replace("x", "");
            let got5 = got4.replace("y", "");
            let got6 = got5.split(",", 2);
            this.startPoint = [Number(got6[0]), Number(got6[1])];
          };
        }
      }
    }
    document.onmousemove = () => {
      if (isDrag) {
        let bg = document.querySelector(".batch_grid").children;
        for (let i = 0; i < bg.length; i++) {
          bg[i].classList.remove("marked");
        }
        let startX = this.startPoint[0];
        let startY = this.startPoint[1];
        let nowX = this.currentClass[0];
        let nowY = this.currentClass[1];
        let Xi;
        let Yi;
        Xi = nowX - startX;
        Yi = nowY - startY;
        for (let xi = startX; xi < startX + Xi + 1; xi++) {
          for (let yi = startY; yi < startY + Yi + 1; yi++) {
            document
              .querySelector(".batch_grid .grid_cell.x" + xi + ".y" + yi)
              .classList.add("marked");
          }
        }
      } else {
        return;
      }
    };

    document.onmouseup = () => {
      isDrag = false;
      if (isActive) {
        let startX = String(this.startPoint[0]);
        let startY = String(this.startPoint[1]);
        let nowX = String(this.currentClass[0] + 1);
        let nowY = String(this.currentClass[1] + 1);
        this.start_x = startX;
        this.end_x = nowX;
        this.start_y = startY;
        this.end_y = nowY;
        document.querySelector(".batch_comp").style.display = "inline-block";
        isActive = false;
        isLayout = true;
        isDrag = false;
      }
    };
  },
  methods: {
    start() {
      document.querySelector(".batch_grid").style.display = "grid";
      document.querySelector(".batch_close").style.display = "block";
      document
        .querySelector(".grid_box_wrap.real")
        .classList.remove("layout_mode");
      document.querySelector("#layout_button").style.display = "none";
    },
    close() {
      document.querySelector(".batch_grid").style.display = "none";
      document.querySelector(".batch_close").style.display = "none";
      document.querySelector("#layout_button").style.display = "inline-block";
    },
    getInfo(e) {
      let got = e.target.getAttribute("class");
      let got2 = got.substring(10);
      let got3 = got2.replace(" ", ",");
      let got4 = got3.replace("x", "");
      let got5 = got4.replace("y", "");
      let got6 = got5.split(",", 2);
      this.currentClass = [Number(got6[0]), Number(got6[1])];
    },
    comp() {
      document.querySelector(".batch_grid .real_grid_cell").style.display =
        "block";
      let bg = document.querySelector(".batch_grid").children;
      for (let i = 0; i < bg.length; i++) {
        bg[i].classList.remove("marked");
      }
      let maded = document.querySelector(".batch_grid .real_grid_cell");
      let styling = maded.getAttribute("style");
      let toclone = document.querySelector("#imsi");
      //let clone = toclone.cloneNode(true);
      toclone.style.cssText = styling;
      toclone.setAttribute("id", "");
      document.querySelector(".grid_box_wrap.real").append(toclone);

      document.querySelector(".grid_box_wrap.batch_grid").style.display =
        "none";
      document.querySelector(".batch_close").style.display = "none";
      document.querySelector(".batch_comp").style.display = "none";
      document.querySelector("#layout_button").style.display = "block";
      document.querySelector(".batch_grid .real_grid_cell").style.display =
        "none";
      this.divEle.push({ title: "컴포넌트 new" });
      console.log(this.divEle);
    },
    layout_change() {
      if (
        document
          .querySelector(".grid_box_wrap.real")
          .classList.contains("layout_mode")
      ) {
        document
          .querySelector(".grid_box_wrap.real")
          .classList.remove("layout_mode");
      } else {
        document
          .querySelector(".grid_box_wrap.real")
          .classList.add("layout_mode");
      }
    },
    downMove(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");

      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 0) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
        if (ii === 2) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },

    upMove(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 0) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
        if (ii === 2) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },

    rightMove(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 1) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
        if (ii === 3) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },

    leftMove(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 1) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
        if (ii === 3) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },
    widUp(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 3) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },

    widDown(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 3) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },
    heiUp(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 2) {
          grid_area_array_number[ii] = grid_area_array_number[ii] - 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },

    heiDown(e) {
      let dom = e.target.parentNode.parentNode;
      let grid_area = dom.style.gridArea;
      let grid_area_array = grid_area.split("/");
      console.log(grid_area);
      let grid_area_array_number = [];
      for (let i = 0; i < grid_area_array.length; i++) {
        grid_area_array_number.push(Number(grid_area_array[i]));
      }
      console.log(grid_area_array_number);
      for (let ii = 0; ii < grid_area_array_number.length; ii++) {
        if (ii === 2) {
          grid_area_array_number[ii] = grid_area_array_number[ii] + 1;
        }
      }
      console.log(grid_area_array_number);
      let new_grid_area;
      for (let iii = 0; iii < grid_area_array_number.length; iii++) {
        if (iii === 0) {
          new_grid_area = String(grid_area_array_number[iii]) + "/";
        } else if (iii === 3) {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]);
        } else {
          new_grid_area =
            String(new_grid_area) + String(grid_area_array_number[iii]) + "/";
        }
      }
      console.log(new_grid_area);
      e.target.parentNode.parentNode.style.gridArea = new_grid_area;
    },
    removeElement(e) {
      e.target.parentNode.parentNode.remove();
    },
    optionOpen() {
      document.querySelector(".setting_pop").style.display = "block";
    },
    optionClose() {
      document.querySelector(".setting_pop").style.display = "none";
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.port_wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #252f80;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #252f80;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: #3e4ec6;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #252f80;
}

.control_pannel button {
  border: none;
  background: #3e4ec6;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #252f80;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #252f80;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #252f80;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: #3e4ec6;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #252f80;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
</style>
