<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_smartFactoryTaskList"
        :checkRole="manageRole"
      />
      <div class="list_wrap">
        <div
          id="listFilter"
          class="list_filter_wrap float_box"
          ref="listFilter"
        >
          <div class="filter_list_box" ref="filterListBox">
            <ul class="float_box" ref="filterBox">
              <li v-if="filterList.length === 0" style="padding-right: 20px">
                <span>필터</span> : <span>전체</span>
              </li>
              <li
                v-for="(item, index) in filterList"
                :key="index"
                ref="filterItem"
              >
                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                <button class="btn_close" @click="removeFilter(index)"></button>
              </li>
            </ul>
          </div>
          <button
            class="btn_all_filter"
            ref="filterAllBtn"
            @click="filterToggle"
          >
            전체보기
          </button>
        </div>
        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ dataList.length }}</span> Contents
            </div>
            <select
              name="a"
              style="width: 585px; background-position: 555px center"
              v-model="perPage"
            >
              <option value="0">프로젝트를 선택해 주세요.</option>
              <option
                v-for="perPage in perPageList"
                :key="perPage.id"
                :value="perPage.id"
              >
                {{ perPage.value }}
              </option>
            </select>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <!-- <colgroup>
                                <col style="width: 60px;">
                            </colgroup> -->
              <thead>
                <tr>
                  <th v-for="(item, index) in columnDefs" :key="index">
                    <div class="col_name">
                      {{ item.field }}
                      <button class="btn_filter" @click="showFilter"></button>
                    </div>
                  </th>
                </tr>
                <tr id="filterWrap" ref="filterInputWrap">
                  <th
                    class="filter_input"
                    v-for="(item, index) in columnId"
                    :key="index"
                  >
                    <div class="input_box" :data-id="item.id">
                      <input
                        type="text"
                        @focus="setActive"
                        @keydown.enter="addFilter"
                      />
                      <button
                        class="btn_add"
                        ref="btnAddfilter"
                        @click="addFilter"
                        :data-index="index"
                      ></button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="m_pointer"
                  v-for="(item, index) in dataListSliced"
                  :key="index"
                  @click="goPage(item)"
                >
                  <td>{{ item.project_name }}</td>
                  <td>{{ item.task_name }}</td>
                  <td>{{ item.task_type }}</td>
                  <td>{{ item.create_name }}</td>
                  <td>{{ item.team_name }}</td>
                  <td>{{ item.last_visit }}</td>
                  <td>{{ item.create_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

import "@/assets/js/filter";
import gsap from "gsap";
import common from "@/assets/js/common";
export default {
  name: "SmartFactoryTaskList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    PaginationUi,
    BreadcrumbLayout,
  },
  computed: {},
  data() {
    const filterList = [];
    const filterRowData = [];

    return {
      filterList,
      filterRowData,

      perPageList: [],
      perPage: 0,

      totalItems: 100,
      itemsPerPage: 10,
      currentPage: 1,

      dataList: [],
      dataListSliced: [],

      columnDefs: [
        { field: "Project Name" },
        { field: "Task Name" },
        { field: "Task Type" },
        { field: "Manager" },
        { field: "Team" },
        { field: "Last Visit" },
        { field: "Create Date" },
      ],
      columnId: [
        { id: "project_name" },
        { id: "task_name" },
        { id: "task_type" },
        { id: "create_name" },
        { id: "team_name" },
        { id: "last_visit" },
        { id: "create_date" },
        // { id: "project_id" },
        // { id: "monitoring_task_type_id" },
        // { id: "task_id" },
        // { id: "team_id" },
      ],
      isfilter: true,
      filterDataList: {
        origin: {},
      },
      showAddBtn: "none",

      checkRole: false,

      // role
      viewRole: false,
      manageRole: false,
    };
  },
  watch: {
    async perPage() {
      await this.setMonTaskList(this.perPage);
      // await this.checkAccessAddButton();
    },
  },

  async mounted() {
    // Role
    this.setRole();

    await this.setMonTaskList();
    // make selectbox
    await this.makePrjSelList();
    // await this.checkAccessAddButton();
  },
  methods: {
    async setRole() {
      this.manageRole = await common.getUserRole("type1");
      this.viewRole = await common.getUserRole("type1");

      if (!this.viewRole) {
        common.goHome();
      }
    },

    async setMonTaskList(projectId) {
      this.dataList = [];
      const dataList = await this.getMonTaskList(projectId);
      console.log("!!! :: ", dataList);

      if (
        typeof dataList !== "undefined" &&
        dataList !== null &&
        dataList !== ""
      ) {
        this.dataList = dataList;
      }

      this.currentPage = 1;
      this.totalItems = this.dataList.length;
      this.displayDataPerPage(this.dataList);
      this.filterListClear();
    },

    // make project select list
    async makePrjSelList() {
      const projectList = this.getProjectList();
      projectList.then((list) => {
        const o = [];
        list.forEach((e) => {
          o.push({ id: e.id, value: e.projectName });
        });
        this.perPageList = o;
      });
    },
    goPage(item) {
      const taskType = item.monitoring_task_type_id.toString();

      const query = {
        prjId: item.project_id,
        taskId: item.task_id,
        teamId: item.team_id,
        taskTypeId: item.monitoring_task_type_id,
      };

      switch (taskType) {
        // Remote Diagnostics
        case "1":
          this.$router.push({
            name: "dctDashboard",
            query,
          });
          break;
        // Anomaly Detection
        case "2":
          this.$router.push({
            name: "moaDetection",
            query,
          });
          break;
        // Vision Edge Inference Monitoring
        case "3":
          this.$router.push({
            name: "EdgeInfer",
            query,
          });
          break;
        // Vision Auto Labeling Monitoring
        case "4":
          this.$router.push({ name: "AutoLabe", query });
          break;
        // Vision AI/ML Statistics
        case "5":
          this.$router.push({ name: "VisionData", query });
          break;
        // Smart Mining Dashboard
        case "6":
          this.$router.push({ name: "SmartMiningDashboard", query });
          break;
        // Driving Data Management
        case "8":
          this.$router.push({ name: "DrivingDataManagement", query });
          break;
        // Driving Data Monitoring
        case "9":
          this.$router.push({ name: "DrivingDataMonitoring", query });
          break;
        // Multi-Agent Monitoring
        case "10":
          this.$router.push({ name: "MultiAgentMonitoring", query });
          break;
        // Single-Agent Monitoring
        case "11":
          this.$router.push({ name: "SingleAgentMonitoring", query });
          break;
        case "17":
          this.$router.push({ name: "LabelMonitoring", query });
          break;
        case "18":
          this.$router.push({ name: "RealtimeDirectrateMonitoring", query });
          break;
        case "19":
          this.$router.push({ name: "ModelPerformanceMonitoring", query });
          break;
        case "20":
          this.$router.push({ name: "InferenceStatus", query });
          break;
        case "21":
          this.$router.push({ name: "SystemDataStatus", query });
          break;
      }
    },
    goAddPage() {
      if (
        this.perPage != null &&
        this.perPage != "" &&
        typeof this.perPage != "undefined"
      ) {
        const query = {
          prjId: this.perPage,
        };
        this.$router.push({ name: "AddTask", query });
      } else {
        this.$router.push({ name: "AddTask" });
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.displayDataPerPage(this.dataList);
    },
    filterToggle() {
      const $listFilter = this.$refs.listFilter;
      const $filterBox = this.$refs.filterListBox;
      const $filterBtn = this.$refs.filterAllBtn;
      const hasOpenClass = $filterBox.classList.contains("open");
      if (hasOpenClass) {
        gsap.to($listFilter, {
          onStart: () => {
            $filterBox.classList.remove("open");
            $filterBtn.classList.remove("active");
            $filterBtn.innerText = "전체보기";
          },
          height: "70px",
          duration: 0.3,
        });
      } else {
        gsap.to($listFilter, {
          onStart: () => {
            $filterBox.classList.add("open");
            $filterBtn.classList.add("active");
            $filterBtn.innerText = "접기";
          },
          height: "auto",
          duration: 0.3,
        });
      }
    },
    async addFilter(event) {
      // console.log("addFilter");

      const _this = event.target;
      const $thTag = _this.parentNode.parentNode;
      const $addBtn = $thTag.querySelector("button");
      const $input = $thTag.querySelector("input");
      // input 글자수
      const trimVal = $input.value.trim();

      // 컬럼 이름 가져오기
      const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode
        .querySelector("tr:first-child")
        .querySelectorAll(".btn_filter");
      let activeIndex = Array.from(keyNameArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyName = String(keyNameArr[activeIndex].parentNode.textContent);

      const dataKeyArr =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".input_box");
      const dataKeyindex = Array.from(dataKeyArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyId = String(dataKeyArr[dataKeyindex].getAttribute("data-id"));

      const obj = {};
      // console.log();
      // console.log(this.getAttribute('data-index'))
      // console.log($input);

      window.filterData.taget = _this.getAttribute("data-index");

      if (0 < trimVal.length) {
        obj["keyId"] = keyId;
        obj["value"] = trimVal;
        obj["keyName"] = keyName;
        $input.value = "";
        this.filterList.push(obj);
        this.$refs.filterAllBtn.style.display = "block";
        $addBtn.parentNode.classList.add("disable");
        $addBtn.previousElementSibling.disabled = true;
        this.filterAction();
      }
    },
    removeFilter(index) {
      // console.log(this.$refs.filterAllBtn.style);
      const $filterItem = this.$refs.filterItem;
      const $filterInputWrap = this.$refs.filterInputWrap;
      const dataId = this.filterList[index].keyId.trim();
      // console.log(dataId);
      // console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
      $filterInputWrap
        .querySelector(`[data-id='${dataId}']`)
        .classList.remove("disable");
      $filterInputWrap
        .querySelector(`[data-id='${dataId}']`)
        .querySelector("input").disabled = false;
      this.filterList.splice(index, 1);
      if ($filterItem.length <= 1) {
        this.$refs.filterAllBtn.style.display = "none";
      }
      this.filterAction(true);
    },
    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },

    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.dataListSliced = rowData.slice(startIndex, endIndex);
    },
    filterAction(remove) {
      const vm = this;
      // 필터 리스트
      const _filterLsit = vm.filterList;
      // 테이블 리스트
      let _tableList = vm.dataList;
      if (vm.isfilter) {
        vm.filterDataList.origin = _tableList;
        vm.isfilter = false;
      }

      if (_filterLsit.length > 0) {
        const lodash = require("lodash");

        // 필터 삭제
        if (remove) {
          let _filterDataListOrign = [];
          vm.filterDataList.origin.forEach((item) => {
            _filterDataListOrign.push(item);
          });

          for (var j = 0; j < _filterLsit.length; j++) {
            const searchString = common.getUpper(_filterLsit[j].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[j].keyId;
            const filteredData = lodash.filter(_filterDataListOrign, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
            _filterDataListOrign = filteredData;
          }
          // 필터 추가
        } else {
          for (var i = 0; i < _filterLsit.length; i++) {
            const searchString = common.getUpper(_filterLsit[i].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[i].keyId;
            const filteredData = lodash.filter(_tableList, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
          }
        }

        // console.log("필터 동작 시작");
      } else {
        vm.dataList = vm.filterDataList.origin;
      }
      vm.currentPage = 1;
      vm.totalItems = vm.dataList.length;
      vm.displayDataPerPage(vm.dataList);
    },
    showFilter(event) {
      const _this = event.target;

      const $filterBtn =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
      const $filterWrap = document.querySelector("#filterWrap");
      const $filterInput = $filterWrap.querySelectorAll(".filter_input");

      const hasShowClass = $filterWrap.classList.contains("show");
      const hasActiveClass = _this.classList.contains("active");

      for (var i = 0; i < $filterBtn.length; i++) {
        $filterBtn[i].classList.remove("active");
      }
      _this.classList.add("active");
      if (hasShowClass) {
        if (hasActiveClass) {
          $filterWrap.classList.remove("show");
          _this.classList.remove("active");
        }
      } else {
        $filterWrap.classList.add("show");
      }
      let activeIndex = Array.from($filterBtn).findIndex((element) =>
        element.classList.contains("active")
      );
      // console.log(activeIndex);
      // input
      for (var j = 0; j < $filterInput.length; j++) {
        $filterInput[j].querySelector("div").classList.remove("active");
      }
      // console.log($filterInput[activeIndex]);
      if (activeIndex > -1) {
        $filterInput[activeIndex].querySelector("div").classList.add("active");
      }
    },
    filterListClear() {
      const $filterWrap = this.$refs.filterInputWrap;

      for (let i = 0; i < this.filterList.length; i++) {
        const dataId = this.filterList[i].keyId.trim();
        $filterWrap
          .querySelector(`[data-id='${dataId}']`)
          .querySelector("input").disabled = false;
        $filterWrap
          .querySelector(`[data-id='${dataId}']`)
          .classList.remove("disable");
        $filterWrap
          .querySelector(`[data-id='${dataId}']`)
          .classList.remove("active");
      }
      const filterCssList = document.querySelectorAll(".btn_filter");
      for (let j = 0; j < filterCssList.length; j++) {
        const filterCss = filterCssList[j];
        // console.log(filterCss);
        filterCss.classList.remove("active");
      }

      this.$refs.filterAllBtn.style.display = "none";

      this.filterList.splice(0, this.filterList.length);
    },

    async checkAccessAddButton() {
      const memberRole = await this.getMemberRole();

      if (memberRole.role == "SM") {
        console.log("block");
        this.showAddBtn = "block";
        this.checkRole = true;
      } else {
        console.log(this.perPage);
        if (this.perPage != 0) {
          const projectRole = await this.getProjectRole();
          console.log(projectRole.projectRole);
          if (
            projectRole.projectRole == "Manager" ||
            projectRole.projectRole == "Researcher"
          ) {
            console.log("block");
            this.showAddBtn = "block";
            this.checkRole = true;
          } else {
            console.log("none");
            this.showAddBtn = "none";
            this.checkRole = false;
          }
        } else {
          console.log("none");
          this.showAddBtn = "none";
          this.checkRole = false;
        }
      }
    },

    // ----- API
    async getProjectList() {
      try {
        const response = await common.apiGet("/project");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getMonTaskList(prjId) {
      let url = "/monitoring-task";
      if (prjId > 0) url = url + "/project/" + prjId;
      const param = {
        "project-type-id": 2,
      };
      try {
        const response = await common.apiGet(url, param);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.perPage}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  position: relative;
  .table_list {
    margin-top: 110px;
    height: 715px;
  }
  .list_filter_wrap {
    position: absolute;
    top: -90px;
  }
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
</style>
