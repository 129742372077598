<template>
  <div class="component_setting">
    <div class="setting">
      <div>
        <span>맵 타입 : </span>
        <span>
          <input type="radio" name="map" value="normal" v-model="mapType" />
          <input type="radio" name="map" value="satellite" v-model="mapType" />
          <input type="radio" name="map" value="both" v-model="mapType" />
        </span>
      </div>
    </div>
    <div class="map">
      <MapController
        :mapType="mapType"
        :latlngArray="latlngArray"
        :searchResult="searchResult"
      />
    </div>
  </div>
</template>
<script>
import componentTest from "@/assets/js/componentTest";
import MapController from "./MapController.vue";
export default {
  components: {
    MapController,
  },
  data() {
    return {
      gpsMapDataArray: null,
      mapType: "normal",
      latlngArray: null,
      searchResult: null,
    };
  },
  watch: {},
  async mounted() {
    await this.callApi();
  },
  methods: {
    async callApi() {
      try {
        const param = {
          edge_id: "1",
          date_from: "2024-09-26",
          date_to: "2024-09-28",
          limit: 15,
          offset: 0,
        };
        const response = await componentTest.postMap("/map2", param);
        const response1 = await componentTest.postMap("/map3", param);
        console.log("response1response1 :: ", response1.data);
        console.log("신규 API 맵 결과 :: ", response.data);
        let arr = response.data.items;

        let newArr = [];
        for (let idx of arr) {
          newArr.push({ position: [idx.x, idx.y], tags: [idx.tag] });
        }

        this.latlngArray = newArr;
        this.searchResult = response1.data;

        console.log("this.latlngArray :: ", this.latlngArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.component_setting {
  width: 100%;
  height: 100%;
  .setting {
    width: 100%;
    height: 100%;
  }
  .map {
    width: 100%;
    height: 100%;
  }
}
</style>
