<template>
  <div class="port_wrap">
    <div class="top_control">
      <select>
        <option>Template type</option>
        <option>Template type</option>
        <option>Template type</option>
        <option>Template type</option>
      </select>

      <select @change="(e) => (customYn = e.target.value)">
        <option :value="null">Layout 개인화 가능여부</option>
        <option :value="'Y'">Layout 개인화 가능</option>
        <option :value="'N'">Layout 개인화 불가능</option>
      </select>
      <button class="final_save" @click="finalSave">레이아웃 저장</button>
    </div>
    <div class="component_pannel">
      <button @click="() => addItem(Component1)">Filter</button>
      <button @click="() => addItem(Component2)">Button</button>
      <button @click="() => addItem(Component3)">Component3</button>
      <button @click="() => addItem(Component4)">Component4</button>
    </div>
    <div class="rightCell">
      <grid-layout
        v-model:layout.sync="layout"
        :col-num="8"
        :row-height="30"
        :is-draggable="draggable"
        :is-resizable="resizable"
        :vertical-compact="true"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="(item, index) in layout"
          :static="item.static"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="index"
        >
          <span class="text">{{ itemTitle(item) }}</span>
          <span class="remove" @click="removeItem(item.i)">x</span>
          <span class="setting_button" @click="settingOpen(item.i)">설정</span>
          <component
            :is="
              item.i === 'Filter'
                ? 'FilterComponent'
                : item.i === 'Button'
                ? 'ButtonComponent'
                : null
            "
            :interface="null"
          ></component>
        </grid-item>
      </grid-layout>
    </div>
  </div>
  <div class="setting_pop">
    <div class="setting_pop_cell">
      <div class="setting_pop_cell_x" @click="optionClose">X</div>
      <h4>{{ comp_name }}</h4>

      <component
        :is="
          this.settingRender === 'Filter'
            ? 'FilterComponentSetting'
            : this.settingRender === 'Button'
            ? 'ButtonComponentSetting'
            : null
        "
        :interface="null"
      ></component>

      <div class="setting_pop_cell_bottom_button_box">
        <button>수정</button><button @click="optionClose">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/assets/js/common";
import { GridLayout, GridItem } from "vue-grid-layout-v3";
import FilterComponent from "./testComponents/filter.vue";
import FilterComponentSetting from "./testComponents/filter_setting.vue";
import ButtonComponent from "./testComponents/button.vue";
import ButtonComponentSetting from "./testComponents/button_setting.vue";

export default {
  components: {
    GridLayout,
    GridItem,
    FilterComponent,
    FilterComponentSetting,
    ButtonComponent,
    ButtonComponentSetting,
  },
  data() {
    return {
      layout: [],
      draggable: true,
      resizable: true,
      index: 0,
      Component1: "Filter",
      Component2: "Button",
      Component3: "Component3",
      Component4: "Component4",
      comp_name: "",
      settingRender: "",
      baseUrl: "https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com",
      customYn: null,
      vessle: [],
    };
  },
  mounted() {
    this.getTemplate();
  },
  methods: {
    async getTemplate() {
      try {
        const response = await common.apiGet("/templet");
        console.log(response);
        let number = 0;
        console.log(JSON.parse(response.data.data[number].components));

        this.layout.push(...JSON.parse(response.data.data[number].components));
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async finalSave() {
      if (this.customYn === null) {
        alert("layout 개인화 여부를 선택해 주세요.");
      }
      let data = {
        customYn: this.customYn,
        components: JSON.stringify(this.layout),
      };
      console.log(data);
      try {
        const response = await common.apiPost("/templet", data);
        console.log(response);
        alert("저장되었습니다.");
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    itemTitle(item) {
      let result = item.i;
      if (item.static) {
        result += " - Static";
      }
      return result;
    },
    optionClose() {
      document.querySelector(".setting_pop").style.display = "none";
    },
    addItem: function (title) {
      if (this.layout.findIndex((e) => e.i === title) === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % 8,
          y: this.layout.length + 8,
          w: 4,
          h: 4,
          i: title,
        });
      } else {
        alert("이미 배치된 컴포넌트를 추가할 수 없습니다.");
      }
    },
    removeItem: function (val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },
    settingOpen(name) {
      this.comp_name = name;
      this.settingRender = name;
      document.querySelector(".setting_pop").style.display = "block";
    },
  },
};
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}
.port_wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #252f80;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #252f80;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: #3e4ec6;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #252f80;
}

.control_pannel button {
  border: none;
  background: #3e4ec6;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #252f80;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #252f80;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #252f80;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: #3e4ec6;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #252f80;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: #3e4ec6;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
  border: 1px solid #d4d4d4 !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
  padding-left: 12px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: #3e4ec6;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #252f80;
}
.vue-grid-item span.setting_button:hover {
  background: #252f80;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.final_save {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: #3e4ec6;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
}
</style>
