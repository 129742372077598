<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_apiDashboard" :checkRole="manageRole" />
      <div class="list_filter_wrap float_box">
        <div class="filter_list_box">
          <div class="code_search_table">
            <div>
              <select
                name="division"
                ref="division"
                :value="divisonId"
                @change="setDivisonId($event)"
              >
                <option value="" selected>Divison 선택</option>
                <option
                  v-for="(item, index) in divisionList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div>
              <select>
                <option value="">Team 선택</option>
                <option value="">1</option>
                <option value="">2</option>
              </select>
            </div>
            <div>
              <select
                name="prjId"
                ref="prjId"
                :value="prjId"
                @change="setPrjId($event)"
              >
                <option value="">Project 선택</option>
                <option
                  v-for="(item, index) in prjList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.projectName }}
                </option>
              </select>
            </div>

            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="search_from_box">
                <button
                  type="button"
                  class="btn_send mcbtn"
                  @click="clickSearch()"
                >
                  조회
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas ref="chartApiCall" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
        <div class="chart">
          <canvas ref="chartTopApi" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas ref="chartApiSuccess" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
        <div class="chart">
          <canvas ref="chartApiFail" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas ref="chartDivision" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
        <div class="chart">
          <canvas ref="chartTeam" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)"/>
        </div>
        <div class="chart">
          <canvas ref="chartProject" style="background-color: white; box-shadow:0px 0px 10px rgba(0, 43, 104, 0.2)" />
        </div>
      </div>

      <!-- <div style="background: aqua">
        <button type="button" @click="clickAction">버튼</button>
      </div> -->
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import { Chart, registerables } from "chart.js/auto";
Chart.register(...registerables);

export default {
  name: "apiDashboard",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    BreadcrumbLayout,
  },
  data() {
    return {
      divisonId: "",
      divisionList: [],
      prjId: "",
      prjList: [],
      chartApiCall: null,
      chartTopApi: null,
      chartApiSuccess: null,
      chartApiFail: null,
      chartDivision: null,
      chartTeam: null,
      chartProject: null,
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 7,
    };
  },
  created() {},
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  warch: {
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },

  async mounted() {
    //BreadcrumbLayout
    //this.setRole();
    this.divisionList = await this.getDivisionList();
    await this.setPrjSelect();
    console.log(this.divisionList);
    this.setDate(this.selectDuration);
    this.createChart();
    console.log(this.getDivTeamData());
  },

  methods: {
    setDivisonId(event) {
      this.divisonId = event.target.value;
      console.log(this.divisonId);
    },
    setPrjId(event) {
      this.prjId = event.target.value;
    },
    async setPrjSelect() {
      this.prjList = await this.getPrjListData();
    },
    // API
    async getPrjListData() {
      try {
        const response = await common.apiGet("/project");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDivTeamData() {
      try {
        const response = await common.apiGet(
          "/team/division-team?division_id=2"
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDivisionList() {
      try {
        const response = await common.apiGet("/division");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    createChart() {
      this.chartApiCall = new Chart(this.$refs.chartApiCall, {
        type: "line",
        data: {
          labels: [
            "2024-08-01",
            "2024-08-02",
            "2024-08-03",
            "2024-08-04",
            "2024-08-05",
          ],
          datasets: [
            {
              title: "총 호출 합계",
              label: "총 호출 합계",
              data: [12, 19, 3, 5, 2],
              fill: true,
              /*
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            */
              //borderColor: ["black"],
              //borderWidth: 1,
            },
            /*
          {
            label: "# of Votes",
            data: [3, 2, 5, 3, 19, 12],

            borderColor: ["red"],
            borderWidth: 3,
          },
          */
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "총 호출 합계",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartTopApi = new Chart(this.$refs.chartTopApi, {
        type: "bar",
        data: {
          labels: ["API-01", "API-02", "API-03", "API-04", "API-05"],
          datasets: [
            {
              label: "TOP API",
              data: [12, 19, 3, 5, 2],
              backgroundColor: this.color,
            },
          ],
        },
        options: {
          indexAxis: "y",
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "TOP API",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartApiSuccess = new Chart(this.$refs.chartApiSuccess, {
        type: "line",
        data: {
          labels: ["API-01", "API-02", "API-03", "API-04", "API-05"],
          datasets: [
            {
              label: "SUCCESS",
              data: [6, 2, 8, 3, 3],
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "SUCCESS",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartApiFail = new Chart(this.$refs.chartApiFail, {
        type: "line",
        data: {
          labels: ["API-01", "API-02", "API-03", "API-04", "API-05"],
          datasets: [
            {
              label: "FAIL",
              data: [4, 2, 1, 13, 10],
              borderColor: ["#ff6384"],
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "FAIL",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });

      this.chartDivision = new Chart(this.$refs.chartDivision, {
        type: "pie",
        data: {
          labels: [
            "Division-01",
            "Division-02",
            "Division-03",
            "Division-04",
            "Division-05",
          ],
          datasets: [
            {
              data: [12, 19, 3, 5, 2],
            },
          ],
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "DIVISION",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
      this.chartTeam = new Chart(this.$refs.chartTeam, {
        type: "pie",
        data: {
          labels: ["Team-01", "Team-02", "Team-03", "Team-04", "Team-05"],
          datasets: [
            {
              data: [33, 56, 32, 51, 27],
            },
          ],
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "TEAM",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
      this.chartProject = new Chart(this.$refs.chartProject, {
        type: "pie",
        data: {
          labels: [
            "Project-01",
            "Project-02",
            "Project-03",
            "Project-04",
            "Project-05",
          ],
          datasets: [
            {
              data: [40, 2, 21, 8, 3],
            },
          ],
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "PROJECT",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
    },
    // -------- DATE PICKER -----
    // -------- DATE PICKER -----
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    async getDriList() {
      const vm = this;
      // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
      // 켈린더 display처리
      const $calenderBox = vm.$refs.calenderBox;
      const $startDateBox = vm.$refs.startDateBox;
      const $endDateBox = vm.$refs.endDateBox;
      //const $drivReclistBox = vm.$refs.drivReclistBox;

      $calenderBox.style.display = "none";
      $startDateBox.style.display = "block";
      $endDateBox.style.display = "none";
      //$drivReclistBox.style.display = "block";

      vm.picColor.startStatus = false;
      vm.picColor.endStatus = false;

      console.log(vm.picStartDate);
      console.log(vm.picEndDate);
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },

    // ------------
    clickAction() {
      console.log(this.chartApiCall.data.datasets[0].data);

      //this.chart111.update();
      const newData = [3, 2, 9, 5, 2, 3];
      this.chartApiCall.data.datasets[0].data = [];
      /*
      chart.data.datasets.forEach((data) => {
        data.data.push(3);
      });
      */
      this.chartApiCall.data.datasets[0].data = newData;
      this.chartApiCall.update();
    },
  },
};
</script>
<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height: 40px;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    width: 100%;
    height: 100%;
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
    }
  }
}
</style>
