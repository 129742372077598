<template>
  <div class="map">
    <ul class="map_type" v-if="mapType === 'both'">
      <li>
        <button
          type="button"
          class="map_type_icon map_type_01_icon mcbtn"
          @click="chgMapType('noarmal')"
        >
          <font-awesome-icon :icon="['fas', 'map-location-dot']" />
        </button>
        <div class="map_type_txt map_type_01_txt">일반지도</div>
      </li>
      <li>
        <button
          type="button"
          class="map_type_icon map_type_02_icon mcbtn"
          @click="chgMapType('satellite')"
        >
          <font-awesome-icon :icon="['fas', 'globe']" />
        </button>
        <div class="map_type_txt map_type_02_txt">위성지도</div>
      </li>
    </ul>
    <div
      :id="otherProp != null ? 'map_' + otherProp.key : 'map'"
      class="map_inner"
      :style="{
        width: searchResult != null ? 'calc(100% - 150px)' : '100%',
        height: mapHeight > 0 ? mapHeight - 62 + 'px' : '100%',
      }"
    ></div>
    <div class="search" v-if="searchResult !== null" style="width: 150px">
      <h3>{{ searchResult.keyword }}</h3>
      <ul>
        <li>{{ searchResult.x }} / {{ searchResult.y }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import common from "@/assets/js/common";
import "leaflet-mouse-position";
let defaultIcon = new L.Icon({
  iconUrl: common.getDefaultMarker(),
  iconAnchor: [12, 41],
  shadowUrl: common.getDefaultMarkerShadow(),
});
export default {
  props: {
    resize: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Object,
    },
    mapType: {
      type: String,
      default: "normal",
    },
    gpsMapDataArray: {
      type: Array,
      default: null,
    },
    markerSpots: {
      type: Array,
      default: null,
    },
    latlngArray: {
      type: Array,
      default: null,
    },
    searchResult: {
      type: Object,
      default: null,
    },
    mapHeight: {
      type: Number,
      default: 0,
    },
    currentPose: {
      type: Object,
      default: null,
    },
    deviceIds: {
      type: Object,
      default: null,
    },
    pointList: {
      type: Array,
      default: null,
    },
    mapPage: {
      type: String,
      default: "",
    },
    siteCenterSpot: {
      type: Object,
      default: null,
    },
    otherProp: {
      type: Object,
    },
  },
  data() {
    return {
      latlng: {
        lon: common.getDefaultLong(),
        lat: common.getDefaultLat(),
      },
      map: null,
      marker: null,
      tileLayer: null,
      tooltip: null,
      latlngList: null,
      mapTileType: null,
      polylines: [],
      mapPointList: [],
      theMarker: null,
      latlngAdd: null,
      tempMarker: null,
      preLatLng: null,
      isInit: true,
    };
  },
  watch: {
    resize() {
      const resizeObserver = new ResizeObserver(() => {
        this.map.invalidateSize();
      });

      resizeObserver.observe(
        document.getElementById(
          this.otherProp != null ? "map_" + this.otherProp.key : "map"
        )
      );
    },
    searchResult() {},
    latlngArray() {
      const vm = this;
      for (let latlng of this.latlngArray) {
        let popupContent = "<div>";
        for (let tag of latlng.tags) {
          for (let item of tag) {
            if (item.key === "car") {
              popupContent +=
                "<p><svg xmlns='http://www.w3.org/2000/svg' style='width: 1.5em; height: 1.5em; margin-right:8px; vertical-align: -0.5em' aria-hidden='true' focusable='false' viewBox='0 0 512 512'><path fill='#12a1d5' d='M135.2 117.4L109.1 192l293.8 0-26.1-74.6C372.3 104.6 360.2 96 346.6 96L165.4 96c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32l181.2 0c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2l0 144 0 48c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-48L96 400l0 48c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-48L0 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z'/></svg>" +
                item.value +
                "</p>";
            } else if (item.key === "des") {
              popupContent +=
                "<p><svg xmlns='http://www.w3.org/2000/svg' style='width: 1.5em; height: 1.5em; margin-right:8px; vertical-align: -0.5em' aria-hidden='true' focusable='false' viewBox='0 0 512 512'><path fill='#12a1d5' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z'/></svg>" +
                item.value +
                "</p>";
            }
          }
        }
        popupContent += "</div>";

        this.marker = L.marker(latlng.position, {
          icon: defaultIcon,
        }).addTo(vm.map);

        const popup = new L.popup(latlng.position, {
          offset: [0, -20],
          content: popupContent,
          className: "add",
        });
        this.marker.setLatLng(latlng.position).bindPopup(popup);
      }

      //const bounds = L.latLng(this.latlng).toBounds(500); // 500 = metres
      if (this.searchResult !== null) {
        // Then move the map

        const offset = this.map.getSize().x * 0.15;
        // Then move the map
        this.map.panBy(new L.Point(offset, 0), { animate: false });
      }
    },
    pointList() {
      const vm = this;
      this.mapPointList = [...this.pointList];
      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          vm.map.removeLayer(layer);
        }
        if (layer instanceof L.Polyline) {
          vm.map.removeLayer(layer);
        }
      });
      this.markerListAdd();
      if (this.page !== "site") {
        this.polylinesAdd();
        this.map.fitBounds(vm.polylines.getBounds());
      } else {
        //console.log("vm.siteCenterSpot :: ", vm.siteCenterSpot);
        //this.map.panTo(vm.siteCenterSpot).setZoom(20);
        this.map.setView(vm.siteCenterSpot, 20);

        //this.map.fitBounds(vm.marker.getBounds());
      }
    },
    mapType() {
      this.mapTileType = this.mapType;
    },
    mapTileType() {
      const mapLayer =
        this.mapTileType !== "satellite"
          ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          : "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      L.tileLayer(mapLayer).addTo(this.map);
    },
    currentPose() {
      const vm = this;

      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          vm.map.removeLayer(layer);
        }
      });
      const array = [];
      for (let item of this.currentPose) {
        console.log("커런트 포즈!!! :; ", item);
        const txt = item.devicdId;
        const param = {
          lat: item.currentPose.latitude,
          lng: item.currentPose.longitude,
        };

        array.push([param.lat, param.lng]);
        this.marker = L.marker(param, {
          icon: defaultIcon,
        })
          .addTo(this.map)
          .bindTooltip(txt, {
            permanent: true,
            direction: "top",
            offset: [0, -35],
          });
      }
      if (this.isInit && array.length > 0) {
        let isArrayNull = false;
        for (let i = 0; i < array.length; i++) {
          console.log(array[i]);
          if (array[i] === null) {
            isArrayNull = true;
          }
        }
        if (!isArrayNull) {
          let bounds = new L.LatLngBounds(array);
          this.map.fitBounds(bounds);
        } else {
          isArrayNull = false;
        }
      }
      this.isInit = false;
    },
    gpsMapDataArray() {
      const vm = this;
      let newArr = [];
      this.polylines = [];
      // 로봇페이지에서 웹소켓으로 맵 호출할 때

      if (this.mapPage === "robot") {
        this.map.eachLayer(function (layer) {
          if (layer instanceof L.Polyline) {
            vm.map.removeLayer(layer);
          }
        });

        for (let item of this.gpsMapDataArray) {
          console.log("YYYYYYYYYYYY :: ", this.gpsMapDataArray);
          item.map((a) => {
            let b = {};

            b["lat"] = a["latitude"];
            b["lng"] = a["longitude"];
            newArr.push(b);
          });

          this.polylines.push(newArr);
        }

        if (this.polylines.length > 0) {
          new L.polyline(vm.polylines, {
            color: "#f15046",
            weight: 3,
            opacity: 0.7,
            smoothFactor: 1,
          }).addTo(vm.map);
        }
      } else {
        // 그 외
        let newLatLng = null;
        this.polylines = [...this.gpsMapDataArray];
        if (this.polylines.length > 0) {
          newLatLng = new L.LatLng(vm.polylines[0][0], vm.polylines[0][1]);
          this.map.panTo(newLatLng);
        }
        this.preLatLng = newLatLng;
        console.log("ployLineployLineployLine :: ", this.polylines);

        if (this.polylines.length > 0) {
          new L.polyline(vm.polylines, {
            color: "#f15046",
            weight: 3,
            opacity: 0.7,
            smoothFactor: 1,
          }).addTo(vm.map);
        }
        if (newLatLng != null) {
          this.marker.setLatLng(newLatLng);
          if (this.init) {
            this.map.panTo(newLatLng);
            this.init = false;
          }
        }
      }
    },
  },
  created() {},
  async mounted() {
    if (this.otherProp != undefined && this.otherProp != "mission") {
      this.mapTileType = this.otherProp?.components[0]?.mapType;
    }

    if (this.pointList > 0) {
      this.mapPointList = [...this.pointList];
    }
    await this.createMap();

    const vm = this;

    if (this.mapHeight >= 0) {
      const resizeObserver = new ResizeObserver(() => {
        this.map.invalidateSize();
      });

      resizeObserver.observe(
        document.getElementById(
          this.otherProp != null ? "map_" + this.otherProp.key : "map"
        )
      );
    }
    this.map.on("popupopen", function () {
      vm.popupButtonAction();
    });
    this.emitter.on("updateMap", (e) => {
      const newLatLng = new L.LatLng(e[0], e[1]);
      //this.map.panTo(newLatLng);

      this.marker.setLatLng(newLatLng);
    });
    // 맵 우클릭 시
    this.map.on("contextmenu", function (e) {
      if (vm.tempMarker != null) {
        vm.map.removeLayer(vm.tempMarker);
      }
      if (vm.otherProp === "mission") {
        let icon = L.divIcon({
          className: "custom-div-icon",
          html: "<div style='font-size:23px; background-color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #020a0a;' class='marker-pin'>f</div>",
          iconSize: [30, 42],
        });
        vm.tempMarker = vm.marker = L.marker(
          vm.mapPointList[vm.mapPointList.length - 1],
          {
            icon: icon,
          }
        ).addTo(vm.map);

        vm.latlngAdd = e.latlng;
        vm.latlngAdd.point = vm.mapPointList.length;

        const popupContent =
          "<div><div style='font-size:17px; margin-bottom:20px'>해당 위치를 지정하시겠습니까?</div><div style='display:flex; gap:10px; justify-content:space-between'><button type='button' class='btn_add_position mcbtn' style='width:100px; height:30px; border-radius:10px;'>확인</button><button type='button' class='btn_cancel_position mcbtn' style='width:100px; height:30px; border-radius:10px;'>취소</button></div></div>";

        /*
        let icon = L.divIcon({
          className: "custom-div-icon",
          html: "<div style='background-color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #020a0a;' class='marker-pin'>f</div>",
          iconSize: [30, 42],
        });
        vm.theMarker = L.marker(latlng, {
          icon: icon,
        }).addTo(vm.map);
*/
        const popup = new L.popup(vm.latlngAdd, {
          offset: [0, -20],
          content: popupContent,
          className: "add",
        });
        vm.marker
          .setLatLng(vm.latlngAdd)
          .bindPopup(popup, { closeButton: false })
          .openPopup();

        vm.popupButtonAction();
      } else if (vm.page === "site") {
        let txt = "";
        if (vm.mapPointList.length === 2) {
          return false;
        }

        if (vm.mapPointList.length < 1) {
          txt = "s";
        } else {
          txt = "f";
        }

        if (vm.mapPointList.length === 1 && vm.mapPointList[0].point === "f") {
          txt = "s";
        }

        let icon = L.divIcon({
          className: "custom-div-icon",
          html:
            "<div style='font-size:23px; background-color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #020a0a;' class='marker-pin'>" +
            txt +
            "</div>",
          iconSize: [30, 42],
        });
        vm.latlngAdd = e.latlng;

        vm.latlngAdd.point = txt;
        vm.tempMarker = vm.marker = L.marker(vm.latlngAdd, {
          icon: icon,
        }).addTo(vm.map);
        const popupContent =
          "<div><div style='font-size:17px; margin-bottom:20px'>해당 위치를 지정하시겠습니까?</div><div style='display:flex; gap:10px; justify-content:space-between'><button type='button' class='btn_add_position mcbtn' style='width:100px; height:30px; border-radius:10px;'>확인</button><button type='button' class='btn_cancel_position mcbtn' style='width:100px; height:30px; border-radius:10px;'>취소</button></div></div>";

        const popup = new L.popup(vm.latlngAdd, {
          offset: [0, -20],
          content: popupContent,
          className: "add",
        });
        vm.marker
          .setLatLng(vm.latlngAdd)
          .bindPopup(popup, { closeButton: false })
          .openPopup();

        vm.popupButtonAction();
      }
    });
  },
  methods: {
    resetPoint() {
      const vm = this;
      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          vm.map.removeLayer(layer);
        }
        if (layer instanceof L.Polyline) {
          vm.map.removeLayer(layer);
        }
      });
      const latlng = {
        lat: common.getDefaultLat(),
        lng: common.getDefaultLong(),
      };
      //this.mapP = [];
      this.map.setView(latlng, 20);
    },
    updatePoint(item) {
      console.log(item);
    },
    updateLatlng(lat, lng) {
      const newLatLng = new L.LatLng(lat, lng);
      this.map.panTo(newLatLng);
    },
    popupButtonAction() {
      document.querySelectorAll(".btn_add_position").forEach((e) => {
        e.addEventListener("click", this.addPosition);
      });
      document.querySelectorAll(".btn_cancel_position").forEach((e) => {
        e.addEventListener("click", this.cancelPosition);
      });
    },
    removePoint(sort) {
      const vm = this;
      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          vm.map.removeLayer(layer);
        }
      });
      for (let i = 0; i < vm.mapPointList.length; i++) {
        if (vm.mapPointList[i].point === sort) {
          vm.mapPointList.splice(i, 1);
        }
      }
      this.markerSiteAdd();
    },
    // 우클릭 후 확인 버튼으로 위치 추가할 시
    addPosition() {
      const vm = this;
      this.map.closePopup();
      vm.mapPointList.push(vm.latlngAdd);

      console.log("vm.mapPointList :: ", vm.mapPointList);
      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Polyline) {
          vm.map.removeLayer(layer);
        }
      });
      if (this.page !== "site") {
        this.polylinesAdd();
        if (vm.mapPointList.length > 0) this.map.removeLayer(vm.marker);
        this.map.fitBounds(vm.polylines.getBounds());
        this.markerListAdd();
      } else {
        this.markerSiteAdd();
      }

      this.$emit("updatePoint", vm.mapPointList);
    },
    // 우클릭 후 취소 버튼 클릭 시
    cancelPosition() {
      const vm = this;
      this.map.removeLayer(vm.marker);
    },
    // 맵 타입 변경
    chgMapType(type) {
      this.mapTileType = type;
    },

    viewMap() {
      const vm = this;
      this.map.fitBounds(vm.polylines.getBounds());
    },
    rearrangeMarker(item) {
      console.log("itemitemitem :: ", item);
      const vm = this;
      let num = 0;
      let arr = [];
      if (item) {
        arr = [...item];
      } else {
        arr = [...this.pointList];
      }
      this.map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          vm.map.removeLayer(layer);
        }
        if (layer instanceof L.Polyline) {
          vm.map.removeLayer(layer);
        }
      });
      for (let i = 0; i < arr.length; i++) {
        if (i === 0) {
          num = "s";
        } else if (i === arr.length - 1) {
          num = "f";
        } else {
          num = i.toString();
        }
        const icon = L.divIcon({
          className: "custom-div-icon",
          html:
            "<div style='font-size:22px; background-color:#00B4ED; color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #fff;' class='marker-pin'>" +
            num +
            "</div>",
          iconSize: [30, 42],
        });

        this.marker = L.marker(arr[i], {
          icon: icon,
        }).addTo(vm.map);
      }
      this.polylines = L.polyline(arr, {
        color: "#f15046",
        weight: 3,
        opacity: 0.7,
        smoothFactor: 1,
      }).addTo(vm.map);
      this.mapPointList = [...arr];
      this.map.fitBounds(vm.polylines.getBounds());
      this.map.setZoom(20);
    },

    // 마커 추가
    markerListAdd() {
      const vm = this;
      let num = "";
      for (let i = 0; i < this.mapPointList.length; i++) {
        //for (let [index, $point] of this.mapPointList) {
        if (i === 0) {
          num = "s";
        } else if (i === this.mapPointList.length - 1) {
          num = "f";
        } else {
          num = i.toString();
        }
        const icon = L.divIcon({
          className: "custom-div-icon",
          html:
            "<div style='font-size:22px; background-color:#00B4ED; color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #fff;' class='marker-pin'>" +
            num +
            "</div>",
          iconSize: [30, 42],
        });

        this.marker = L.marker(vm.mapPointList[i], {
          icon: icon,
        }).addTo(vm.map);
      }
    },
    markerSiteAdd() {
      const vm = this;
      for (let i = 0; i < this.mapPointList.length; i++) {
        const icon = L.divIcon({
          className: "custom-div-icon",
          html:
            "<div style='font-size:22px; background-color:#00B4ED; color:#fff; width:30px; height:30px; border-radius:50%; text-align:center; line-height:30px;border:2px solid #fff;' class='marker-pin'>" +
            this.mapPointList[i].point +
            "</div>",
          iconSize: [30, 42],
        });

        this.marker = L.marker(vm.mapPointList[i], {
          icon: icon,
        }).addTo(vm.map);
      }
    },
    polylinesAdd() {
      const vm = this;
      this.polylines = L.polyline(vm.mapPointList, {
        color: "#f15046",
        weight: 3,
        opacity: 0.7,
        smoothFactor: 1,
      }).addTo(vm.map);
    },
    async createMap() {
      if (this.pointList != undefined) {
        this.mapPointList = [...this.pointList];
      }

      let latlng = this.latlng;

      let mapLayer = "";
      if (this.mapTileType != undefined && this.mapTileType != null) {
        mapLayer =
          this.mapTileType !== "satellite"
            ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            : "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      } else {
        mapLayer =
          this.mapType !== "satellite"
            ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            : "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      }
      if (this.mapPointList.length > 0) {
        this.map = L.map(
          this.otherProp != null ? "map_" + this.otherProp.key : "map",
          {
            zoomControl: true,
            zoom: 1,
            zoomAnimation: false,
            fadeAnimation: true,
            markerZoomAnimation: true,
          }
        ).setView(this.mapPointList[0], 20);
      } else {
        this.map = L.map(
          this.otherProp != null ? "map_" + this.otherProp.key : "map",
          {
            zoomControl: true,
            zoom: 1,
            zoomAnimation: false,
            fadeAnimation: true,
            markerZoomAnimation: true,
          }
        ).setView(latlng, 20);
      }

      let $latlngList;
      if (this.latlngList !== null && this.latlngList != []) {
        $latlngList = this.latlngList;
      } else {
        $latlngList = [this.latlng];
      }
      const vm = this;
      if (this.page !== "site") {
        if (this.mapPointList.length > 0) {
          this.markerListAdd();
          this.polylinesAdd();

          this.map.setZoom(20);
        } else {
          for (let $latlng of $latlngList) {
            this.marker = L.marker($latlng, {
              icon: defaultIcon,
            }).addTo(this.map);
          }
        }
      }

      this.tileLayer = L.tileLayer(mapLayer).addTo(vm.map);

      this.map.zoomControl.setPosition("topright");
      const target = document.querySelectorAll(".leaflet-control-zoom");

      //if (this.page === undefined) {
      target.forEach((e) => {
        e.querySelector(".fit")?.remove();
        const div = document.createElement("div");
        div.classList.add("mcbtn");
        div.classList.add("fit");
        div.style.position = "relative";
        div.style.width = "30px";
        div.style.height = "30px";
        div.style.cursor = "pointer";
        div.innerHTML =
          "<span style='position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:16px; height:16px; border:1px solid #fff'></span><span style='position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:8px; height:8px; background:#fff'></span>";
        div.addEventListener("click", vm.onFitBounds);
        if (this.page !== "site") {
          e.append(div);
        }
      });
      //}
      target.forEach((e) => {
        e.style.setProperty("display", "flex");
        e.style.setProperty("gap", "6px");
        e.style.setProperty("border", "0");
        e.querySelectorAll("a").forEach((g) => {
          g.classList.add("mcbtn");
          g.style.setProperty("width", "30px");
          g.style.setProperty("height", "30px");
          g.style.setProperty("overflow", "hidden");

          g.querySelector("span").style.setProperty("color", "#fff");
          g.querySelector("span").style.setProperty("font-size", "26px");
          g.querySelector("span").style.setProperty("font-weight", "600");
          g.querySelector("span").style.setProperty("line-height", "30px");
        });
      });
    },
    onFitBounds(e) {
      const vm = this;
      e.stopImmediatePropagation();
      e.stopPropagation();
      //console.log("8888888888888 :: ", this.polylines[0][0].lat);
      if (
        this.polylines[0] != undefined &&
        this.polylines[0].length > 1 &&
        this.mapPage != "vector"
      ) {
        console.log("this.polylines : ", this.polylines);
        this.polylines = L.polyline(vm.polylines, {
          color: "#f15046",
          weight: 3,
          opacity: 0.7,
          smoothFactor: 1,
        });
        this.map.fitBounds(vm.polylines.getBounds());
      } else if (this.currentPose) {
        const array = [];

        for (let item of this.currentPose) {
          console.log("커런트 포즈!!! :; ", item);
          if (
            item.currentPose.latitude !== null &&
            item.currentPose.longitude !== null
          ) {
            const param = {
              lat: item.currentPose.latitude,
              lng: item.currentPose.longitude,
            };
            array.push([param.lat, param.lng]);
            this.marker = L.marker(param, {
              icon: defaultIcon,
            }).addTo(this.map);
            let bounds = new L.LatLngBounds(array);
            this.map.fitBounds(bounds);
          }
        }
      } else {
        if (this.polylines.length > 0) {
          console.log("this.polylines :: ", this.polylines);
          this.polylines = L.polyline(vm.polylines, {
            color: "#f15046",
            weight: 3,
            opacity: 0.7,
            smoothFactor: 1,
          });
          this.map.fitBounds(vm.polylines.getBounds());
        } else if (
          this.gpsMapDataArray != null &&
          this.gpsMapDataArray.length > 0
        ) {
          let a = null;
          let b = null;
          console.log(this.gpsMapDataArray[0]);
          if (
            "x" in this.gpsMapDataArray[0] &&
            this.gpsMapDataArray[0].x != null
          ) {
            a = new L.LatLng(
              this.gpsMapDataArray[0].x,
              this.gpsMapDataArray[0].y
            );
            b = new L.LatLng(
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1].x,
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1].y
            );
          } else if (
            "latitude" in this.gpsMapDataArray[0] &&
            this.gpsMapDataArray[0].latitude != null
          ) {
            a = new L.LatLng(
              this.gpsMapDataArray[0].latitude,
              this.gpsMapDataArray[0].longitude
            );
            b = new L.LatLng(
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1].latitude,
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1].longitude
            );
          } else {
            a = new L.LatLng(
              this.gpsMapDataArray[0][0],
              this.gpsMapDataArray[0][1]
            );
            b = new L.LatLng(
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1][0],
              this.gpsMapDataArray[this.gpsMapDataArray.length - 1][1]
            );
          }
          const bounds = new L.LatLngBounds(a, b);
          this.map.fitBounds(bounds);
        } else {
          this.map.panTo(this.marker.getLatLng());
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.map {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid #d5dae3;
  overflow: hidden;
  .map_type {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 5;
    & > li {
      padding: 10px;
      border: 1px solid #e5e9ef;
      background-color: #fff;
      border-radius: 6px;
      .map_type_icon {
        width: 60px;
        height: 50px;
        font-size: 30px;
        color: #fff;
        text-align: center;
      }
      .map_type_txt {
        margin-top: 10px;
      }
    }
  }
  .map_inner {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    z-index: 4;
  }
  .search {
    position: relative;
    border-left: 1px solid #d5dae3;
    h3 {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #d5dae3;
      font-weight: 600;
    }
    ul {
      height: 100%;
      overflow-y: auto;
      li {
        display: inline-flex;
        text-align: center;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #d5dae3;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.map_controller_wrap {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

#map .leaflet-control-zoom {
  background-color: red !important;
}
</style>
