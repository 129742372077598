<template>
  <div class="setting_pop_cell_contents_wrap">
    <div class="input-block">
      <div class="divide">
        <div class="first">Component ID</div>
        <div class="second">{{ items[0].value }}</div>
      </div>
      <div class="divide">
        <div class="first">선택구간</div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "RemoteControllerSetting",
  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex: {
      type: Number,
      required: true,
    },
    handlerList: {
      //handler
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        { label: "Component ID", value: "" },
        { label: "Display Title", value: "" },
        { label: "Controller 추가", value: {} },
      ],
      nowindex: 0,
      componentType: "",
      components: [],
      layoutComponent: {},
    };
  },
  async mounted() {
    this.$refs.lodingWrap.style.display = "block";

    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);

    if (this.components[this.nowindex]?.displayTitle != undefined) {
      this.displayTitle = this.components[this.nowindex]?.displayTitle;
    } else {
      this.displayTitle = "";
    }
    const components_cp = this.components;
    for (let i = 0; i < components_cp.length; i++) {
      let valueStart = components_cp[i].valueStart;
      let valueEnd = components_cp[i].valueEnd;
      if (valueStart !== null) {
        if (typeof valueStart === "string") {
          valueStart = new Date(valueStart);
        }
      }
      if (valueEnd !== null) {
        if (typeof valueEnd === "string") {
          valueEnd = new Date(valueEnd);
        }
      }
    }

    this.components = _.cloneDeep(components_cp);
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    const type = this.componentType;
    const handlerCount = String(this.handlerList.length + 1).padStart(3, "0");
    const component = {
      type: type,
      labelText: "",
      value: "",
      valueStart: "",
      valueEnd: "",
      key: this.components.length + 1 + "-" + Math.floor(Date.now()),
      handlerKey: "Handler" + handlerCount,
      remove: false,
      child: {},
      parentHandlerKey: "",
      controlHandlerLabelText: "",
      keyValueList: [],
      keyValueType: "",
      parameter: "",
      range: {
        startRange: 0,
        endRange: 0,
        step: 0,
      },
    };
    this.components.push(component);
    this.component = component;
    this.$emit("updateHandler", component);
    this.component = _.cloneDeep(this.component);
    this.$refs.lodingWrap.style.display = "none";
  },
  methods: {
    updateParent({ index, value }) {
      this.components[index].parentHandlerKey = value;
      this.components = _.cloneDeep(this.components);
    },
    updateItem(index, newValue) {
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    removeItem(index) {
      this.$emit("removeHandler", this.components[index]);
      this.components.splice(index, 1); // 해당 인덱스의 객체를 배열에서 제거
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
