<template>
  <div class="tabs">
    <div class="tab-header">
      <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: index === activeTab }"
          @click="setActiveTab(index)"
      >
        {{ tab.label }}
      </button>
    </div>
    <div class="tab-content">
      <slot :name="tabs[activeTab]?.slotName"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabComponent",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    defaultActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeTab: this.defaultActive
    };
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    }
  }
};
</script>

<style scoped>
.tabs {
  width: 100%;
}

.tab-header {
  display: flex;
  gap: 8px;
  border-bottom: 2px solid #ddd;
}

.tab-header button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.tab-header button.active {
  border-bottom: 3px solid #ff4b4b;
  font-weight: bold;
  color: #ff4b4b;
}

.tab-content {
  padding: 20px;
  font-size: 16px;
}
</style>