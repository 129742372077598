<template>
  <div class="dashboard">
    <div class="stats">
      <div class="stat-item">
        <label>Sentences</label>
        <span>{{ sentences }}</span>
      </div>

      <div class="stat-item">
        <label>LLM Type Result</label>
        <span>{{ state.llmTypeResult }}</span>
      </div>
      <div class="stat-item">
        <label>Heading</label>
        <span>{{ state.heading }} / {{ state.heading_ref }}</span>
      </div>
      <div class="stat-item">
        <label>Customer Requirement</label>
        <span>{{ state.customerRequirement }} / {{ state.customerRequirement_ref }}</span>
      </div>
      <div class="stat-item">
        <label>Comment</label>
        <span>{{ state.comment }} / {{ state.comment_ref }}</span>
      </div>
      <div class="stat-item">
        <label>Accuracy</label>
        <span>{{ isNaN(accuracy) ? 0 : accuracy }} %</span>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref} from "vue";

export default {
  name: "DashboardComp",
  data() {
    let sentences = 0;
    return {
      sentences,
    }
  },
  setup() {
    const state = reactive({
      llmTypeResult: 0,
      heading: 0,
      heading_ref: 0,
      customerRequirement: 0,
      customerRequirement_ref: 0,
      comment: 0,
      comment_ref: 0,
      success_count: ref(0),
      try_count: ref(0),
    });

    const set_llmTypeResult = () => {
      state.llmTypeResult += 1;
    }
    const set_heading = () => {
      state.heading += 1;
    }
    const set_heading_ref = () => {
      state.heading_ref += 1;
    }
    const set_customerRequirement = () => {
      state.customerRequirement += 1;
    }
    const set_customerRequirement_ref = () => {
      state.customerRequirement_ref += 1;
    }
    const set_comment = () => {
      state.comment += 1;
    }
    const set_comment_ref = () => {
      state.comment_ref += 1;
    }
    const set_success_count = () => {
      state.success_count += 1;
    }

    const accuracy = computed(() => {
      let result = (state.success_count / state.llmTypeResult);
      if (result == Infinity){
        result = 1;
      }
      const tmp = parseFloat(result * 100).toFixed(2);
      // console.log(tmp);
      return tmp;
    });

    const clearStateData = () => {
      state.llmTypeResult = 0;
      state.heading = 0;
      state.heading_ref = 0;
      state.customerRequirement = 0;
      state.customerRequirement_ref = 0;
      state.comment = 0;
      state.comment_ref = 0;
      state.success_count = 0;
      state.try_count = 0;
    }

    return {
      state,
      set_llmTypeResult,
      set_heading,
      set_heading_ref,
      set_customerRequirement,
      set_customerRequirement_ref,
      set_comment,
      set_comment_ref,
      set_success_count,
      accuracy,
      clearStateData
    }
  },
  methods: {
    set_sentences_length(dataLength){
      this.sentences = dataLength;
    },
    set_sentances_length_reset(){
      this.sentences = 0;
    }
  }
};
</script>

<style scoped>
.dashboard {
  max-width: 1240px;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;  /* 각 항목 너비 조정 */
}

label {
  font-weight: bold;
  font-size: 12px;
  color: #333;
}

span {
  display: block;
  padding: 2px;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  margin-top: 2px;
}

.stat-item span {
}
</style>
