import axios from "axios";
// Vector Logger API
const axiosInstanComponentTest = axios.create({
  baseURL: "https://ml5bmeaed6.execute-api.ap-northeast-2.amazonaws.com/test",
  headers: {
    "Content-Type": "application/json",
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    "X-API-Key": "mcspkey1",
  },
});

const componentTest = {
  postMap(url, data = {}) {
    return axiosInstanComponentTest.post(url, data);
  },
};

export default componentTest;
