<template>
  <div class="title_wrap">
    <div class="breadcrumb">
      <span
        v-for="(item, index) in dataList"
        :key="index"
        v-bind:class="{ active: index == lastIndex }"
        @click="goBcPage(item)"
      >
        {{ item.name }}
      </span>
    </div>
    <h2>{{ data.name }}</h2>
    <div
      v-if="checkRole && data.addButton != '' && data.addButton != null"
      class="title_btn"
    >
      <button type="button" @click="goAddPage()" class="add_project bgicon">
        {{ data.addButton }}
      </button>
    </div>
    <div
      v-if="checkRole && data.logButton != '' && data.logButton != null"
      class="title_btn"
    >
      <button type="button" class="btn_view_log" disabled>
        {{ data.logButton }}
      </button>
    </div>
    <div
      v-if="checkRole && data.modButton != '' && data.modButton != null"
      class="title_btn"
    >
      <button type="button" @click="goModPage()" class="setting_project bgicon">
        {{ data.modButton }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "BreadcrumbLayout",
  props: {
    pageId: String,
    name: String,
    params: Object,
    subParams: Object,
    subItems: Object,
    checkRole: Boolean,
  },
  data() {
    return {
      data: [],
      dataList: [],
      lastIndex: 0,
      items: [
        // My 프로젝트(Home)
        {
          id: "project_home",
          name: "My 프로젝트(Home)",
          addButton: "프로젝트 등록",
          logButton: "",
          url: "addProject",
          subItems: [
            {
              name: "프로젝트 운영",
              url: "",
              type: "",
            },
            {
              name: "My 프로젝트(Home)",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "project_addProject",
          name: "프로젝트 생성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "프로젝트 운영",
              url: "",
              type: "",
            },
            {
              name: "My 프로젝트(Home)",
              url: "Home",
              type: "",
            },
            {
              name: "프로젝트 생성",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "project_moddProject",
          name: "프로젝트 관리",
          addButton: "",
          logButton: "PRJ 수정 로그 보기",
          url: "",
          subItems: [
            {
              name: "프로젝트 운영",
              url: "",
              type: "",
            },
            {
              name: "My 프로젝트(Home)",
              url: "Home",
              type: "",
            },
            {
              name: "프로젝트 워크스페이스",
              url: "Workspace",
              type: "params",
              paramIndex: 0,
            },
            {
              name: "프로젝트 관리",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "project_workspace",
          name: "프로젝트 워크스페이스",
          addButton: "",
          logButton: "",
          modButton: "프로젝트 관리",
          url: "modProject",
          type: "params",
          subItems: [
            {
              name: "프로젝트 운영",
              url: "",
              type: "",
            },
            {
              name: "My 프로젝트(Home)",
              url: "Home",
              type: "",
            },
            {
              name: "프로젝트 워크스페이스",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "project_projectList",
          name: "프로젝트 목록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "프로젝트 운영",
              url: "",
              type: "",
            },
            {
              name: "프로젝트 목록",
              url: "",
              type: "",
            },
          ],
        },
        // 데이터 목록
        {
          id: "monTASK_dataList",
          name: "디바이스 데이터 목록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "디바이스 데이터 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_detailData",
          name: "데이터셋 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "디바이스 데이터 목록",
              url: "dataList",
              type: "",
            },
            {
              name: "데이터셋 상세",
              url: "",
              type: "",
            },
          ],
        },
        // 모니터링 TASK 목록
        {
          id: "monTASK_monTaskList",
          name: "모니터링 TASK 목록",
          addButton: "신규 모니터링 TASK 등록",
          logButton: "",
          url: "AddTask",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_monTaskList2",
          name: "Template Task 목록",
          addButton: "신규 template TASK 등록",
          logButton: "",
          url: "AddTemplate",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Template Task 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_addMonTask",
          name: "모니터링 TASK - 신규 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "monTaskList",
              type: "",
            },
            {
              name: "모니터링 TASK - 신규 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_modMonTask",
          name: "모니터링 TASK 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "monTaskList",
              type: "",
            },
            {
              name: "데이터 상세",
              url: "monTaskList",
              type: "query",
              paramIndex: 0,
            },
            {
              name: "모니터링 TASK 관리",
              url: "",
              type: "",
            },
          ],
        },

        {
          // Remote Diagnostics
          id: "monTASK_taskRD",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "monTaskList",
              type: "",
            },
            {
              name: "Remote Diagnostic Service 대시보드",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Anomaly Detection
          id: "monTASK_taskAD",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "monTaskList",
              type: "",
            },
            {
              name: "Motor Output Anomaly Detection 상세",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Vision Edge Inference Monitoring
          id: "monTASK_taskVEIM",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Edge Inference 모니터링",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Vision Auto Labeling Monitoring
          id: "monTASK_taskVALM",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Auto Labeling 검토 상세",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Vision AI/ML Statistics
          id: "monTASK_taskVAMS",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Vision 데이터 처리 통계",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Smart Mining Dashboard
          id: "monTASK_taskSmartMining",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK 목록",
              url: "monTaskList",
              type: "",
            },
            {
              name: "Smart Mining Dashboard",
              url: "",
              type: "",
            },
          ],
        },
        // 차량 모니터링 목록
        {
          id: "monTASK_vehicleDashboard",
          name: "Vehicle Diagnostic Service 대시보드",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Vehicle Diagnostic Service 대시보드",
              url: "",
              type: "",
            },
          ],
        },
        // MLOps TASK 목록
        {
          id: "monTASK_mlopsList",
          name: "MLOps TASK 목록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "MLOps TASK 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_mloObservation",
          name: "MLOps – Observation Log 모니터링",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "MLOps TASK 목록",
              url: "mlopsList",
              type: "",
            },
            {
              name: "MLOps – Observation Log 모니터링",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_mloPipeline",
          name: "MLOps – Pipeline Log 모니터링",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "MLOps TASK 목록",
              url: "mlopsList",
              type: "",
            },
            {
              name: "MLOps – Pipeline Log 모니터링",
              url: "",
              type: "",
            },
          ],
        },

        {
          id: "sysOps_portlet",
          name: "Templet 목록",
          addButton: "신규 Template 등록",
          logButton: "",
          url: "portleTemplateInsert",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Templet 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_portlet_detail",
          name: "Templet 뷰",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Templet 목록",
              url: "PortletTemplateList",
              type: "",
            },
            {
              name: "Templet 뷰",
              url: "portlet_detail",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_portleTemplateInsert",
          name: "신규 Template 등록",
          addButton: "",
          logButton: "",
          url: "portleTemplateInsert",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Templet 목록",
              url: "PortletTemplateList",
              type: "",
            },
            {
              name: "신규 Template 등록",
              url: "portleTemplateInsert",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_portleTemplateEdit",
          name: "Template 수정",
          addButton: "",
          logButton: "",
          url: "portleTemplateEdit",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Templet 목록",
              url: "PortletTemplateList",
              type: "",
            },
            {
              name: "Template 수정",
              url: "portleTemplateEdit",
              type: "",
            },
          ],
        },
        // Team 관리
        {
          id: "sysOps_teamList",
          name: "Team 관리 - 목록",
          addButton: "신규 Team 등록",
          logButton: "",
          url: "addTeam",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_addTeam",
          name: "Team 생성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "teamList",
              type: "",
            },
            {
              name: "Team 생성",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_modTeam",
          name: "Team 관리",
          addButton: "",
          logButton: "Team 수정 로그 보기",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "teamList",
              type: "",
            },
            {
              name: "Team 관리",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_addTemplate",
          name: "template TASK - 신규 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Template TASK 목록",
              url: "",
              type: "",
            },
            {
              name: "모니터링 TASK - 신규 등록",
              url: "",
              type: "",
            },
          ],
        },
        // Division 관리
        {
          id: "sysOps_divisionList",
          name: "Division 관리 - 목록",
          addButton: "신규 Division 등록",
          logButton: "",
          url: "addDivision",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_addDivision",
          name: "Division 생성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "divisionList",
              type: "",
            },
            {
              name: "Division 생성",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_modDivision",
          name: "Division 관리",
          addButton: "",
          logButton: "Division 수정 로그 보기",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "divisionList",
              type: "",
            },
            {
              name: "Division 관리",
              url: "",
              type: "",
            },
          ],
        },
        // 사용자 정보 관리
        {
          id: "sysOps_memberList",
          name: "사용자 정보 관리 - 목록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_detailMember",
          name: "사용자 정보 관리 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 목록",
              url: "memberList",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 상세",
              url: "",
              type: "",
            },
          ],
        },

        // Vehicle TASK 목록
        {
          id: "monTASK_vehicleTaskList",
          name: "Vehicle TASK 목록",
          addButton: "신규 Vehicle TASK 등록",
          logButton: "",
          url: "AddVehicleTask",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Vehicle TASK 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_addVehicleTask",
          name: "Vehicle TASK - 신규 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Vehicle TASK 목록",
              url: "vehicleTaskList",
              type: "",
            },
            {
              name: "Vehicle TASK - 신규 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Driving Data Management
          id: "monTASK_drivingDataManagement",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Vehicle TASK 목록",
              url: "vehicleTaskList",
              type: "",
            },
            {
              name: "Driving Data Management",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Driving Data Management
          id: "monTASK_drivingDataMonitoring",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Vehicle TASK 목록",
              url: "vehicleTaskList",
              type: "",
            },
            {
              name: "Driving Data Monitoring",
              url: "",
              type: "",
            },
          ],
        },
        // Robot TASK 목록
        {
          id: "monTASK_robotTaskList",
          name: "Robot TASK 목록",
          addButton: "신규 Robot TASK 등록",
          logButton: "",
          url: "AddRobotTask",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Robot TASK 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_addRobotTask",
          name: "Robot TASK - 신규 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Robot TASK 목록",
              url: "robotTaskList",
              type: "",
            },
            {
              name: "Robot TASK - 신규 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          // Multi-Agent Monitoring
          id: "monTASK_multiAgentMonitoring",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Robot TASK 목록",
              url: "robotTaskList",
              type: "",
            },
            {
              name: "Multi-Agent Monitoring",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_labelMonitoring",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Label Monitoring",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_realtimeDirectrateMonitoring",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Real-time Direct rate Monitoring",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_modelPerformanceMonitoring",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Model Performance Monitoring",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_inferenceStatus",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Inference Status",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_systemDataStatus",
          name: "",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "System Data Status",
              url: "",
              type: "",
            },
          ],
        },

        // Smart Factory TASK 목록
        {
          id: "monTASK_smartFactoryTaskList",
          name: "Smart Factory TASK 목록",
          addButton: "신규 Smart Factory TASK 등록",
          logButton: "",
          url: "AddSmartFactoryTask",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "monTASK_addSmartFactoryTask",
          name: "Smart Factory TASK - 신규 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "데이터/ TASK 운영",
              url: "",
              type: "",
            },
            {
              name: "Smart Factory TASK 목록",
              url: "smartFactoryTaskList",
              type: "",
            },
            {
              name: "Smart Factory TASK - 신규 등록",
              url: "",
              type: "",
            },
          ],
        },
        // Team 관리
        {
          id: "sysOps_teamList",
          name: "Team 관리 - 목록",
          addButton: "신규 Team 등록",
          logButton: "",
          url: "addTeam",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_addTeam",
          name: "Team 생성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "teamList",
              type: "",
            },
            {
              name: "Team 생성",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_modTeam",
          name: "Team 관리",
          addButton: "",
          logButton: "Team 수정 로그 보기",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Team 관리 - 목록",
              url: "teamList",
              type: "",
            },
            {
              name: "Team 관리",
              url: "",
              type: "",
            },
          ],
        },
        // Division 관리
        {
          id: "sysOps_divisionList",
          name: "Division 관리 - 목록",
          addButton: "신규 Division 등록",
          logButton: "",
          url: "addDivision",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_addDivision",
          name: "Division 생성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "divisionList",
              type: "",
            },
            {
              name: "Division 생성",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_modDivision",
          name: "Division 관리",
          addButton: "",
          logButton: "Division 수정 로그 보기",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "Division 관리 - 목록",
              url: "divisionList",
              type: "",
            },
            {
              name: "Division 관리",
              url: "",
              type: "",
            },
          ],
        },
        // 사용자 정보 관리
        {
          id: "sysOps_memberList",
          name: "사용자 정보 관리 - 목록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_detailMember",
          name: "사용자 정보 관리 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 목록",
              url: "memberList",
              type: "",
            },
            {
              name: "사용자 정보 관리 - 상세",
              url: "",
              type: "",
            },
          ],
        },
        // System Management
        {
          id: "sysOps_systemManagement",
          name: "System Management",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "System Management",
              url: "systemManagement",
              type: "",
            },
          ],
        },
        // API 관리
        {
          id: "sysOps_apiManageList",
          name: "API 관리 - 목록",
          addButton: "신규 API 등록",
          logButton: "",
          url: "addApiManage",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 관리 - 목록",
              url: "apiManageList",
              type: "",
            },
          ],
        },

        {
          id: "sysOps_apiManageDetail",
          name: "API 관리 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 관리 - 목록",
              url: "apiManageList",
              type: "",
            },
            {
              name: "API 관리 - 상세",
              url: "apiManageDetail",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_addApiManage",
          name: "API 관리 - 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 관리 - 목록",
              url: "apiManageList",
              type: "",
            },
            {
              name: "API 관리 - 등록",
              url: "addApiManage",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_modApiManage",
          name: "API 관리 - 수정",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 관리 - 목록",
              url: "apiManageList",
              type: "",
            },
            {
              name: "API 관리 - 수정",
              url: "modApiManage",
              type: "",
            },
          ],
        },
        // API 로그
        {
          id: "sysOps_apiLogList",
          name: "API 로그 - 목록",
          addButton: "",
          logButton: "",
          url: "apiLogList",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 로그 - 목록",
              url: "apiLogList",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_apiLogDetail",
          name: "API 로그 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 로그 - 목록",
              url: "apiLogList",
              type: "",
            },
            {
              name: "API 로그 - 상세",
              url: "apiLogDetail",
              type: "",
            },
          ],
        },

        {
          id: "sysOps_apiDashboard",
          name: "API 모니터링 대시보드",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "API 모니터링 대시보드",
              url: "apiDashboard",
              type: "",
            },
          ],
        },

        {
          id: "sysOps_codeManage",
          name: "공통코드 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "공통코드 관리",
              url: "codeManage",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_siteSetList",
          name: "(R) 작업 Site 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "(R) 작업 Site 관리",
              url: "siteSetList",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_topicSetList",
          name: "(R) Data Topic Set 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "(R) Data Topic Set 관리",
              url: "topicSetList",
              type: "",
            },
          ],
        },
        {
          id: "sysOps_actMsnSetList",
          name: "(R) Action/Mission 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "시스템 운영",
              url: "",
              type: "",
            },
            {
              name: "(R) Action/Mission 관리",
              url: "actMsnSetList",
              type: "",
            },
          ],
        },

        // Edge Device 관리
        {
          id: "deviceNDelivery_edgeDeviceList",
          name: "Edge Device 관리 - 목록",
          addButton: "신규 Edge Device 등록",
          logButton: "",
          url: "addEddgeDevice",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Edge Device 관리 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_addEddgeDevice",
          name: "신규 Edge Device 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Edge Device 관리 - 목록",
              url: "edgeDeviceList",
              type: "",
            },
            {
              name: "신규 Edge Device 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_detailEddgeDevice",
          name: "Edge Device 관리 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Edge Device 관리 - 목록",
              url: "edgeDeviceList",
              type: "",
            },
            {
              name: "Edge Device 관리 - 상세",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_modEddgeDevice",
          name: "Edge Device 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Edge Device 관리 - 목록",
              url: "edgeDeviceList",
              type: "",
            },
            {
              name: "Edge Device 관리 - 상세",
              url: "detailEdgeDevice",
              type: "params",
              paramIndex: 0,
            },
            {
              name: "Edge Device 관리",
              url: "",
              type: "",
            },
          ],
        },
        // Fleet 그룹
        {
          id: "deviceNDelivery_fleetGroupList",
          name: "Fleet 그룹 - 목록",
          addButton: "신규 Fleet 그룹 등록",
          logButton: "",
          url: "addFleetGroup",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Fleet 그룹 - 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_addFleetGroup",
          name: "신규 Fleet 그룹 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Fleet 그룹 - 목록",
              url: "fleetGroupList",
              type: "",
            },
            {
              name: "신규 Fleet 그룹 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_detailFleetGroup",
          name: "Fleet 그룹 - 상세",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Fleet 그룹 - 목록",
              url: "fleetGroupList",
              type: "",
            },
            {
              name: "Fleet 그룹 - 상세",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "deviceNDelivery_modFleetGroup",
          name: "Fleet 그룹 관리",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "장치/배포관리",
              url: "",
              type: "",
            },
            {
              name: "Fleet 그룹 - 목록",
              url: "fleetGroupList",
              type: "",
            },
            {
              name: "Fleet 그룹 - 상세",
              url: "detailFleetGroup",
              type: "params",
              paramIndex: 0,
            },
            {
              name: "Fleet 그룹 관리",
              url: "",
              type: "",
            },
          ],
        },
        // 커뮤니케이션 - 공지사항
        {
          id: "communication_notice",
          name: "공지사항 - 목록",
          addButton: "공지사항 등록",
          logButton: "",
          url: "addNotice",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "공지사항 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addNotice",
          name: "공지사항 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "공지사항 목록",
              url: "/commu/notice",
              type: "",
            },
            {
              name: "공지사항 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addNotice_correction",
          name: "공지사항 수정",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "공지사항 목록",
              url: "/commu/notice",
              type: "",
            },
            {
              name: "공지사항 수정",
              url: "",
              type: "",
            },
          ],
        },
        // 커뮤니케이션 - 약관
        {
          id: "communication_terms",
          name: "약관 - 목록",
          addButton: "약관 등록",
          logButton: "",
          url: "addTerms",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "약관 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addTerms",
          name: "약관 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "약관 목록",
              url: "/commu/terms",
              type: "",
            },
            {
              name: "약관 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addTerms_correction",
          name: "약관 수정",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "약관 목록",
              url: "/commu/terms",
              type: "",
            },
            {
              name: "약관 수정",
              url: "",
              type: "",
            },
          ],
        },
        // 커뮤니케이션 - CS
        {
          id: "communication_cs",
          name: "CS - 목록",
          addButton: "CS 등록",
          logButton: "",
          url: "addCS",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "CS 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addCS",
          name: "CS 등록",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "CS 목록",
              url: "/commu/cs",
              type: "",
            },
            {
              name: "CS 등록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addCS_correction",
          name: "CS 수정",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "CS 목록",
              url: "/commu/cs",
              type: "",
            },
            {
              name: "CS 수정",
              url: "",
              type: "",
            },
          ],
        },
        // 커뮤니케이션 - 메시지
        {
          id: "communication_message",
          name: "메시지 목록",
          addButton: "메시지 작성",
          logButton: "",
          url: "addMessage",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "메시지 목록",
              url: "",
              type: "",
            },
          ],
        },
        {
          id: "communication_addMessage",
          name: "메시지 작성",
          addButton: "",
          logButton: "",
          url: "",
          subItems: [
            {
              name: "켜뮤니케이션",
              url: "",
              type: "",
            },
            {
              name: "메시지 목록",
              url: "/commu/message",
              type: "",
            },
            {
              name: "메시지 전송",
              url: "",
              type: "",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.setPage();
  },
  watch: {
    pageId() {
      this.setPage();
    },
    // params() {
    //     this.setPage();
    // },
    name() {
      this.setPage();
    },
  },
  methods: {
    setPage() {
      const itemSize = this.items.length;

      for (let i = 0; i < itemSize; i++) {
        const item = this.items[i];
        if (item.id === this.pageId) {
          this.data = item;
          this.dataList = item.subItems;
          break;
        }
      }

      const name = this.name;
      if (typeof name !== "undefined" && name !== null && name !== "") {
        this.data.name = name;
      }

      if (this.dataList.length > 0) {
        this.lastIndex = this.dataList.length - 1;
      }

      const subItems = this.subItems;
      if (
        typeof subItems !== "undefined" &&
        subItems !== null &&
        subItems !== ""
      ) {
        const dataListLen = this.dataList.length;
        for (let j = 0; j < dataListLen; j++) {
          const data = this.dataList[j];
          if (typeof data.paramIndex !== "undefined") {
            const index = data.paramIndex;
            this.dataList[j].name = this.subItems[index].name;
            this.dataList[j].url = this.subItems[index].url;
          }
        }
      }
    },

    goBcPage(item) {
      const url = item.url;
      const type = item.type;

      if (typeof url !== "undefined" && url !== null && url !== "") {
        switch (type) {
          case "":
            this.goPageDefault(url);
            break;
          case "params":
            this.goPageParams(url, this.subParams[item.paramIndex]);
            break;
          case "query":
            this.goPageQuerys(url, this.subParams[item.paramIndex]);
            break;
        }
      }
    },

    goAddPage() {
      if (this.checkRole) {
        const url = this.data.url;

        if (typeof url !== "undefined" && url !== null && url !== "") {
          this.goPageDefault(url);
        }
      }
    },

    goModPage() {
      if (this.checkRole) {
        const url = this.data.url;
        const type = this.data.type;

        if (url !== "undefined" && url !== null && url !== "") {
          switch (type) {
            case "":
              this.goPageDefault(url);
              break;
            case "params":
              this.goPageParams(url, this.params);
              break;
            case "query":
              this.goPageQuerys(url, this.params);
              break;
          }
        }
      }
    },

    goPageDefault(url) {
      this.$router.push({
        name: url,
      });
    },
    goPageParams(url, params) {
      this.$router.push({
        name: url,
        params: params,
      });
    },
    goPageQuerys(url, query) {
      this.$router.push({
        name: url,
        query: query,
      });
    },
  },
};
</script>
