<template>
  <div class="chat-container">
    <div class="chat-box" ref="chatBox">
      <div v-for="(message, index) in messages" :key="index" class="message" :class="{'user': message.sender === 'user', 'bot': message.sender === 'bot'}">
        <div v-if="message.sender === 'bot'" class="bot-avatar">🔎</div>
        <!-- 텍스트 메시지 렌더링 -->
        <div v-if="message.type === 'text'" class="message-content" v-html="message.text"></div>
        <!-- 동적 컴포넌트 렌더링 -->
        <div v-else-if="message.type === 'component'" class="message-content">
          <component :is="message.component" v-bind="message.props"></component>
        </div>
      </div>
    </div>
    <div class="input-container rounded-container">
      <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="메시지를 입력하세요..." class="message-input" />
      <button @click="sendMessage" class="send-button">전송</button>
    </div>
  </div>
</template>

<script>
import {ref, onUpdated, nextTick, markRaw} from 'vue';
import axios from 'axios';
import { marked } from 'marked';
import conf from "@/assets/js/conf";
import ChatBotMsgComp from "@/views/componentType/ChatBotMsgComp.vue";

export default {
  components: {ChatBotMsgComp},
  setup() {
    const threadId = Date.now().toString(36);
    // decoding : parseInt(threadId, 36);

    const messages = ref([
      { sender: 'bot', text: '안녕하세요! 무엇이 궁금하세요?', type: 'text'}
    ]);
    const newMessage = ref('');
    const chatBox = ref(null);

    const sendMessage = async () => {
      if (newMessage.value.trim() === '') return;

      messages.value.push({ sender: 'user', text: newMessage.value, type: 'text' });
      const userMessage = newMessage.value;
      newMessage.value = '';

      let loadingText = "응답을 기다리는 중 ...";
      const loadingMessage = { sender: 'bot', text: `${loadingText}`, type: 'text' };
      messages.value.push(loadingMessage);

      const baseURL = conf.getAIDTBaseURL();
      const url = baseURL + '/rag'
      const headerParams = {
        "Content-Type": "application/json"
      }

      try {
        const payload = {
          userId: "jaegyu.lee@hlcompany.com",
          threadId: threadId,
          content: userMessage
        }
        const response = await axios.post(url,  payload, { headers: headerParams });

        const ans_content = response.data.answer.content;

        messages.value.pop(); // 로딩 메시지 제거
        messages.value.push({
          sender: 'bot',
          component: markRaw(ChatBotMsgComp),
          props: { ans_content }, // items를 props로 전달
          type: 'component'
        });

      } catch (error) {
        messages.value.pop(); // 로딩 메시지 제거
        messages.value.push({ sender: 'bot', text: marked('**오류가 발생했습니다.** 다시 시도해주세요.'), type: 'text'});
      }
    };

    onUpdated(() => {
      nextTick(() => {
        if (chatBox.value) {
          chatBox.value.scrollTo({ top: chatBox.value.scrollHeight, behavior: 'smooth' });
        }
      });
    });

    return { messages, newMessage, sendMessage, chatBox };
  }
};
</script>

<style scoped>
::v-deep(*)
{
  all: revert;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.chat-container {
  width: 1200px;
  display: flex;
  flex-direction: column;
  height: 65vh;
  justify-content: space-between;
  padding: 10px;
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
}

.message.bot {
  justify-content: flex-start;
}

.message.user {
  justify-content: flex-end;
  align-self: flex-end;
  text-align: right;
}

.bot-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  margin-right: 10px;
}

.message-content {
  padding: 10px 15px;
  border-radius: 20px;
  background: #e0e0e0;
  color: black;
  max-width: 100%;
  font-size: 14px;
}

.message.user .message-content {
  background: #007bff;
  color: white;
  text-align: left;
}

.input-container {
  display: flex;
  padding: 10px;
  background: #ffffff;
  border-top: 1px solid #ddd;
}

.input-container.rounded-container {
  border-radius: 20px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  background: white;
  color: black;
}

.send-button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  background: #007bff;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

</style>