<template>
  <div id="chart"></div>
</template>

<script>
import ApexCharts from "apexcharts";

export default {
  name: "ApexChartController",
  components: {},
  computed: {},
  props: {
    signalLabels: {
      type: Array,
      default: null,
    },
    signalData: {
      type: Array,
      default: null,
    },
  },
  watch: {},
  data() {
    return {
      chart: null,
      chartOptions: null,
      series: null,
    };
  },

  created() {},
  mounted() {
    this.createChart();
  },
  methods: {
    updateChart(datas, labels) {
      this.chart.updateOptions({
        series: datas,
        xaxis: {
          categories: labels,
        },
      });
    },
    updateChartColor(colors) {
      this.chart.updateOptions({
        colors: colors,
      });
    },
    destroyChart() {
      this.chart.resetSeries(true, false);
    },
    createChart() {
      const vm = this;
      this.chartOptions = {
        chart: {
          offsetX: 0,
          height: "75%",
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          toolbar: {
            show: false,
          },
        },

        series: [
          {
            name: "Desktops",
            data: [],
          },
        ],

        stroke: {
          curve: "smooth",
        },
        // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        //labels: vm.signalLabels,
        xaxis: {
          categories: [],
          tickPlacement: "on",
          range: 60,
        },
      };
      this.chart = new ApexCharts(
        document.querySelector("#chart"),
        vm.chartOptions
      );

      this.chart.render();
    },
  },
};
</script>
<style scoped lang="scss">
#chart {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
