<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Edge Inference 모니터링</span>
                </div>
                <h2>[TASK_VEIM] {{taskInfo.taskName}}</h2>
            </div> -->
      <BreadcrumbLayout
        pageId="monTASK_taskVEIM"
        :name="'[TASK_VEIM] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div id="monCont" class="edge_inference">
          <div class="filter_box">
            <select
              name="a"
              style="width: 165px; background-position: 135px center"
              v-model="selectInfResult"
            >
              <option selected :value="null">Inference 결과</option>
              <option value="ok">OK</option>
              <option value="ng">NG</option>
            </select>
            <select
              name="a"
              style="width: 115px; background-position: 85px center"
              v-model="selectResult"
            >
              <option selected :value="null">결과</option>
              <option value="0">정탐</option>
              <option value="1">오탐</option>
            </select>
            <select
              name="a"
              style="width: 115px; background-position: 85px center"
              v-model="selectStatus"
            >
              <option selected :value="null">상태</option>
              <option value="0">등록</option>
              <option value="1">처리중</option>
              <option value="2">처리완료</option>
            </select>
            <div class="date_filter">
              <select
                name="datelist"
                style="width: 110px; background-position: 80px center"
                @change="dateSelectChange"
                v-model="selectDate"
              >
                <option selected :value="null">1일</option>
                <option value="7">7일</option>
                <option value="14">14일</option>
                <option value="30">30일</option>
              </select>
              <div class="date_period">
                <div class="date_box">
                  <span :style="picStartTxtStyle" ref="picStartTxt">{{
                    picStartDate
                  }}</span>
                  ~
                  <span :style="picEndTxtStyle" ref="picEndTxt">{{
                    picEndDate
                  }}</span>
                </div>
                <button
                  type="button"
                  ref="datePicToggle"
                  @click="datePicToggle()"
                ></button>
                <div
                  class="calender_box radiusbox"
                  ref="calenderBox"
                  style="display: none"
                >
                  <div
                    class="start_date date_box"
                    ref="startDateBox"
                    @click="onDatePic('S')"
                    style="display: block"
                  >
                    <VCalendarUi :dateInterface="'start'" />
                    <button type="button" @click="pickerNext()">다음</button>
                  </div>
                  <div
                    class="end_date date_box"
                    ref="endDateBox"
                    @click="onDatePic('E')"
                  >
                    <VCalendarUi
                      :dateInterface="'end'"
                      :minDate="picStartDate"
                    />
                    <button type="button" @click="pickerPrev()">이전</button>
                    <button type="button" class="mcbtn" @click="getDriList()">
                      검색
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn_send mcbtn"
                @click="getNgStatusAndInfo"
              >
                조회
              </button>
            </div>
          </div>
          <div class="ng_status radiusbox">
            <div class="title_box">
              <strong>NG Status</strong>
            </div>
            <div class="status_box float_box">
              <div>
                <div class="status_title">발생</div>
                <div class="align_right">
                  <strong>{{ ngStatus.all_occur }}</strong>
                  <span>건</span>
                </div>
              </div>
              <div>
                <div class="status_title">처리 중</div>
                <div class="align_right">
                  <strong>{{ ngStatus.processing }}</strong>
                  <span>건</span>
                </div>
              </div>
              <div>
                <div class="status_title">처리 완료</div>
                <div class="align_right">
                  <strong>{{ ngStatus.process_complete }}</strong>
                  <span>건</span>
                </div>
              </div>
              <div>
                <div class="status_title">정탐</div>
                <div class="align_right">
                  <strong>{{ ngStatus.true_negative }}</strong>
                  <span>건</span>
                </div>
              </div>
              <div>
                <div class="status_title">오탐</div>
                <div class="align_right">
                  <strong>{{ ngStatus.false_positive }}</strong>
                  <span>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="edge_inference_items radiusbox">
            <div class="mando_table_wrap">
              <table class="mando_table edge_inference_table">
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 24%" />
                  <col style="width: 24%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                  <col style="width: 15%" />
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">NG 이미지</th>
                    <th colspan="2">정보</th>
                    <th rowspan="2">Inference <br />결과</th>
                    <th rowspan="2">결과</th>
                    <th rowspan="2">상태</th>
                  </tr>
                  <tr>
                    <th>Meta 정보</th>
                    <th>Inference 정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in ngDataList" :key="index">
                    <td>
                      <div class="img_cell">
                        <div>
                          <span>발생일자 : </span>
                          <span>{{ item.create_date }}</span>
                        </div>
                        <!-- <div>
                                                    <span>담당자 : </span>
                                                    <span>{{ item.worker }}</span>
                                                </div> -->
                        <div
                          class="img_box"
                          @click="showModal(item.image_path)"
                        >
                          <img :src="item.image_path" alt="" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="text_cell">
                        <p>
                          CODE : <br />
                          MESSAGE : <br />
                          PLANT : {{ item.plant }} <br />
                          WC : {{ item.wc }} <br />
                          OP_NO : {{ item.op_no }} <br />
                          MCH_NO : {{ item.mch_no }} <br />
                          MODEL_NO : {{ item.model_no }} <br />
                          SHIFT : {{ item.shift }} <br />
                          TIME : {{ item.time }} <br />
                          RESULT : {{ item.result }} <br />
                          SERIAL_NO : {{ item.serial_no }} <br />
                          PLT_NO : {{ item.plt_no }} <br />
                          REWORK_CNT : {{ item.rework_cnt }} <br />
                          CT : {{ item.ct }} <br />
                          POINT : {{ item.point }} <br />
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="text_cell">
                        <p>
                          ML_RESULT : {{ item.ml_result }} <br />
                          ACCURACY : {{ item.ml_accuracy }} <br />
                          SERVING TIME : {{ item.ml_serving_time }} <br />
                          ML_VERSION : {{ item.ml_version }} <br />
                          ML_PREVIOUS_VERSION : {{ item.ml_previous_version }}
                          <br />
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="status_cell">
                        <span v-if="item.ml_result == 'ok'">OK</span>
                        <span v-else-if="item.ml_result == 'ng'">NG</span>
                      </div>
                    </td>
                    <td>
                      <div class="input_cell">
                        <select
                          name="a"
                          v-bind:disabled="!manageRole"
                          style="width: 100px; background-position: 70px center"
                          :value="item.check_result"
                          @change="onSelectChangeResult"
                        >
                          <option disable selected :value="null">전체</option>
                          <option value="0">정탐</option>
                          <option value="1">오탐</option>
                        </select>
                        <button
                          type="button"
                          v-bind:disabled="!manageRole"
                          class="btn_send mcbtn"
                          @click="
                            updateSelectCheckResult(
                              item.uid,
                              item.check_result,
                              updateResult
                            )
                          "
                        >
                          저장
                        </button>
                      </div>
                    </td>
                    <td>
                      <div class="input_cell">
                        <select
                          name="a"
                          v-bind:disabled="!manageRole"
                          style="width: 120px; background-position: 90px center"
                          :value="item.status"
                          @change="onSelectChangeStatus"
                        >
                          <option disable selected :value="null">전체</option>
                          <option value="0">등록</option>
                          <option value="1">처리중</option>
                          <option value="2">처리완료</option>
                        </select>
                        <button
                          type="button"
                          v-bind:disabled="!manageRole"
                          class="btn_send mcbtn"
                          @click="
                            updateSelectStatus(
                              item.uid,
                              item.status,
                              updateStatus
                            )
                          "
                        >
                          저장
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="more_btn_box">
            <!-- 20 add -->
            <button type="button" class="btn_call_itme" @click="showMoreNgData">
              더보기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap" style="display: none">
    <!-- 취소 -->
    <div class="radiusbox modal_contents add_item_modal" ref="modalChart">
      <div class="modal_img_box">
        <img :src="modalImgSrc" alt="" />
      </div>
      <button type="button" class="btn_close" @click="closeModal()"></button>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import common from "@/assets/js/common";
import VCalendarUi from "@/components/VCalendarUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    BreadcrumbLayout,
  },
  data() {
    const picStartDate = null;
    const picEndDate = null;
    const dateSet = 1;
    return {
      taskId: "",
      // -- VCalendarUi / .date_period
      picStartDate,
      picEndDate,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},
      ngStatus: {},
      ngDataList: [],
      listCount: 10,
      listOffset: 0,

      selectInfResult: null,
      selectResult: null,
      selectStatus: null,
      selectDate: null,

      dateSet,

      updateResult: null,
      updateStatus: null,

      zero: true,
      propTitle: "mypdf",

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      // modal
      modalImgSrc: "",
    };
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  async mounted() {
    // Role
    await this.setRole();

    const vm = this;
    const stoday = new Date();
    stoday.setDate(stoday.getDate() - 1);
    const syear = stoday.getFullYear();
    const smonth = stoday.getMonth() + 1;
    const sday = stoday.getDate();
    const start_formattedDate =
      syear +
      "-" +
      (smonth < 10 ? "0" : "") +
      smonth +
      "-" +
      (sday < 10 ? "0" : "") +
      sday;

    const etoday = new Date();
    const eyear = etoday.getFullYear();
    const emonth = etoday.getMonth() + 1;
    const eday = etoday.getDate();
    const end_formattedDate =
      eyear +
      "-" +
      (emonth < 10 ? "0" : "") +
      emonth +
      "-" +
      (eday < 10 ? "0" : "") +
      eday;

    vm.picStartDate = start_formattedDate;
    vm.picEndDate = end_formattedDate;
    window.selectDate.startDate = start_formattedDate;
    window.selectDate.endDate = end_formattedDate;

    await this.getProjectData();

    this.taskId = this.$route.query.taskId;
    await this.setTaskInfo();

    this.projectId = this.$route.query.prjId;
  },
  methods: {
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },

    // async allowAccess() {
    //     const accessPage = await this.checkLoginUser();
    //     console.log(accessPage);

    //     if(!accessPage) {
    //             alert("SM, Project Manager,Project Researcher,Project Observer만 접근 가능");
    //             this.$router.push('/monTASK/monTaskList');
    //     }
    // },

    // async checkLoginUser() {
    //     const role = await this.getMemberRole();
    //     const projectRole = await this.getProjectRole();

    //     // console.log(role);
    //     // console.log(projectRole);

    //     if(role != null && role.role == 'SM') {
    //         return true;
    //     } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher" || projectRole.projectRole == "Observer")) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // },

    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },
    onDatePic(flag) {
      if (flag == "S") {
        if (window.selectDate.startDate !== null) {
          this.picStartDate = window.selectDate.startDate;
        }
      } else if (flag == "E") {
        if (window.selectDate.picEndDate !== null) {
          this.picEndDate = window.selectDate.endDate;
        }
      }
    },
    pickerNext() {
      this.$refs.startDateBox.style.display = "none";
      this.$refs.endDateBox.style.display = "block";

      this.picColor.startStatus = false;
      this.picColor.endStatus = true;
      console.log(this.picColor.endStatus);
      console.log(this.picColor.target);
    },
    pickerPrev() {
      this.$refs.startDateBox.style.display = "block";
      this.$refs.endDateBox.style.display = "none";

      this.picColor.startStatus = true;
      this.picColor.endStatus = false;
    },
    dateSelectChange() {
      switch (this.selectDate) {
        case "7":
          this.dateSet = 7;
          break;
        case "14":
          this.dateSet = 14;
          break;
        case "30":
          this.dateSet = 30;
          break;
        default:
          this.dateSet = 1;
          break;
      }

      const todayDate = new Date();

      todayDate.setDate(todayDate.getDate() - this.dateSet);
      const year1 = todayDate.getFullYear();
      const month1 = todayDate.getMonth() + 1;
      const day1 = todayDate.getDate();
      const formattedDate1 =
        year1 +
        "-" +
        (month1 < 10 ? "0" : "") +
        month1 +
        "-" +
        (day1 < 10 ? "0" : "") +
        day1;

      this.picStartDate = formattedDate1;
      window.selectDate.startDate = formattedDate1;
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);
      console.log(vm.$refs.startDateBox);
      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    async getDriList() {
      const vm = this;
      const $calenderBox = vm.$refs.calenderBox;
      const $startDateBox = vm.$refs.startDateBox;
      const $endDateBox = vm.$refs.endDateBox;

      $calenderBox.style.display = "none";
      $startDateBox.style.display = "block";
      $endDateBox.style.display = "none";

      vm.picColor.startStatus = false;
      vm.picColor.endStatus = false;

      console.log(vm.picStartDate);
      console.log(vm.picEndDate);

      await this.getNgStatusAndInfo();
    },
    async getNgStatusAndInfo() {
      this.getNgStatus();
      this.getNgDataList();
    },

    async getNgStatus() {
      const url = "/SF/ng_001";
      const params = {
        start_date: this.picStartDate,
        end_date: this.picEndDate,
      };

      try {
        const response = await common.apiDl2Post(url, params, "F");
        const resData = response.data;

        const data = {
          all_occur: resData.all_occur
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
          processing: resData.processing
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
          process_complete: resData.process_complete
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
          true_negative: resData.true_negative
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
          false_positive: resData.false_positive
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        };

        this.ngStatus = data;
      } catch (error) {
        console.error("error : ", error);
      }
    },
    async getNgDataList() {
      const url = "/SF/ng_002";

      this.listOffset = 0;

      let infResult = this.selectInfResult;
      if (infResult == null) infResult = "";
      let result = this.selectResult;
      if (result == null) result = "";
      let status = this.selectStatus;
      if (status == null) status = "";

      const params = {
        start_date: this.picStartDate,
        end_date: this.picEndDate,
        ml_result: infResult,
        check_result: result,
        status: status,
        list_count: this.listCount,
        list_offset: this.listOffset,
      };
      await common
        .apiDl2Post(url, params, "F")
        .then((res) => {
          console.log(res);
          this.ngDataList = res.data.result;
          this.listOffset += this.listCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async showMoreNgData() {
      const url = "/SF/ng_002";

      let infResult = this.selectInfResult;
      if (infResult == null) infResult = "";
      let result = this.selectResult;
      if (result == null) result = "";
      let status = this.selectStatus;
      if (status == null) status = "";

      const params = {
        start_date: this.picStartDate,
        end_date: this.picEndDate,
        ml_result: infResult,
        check_result: result,
        status: status,
        list_count: this.listCount,
        list_offset: this.listOffset,
      };

      try {
        const response = await common.apiDl2Post(url, params, "F");
        const data = response.data.result;

        if (data.length == 0) {
          alert("no more data");
          return;
        }
        console.log(this.ngDataList.length);
        data.forEach((e) => {
          this.ngDataList.push(e);
        });
        console.log(this.ngDataList.length);
        this.listOffset += this.listCount;
      } catch (error) {
        console.log(error);
      }
    },
    onSelectChangeResult(event) {
      this.updateResult = event.target.value;
    },
    onSelectChangeStatus(event) {
      this.updateStatus = event.target.value;
    },

    async updateSelectCheckResult(uid, result, updateResult) {
      if (this.manageRole) {
        const url = "/SF/ng_003";
        const data = {
          check_result: updateResult,
          uid: uid,
        };

        let statusMessage;
        if (updateResult == 0) {
          statusMessage = `update check_result successfully ${uid} to 정탐`;
        } else {
          statusMessage = `update check_result successfully ${uid} to 오탐`;
        }

        try {
          await common.apiDl2Post(url, data, "F");
          alert(statusMessage);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateSelectStatus(uid, status, updateStatus) {
      if (this.manageRole) {
        const url = "/SF/ng_004";
        const data = {
          status: updateStatus,
          uid: uid,
        };

        let statusMessage;
        if (updateStatus == 0) {
          statusMessage = `update status successfully ${uid} to 등록`;
        } else if (updateStatus == 1) {
          statusMessage = `update status successfully ${uid} to 처리중`;
        } else {
          statusMessage = `update status successfully ${uid} to 처리완료`;
        }

        try {
          await common.apiDl2Post(url, data, "F");
          alert(statusMessage);
        } catch (error) {
          console.error(error);
        }
      }
    },

    // async checkUserCanAccessButton() {
    //     const role = await this.getMemberRole();
    //     const projectRole = await this.getProjectRole();

    //     if(role != null && role.role == 'SM') {
    //         return true;
    //     } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher")) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    // modal
    showModal(src) {
      if (common.nullCheck(src) !== "") {
        const $modal = this.$refs.modalWrap;
        const $modalChart = this.$refs.modalChart;
        $modal.style.display = "block";
        $modalChart.style.display = "block";
        this.modalImgSrc = src;
      }
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalChart = this.$refs.modalChart;
      $modal.style.display = "none";
      $modalChart.style.display = "none";
    },
  },
};
</script>
<style scoped lang="scss">
.edge_inference {
  .ng_status {
    width: 100%;
    height: 144px;
    background-color: $whiteColor;
    box-shadow: 0px 0px 10px #002b6833;
    margin: 20px 0;
    .title_box {
      position: relative;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      strong {
        font-weight: $bold;
      }
    }
    .status_box {
      width: 100%;
      height: 45px;

      & > div {
        width: 20%;
        height: 45px;
        float: left;
        box-sizing: border-box;
        line-height: 45px;
        padding: 0 40px 0 20px;
        border-right: 1px solid $line01Color;
        &:last-child {
          border: none;
        }
        .status_title {
          width: 30%;
          display: inline-block;
          font-size: $fontSub;
          color: #6e7380;
        }
        .align_right {
          width: 70%;
          display: inline-block;
          text-align: right;
          strong {
            font-size: 30px;
            font-family: $montserrat;
          }
          span {
            font-family: $montserrat;
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .edge_inference_items {
    .mando_table_wrap {
      .mando_table {
        position: relative;
        table-layout: fixed;
        th {
          vertical-align: middle;
        }
        thead {
          tr {
            &:first-child {
              th {
                &:nth-child(1) {
                  border-right: 1px solid $line01Color;
                  &::after {
                    display: none;
                  }
                }
                &:nth-child(2) {
                  border-bottom: 1px solid $line01Color;
                  &::after {
                    display: none;
                  }
                }
                &:nth-child(3) {
                  border-left: 1px solid $line01Color;
                  &::after {
                    height: 50px;
                  }
                }
                &:nth-child(4) {
                  &::after {
                    height: 50px;
                  }
                }
              }
            }
          }
        }
        tbody {
          tr {
            width: 100%;
            height: 206px;
            &:hover {
              background-color: #fff;
            }
            td {
              box-sizing: border-box;
              padding: 0 !important;

              &:last-child {
                border: none;
              }
              & > div {
                height: 206px;
                border-right: 1px solid $line01Color;
                box-sizing: border-box;
                padding: 0 20px;
                margin: 20px 0;
              }
              .img_cell {
                text-align: left;
                & > div {
                  margin-bottom: 10px;
                  &:last-child {
                    margin: 0;
                  }
                }
                .img_box {
                  width: 200px;
                  height: 150px;
                  img {
                    cursor: pointer;
                    width: 100%;
                  }
                }
              }
              .text_cell {
                white-space: pre-line;
                text-align: left;
                height: auto;
              }
              .status_cell {
                line-height: 206px;
              }
              .input_cell {
                padding: 56px 0 0;
                .btn_send {
                  width: 60px;
                  height: 40px;
                  margin: 20px auto 0;
                  display: block;
                  text-align: center;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .more_btn_box {
    width: 100%;
    height: 60px;
    .btn_call_itme {
      width: 100%;
      height: 100%;
      line-height: 60px;
      font-weight: $bold;
      text-align: center;
      background-repeat: no-repeat;
      background-image: url($baseURL + "/common/select_arrowdown.svg");
      background-position: 665px 27px;
    }
  }
}
#modalWrap {
  .modal_contents {
    width: 930px;
    padding-top: 60px;
    .modal_img_box {
      width: 880px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
