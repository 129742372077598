<template>
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callAddDataTest" border="1">callAddDataTest@FileDrag</button>-->
  <!--  </div>-->
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callClearTableData" border="1">callClearTableData@FileDrag</button>-->
  <!--  </div>-->
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callClearStateData" border="1">callClearStateData@FileDrag</button>-->
  <!--  </div>-->
  <div
      class="drop-zone"
      @click="openFileDialog"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
      :class="{ 'drag-over': isDragging }"
  >
    <div v-if="!files.length">Drag & Drop files here or click to upload</div>
    <div v-else>
      <p v-for="(file, index) in files" :key="index">
        {{ file.name }} ({{ (file.size / 1024).toFixed(2) }} KB)
      </p>
    </div>
    <input type="file" ref="fileInput" multiple @change="onFileSelect" hidden />
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import papa from "papaparse";

export default {
  name: "DataParseComp",
  emits: ['callAddData','csvDataLength','callClearTableData', 'callClearStateData'],
  props: ['comp_type'],
  data() {
    return {
    }
  },
  setup(props, { emit }) {
    const files = ref([]);
    const isDragging = ref(false);
    const fileInput = ref(null);
    let csvData = ref([]);

    onMounted(() => {
      fileInput.value = fileInput.value || document.querySelector('input[type="file"]');
    });

    const onDragOver = () => {
      isDragging.value = true;
    };

    const onDragLeave = () => {
      isDragging.value = false;
    };

    const onDrop = (event) => {
      isDragging.value = false;
      handleFiles(event.dataTransfer.files);
    };

    const onFileSelect = (event) => {
      handleFiles(event.target.files);
    };

    const openFileDialog = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleFiles = (fileList) => {
      files.value = [...fileList];
      const file = files.value[0];
      // emit('files-added', files.value);
      csvFileParseAndAddData(file);
    };

    const csvFileParseAndAddData = (file) => {
      papa.parse(file, {
        // header: true,
        skipEmptyLines: true,
        complete: function(results) {
          csvData = results.data;
          // 기존 Data Grid 초기화
          callClearTableData();
          // 기존 State 초기화
          callClearStateData();
          // Call API and Data Insert to Grid
          callAddData(csvData);
        }
      });
    }

    const callAddData = (csvData) => {
      const csvDataLength = csvData.length;
      emit('csvDataLength', csvDataLength);

      csvData.forEach((each_row, index) => {
        if (index === 0) return; // header skip
        // console.log(each_row);
        const keys = ["Project", "ObjectText", "Category", "Domain", "TypeLLM", "CategoryLLM"];
        const row = keys.reduce((obj, key, index) => {
          obj[key] = each_row[index];
          return obj;
        }, {});
        emit('callAddData', row);
      })
    }

    const callAddDataTest = () => {
      const keys = ["Project", "ObjectText", "Category", "Domain", "TypeLLM", "CategoryLLM"];
      // const each_row_case1 = [
      //   "/Brake/IDB_FORD_GE2",
      //   "Mode definition and Interaction with Selectable Drive Mode-Feature (418334/A)",
      //   "Heading",
      //   "6-vehicle_stability",
      //   "",
      //   ""
      // ];
      // const each_row_case2 = [
      //   "/Brake/IDB_FORD_GE2",
      //   "4.1.1.1 Driver Information                                                                                                      (REQ-241393/A)	\nRequirement Status:	Released	\nUplink:	\n\nDownlink:	 REQ-241497/C-Local Variables , REQ-241496/A-Parameter	\nTest Requirement:	\n\nApplicable to:	\n\n\nNotes:	\n\n\nAcceptance Criteria:	\n\n\nLast Change:	\n\n\nLast modified:	22-Jul-2019 10:24	\nRationale:	\n\n\nPurpose:	\n\n\nRequirement Text:        \nThe information that is displayed to the driver based on a request by this function shall be harmonized with the logic of the actual displaying instance.",
      //   "Customer requirement",
      //   "6-vehicle_stability",
      //   "",
      //   ""
      // ];
      const each_row_case3 = [
        "/Brake/IDB_FORD_GE2",
        "This chapter represents the requirements for the ESC Switch evaluation logic. It interprets the input pattern made by the driver and populates the detected button event to the Mode Selection Logic.\\n\\nFigure 5:        State chart for Button Evaluation Logic",
        "Comment",
        "6-vehicle_stability",
        "",
        ""
      ];
      // array to json object (map array)
      const row = keys.reduce((obj, key, index) => {
        obj[key] = each_row_case3[index];
        return obj;
      }, {});

      emit('callAddData', row);
    }

    const callClearTableData = () => {
      emit('callClearTableData');
    }

    const callClearStateData = () => {
      emit('callClearStateData');
    }

    return {
      files,
      isDragging,
      fileInput,
      onDragOver,
      onDragLeave,
      onDrop,
      onFileSelect,
      openFileDialog,
      callAddData,
      callAddDataTest,
      callClearTableData,
      callClearStateData
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.drop-zone {
  width: 1210px;
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}
.drag-over {
  background: #f0f0f0;
}
</style>
