import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class CustomBlock extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('contenteditable', false); // 삭제 방지
    node.style.backgroundColor = value.backgroundColor || '#fff';
    node.style.color = value.color || '#000';
    node.style.fontSize = value.fontSize || '16px';
    node.innerText = value.text || 'Custom Block Content';
    return node;
  }

  static value(node) {
    return {
      backgroundColor: node.style.backgroundColor,
      color: node.style.color,
      fontSize: node.style.fontSize,
      text: node.innerText,
    };
  }
}

CustomBlock.blotName = 'customBlock'; // Quill blot 이름
CustomBlock.tagName = 'div'; // 사용할 DOM 태그

Quill.register(CustomBlock); // Quill에 등록
