<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_topicSetList" :checkRole="manageRole" />
      <div class="title_btn">
        <button
          type="button"
          class="add_project bgicon"
          @click="setTopic('insert')"
        >
          신규 Topic Set 등록
        </button>
      </div>
      <div class="list_wrap float_box">
        <div class="list_filter_wrap float_box">
          <div class="filter_list_box">
            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input_text">
                <input
                  type="text"
                  placeholder="KeyWord"
                  v-model="searchKeyword"
                />
              </div>
              <div class="search_form_box">
                <button
                  type="button"
                  class="btn_send mcbtn"
                  @click="clickSearch()"
                >
                  조회
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ dataList.length }}</span> Topic
            </div>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <colgroup></colgroup>
              <thead>
                <tr>
                  <th>
                    <div class="col_name">Topic Set 명</div>
                  </th>
                  <th>
                    <div class="col_name">등록자</div>
                  </th>
                  <th>
                    <div class="col_name">등록일</div>
                  </th>
                  <th>
                    <div class="col_name">수정일</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="m_pointer"
                  v-for="(item, index) in dataList"
                  :key="index"
                  @click="setTopic('view', item.topic_id)"
                >
                  <td>{{ item.topic_name }}</td>
                  <td>{{ item.register_name }}</td>
                  <td>{{ item.create_date }}</td>
                  <td>{{ item.update_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal" ref="modalWrap" style="display: none">
    <div class="info_modal radiusbox">
      <strong class="modal_title">Topic Set 등록</strong>
      <div class="info">
        <div class="row">
          <div class="title">등록자명</div>
          <div class="txt">
            <input type="text" v-model="topicSet.creater" disabled />
          </div>
          <div class="title">등록일자</div>
          <div class="txt">
            <input type="text" v-model="topicSet.createDate" disabled />
          </div>
        </div>
        <div class="row">
          <div class="title">Topic Set 이름</div>
          <div class="txt">
            <input type="text" v-model="topicSet.topicSetName" />
          </div>
          <div class="txt_already">
            <p v-if="topicSet.already">● 기존에 존재하는 Topic Set명 입니다.</p>
          </div>
        </div>
      </div>
      <div class="topic_area">
        <div class="title">Topics</div>
        <div class="topic">
          <div class="row">
            <div class="left">선택</div>
            <div class="right">Topic Name</div>
          </div>
          <div
            class="row row_content"
            v-for="(item, index) in topicSet.topics"
            :key="index"
          >
            <div class="left all_chk">
              <input
                type="checkbox"
                :id="'topic_item_' + index"
                :checked="item.isChecked"
                @change="chkTopic(item)"
              /><label :for="'topic_item_' + index"></label>
            </div>
            <div class="right">
              <input type="hidden" v-model="item.topicId" />
              <input type="text" class="topic_box" v-model="item.topicName" />
              <button
                v-if="topicSet.topics.length > 1"
                type="button"
                class="topic_remove"
                @click="removeTopic(index)"
              >
                <font-awesome-icon :icon="['fas', 'xmark']" />
              </button>
            </div>
          </div>
          <div class="save_box">
            <button
              type="button"
              class="close_btn btn_red btn_font_s"
              @click="removeTopics"
            >
              선택 삭제
            </button>
            <button type="button" class="mcbtn btn_font_s" @click="addTopic">
              + 추가
            </button>
          </div>
          <button
            type="button"
            class="btn_close"
            @click="closeModal()"
          ></button>
        </div>
        <div class="save_box">
          <button type="button" class="close_btn" @click="closeModal">
            취소
          </button>
          <button type="button" class="mcbtn" @click="registTopic">등록</button>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import "@/assets/js/filter";
import apiRobot from "@/assets/js/apiRobot";
import { mapGetters } from "vuex";

export default {
  name: "topicSetList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    PaginationUi,
    BreadcrumbLayout,
    VCalendarUi,
  },
  data() {
    return {
      filterList: [],
      filterRowData: [],
      perPageList: [],
      perPage: 0,

      searchKeyword: "",

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      dataList: [],
      dataListSliced: [],
      isfilter: true,
      filterDataList: {
        origin: {},
      },

      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 50,
      topicSet: {
        topicId: 0,
        creater: "",
        createDate: "",
        topicSetName: "",
        already: false,
        topics: [],
      },
      today: "",
    };
  },
  created() {},
  async mounted() {
    this.setRole();
    this.setDate(this.selectDuration);
    this.$refs.lodingWrap.style.display = "block";
    await this.getTopicList();
    this.$refs.lodingWrap.style.display = "none";
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    ...mapGetters(["getUserData"]),
  },
  methods: {
    async registTopic() {
      try {
        const topicId = this.topicSet.topicId;
        const topicSetName = this.topicSet.topicSetName;
        const topicName = this.topicSet.topicSetName;
        const topicItems = [];
        const userId = this.getUserData.memId;
        const userName = this.getUserData.name;

        let selectedNum = 0;
        for (let item of this.topicSet.topics) {
          if (item.isChecked) {
            selectedNum++;
          }
        }
        if (topicSetName === "") {
          alert("Topic Set 이름을 입력해 주세요.");
          return false;
        }
        if (selectedNum === 0) {
          alert("최소 1개 이상의 Topic을 선택해 주세요.");
          return false;
        }

        for (let i = 0; i < this.topicSet.topics.length; i++) {
          const idx = i;
          const topicName = this.topicSet.topics[i].topicName;
          if (this.topicSet.topics[i].isChecked) {
            if (this.topicSet.topics[i].topicName === "") {
              alert("선택된 Topic은 공백으로 입력할 수 없습니다.");
              return false;
            } else {
              const param = {
                idx: idx,
                name: topicName,
              };
              topicItems.push(param);
            }
          }
        }

        let response = null;
        if (topicId !== null && topicId !== 0) {
          const param = {
            topic_id: topicId,
            topic_name: topicName,
            topic_items: topicItems,
            register_id: userId,
            register_name: userName,
            updater_id: userId,
            updater_name: userName,
          };

          response = await apiRobot.post("/topic/topic_update", param);
        } else {
          const param = {
            topic_name: topicName,
            topic_items: topicItems,
            register_id: userId,
            register_name: userName,
          };

          response = await apiRobot.post("/topic/topic_insert", param);
        }
        if (response.status === 200 || response.status === "200") {
          if (response.data.result === "already") {
            this.topicSet.already = true;
            return false;
          } else {
            alert("Topic Set이 등록되었습니다.");
            this.closeModal();
            this.getTopicList();
            this.topicSet.topicSetName = "";
            this.topicSet.topics = [];
            const param = {
              topicName: "",
              isChecked: false,
            };
            this.topicSet.topics.push(param);
          }
        }
      } catch (error) {
        console.error("error", error);
      }
    },

    removeTopics() {
      this.topicSet.topics = this.topicSet.topics.filter((e) => !e.isChecked);
    },
    removeTopic(index) {
      this.topicSet.topics.splice(index, 1);
    },
    addTopic() {
      const param = {
        topicName: "",
        isChecked: false,
      };
      this.topicSet.topics.push(param);
    },
    chkTopic(item) {
      item.isChecked ? (item.isChecked = false) : (item.isChecked = true);
    },
    async setTopic(status, topicId) {
      if (status === "insert") {
        if (this.topicSet.topics.length < 1) {
          const param = {
            topicName: "",
            isChecked: false,
          };
          this.topicSet.topics.push(param);
        }
        this.topicSet.creater = this.getUserData.name;
        this.topicSet.createDate = this.today;
      } else {
        await this.setTopicDetail(topicId);
      }
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "block";
    },
    async setTopicDetail(topicId) {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      try {
        const param = {
          topic_id: topicId,
        };
        const response = await apiRobot.post("/topic/topic_select", param);
        if (response.status === 200 || response.status === "200") {
          const data = response.data.item;
          const item = response.data.items;

          this.topicSet.topicId = topicId;
          this.topicSet.topicSetName = "";
          this.topicSet.topics = [];
          this.topicSet.topicSetName = data.topic_name;
          this.topicSet.creater = data.register_name;
          this.topicSet.createDate = data.create_date;
          this.topicSet.already = false;
          for (let topic of item) {
            const param = {
              topicName: topic.name,
              topicId: topic.idx,
              isChecked: true,
            };
            this.topicSet.topics.push(param);
          }

          loading.style.display = "none";
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "none";
    },
    clickSearch() {
      this.getTopicList();
    },
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.today = vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },

    async getTopicList() {
      try {
        const keyword = this.searchKeyword;
        const startDate = this.picStartDate;
        const endDate = this.picEndDate;
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const limit = this.itemsPerPage;
        const param = {
          date_from: startDate,
          date_to: endDate,
          keyword: keyword,
          order_name: "create_date",
          order_value: "desc",
          limit: limit,
          offset: offset,
        };
        console.log("%%%%%%%%%%%%% :: ", param);
        const response = await apiRobot.post("/topic/topic_select_list", param);
        if (response.status === 200 || response.status === "200") {
          //this.totalItems = response.data.total;
          //this.listOffset = response.data.current_offset;
          this.dataList = response.data.items;
        }
      } catch (error) {
        console.error("error", error);
      }
      this.currentPage = 1;
      this.totalItems = this.dataList.length;
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getTopicList();
    },

    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  .list_filter_wrap {
    overflow: visible;
    .filter_list_box {
      width: 100%;
      height: 100%;
      &::after {
        border-radius: 10px;
      }
      .filter_box {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        padding: 0 30px;
        height: 100%;
        box-sizing: border-box;
        .date_filter {
          position: relative;
          .date_period {
            margin: 0;
          }
          &::after {
            display: none;
          }
        }
        .input_text {
          input {
            width: 220px;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
        }
        .search_form_box {
          .btn_send {
            height: 40px;
            padding: 0 15px;
            border-radius: 10px;
            line-height: 37px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .table_list {
    margin-top: 20px;
    height: 715px;
  }
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
.title_btn {
  position: absolute;
  top: 30px;
  right: 42px;
  display: flex;
  gap: 15px;
  button {
    border-radius: 10px;
    font-weight: $bold;
    background-repeat: no-repeat;
    &.bgicon {
      padding: 13px 15px 13px 35px;
      color: $whiteColor;
      background-color: $mainColor;
      background-position-y: center;
      background-position-x: 10px;
      &.add_project {
        background-image: url($baseURL + "common/add_15_wh.svg");
      }
      &.setting_project {
        background-image: url($baseURL + "common/contents_btnicon_settings.svg");
      }
    }
    &.btn_view_log {
      background-color: $whiteColor;
      border: 1px solid #dedede;
      border-radius: 10px;
      padding: 13px 17px;
      font-weight: $regular;
    }
    &:disabled {
      background-color: $line02Color;
    }
  }
}
#modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .info_modal {
    width: 640px;
    padding: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal_title {
      display: block;
      font-size: $fontMenuTitle;
      font-weight: $bold;
      margin-bottom: 30px;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border-bottom: 1px solid #d5dae3;
      padding-bottom: 25px;
      .row {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        margin-top: 10px;
        .title {
          width: 15%;
          min-width: 100px;
        }
        .txt {
          flex: 1;
          input {
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
        }
        .txt_already {
          width: 50%;
          p {
            color: #f15046;
            font-weight: 600;
          }
        }
      }
    }
    .save_box {
      width: 100%;
      height: 45px;
      text-align: right;
      position: relative;
      margin: 20px 0;
      button {
        width: 90px;
        height: 45px;
        text-align: center;
        border-radius: 10px;
        margin: 0 10px;
        &.btn_font_s {
          width: 80px;
          height: 40px;
          font-size: 13px;
        }
      }
      .close_btn {
        border: 1px solid #dedede;
        &.btn_red {
          border: 0;
          color: #fff;
          background-color: #f15046;
        }
      }
    }
    .btn_close {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-image: url($baseURL + "/common/popup_cancel.svg");
      background-position: center;
      position: absolute;
      top: 6px;
      right: 6px;
    }
    .topic_area {
      padding-top: 25px;
      .title {
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: 600;
      }
      .topic {
        padding-top: 20px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        .row {
          display: flex;
          padding-bottom: 10px;
          align-items: center;
          .left {
            width: 85px;
            text-align: center;
            font-weight: 600;
          }
          .right {
            flex: 1;
            text-align: center;
            font-weight: 600;
          }
          &.row_content {
            margin-top: 10px;
            padding-bottom: 0;
            .left {
              font-weight: 500;
            }
            .right {
              position: relative;
              text-align: left;
              margin-right: 15px;
              font-weight: 500;
              .topic_box {
                width: 100%;
                padding: 0 10px;
                height: 35px;
                border: 1px solid #d5dae3;
                border-radius: 10px;
                box-sizing: border-box;
              }
              .topic_remove {
                position: absolute;
                display: inline-flex;
                top: 50%;
                right: 7px;
                width: 30px;
                height: 30px;
                transform: translateY(-50%);
                justify-content: center;
                align-items: center;
                background-color: #405261;
                border-radius: 50%;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
