<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="sysOps_portlet_detail"
        :checkRole="manageRole"
      />
      <div class="port_wrap">
        <div class="top_control">
          <input type="text" :value="template.name" class="tempname" disabled />
          <div>
            <select :value="template.customYn" :disabled="true">
              <option :value="'Y'">개인화 가능</option>
              <option :value="'N'">개인화 불가능</option>
            </select>

            <select
              name="prjType"
              ref="prjType"
              :disabled="true"
              :value="template.type"
            >
              <option
                v-for="(item, index) in prjTypeList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="right_layout">
            <button id="btn_edit" class="edit_button" @click="handleEdit">
              수정하기
            </button>
            <button id="btn_del" class="delete_button" @click="handleDelete">
              삭제
            </button>
          </div>
        </div>
        <div class="rightCell">
          <GridLayout
            v-model:layout="layout"
            :col-num="30"
            :row-height="30"
            class="templet_layout"
            style="margin-top: 10px"
            :is-draggable ="false"
          >
            <div class="no-drag">
              <grid-item
                v-for="item in components"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.key"
                :type="item.type"
                :style="item.boxLayer===true ?  'border: 2px solid gray':'  border: 0px solid #d4d4d4'"
        
              >
                <span class="text">{{ itemTitle(item) }}</span>
                <!-- <span class="setting_button" @click="settingOpen(item.i)"
                >설정</span
              > -->

                <component
                  v-if="item && item.i && item.type"
                  :is="
                    item.type === 'Filter'
                      ? 'FilterComponent'
                      : item.type === 'Button'
                      ? 'ButtonComponent'
                      : item.type === 'Map'
                      ? 'MapController'
                      : null
                  "
                  :key="item.key"
                  :mapId="'Map_' + comp_view_data_key"
                  :mapHeight="item.newHPx"
                  :editYn="'N'"
                  :otherProp="item"
                  :useButton ="item.useButton"
                  :handlerList="handlerList"
                ></component>
              </grid-item>
            </div>
          </GridLayout>
        </div>
      </div>
      <div class="setting_pop">
        <div class="setting_pop_cell">
          <div class="setting_pop_cell_x" @click="optionClose">X</div>
          <h4>{{ comp_name }}</h4>

          <component
            :is="
              this.settingRender === 'Filter'
                ? 'FilterComponentSetting'
                : this.settingRender === 'Button'
                ? 'ButtonComponentSetting'
                : null
            "
            :interface="1"
            :handlerList="handlerList"
            :otherProp="Math.floor(Date.now())"
          ></component>

          <div class="setting_pop_cell_bottom_button_box">
            <button>수정</button><button @click="optionClose">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import { GridLayout, GridItem } from "vue-grid-layout-v3";
import FilterComponent from "../testComponents/filter.vue";
import FilterComponentSetting from "../testComponents/filter_setting.vue";
import ButtonComponent from "../testComponents/button.vue";
import ButtonComponentSetting from "../testComponents/button_setting.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import MapController from "@/components/MapController.vue";
import MapControllerSetting from "@/components/MapControllerSetting.vue";
import _ from "lodash";
export default {
  name: "PortletTemplate2",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    GridLayout,
    GridItem,
    FilterComponent,
    FilterComponentSetting,
    ButtonComponent,
    ButtonComponentSetting,
    MapController,
    MapControllerSetting,
    PaginationUi,
  },
  data() {
    return {
      layout: [],
      draggable: true,
      resizable: true,
      index: 0,
      totalItems: 100,
      itemsPerPage: 10,
      Component1: "Filter",
      Component2: "Button",
      Component3: "Map",
      Component4: "Component4",
      comp_name: "",
      settingRender: "",
      baseUrl: "https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com",
      customYn: null,
      vessle: [],
      template: {},
      loadpop: true,
      didopen: false,
      templateName: "",
      isloaded: false,
      onload: true,
      components: {},
      prjTypeList: [],
      prjData: {
        prjId: "",
        prjType: "",
      },
      handlerList: [],
    };
  },
  mounted() {
    this.setRole();
    this.getTemplate();
    this.setPrjTypeList(this.getPrjTypeData());
  },
  methods: {
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },
    async getTemplate() {
      try {
        const query = this.$router.currentRoute.value.query;
        if (query.id) {
          const response = await common.apiGet(`/template/` + query.id);
          this.template = _.cloneDeep(response.data.data);
          this.test();
          const components = JSON.parse(this.template.components);
          this.components = components;
          this.customYn = this.template.customYn;
          this.templateName = this.template.name;
          //layout 값을 가져오고 handlerId 값 셋팅하기
          const layoutCopy = this.layout;
          const handlerListCp = [];
          for (let i = 0; i < layoutCopy.length; i++) {
            if (layoutCopy[i].components !== undefined) {
              handlerListCp.push(...layoutCopy[i].components);
            }
          }
          for (let i = 0; i < handlerListCp.length; i++) {
            this.bfsAddHandler(handlerListCp[i]);
          }
          return response.data;
        } else {
          alert("데이터가 올바르지 않습니다.");
          return;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    test() {
      console.log(this.allData);
    },
    itemTitle(item) {
      let result = item.type;
      if (item.static) {
        result += " - Static";
      }
      return result;
    },
    optionClose() {
      document.querySelector(".setting_pop").style.display = "none";
    },
    addItem: function (title) {
      if (this.layout.findIndex((e) => e.i === title) === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % 8,
          y: this.layout.length + 8,
          w: 4,
          h: 4,
          i: title,
        });
      } else {
        alert("이미 배치된 컴포넌트를 추가할 수 없습니다.");
      }
    },
    removeItem: function (val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },
    settingOpen(name) {
      this.comp_name = name;
      this.settingRender = name;
      document.querySelector(".setting_pop").style.display = "block";
    },
    showFilter(event) {
      const _this = event.target;

      const $filterBtn =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
      const $filterWrap = document.querySelector("#filterWrap");
      const $filterInput = $filterWrap.querySelectorAll(".filter_input");

      const hasShowClass = $filterWrap.classList.contains("show");
      const hasActiveClass = _this.classList.contains("active");

      for (var i = 0; i < $filterBtn.length; i++) {
        $filterBtn[i].classList.remove("active");
      }
      _this.classList.add("active");
      if (hasShowClass) {
        if (hasActiveClass) {
          $filterWrap.classList.remove("show");
          _this.classList.remove("active");
        }
      } else {
        $filterWrap.classList.add("show");
      }
      let activeIndex = Array.from($filterBtn).findIndex((element) =>
        element.classList.contains("active")
      );
      // console.log(activeIndex);
      // input
      for (var j = 0; j < $filterInput.length; j++) {
        $filterInput[j].querySelector("div").classList.remove("active");
      }
      // console.log($filterInput[activeIndex]);
      if (activeIndex > -1) {
        $filterInput[activeIndex].querySelector("div").classList.add("active");
      }
    },
    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },

    async addFilter(event) {
      // console.log("addFilter");

      const _this = event.target;
      const $thTag = _this.parentNode.parentNode;
      const $addBtn = $thTag.querySelector("button");
      const $input = $thTag.querySelector("input");
      // input 글자수
      const trimVal = $input.value.trim();

      // 컬럼 이름 가져오기
      const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode
        .querySelector("tr:first-child")
        .querySelectorAll(".btn_filter");
      let activeIndex = Array.from(keyNameArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyName = String(keyNameArr[activeIndex].parentNode.textContent);

      const dataKeyArr =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".input_box");
      const dataKeyindex = Array.from(dataKeyArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyId = String(dataKeyArr[dataKeyindex].getAttribute("data-id"));

      const obj = {};
      // console.log();
      // console.log(this.getAttribute('data-index'))
      // console.log($input);

      window.filterData.taget = _this.getAttribute("data-index");

      if (0 < trimVal.length) {
        obj["keyId"] = keyId;
        obj["value"] = trimVal;
        obj["keyName"] = keyName;
        $input.value = "";
        this.filterList.push(obj);
        this.$refs.filterAllBtn.style.display = "block";
        $addBtn.parentNode.classList.add("disable");
        $addBtn.previousElementSibling.disabled = true;
        this.filterAction();
      }
    },

    filterAction(remove) {
      const vm = this;
      // 필터 리스트
      const _filterLsit = vm.filterList;
      // 테이블 리스트
      let _tableList = vm.dataList;
      if (vm.isfilter) {
        vm.filterDataList.origin = _tableList;
        vm.isfilter = false;
      }

      if (_filterLsit.length > 0) {
        const lodash = require("lodash");

        // 필터 삭제
        if (remove) {
          let _filterDataListOrign = [];
          vm.filterDataList.origin.forEach((item) => {
            _filterDataListOrign.push(item);
          });

          for (var j = 0; j < _filterLsit.length; j++) {
            const searchString = common.getUpper(_filterLsit[j].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[j].keyId;
            const filteredData = lodash.filter(_filterDataListOrign, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
            _filterDataListOrign = filteredData;
          }
          // 필터 추가
        } else {
          for (var i = 0; i < _filterLsit.length; i++) {
            const searchString = common.getUpper(_filterLsit[i].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[i].keyId;
            const filteredData = lodash.filter(_tableList, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
          }
        }

        // console.log("필터 동작 시작");
      } else {
        vm.dataList = vm.filterDataList.origin;
      }
      vm.currentPage = 1;
      vm.totalItems = vm.dataList.length;
      vm.displayDataPerPage(vm.dataList);
    },
    handleDelete() {
      // confirm 창을 띄워 사용자에게 확인 요청
      const userConfirmed = window.confirm("삭제하시겠습니까?");
      // 사용자가 '확인'을 눌렀을 경우만 작업 수행
      if (userConfirmed) {
        // 삭제 작업 실행
        this.deleteItem();
      }
    },
    async getPrjTypeData() {
      try {
        const response = await common.apiGet("/project-type");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setPrjTypeList(prjTypeList) {
      prjTypeList.then((list) => {
        const dataList = [];
        list.forEach((element) => {
          dataList.push({
            id: element.projectTypeId,
            name: element.name,
          });
        });
        this.prjTypeList = dataList;
      });
    },
    setPrjType(event) {
      this.prjData["prjType"] = event.target.value;
    },
    async deleteItem() {
      // 또는 서버와의 통신이나 상태 변경 작업 등을 여기에 추가
      try {
        const id = this.template.id;
        await common.apiDelete("/template/" + id);
        this.$router.push({ path: "/sysOps/portleTemplate" });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    handleEdit() {
      const query = {
        id: this.template.id,
      };
      console.log(this.template);
      this.$router.push({ path: "/sysOps/portleTemplateEdit", query: query });
    },
  },
};
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}

.port_wrap {
  width: calc(100% - 0px);
  height: 100vh;
  padding: 20px;
  background: #f4f4f4;
  position: relative;
  top: auto;
  left: auto;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  display: flex;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: #007aff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  width: calc(100% - 40px);
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #007aff;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}

.control_pannel button {
  border: none;
  background: #007aff;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
  display: flex;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: #00b4ed;
  color: white;
  font-size: 14px;
  font-weight: normal;
  right: 10px;
  top: 10px;
  gap: 10px;
  margin-left: 8px;
  margin-right: 5px;
}
.top_control button:hover {
  background: #00b4ed;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #00b4ed;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: 00B4ED;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00b4ed;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  /* border-bottom: 1px solid #d4d4d4; */
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
  padding-left: 12px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: #00B4ED;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00b4ed;
}
.vue-grid-item span.setting_button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.final_save {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: 00B4ED;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
}
.load_btn {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: 00B4ED;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
  right: 130px !important;
}
.load_pop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.load_pop .load_pop_cell {
  width: 1000px;
  height: 600px;
  background: white;
  position: relative;
  padding: 20px;
}
.load_pop .load_pop_cell select {
  width: 150px;
  height: 40px;
  border: 1px solid #d4d4d4;
  margin-left: 20px;
  display: inline-block;
  background-position: right 4px center;
  vertical-align: top;
}
.load_pop .load_pop_cell .search {
  display: inline-block;
  width: 240px;
  height: 40px;
  border-radius: 0px;
  padding-right: 60px;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}
.load_pop .load_pop_cell .search input[type="text"] {
  width: 100%;
  height: 38px;
  background: white;
  border: none;
  padding-left: 10px;
}
.load_pop .load_pop_cell .search button {
  width: 60px;
  height: 38px;
  background: #f4f4f4;
  border-left: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}
.load_pop .load_pop_cell .new_open {
  width: 100px;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  float: right;
}
.load_pop .load_pop_cell .new_open:hover {
  background: #00b4ed;
}
.load_pop .load_pop_cell h4 {
  height: 50px;
  line-height: 50px;
  font-size: 45px;
  font-weight: lighter;
  color: black;
  padding: 0px;
  margin: 0px;
  margin-bottom: 7px;
}
.load_pop .load_pop_cell .sc_wrap {
  width: 100%;
  height: 490px;
  background: #f4f4f4;
  overflow: auto;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}
.load_pop .load_pop_cell .sc_wrap:after {
  clear: both;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell {
  width: 100%;
  height: 50px;
  float: left;
  position: relative;
  padding: 0px;
  padding-bottom: 10px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real {
  width: 100%;
  height: 40px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  position: Relative;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real:hover {
  border: 1px solid skyblue;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data:after {
  content: "...New Data";
  font-size: 17px;
  font-weight: bold;
  color: black;
  display: block;
  width: 100%;
  text-align: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved {
  position: Absolute;
  top: 0px;
  text-align: center;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 38px;
  font-size: 17px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-left: 14px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved span {
  display: block;
  width: 200px;
  height: 38px;
  position: absolute;
  text-align: center;
  bottom: 0px;
  right: 0px;
  line-height: 38px;
  font-size: 11px;
  font-weight: normal;
  color: gray;
}
.load_pop .load_pop_cell .load_pop_cell_x {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: -25px;
  z-index: 22;
  background: #444;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
}
.load_pop .load_pop_cell .load_pop_cell_x:hover {
  background: black;
}
.tempname {
  width: 200px;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 14px;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.list_wrap {
  position: relative;
}
.list_wrap .table_list {
  height: 715px;
}

.vue-draggable-handle {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  padding: 0 8px 8px 0;
  cursor: pointer;
  background-color: black;
  background-origin: content-box;
  border-radius: 10px;
}

.right_layout {
  margin-left: auto; /* 자동으로 왼쪽 여백을 채워 우측으로 밀어냄 */
}
</style>
