<template>
  <DashBoardCompForReqCat ref="dashboard" :style="{ marginTop: '5px', marginBottom: '5px' }" ></DashBoardCompForReqCat>
  <!-- The AG Grid component -->
  <!--  <div style="padding: 20px"> -->
  <!--    <button @click="addData()" border="1">AddData@GridComp</button> -->
  <!--  </div> -->
  <!--  <div style="padding: 20px">-->
  <!--    <button @click="clearTableData" border="1">clearTableData@GridComp</button>-->
  <!--  </div>-->
  <div class="exp_button">
    <button @click="exportCSV" class="btn">Export to CSV</button>
  </div>
  <ag-grid-vue
      :rowData="rowData"
      :columnDefs="colDefs"
      :theme="theme"
      style="height: 500px; width: 1250px;"
      @grid-ready="onGridReady"
      ref="agGrid">
  </ag-grid-vue>
</template>

<script>
import {reactive, ref} from 'vue';
import { AgGridVue } from "ag-grid-vue3";
import axios from 'axios';

import { AllCommunityModule, ModuleRegistry, themeBalham } from 'ag-grid-community';
import DashBoardCompForReqCat from "@/views/componentType/DashBoardCompForReqCat.vue";
import conf from "@/assets/js/conf";
ModuleRegistry.registerModules([AllCommunityModule]);

export default {
  name: "GridComponentForReqCat",
  components: {
    DashBoardCompForReqCat,
    AgGridVue,
  },
  data () {
    return {}
  },
  setup() {
    const colDefs = reactive([
      { field: "Project" },
      { field: "ObjectText" },
      { field: "Category" },
      // { field: "Domain" },
      { field: "CategoryLLM" },
      { field: "CategoryLLMReason", width: "400px" },
    ]);

    const rowData = reactive([]);

    let gridApi = null;
    const onGridReady = (params) => {
      gridApi = params.api;
    };

    const callAPI = async (row, payload) => {
      // const API = axios.create({
      //   baseURL: 'http://172.20.102.115:8000',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     "Accept": "application/json"
      //   },
      // });
      // const response = await API.post('/categorization/statement', payload);

      const baseURL = conf.getAIDTBaseURL();
      const url = baseURL + "/categorization/requirement";
      const headerParams = {
        "Content-Type": "application/json",
        "Accept": "application/json"
      };
      const response = await axios.post(url, payload, {headers: headerParams});
      const content = JSON.parse(response.data.content);
      row.CategoryLLM = content["Category"];
      row.CategoryLLMReason = content["Explanation"];
      return row;
    }

    // param_row sample
    const addData = async (row) => {
      const payload = {
        "messages":
            [
              {
                "content": "You are an AI language model trained to classify sentences into one of the following 8 categories:\n            - Development Process\n            - Hardware\n            - Cyber Security\n            - Software\n            - Electrical & Electronic\n            - etc.\n            \n            The hardware requirements document specifies aspects such as the shape, specifications, and mechanical design characteristics of the device. \n            Electrical & Electronic requirements document includes statements about electrical signals, power, ECU specifications, and ECU characteristics. \n            Development Process requirements must align with the A-SPICE standard, encompassing requirements related to A-SPICE processes like ASIL and level assessments.\n            \n            Cyber Security is a category that describes the requirements related to the cyber security of the vehicle.\n            Software is a category that describes the requirements related to the software components of the vehicle.\n            etc. is a category that describes the requirements that do not fit into any of the above categories.\n            \n            Given the following sentence, determine the most appropriate category from the list above.\n            And please provide the category name and a brief explanation for your choice.\n            Respond with the right json format:\n            {\n                \"Category\": [Selected Category],\n                \"Explanation\": [Brief explanation for the choice]\n            }\n",
                "role": "system"
              },
              {
                "role": "user",
                "content": "paragraph \n" + row.ObjectText
              }
            ]
      }
      const newItem = await callAPI(row, payload);
      gridApi.applyTransaction({ add: [newItem] });

      // Status Update "Sentences, LLM Type Result, Heading, Customer requirement, Comment, Accuracy"
      updateStatus(newItem);
    };

    const dashboard = ref(null);
    const updateStatus = (item) => {
      if(item){
        if(dashboard.value) {
          // llm result count
          dashboard.value.set_llmCategorize();

          // item["CategoryLLM"] 처리
          if(item["CategoryLLM"] == "Software"){
            dashboard.value.set_software();
          } else if(item["CategoryLLM"] == "A-SPICE"){
            dashboard.value.set_aSpice();
          } else if(item["CategoryLLM"] == "Cyber Security"){
            dashboard.value.set_cyberSecurity();
          } else if(item["CategoryLLM"] == "Hardware"){
            dashboard.value.set_hardware();
          } else if(item["CategoryLLM"] == "Electrical & Electronic"){
            dashboard.value.set_electricalElectronic();
          }
        }
      }
    }

    const clearTableData = () => {
      if(gridApi) {
        // gridApi.setNewRowData([]);
        gridApi.setGridOption('rowData', []);
      }
    }

    const exportCSV = () => {
      const rowDataCount = gridApi.getDisplayedRowCount();
      if (rowDataCount === 0) {
        alert("Export 데이터가 없습니다.");
        return;
      }
      if (gridApi) {
        gridApi.exportDataAsCsv({
          fileName: "export.csv",
          columnSeparator: ",",
          skipColumnHeaders: false,
          onlySelected: false,
        });
      }
    };

    return {
      rowData,
      colDefs,
      onGridReady,
      addData,
      clearTableData,
      updateStatus,
      dashboard,
      theme: themeBalham,
      exportCSV,
    }
  },
  methods: {
    updateStatusDataLength(dataLength){
      // console.log(dataLength);
      const paragraphs_length = dataLength - 1; // except header
      this.$refs.dashboard.set_paragraphs_length(paragraphs_length);
    },
    clearStateData(){
      this.$refs.dashboard.clearStateData();
      this.$refs.dashboard.set_paragraphs_length_reset();
    }
  }
}
</script>

<style scoped>
.exp_button {
  width: 1240px;
  display: flex;
  justify-content: flex-end;
  padding-right: 70px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.btn {
  display: inline-block;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #c7c7c7;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
}

.btn:hover {
  background-color: #12a1d5;
}

.btn:active {
  transform: scale(0.95);
}
</style>