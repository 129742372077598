<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout :pageId="pageId" />
      <div id="management">
        <div
          class="btn_area"
          :style="
            pipelineData.pipelineId !== 0 ? '' : 'justify-content:flex-end'
          "
        >
          <div v-if="pipelineData.pipelineId !== 0">
            <button type="button" class="mcbtn" @click="showModal(1)">
              실행
            </button>
            <button type="button" class="mcbtn" @click="goMonitoring">
              모니터링
            </button>
          </div>
          <div>
            <button type="button" class="mcbtn" @click="goList">목록</button>
          </div>
        </div>
        <div class="row_box three_item">
          <div style="width: calc(100% / 3)">
            <div class="data_key">Name</div>
            <div class="data_val">
              <input type="text" v-model="pipelineData.pipelineNm" />
            </div>
          </div>
          <div style="width: calc(100% / 3)">
            <div class="data_key" style="text-align: center">Version</div>
            <div class="data_val">
              <div class="value">
                {{
                  pipelineData.pipelineVersion !== 0
                    ? pipelineData.pipelineVersion
                    : 1
                }}
              </div>
            </div>
          </div>
          <div style="width: calc(100% / 3)">
            <div class="data_key" style="text-align: center">Task</div>
            <div class="data_val">
              <select v-model="pipelineData.pipelineTask">
                <option value="">선택해 주세요.</option>
                <option value="test">test</option>
                <option value="test2">test2</option>
                <option value="dtv01">dtv01</option>
                <option value="dtv02">dtv02</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row_box three_item">
          <div style="width: calc(100% / 3)">
            <div class="data_key">type</div>
            <div class="data_val">
              <select v-model="pipelineData.pipelineType">
                <option value="0">선택해 주세요.</option>
                <option value="1">inference</option>
                <option value="2">ml</option>
              </select>
            </div>
          </div>
          <div style="width: calc(100% - (100% / 3))">
            <div class="data_key" style="text-align: center">Description</div>
            <div class="data_val">
              <input
                type="text"
                placeholder="설명"
                v-model="pipelineData.pipelineDesc"
              />
            </div>
          </div>
        </div>
        <div class="row_box textarea_item">
          <div class="data_key" style="height: auto">Source</div>
          <div class="data_val" style="height: auto">
            <div
              class="source_top"
              :style="
                pipelineData.sourceVersion < 1 ? 'justify-content:flex-end' : ''
              "
            >
              <p v-if="pipelineData.sourceVersion > 0">
                <span>{{
                  pipelineData.sourceNm !== undefined
                    ? pipelineData.sourceNm + " / "
                    : ""
                }}</span>
                <span>{{ pipelineData.sourceVersion }}</span>
              </p>
              <button type="button" class="mcbtn" @click="showModal(2)">
                설정
              </button>
            </div>
            <textarea
              class="txtarea"
              v-model="pipelineData.sourceConfig"
            ></textarea>
            <!-- <div class="txtarea" @click="unfoldTxt">
              {{ pipelineData.sourceConfig }}
            </div> -->
          </div>
        </div>
        <div class="row_box textarea_item roi_item">
          <div class="data_key" style="height: auto">ROI</div>
          <div class="data_val">
            <div class="roi_img">
              <template
                v-if="selectedImgROI !== '' && selectedImgROI != undefined"
              >
                <img :src="selectedImgROI" alt="" />
              </template>
              <template v-else> ROI Image </template>
            </div>
            <div class="roi_img">
              <div class="config">
                <template v-if="selectedImgROIConfig !== ''">
                  {{ selectedImgROIConfig }}
                </template>
                <template v-else> <span>ROI Config</span></template>
              </div>
            </div>
            <div class="roi_img">
              <button type="button" class="mcbtn" @click="showModal(3)">
                설정
              </button>
              <!-- <select>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select> -->
            </div>
          </div>
        </div>

        <div class="row_box textarea_item edge_item">
          <div class="data_key" style="height: auto">Edge</div>
          <div class="data_val" style="height: auto">
            <div class="edge_ids">
              <ul>
                <li v-for="(item, index) in selectedEdge" :key="index">
                  <div>{{ item.edgeName }}</div>
                  <button type="button" @click="removeEdge(item)">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                  </button>
                </li>
              </ul>
            </div>
            <div class="btn_edge">
              <button type="button" class="mcbtn" @click="showModal(4)">
                설정
              </button>
            </div>
          </div>
        </div>

        <div class="row_box two_item" v-if="pipelineData.pipelineId !== 0">
          <div>
            <div class="data_key">Create User</div>
            <div class="data_val">
              <div class="txt">{{ pipelineData.createUser }}</div>
            </div>
          </div>
          <div>
            <div class="data_key">Create Date</div>
            <div class="data_val">
              <div class="txt">{{ pipelineData.createDate }}</div>
            </div>
          </div>
        </div>
        <div class="row_box two_item" v-if="pipelineData.pipelineId !== 0">
          <div>
            <div class="data_key">Update User</div>
            <div class="data_val">
              <div class="txt">{{ pipelineData.updateUser }}</div>
            </div>
          </div>
          <div>
            <div class="data_key">Update Date</div>
            <div class="data_val">
              <div class="txt">{{ pipelineData.updateDate }}</div>
            </div>
          </div>
        </div>
        <div class="row_box two_item">
          <div>
            <div class="data_key">Order</div>
            <div class="data_val">
              <input type="number" v-model="pipelineData.order" />
            </div>
          </div>
          <div>
            <div class="data_key">Use</div>
            <div class="data_val">
              <select v-model="pipelineData.useYn">
                <option value="y">Y</option>
                <option value="n">N</option>
              </select>
            </div>
          </div>
        </div>
        <div class="save_box">
          <button type="button" @click="showModalAlert()">취소</button>
          <button type="button" class="mcbtn" @click="registMlops">저장</button>
        </div>
      </div>
    </div>
  </div>

  <div id="modalWrap" ref="modalWrap_01" style="display: none">
    <div
      class="radiusbox modal_contents message_modal modal_mlops modal_mlops_01"
    >
      <h2>실행</h2>
      <div class="row">
        <div class="title_top">
          {{ pipelineData.pipelineNm }} -
          {{
            pipelineData.pipelineVersion !== 0
              ? pipelineData.pipelineVersion
              : 1
          }}
        </div>
        <div class="value_top">
          <button type="button" class="mcbtn" @click="execute">실행</button>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>To</span>
        </div>
        <div class="value">
          <div class="input_box">
            <input type="text" v-model="executeEmail" />
            <div class="remove_area">
              <div v-for="(item, index) in executeEmailList" :key="index">
                <div class="input">{{ item }}</div>
                <button
                  type="button"
                  class="btn_remove"
                  @click="removeEmail(item)"
                >
                  <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <button type="button" class="mcbtn" @click="addEmail">추가</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>Message</span>
        </div>
        <div class="value">
          <div class="txt_wrap">
            <textarea
              placeholder="text message"
              v-model="executeTxt"
            ></textarea>
          </div>
        </div>
      </div>
      <button type="button" class="btn_modal_close" @click="modalClose(1)">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_02" style="display: none">
    <div
      class="radiusbox modal_contents message_modal modal_mlops modal_mlops_02"
    >
      <h2>소스 목록</h2>
      <div class="row row_01">
        <input type="text" v-model="sourceNm" placeholder="Source Name 검색" />
        <button type="button" class="mcbtn" @click="getSourceList">검색</button>
        <button type="button" class="mcbtn" @click="selectSource">설정</button>
      </div>
      <div class="row">
        <div class="tbl_source">
          <div class="title">
            <div>Source Name</div>
            <div>Version</div>
            <div>Type</div>
            <div>Description</div>
          </div>
          <div class="content">
            <div
              class="tbl_row"
              v-for="(item, index) in sourceList"
              :key="index"
              @click="selectItem($event, item)"
              :style="!item.isMax ? 'display:none' : ''"
              :class="item.class"
            >
              <div>
                <button
                  type="button"
                  class="btn btn_plus"
                  @click="togglePlus($event, item.class)"
                  v-if="item.isMax"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" class="ico_plus" />
                  <font-awesome-icon
                    :icon="['fas', 'minus']"
                    class="ico_minus"
                  />
                </button>
                <strong v-if="!item.isMax">ㄴ</strong>
                <span>{{ item.sourceNm }}</span>
              </div>
              <div>{{ item.sourceVersion }}</div>
              <div>{{ item.sourceType === 1 ? "inference" : "ml" }}</div>
              <div>{{ item.sourceDesc }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="list_wrap">
        <div class="table_list">
          <div class="list_table_footer">
            <PaginationUi
              :idNm="'_01'"
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div> -->
      <button type="button" class="btn_modal_close" @click="modalClose(2)">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_03" style="display: none">
    <div
      class="radiusbox modal_contents message_modal modal_mlops modal_mlops_03"
    >
      <h2>ROI 설정</h2>
      <div class="row row_01">
        <button type="button" class="mcbtn" @click="setROI">설정</button>
      </div>
      <div class="row">
        <div class="item">
          <p class="title">이미지</p>
          <div class="img">
            <cropper
              ref="cropper"
              :src="imgROI"
              :resizeImage="{ wheel: false }"
              :defaultSize="cropSize"
              :defaultPosition="cropPosition"
              :stencil-props="{ previewClass: 'line' /* handlers: {}, */ }"
              @change="chgCrop"
            />
          </div>
          <div class="control">
            <input
              type="file"
              id="chgImg"
              accept="image/*"
              @change="chgImg"
              class="blind"
            />
            <label class="mcbtn" for="chgImg">이미지 업로드</label>
            <button type="button" class="mcbtn" @click="resetROI">
              ROI 초기화
            </button>
          </div>
        </div>
        <div class="item">
          <p class="title">ROI 이미지</p>
          <div class="img">
            <preview
              v-if="result.image"
              ref="preview"
              :width="imageWidth"
              :height="imageHeight"
              :image="result.image"
              :coordinates="result.coordinates"
            />
          </div>
          <div class="control">
            <div class="preview-controller">
              <div>
                X :&nbsp;<input type="text" :value="previewX" disabled />
              </div>
              <div>
                Y :&nbsp;<input type="text" :value="previewY" disabled />
              </div>
              <div>
                Width :&nbsp;<input
                  type="text"
                  :value="previewWidth"
                  disabled
                />
              </div>
              <div>
                Height :&nbsp;<input
                  type="text"
                  :value="previewHeight"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="btn_modal_close" @click="modalClose(3)">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>

  <div id="modalWrap" ref="modalWrap_04" style="display: none">
    <div
      class="radiusbox modal_contents message_modal modal_mlops modal_mlops_04"
    >
      <h2>Edge 목록</h2>
      <div class="row row_01">
        <input type="text" v-model="sourceNm" />
        <button type="button" class="mcbtn" @click="getEdgeList">검색</button>
        <button type="button" class="mcbtn" @click="addEdge">추가</button>
      </div>
      <div class="row">
        <div class="tbl_edge">
          <div class="title">
            <div>Edge Name</div>
            <div>IP</div>
            <div>Site</div>
            <div>Wc</div>
            <div>Description</div>
          </div>
          <div class="content">
            <div
              class="tbl_row edge_row"
              v-for="(item, index) in edgeListSliced"
              :key="index"
              @click="selectEdge($event, item)"
            >
              <div>{{ item.edgeName }}</div>
              <div>{{ item.edgeIp }}</div>
              <div>{{ item.site }}</div>
              <div>{{ item.wc }}</div>
              <div>{{ item.edgeDesc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list_wrap">
        <div class="table_list">
          <div class="list_table_footer">
            <PaginationUi
              :idNm="'_02'"
              :totalItems="totalItems02"
              :itemsPerPage="itemsPerPage02"
              @page-changed="onPageChanged02"
            ></PaginationUi>
          </div>
        </div>
      </div>
      <button type="button" class="btn_modal_close" @click="modalClose(4)">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import PaginationUi from "@/components/PagenationUi2.vue";
import common from "@/assets/js/common";
import apiMlops from "@/assets/js/apiMlops";
import { mapGetters } from "vuex";
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    PaginationUi,
    Cropper,
    Preview,
  },
  computed: {
    ...mapGetters(["getUserData"]),

    descLen() {
      return this.desc.length;
    },
    previewX: function () {
      return this.result?.coordinates?.left;
    },
    previewY: function () {
      return this.result?.coordinates?.top;
    },
    previewWidth: function () {
      return this.result?.coordinates?.width;
    },
    previewHeight: function () {
      return this.result?.coordinates?.height;
    },

    // VCalendar
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    ...mapGetters(["getUserData"]),
  },
  data() {
    const userData = {};

    const picStartDate = null;
    const picEndDate = null;

    return {
      isInitImg: true,
      cropSize: {
        width: 300,
        height: 300,
      },
      cropPosition: {
        left: 100,
        top: 100,
      },
      executeTxt: "",
      userData,

      picStartDate,
      picEndDate,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      desc: "",

      // breadcrumb
      pageId: "monTASK_addMlops",

      // role
      viewRole: false,
      manageRole: false,
      usePipeline: "Y",

      sourceList: [],
      sourceListSliced: [],
      sourceNm: "",

      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      totalItems02: 0,
      itemsPerPage02: 10,
      currentPage02: 1,
      selectedSource: null,
      tempSelectedSource: null,

      selectedEdge: [],
      tempSelectedEdge: [],

      imgROI: "",
      imgFileName: "",
      selectedImgROI: "",
      selectedImgROIConfig: "",
      result: {
        coordinates: null,
        image: null,
      },
      imageWidth: 0,
      imageHeight: 0,
      file: null,
      pipelineData: {
        pipelineId: 0,
        pipelineNm: "",
        pipelineDesc: "",
        pipelineTask: "",
        pipelineType: 0,
        pipelineVersion: 0,
        sourceConfig: "",
        sourceId: 0,
        sourceNm: "",
        sourceVersion: 0,
        sourceType: "",
        createUser: "",
        createDate: "",
        updateUser: "",
        updateDate: "",
        useYn: "y",
        order: 0,
      },
      search: {
        pipelineNm: "",
        pipelineType: 0,
        pipelineTask: "",
        dateFrom: "",
        dateTo: "",
      },

      edgeList: [],
      edgeListSliced: [],
      executeEmail: "",
      executeEmailList: [],
    };
  },
  async mounted() {
    // Role
    await this.setRole();

    console.log(this.$route.params.id);
    console.log(this.$route.params.version);

    const getCKUser = this.getUserData;
    const getUser = this.$store.state.user;
    if (getCKUser == undefined) {
      this.userData = getUser;
    } else {
      this.userData = getCKUser;
    }

    let pipelineId = 0;
    let pipelineVersion = 0;

    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();

    for (const entry of entries) {
      console.log(entry[0] + " ! " + entry[1]);
      if (entry[0] === "id" && entry[1] != 0) {
        pipelineId = entry[1];
      } else if (entry[0] === "version" && entry[1] != 0) {
        pipelineVersion = entry[1];
      } else if (entry[0] === "pipelineNm" && entry[1] != null) {
        this.search.pipelineNm = entry[1];
      } else if (entry[0] === "pipelineType" && entry[1] != null) {
        this.search.pipelineType = entry[1];
      } else if (entry[0] === "pipelineTask" && entry[1] != null) {
        this.search.pipelineTask = entry[1];
      } else if (entry[0] === "dateFrom" && entry[1] != null) {
        this.search.dateFrom = entry[1];
      } else if (entry[0] === "dateTo" && entry[1] != null) {
        this.search.dateTo = entry[1];
      }
    }
    //const pipelineVersion = this.$route.params.id;
    if (
      typeof pipelineId != "undefined" &&
      pipelineId != null &&
      pipelineId != "" &&
      pipelineId != 0
    ) {
      this.pipelineId = pipelineId;
      const data = await this.getMlopsData(pipelineId, pipelineVersion);
      console.log("!!!!!!!!!!!! :: data :: ", data);
      this.pipelineData = data;
      this.pipelineData.sourceConfig = this.returnToJson(data.sourceConfig);
      this.selectedEdge = data.pipelineEdgeList;

      this.selectedImgROI = data.pipelineRoiList[0]?.roiImage;
      this.selectedImgROIConfig = data.pipelineRoiList[0]?.roiConfig;
      if (data.pipelineRoiList.length > 0) {
        this.initImg();
      }

      this.pageId = "monTASK_modMlops";
      console.log("this.pipelineData :: ", this.pipelineData);
      //await this.setBreadcrumb();
    } else {
      this.pageId = "monTASK_addMlops";
    }

    // this.checkAccess();
  },
  methods: {
    // onPageChanged(page) {
    //   this.currentPage = page;
    //   this.displayDataPerPage(this.sourceList);
    // },
    onPageChanged02(page) {
      this.currentPage02 = page;
      this.displayDataPerPage02(this.edgeList);
    },
    // displayDataPerPage(rowData) {
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //   const endIndex = startIndex + this.itemsPerPage;
    //   this.sourceListSliced = rowData.slice(startIndex, endIndex);
    // },
    displayDataPerPage02(rowData) {
      const startIndex = (this.currentPage02 - 1) * this.itemsPerPage02;
      const endIndex = startIndex + this.itemsPerPage02;
      this.edgeListSliced = rowData.slice(startIndex, endIndex);
    },
    initImg() {
      this.isInitImg = true;
      const image = new Image();
      //let imgUrl;

      image.src = this.selectedImgROI;
      this.imgROI = image.src;
    },
    goMonitoring() {
      this.$router.push({
        name: "MlopsMonitoring",
        params: {
          id: this.pipelineData.pipelineId,
          version: this.pipelineData.pipelineVersion,
          type: this.pipelineData.pipelineType,
        },
      });
    },
    removeEmail(item) {
      this.executeEmailList = this.executeEmailList.filter((e) => e !== item);
    },
    addEmail() {
      const email = this.executeEmail;
      const regEmail =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

      if (!regEmail.test(email)) {
        alert("이메일 형식에 따라 정확히 입력해주세요");
        return;
      }

      this.executeEmailList.push(email);
      this.executeEmail = "";
    },
    removeEdge(item) {
      this.selectedEdge = this.selectedEdge.filter(
        (e) => e.edgeId !== item.edgeId
      );
    },
    addEdge() {
      this.selectedEdge = this.tempSelectedEdge;
      console.log("GGGGGGGGGGG :: ", this.selectedEdge);
      this.tempSelectedEdge = [];
      document.querySelectorAll(".edge_row").forEach((e) => {
        e.classList.remove("active");
      });
      this.modalClose(4);
    },

    selectEdge(e, item) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      const edgeId = item.edgeId;
      if (e.currentTarget.classList.contains("active")) {
        e.currentTarget.classList.remove("active");
        this.tempSelectedEdge = this.tempSelectedEdge.filter(
          (e) => e.edgeId !== edgeId
        );
      } else {
        e.currentTarget.classList.add("active");
        this.tempSelectedEdge.push(item);
      }
    },
    goList(save) {
      if (save) {
        this.$router.push("/monTASK/mlopsList");
      } else {
        const pipelineNm = this.search.pipelineNm;
        const pipelineType = this.search.pipelineType;
        const pipelineTask = this.search.pipelineTask;
        const dateFrom = this.search.dateFrom;
        const dateTo = this.search.dateTo;

        this.$router.push(
          "/monTASK/mlopsList?pipelineNm=" +
            pipelineNm +
            "&pipelineType=" +
            pipelineType +
            "&pipelineTask=" +
            pipelineTask +
            "&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo
        );
      }
    },
    async execute() {
      const pipelineNm = this.pipelineData.pipelineNm;
      const pipelineVersion = this.pipelineData.pipelineVersion;
      const userName = this.getUserData.name;
      const email = this.executeEmailList[0];
      const txt = this.executeTxt;
      let param = {};

      try {
        this.$refs.lodingWrap.style.display = "block";
        let response = null;
        if (this.pipelineData.pipelineType == 2) {
          param = {
            pipeline_name: pipelineNm,
            pipeline_version: pipelineVersion,
            run_user: userName,
            approver_email: email,
            mail_message: txt,
            run_ml: "y",
          };
          response = await apiMlops.post("/mlops/run_pipeline_ml/", param);
        } else if (this.pipelineData.pipelineType == 1){
          param = {
            pipeline_name: pipelineNm,
            pipeline_version: pipelineVersion,
            run_user: userName,
            approver_email: email,
            mail_message: txt,
          };
          response = await apiMlops.post("/mlops/run_pipeline_inf/", param);
        } else {
          param = {
            pipeline_name: pipelineNm,
            pipeline_version: pipelineVersion,
            run_user: userName,
            approver_email: email,
            mail_message: txt,
          };
          response = await apiMlops.post("/mlops/run_pipeline_inf/", param);
        }
        if (response.status === 200 || response.status === "200") {
          alert("저장되었습니다.");
          this.modalClose(1);
          this.$refs.lodingWrap.style.display = "none";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getEdgeList() {
      const param = {
        searchKeyword: "",
      };
      try {
        const response = await common.apiGet("/pipeline/edge/list", param);
        this.edgeList = response.data.data;
        this.currentPage02 = 1;
        this.totalItems02 = this.edgeList.length;
        this.displayDataPerPage02(this.edgeList);
        console.log("this.edgeList :: ", this.edgeList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async getMlopsData(id, version) {
      const param = {
        pipelineId: id,
        pipelineVersion: version,
      };
      try {
        const response = await common.apiGet("/pipeline", param);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async registMlops() {
      const edgeList = this.selectedEdge;
      const edgeArray = [];
      for (let item of edgeList) {
        const param = {
          pipelineId: this.pipelineData.pipelineId,
          pipelineVersion: this.pipelineData.pipelineVersion,
          edgeId: item.edgeId,
        };
        edgeArray.push(param);
      }

      const param = {
        pipelineId: this.pipelineData.pipelineId,
        pipelineVersion: this.pipelineData.pipelineVersion,
        pipelineType: this.pipelineData.pipelineType,
        pipelineNm: this.pipelineData.pipelineNm,
        pipelineDesc: this.pipelineData.pipelineDesc,
        pipelineTask: this.pipelineData.pipelineTask,
        sourceId: this.pipelineData.sourceId,
        sourceVersion: this.pipelineData.sourceVersion,
        sourceConfig: this.pipelineData.sourceConfig,
        lastRunVersion: 0,
        order: this.pipelineData.order,
        useYn: this.pipelineData.useYn,
        // createUser: this.pipelineData.name,
        // updateUser: this.pipelineData.name,
        // createDate: this.pipelineData.createDate,

        pipelineEdgeList: edgeArray,
        pipelineRoiList: [
          {
            pipelineId: this.pipelineData.pipelineId,
            pipelineVersion: this.pipelineData.pipelineVersion,
            roiImage: this.selectedImgROI,
            roiConfig: this.selectedImgROIConfig,
          },
        ],
      };
      try {
        let response = null;
        if (param.pipelineId !== 0) {
          param.updateUser = this.getUserData.name;
          response = await common.apiPatch(
            "/pipeline/" + param.pipelineId,
            param
          );
        } else {
          param["createUser"] = this.userData.name;
          param["updateUser"] = this.userData.name;
          response = await common.apiPost("/pipeline", param);
        }
        if (response.status === 200 || response.status === "200") {
          alert("저장되었습니다.");
          this.goList(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setROI() {
      const roiConfig =
        '{"x1":' +
        this.previewX +
        ',"y1":' +
        this.previewY +
        ',"x2":' +
        this.previewWidth +
        ',"y2":' +
        this.previewHeight +
        "}";
      //this.selectedImgROI = this.imgROI;
      this.selectedImgROIConfig = roiConfig;
      if (this.file !== null) {
        const formData = new FormData();
        const fileUpload = this.file;
        formData.append("multipartFile", fileUpload);
        this.$refs.lodingWrap.style.display = "block";
        try {
          const response = await common.apiPostMulti(
            "/pipeline/file/upload",
            formData
          );
          if (response.status === 200 || response.status === "200") {
            this.selectedImgROI =
              "https://cdn.dlfe.dev.micosa.io/" + response.data.data;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      this.modalClose(3);
      this.$refs.lodingWrap.style.display = "none";
    },
    resetROI() {
      const input = document.getElementById("chgImg");
      input.value = null;

      this.imageWidth = 0;
      this.imageHeight = 0;
      this.imgROI = "";
      this.imgFileName = "";
      this.file = null;
      this.result.coordinates = null;

      this.result.image = null;
    },
    chgCrop({ coordinates, image }) {
      this.$refs.cropper.refresh();
      //console.log("$$$$$$$$$$ :: ", coordinates);

      let config = {};
      if (this.selectedImgROIConfig !== "" && this.isInitImg) {
        const roiConfig = JSON.parse(this.selectedImgROIConfig);
        console.log("roiConfig :: ", roiConfig);

        this.cropSize.width = roiConfig.x2;
        this.cropSize.height = roiConfig.y2;
        this.cropPosition.left = roiConfig.x1;
        this.cropPosition.top = roiConfig.y1;

        //this.defaultCoordinates.left = 10;
        //this.defaultCoordinates.top = 10;

        //config = reConfig;

        config.width = this.cropSize.width;
        config.height = this.cropSize.height;
        config.left = this.cropPosition.left;
        config.top = this.cropPosition.top;
      } else {
        config = coordinates;
      }

      this.result = {
        coordinates: config,
        image: image,
      };

      let w = document.querySelector(".vue-rectangle-stencil").style.width;
      let h = document.querySelector(".vue-rectangle-stencil").style.height;

      this.imageWidth = Number(w.split("px")[0]);
      this.imageHeight = Number(h.split("px")[0]);
      this.isInitImg = false;

      //this.imageWidth = this.result.coordinates?.width;
      //this.imageHeight = this.result.coordinates?.height;
    },
    chgImg() {
      const inputFile = document.getElementById("chgImg");
      const file = inputFile.files[0];
      this.file = file;

      // 파일 로드가 완료되면 실행될 콜백 함수를 정의합니다.

      const image = new Image();
      //let imgUrl;
      if (file) {
        image.src = URL.createObjectURL(file);
        this.imgFileName = file.name;
        this.imgROI = image.src;
      }
    },
    unfoldTxt(e) {
      if (this.pipelineData.sourceConfig !== "") {
        if (e.currentTarget.classList.contains("active")) {
          e.currentTarget.classList.remove("active");
        } else {
          e.currentTarget.classList.add("active");
        }
      }
    },
    returnToJson(str) {
      const strToJson = str.toString().replaceAll("'", '"');
      // .replaceAll("True", "true")
      // .replaceAll("False", "false")
      // .replaceAll(":,", ':"",');
      let obj = null;
      try {
        obj = JSON.parse(strToJson);
        return JSON.stringify(obj, undefined, 4);
      } catch (error) {
        alert(error);
        return str;
      }
    },
    selectSource() {
      console.log("%%%%%%%%%% :: ", this.tempSelectedSource);
      this.pipelineData.sourceId = this.tempSelectedSource.sourceId;
      this.pipelineData.sourceNm = this.tempSelectedSource.sourceNm;
      // this.pipelineData.sourceConfig = this.prettyJson(
      //   this.tempSelectedSource.sourceConfig
      // );

      this.pipelineData.sourceConfig = this.returnToJson(
        this.tempSelectedSource.sourceConfig
      );
      this.pipelineData.sourceVersion = this.tempSelectedSource.sourceVersion;
      this.pipelineData.sourceType = this.tempSelectedSource.sourceType;

      this.modalClose(2);
    },
    selectItem(e, item) {
      e.stopImmediatePropagation();
      e.stopPropagation();

      if (e.currentTarget.classList.contains("active")) {
        e.currentTarget.classList.remove("active");
        this.tempSelectedSource = null;
      } else {
        document.querySelectorAll(".tbl_row").forEach((e) => {
          e.classList.remove("active");
        });
        e.currentTarget.classList.add("active");
        this.tempSelectedSource = item;
      }
    },
    togglePlus(e, itemClass) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      if (e.currentTarget.classList.contains("btn_plus")) {
        e.currentTarget.classList.remove("btn_plus");
        e.currentTarget.classList.add("btn_minus");
        e.currentTarget.querySelector(".ico_plus").style.display = "none";
        e.currentTarget.querySelector(".ico_minus").style.display = "block";
        document.querySelectorAll(".sub-" + itemClass).forEach((e) => {
          e.style.display = "flex";
        });
      } else {
        e.currentTarget.classList.remove("btn_minus");
        e.currentTarget.classList.add("btn_plus");
        e.currentTarget.querySelector(".ico_minus").style.display = "none";
        e.currentTarget.querySelector(".ico_plus").style.display = "block";
        document.querySelectorAll(".sub-" + itemClass).forEach((e) => {
          e.style.display = "none";
        });
      }
    },
    modalClose(num) {
      let $modal = null;
      if (num === 1) {
        $modal = this.$refs.modalWrap_01;
      } else if (num === 2) {
        $modal = this.$refs.modalWrap_02;
      } else if (num === 3) {
        $modal = this.$refs.modalWrap_03;
      } else if (num === 4) {
        $modal = this.$refs.modalWrap_04;
      }
      $modal.style.display = "none";
    },
    async showModal(num) {
      this.$refs.lodingWrap.style.display = "block";
      let $modal = null;
      if (num === 1) {
        $modal = this.$refs.modalWrap_01;
      } else if (num === 2) {
        await this.getSourceList();
        $modal = this.$refs.modalWrap_02;
      } else if (num === 3) {
        $modal = this.$refs.modalWrap_03;
        if (this.selectedImgROI !== "" && this.selectedImgROI != undefined) {
          this.initImg();
        }
      } else if (num === 4) {
        $modal = this.$refs.modalWrap_04;
        await this.getEdgeList();
      }
      $modal.style.display = "block";
      this.$refs.lodingWrap.style.display = "none";
    },
    async setRole() {
      const prjId = this.$route.query.prjId;

      if (typeof prjId !== "undefined" && prjId !== null && prjId !== "") {
        this.manageRole =
          (await common.getUserRole("type2")) ||
          (await common.getPrjRole("type2", prjId));
        this.viewRole =
          (await common.getUserRole("type2")) ||
          (await common.getPrjRole("type2", prjId));
      } else {
        this.manageRole = await common.getUserRole("type1");
        this.viewRole = await common.getUserRole("type1");
      }

      if (!this.viewRole) {
        common.goHome();
      }
    },

    async getCheckYn(list, value) {
      let result = "N";

      list.forEach((item) => {
        if (item === value) {
          result = "Y";
        }
      });

      return result;
    },

    showModalAlert() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      $modal.style.display = "block";
      $modalAlert.style.display = "block";
    },

    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalDevice = this.$refs.modalDevice;
      const $modalAlert = this.$refs.modalAlert;
      $modal.style.display = "none";
      $modalDevice.style.display = "none";
      $modalAlert.style.display = "none";
    },

    async checkAllowAccessCreateBtn() {
      const role = await this.getMemberRole();
      const projectRole = await this.getProjectRole();

      // console.log(role);
      // console.log(projectRole);

      if (role != null && role.role == "SM") {
        return true;
      } else if (
        projectRole != null &&
        (projectRole.projectRole == "Manager" ||
          projectRole.projectRole == "Researcher")
      ) {
        return true;
      } else {
        return false;
      }
    },

    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },

    async getSourceList() {
      const sourceNm = this.sourceNm;
      const param = {};
      if (sourceNm !== "") {
        param.sourceNm = sourceNm;
      }
      try {
        const response = await common.apiGet("/source/all", param);
        const data = response.data.data;
        let sourceId = 0;
        data.forEach((e) => {
          if (e.sourceId !== sourceId) {
            sourceId = e.sourceId;
            e.isMax = true;
            e.class = "class-" + sourceId + "-";
            //console.log(sourceId + e.sourceVersion.toString());
          } else {
            e.class = "sub-class-" + sourceId + "-";
            e.isMax = false;
          }
        });
        this.sourceList = data;
        console.log("this.sourceList :: ", this.sourceList);

        this.currentPage = 1;
        this.totalItems = this.sourceList.length;
        //this.displayDataPerPage(this.sourceList);
      } catch (error) {
        console.error("error", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
#management {
  .btn_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      gap: 15px;
      button {
        height: 40px;
        padding: 0 15px;
        border-radius: 10px;
      }
    }
  }
  .row_box {
    & > div {
      height: auto;
    }
    &.textarea_item {
      & > div {
        height: 100px;
      }
      &.roi_item {
        .data_val {
          position: relative;
          width: calc(100% - 200px);
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .roi_img {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            height: 100%;
            min-height: 100px;
            text-align: center;
            box-sizing: border-box;
            &:nth-child(2) {
              position: absolute;
              left: 420px;
              width: calc(100% - 500px);
            }
            img {
              width: 100%;
            }
            button {
              width: 60px;
              height: 40px;
              border-radius: 10px;
            }
            select {
              background-position: center right 15px;
            }
            &:first-child {
              width: 400px;
              border: 1px solid #d5dae3;
              background: #fff;
              border-radius: 10px;
            }
            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              flex-direction: column;
              justify-content: space-between;
              width: 60px;
            }
            .config {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              display: inline-flex;
              padding: 10px;
              background-color: #fff;
              border: 1px solid #d5dae3;
              border-radius: 10px;
              box-sizing: border-box;
              span {
                display: inline-flex;
                width: 100%;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
      &.edge_item {
        .data_val {
          display: flex;
          align-items: center;
          gap: 20px;
          .edge_ids {
            flex: 1;
            min-height: 100px;
            padding: 15px;
            background-color: #fff;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            ul {
              display: flex;
              align-items: center;
              gap: 10px;
              li {
                display: inline-flex;
                align-items: center;
                position: relative;
                padding: 0 34px 0 10px;
                height: 40px;
                border: 1px solid #d5dae3;
                border-radius: 10px;
                & > div {
                }
                & > button {
                  position: absolute;
                  top: 50%;
                  right: 7px;
                  transform: translateY(-50%);
                  color: #fff;
                  font-size: 16px;
                  background-color: #f15046;
                  width: 20px;
                  height: 20px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                }
              }
            }
          }
          .btn_edge {
            height: 100%;
            button {
              width: 60px;
              height: 40px;
              border-radius: 10px;
            }
          }
        }
      }
      .data_key {
        line-height: 100px;
      }
    }
    .data_val {
      input[type="number"] {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        padding: 0 10px;
      }
      .source_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        button {
          width: 60px;
          height: 40px;
          border-radius: 10px;
        }
      }
      .txtarea {
        padding: 10px;
        min-height: 100px;
        border: 1px solid #d5dae3;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        resize: vertical;
        word-wrap: break-word;
        &:active {
          height: 100%;
        }
      }
      .txt {
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        border: 1px solid #d5dae3;
        border-radius: 10px;
      }
    }
  }

  .row_box.three_item {
    & > div {
      float: left;
      .data_key {
        box-sizing: border-box;
      }
      .data_val {
        .value {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: #fff;
          box-sizing: border-box;
          border: 1px solid #d5dae3;
          border-radius: 10px;
        }
      }
    }
  }
  .row_box.toggle_item {
    .data_key {
      height: 50px;
      line-height: 50px;
    }
    .data_val {
      height: 50px;
      ul {
        height: 50px;
        li {
          width: 210px;
          margin-right: 15px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .table_list {
    .total_num {
      padding-bottom: 20px;
    }
  }

  .filter_box {
    .date_filter {
      position: static;
      padding-left: 0;
      &::after {
        display: none;
      }
      .date_period {
        width: calc(100% - 70px);
        height: 45px;
        padding-right: 70px;
        button {
          width: 70px;
          height: 45px;
        }
      }
    }
  }
}
.modal_mlops {
  width: 40%;
  min-width: 500px;
  min-height: 300px;
  height: auto;
  padding: 50px 0 0;
  overflow: hidden;
  h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #405261;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  textarea {
    resize: none;
    border: 1px solid #d5dae3;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
  }
  input[type="text"],
  .input {
    height: 40px;
    border: 1px solid #d5dae3;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .input {
    display: inline-flex;
    align-items: center;
    padding-right: 35px;
  }
  &.modal_mlops_01 {
    .row {
      display: flex;
      border-bottom: 1px solid #d5dae3;
      .title_top {
        display: inline-flex;
        align-items: center;
        width: calc(100% - 100px);
        padding-left: 20px;
        text-align: left;
        box-sizing: border-box;
      }
      .value_top {
        width: 100px;
        padding: 10px 0;
      }
      .title {
        width: 124px;
        background-color: #f1f4f5;
        span {
          display: inline-flex;
          align-items: center;
          height: 100%;
        }
      }
      .value {
        display: flex;
        flex: 1;
        padding: 10px 0 10px 10px;
        text-align: left;
        .input_box {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 10px;
          .remove_area {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            & > div {
              position: relative;
              .btn_remove {
                position: absolute;
                top: 50%;
                right: 7px;
                transform: translateY(-50%);
                color: #fff;
                font-size: 16px;
                background-color: #f15046;
                width: 20px;
                height: 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
              }
            }
          }
        }
        .btn_box {
          width: 100px;
          display: flex;
          justify-content: center;
        }
        .txt_wrap {
          width: 100%;
          box-sizing: border-box;
          padding-right: 20px;
          textarea {
            width: 100%;
          }
        }
      }
      .mcbtn {
        width: 60px;
        height: 40px;
        border-radius: 10px;
      }
    }
  }
  &.modal_mlops_02 {
    .row {
      .tbl_source {
        max-height: 50vh;
        overflow-y: auto;
        .title {
          display: flex;
          align-items: center;
          height: 46px;
          background-color: #ffa800;
          & > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #fff;
            font-weight: 600;
            box-sizing: border-box;
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 86px;
              border-left: 1px solid #fff;
              border-right: 1px solid #fff;
            }
            &:nth-child(3) {
              width: 100px;
              border-right: 1px solid #fff;
            }
            &:nth-child(4) {
              flex: 1;
            }
          }
        }
        .content {
          .tbl_row {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: #fff2d9;
            &.active {
              background-color: #d9ecf2;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #fff;
            }
            & > div {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-height: 40px;
              height: 100%;
              box-sizing: border-box;
              &:nth-child(1) {
                width: 30%;
                gap: 7px;
                justify-content: flex-start;
                padding: 0 10px;
              }
              &:nth-child(2) {
                width: 86px;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
              }
              &:nth-child(3) {
                width: 100px;
                border-right: 1px solid #fff;
              }
              &:nth-child(4) {
                flex: 1;
                padding: 10px;
                text-align: left;
              }
              .btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                color: #fff;
                &.btn_plus {
                  background-color: #02a744;
                }
                &.btn_minus {
                  background-color: #f15046;
                }
                .ico_plus {
                }
                .ico_minus {
                  display: none;
                }
              }
              strong {
                margin-left: 10px;
              }
            }
          }
        }
      }

      &.row_01 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        box-sizing: border-box;
        padding: 10px;
        button {
          width: 60px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
  &.modal_mlops_03 {
    width: 70%;
    .row {
      display: flex;
      padding-bottom: 20px;
      &.row_01 {
        justify-content: flex-end;
        gap: 10px;
        box-sizing: border-box;
        padding: 10px;
        button {
          width: 60px;
          height: 40px;
          border-radius: 10px;
        }
      }
      .item {
        position: relative;
        padding-bottom: 70px;
        width: 50%;
        box-sizing: border-box;
        border-top: 1px solid #d5dae3;
        border-bottom: 1px solid #d5dae3;
        &:first-child {
          border-right: 1px solid #d5dae3;
          .img {
          }
        }
        &:last-child {
          .img {
            display: inline-flex;
            align-items: center;
          }
        }
        .title {
          display: inline-flex;
          height: 40px;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #00b4ed;
          color: #fff;
          font-weight: 600;
        }
        .img {
          max-width: 100%;
          min-height: 300px;
          height: calc(100% - 40px);
          padding: 35px 35px 0;
          box-sizing: border-box;
        }
        .control {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          width: 100%;
          padding: 0 35px;
          bottom: 15px;
          left: 0;
          box-sizing: border-box;
          label,
          button {
            display: inline-flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            border-radius: 10px;
            cursor: pointer;
          }
          .preview-controller {
            position: absolute;
            bottom: 0;
            display: flex;
            gap: 15px;
          }
          .preview-controller > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .preview-controller input {
            width: 60px;
          }
        }
      }
    }
  }
  &.modal_mlops_04 {
    .row {
      .tbl_edge {
        .title {
          display: flex;
          align-items: center;
          height: 46px;
          background-color: #ffa800;
          & > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #fff;
            font-weight: 600;
            box-sizing: border-box;
            border-right: 1px solid #fff;
            &:nth-child(1) {
              width: 210px;
            }
            &:nth-child(2) {
              width: 140px;
            }
            &:nth-child(3) {
              width: 50px;
            }
            &:nth-child(4) {
              width: 50px;
            }
            &:nth-child(5) {
              flex: 1;
            }
          }
        }
        .content {
          .tbl_row {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: #fff2d9;
            &.active {
              background-color: #d9ecf2;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #fff;
            }
            & > div {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-height: 40px;
              height: 100%;
              box-sizing: border-box;
              border-right: 1px solid #fff;
              &:nth-child(1) {
                width: 210px;
              }
              &:nth-child(2) {
                width: 140px;
              }
              &:nth-child(3) {
                width: 50px;
              }
              &:nth-child(4) {
                width: 50px;
              }
              &:nth-child(5) {
                flex: 1;
              }

              .btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                color: #fff;
                &.btn_plus {
                  background-color: #02a744;
                }
                &.btn_minus {
                  background-color: #f15046;
                }
                .ico_plus {
                }
                .ico_minus {
                  display: none;
                }
              }
            }
          }
        }
      }

      &.row_01 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        box-sizing: border-box;
        padding: 10px;
        button {
          width: 60px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
  .btn_modal_close {
    position: absolute;
    top: 8px;
    right: 10px;
    color: #fff;
    font-size: 28px;
  }
}
</style>
