<template>
  <div class="date_period_second">
    <tr v-for="(item, index) in barKeyValueList" :key="index">
      <td>
        <div v-if="barKeyValueType === 'checkbox'">
          <input
            type="checkbox"
            :id="'checkbox-' + index"
            :value="item.value"
            :name="'checkbox' + index"
            :key="item.key"
          />
          <label :for="'checkbox-' + index"></label>
          <span> {{ item.value }}</span>
        </div>
        <div v-if="barKeyValueType === 'radio'">
          <input
            type="radio"
            :value="item.value"
            :id="'radio' + index"
            name="radio"
            :key="item.key"
          />
          <label for="radio"></label>
          <span>{{ item.value }}</span>
        </div>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: {
    interface: {
      type: Object,
      required: true,
    },
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
    keyValueList: {
      type: Object,
    },
    keyValueType: {
      type: String,
    },
  },
  data() {
    return {
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      DataMapCateGory: "Data",
      parentHandlerKey: "",
      barKeyValueType: "",
      inputKey: "",
      inputValue: "",
      barKeyValueList: [],
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    //const handlerList = this.handlerList;
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    vm.labelText = prop.labelText;
    vm.parameter = prop.parameter;
    vm.barKeyValueList = prop.keyValueList;
    vm.barKeyValueType = prop.keyValueType;
    //handler 제어 할려면 handler ID 값을 따로 저장해두는 곳이 있어야 하지 않나...
    console.log("selectValueBarView");
    console.log(prop);
  },
  methods: {
    updateParent(event) {
      this.$emit("updateParent", {
        index: this.componentIndex,
        value: event.target.value,
      });
      console.log(this.handlerList);
    },
    labelTextChange(event) {
      this.labelText = event.target.value;
      const item = this.otherProp;
      item.labelText = this.labelText;
    },
    parameterTextChange(event) {
      this.parameter = event.target.value;
      const item = this.otherProp;
      item.parameter = this.parameter;
    },
    removeItem() {
      this.$emit("removeItem");
    },
    insertKeyValue() {
      const vm = this;
      const key = vm.inputKey;
      const value = vm.inputValue;
      const object = { key: key, value: value };
      this.barKeyValueList.push(object);
      const item = vm.otherProp;
      item.keyValueList = vm.barKeyValueList;
    },
    removeKeyValue(index) {
      this.barKeyValueList.splice(index, 1);
      const item = this.otherProp;
      item.keyValueList = this.barKeyValueList;
    },
    keyValueTypeChange(event) {
      const keyValue = event.target.value;
      this.barKeyValueType = keyValue;
      this.$emit("update:keyValueType", this.barKeyValueType);
      const item = this.otherProp;
      item.keyValueType = this.barKeyValueType;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
</style>
