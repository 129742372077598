<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents" class="contents">
      <div id="test"></div>
      <section style="display: flex; gap: 30px">
        <div style="width: 50%">
          <input type="file" id="file_1" @change="change_1" />
          <div id="videoArea"></div>

          <video
            id="video_1"
            preload="none"
            style="width: 100%"
            playsinline
          ></video>
        </div>
        <div style="width: 50%">
          <input type="file" id="file_2" multiple @change="change_2()" />
          <video id="video_2" controls style="width: 100%" playsinline></video>
        </div>
      </section>
      <div>
        <input type="file" id="file_3" multiple @change="csv_test" />
      </div>
      <div class="item_info">
        <div class="float_box">
          <div class="data_set_mon">
            <div class="data_set">
              <div class="time_controller radiusbox">
                <div class="controller_box">
                  <button
                    type="button"
                    class="start_point btn_point"
                    ref="fPointBtn"
                    @click="rangePoint($event, 'fPoint')"
                  ></button>
                  <div class="btn_wrap" ref="controlBtnBox">
                    <button
                      type="button"
                      class="first_point"
                      @click="controlBtn($event, 'fTime')"
                    ></button>
                    <button
                      type="button"
                      class="back_seconds"
                      @click="controlBtn($event, 'back3')"
                    ></button>
                    <button
                      type="button"
                      class="play_pause pause"
                      ref="playPauseBtn"
                      @click="controlBtn($event, 'playPause')"
                    ></button>
                    <button
                      type="button"
                      class="forward_seconds"
                      @click="controlBtn($event, 'forward3')"
                    ></button>
                    <button
                      type="button"
                      class="end_point"
                      @click="controlBtn($event, 'eTime')"
                    ></button>
                  </div>
                  <button
                    type="button"
                    class="end_point btn_point"
                    ref="ePointBtn"
                    @click="rangePoint($event, 'ePoint')"
                  ></button>
                </div>
                <button
                  type="button"
                  class="add_select_section"
                  ref="selectBtn"
                  @click="addDrivRecBtnModal()"
                  style="display: none"
                >
                  선택구간 추가
                </button>
                <div class="start_end" ref="selecTimeBox">
                  <div class="start_point_time">
                    <div>시작 시간</div>
                    <span>00:00:00:00</span>
                  </div>
                  <div class="end_point_time">
                    <div>종료 시간</div>
                    <span>00:00:00:00</span>
                  </div>
                </div>
                <div class="range_box">
                  <div class="time_move_box" ref="timeInputBox">
                    <strong>00:00:00:00</strong>
                  </div>
                  <div class="range_item" ref="rangeItem">
                    <input
                      id="exIn"
                      type="range"
                      min="0"
                      value="0"
                      class="slider"
                      :max="videoDuration"
                    />
                    <div class="flow_bg"></div>
                    <div class="range_bg"></div>
                    <div class="pin_point f_point" style="display: none"></div>
                    <div class="pin_point e_point" style="display: none"></div>

                    <div
                      class="time_mark"
                      v-for="(item, index) in recList"
                      :key="index"
                      :style="{
                        display: 'block',
                        width: item.end_time - item.start_time + '%',
                        left: item.start_time + '%',
                      }"
                    >
                      <div
                        :style="{ backgroundColor: item.record_color }"
                      ></div>
                      <div
                        class="mark_index"
                        :style="{ backgroundColor: item.record_color }"
                      >
                        {{ item.no }}
                      </div>
                    </div>
                  </div>
                  <div class="end_time" ref="endTime">
                    <strong>00:00:00:00</strong>
                  </div>
                </div>
              </div>
              <div class="float_box">
                <div class="radiusbox list_box">
                  <div class="title_box">
                    <strong>선택 구간</strong>
                  </div>
                  <div class="mando_table_wrap">
                    <table class="mando_table select_section">
                      <colgroup>
                        <col style="width: 55px" />
                        <col style="width: 100px" />
                        <col style="width: 100px" />
                        <col style="width: 50px" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Start time</th>
                          <th>End time</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody ref="recListWrap">
                        <tr
                          class=""
                          v-for="(item, index) in recList"
                          :key="index"
                          :data-start-value="item.start_time_value"
                          :data-end-value="item.end_time_value"
                        >
                          <!-- :data-st="item.start_time" -->
                          <td
                            @click="selectStartTime($event)"
                            :style="{ color: item.record_color }"
                          >
                            <div
                              class="list_item_icon"
                              :style="{ borderColor: item.record_color }"
                            >
                              <div
                                :style="{ borderLeftColor: item.record_color }"
                              ></div>
                            </div>
                            {{ item.no }}
                          </td>
                          <td @click="selectStartTime($event)">
                            {{ item.start_timestamp }}
                          </td>
                          <td @click="selectStartTime($event)">
                            {{ item.end_timestamp }}
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn_evt_group"
                              @click="btnEvtGroup($event)"
                            ></button>
                            <ul class="evt_btn_box radiusbox">
                              <li
                                class="copy_item"
                                @click="
                                  copyTextTime(
                                    $event,
                                    item.start_time,
                                    item.end_time
                                  )
                                "
                              >
                                <button type="button">키 복사</button>
                              </li>
                              <!-- <li class="retouch_item">
                                                                    <button type="button">수정</button>
                                                                </li> -->
                              <li class="delete_item">
                                <button
                                  type="button"
                                  @click="
                                    deleteDriRec(
                                      $event,
                                      item.id,
                                      item.driving_record_id
                                    )
                                  "
                                >
                                  삭제
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="img-layer">
        <h3>이미지 레이어 액션</h3>
        <label for="chgImg" class="m_pointer">파일선택</label>
        <div class="imgTxt">{{ imgFileName }}</div>
        <input
          type="file"
          class="blind"
          accept="image/*"
          id="chgImg"
          @change="chgImg"
        />
        <button type="button" @click="crop">크롭버튼</button>
        <div class="crop-wrap">
          <div class="cropper">
            <cropper
              ref="cropper"
              :src="img"
              :stencil-props="{
                previewClass: 'line',
                /*
                handlers: {},
                */
              }"
              @change="onChange"
            />
          </div>

          <div class="preview">
            <preview
              ref="preview"
              :width="imageWidth"
              :height="imageHeight"
              :image="result.image"
              :coordinates="result.coordinates"
            />
            <div class="preview-controller">
              <div>
                X :&nbsp;<input type="text" :value="previewX" disabled />
              </div>
              <div>
                Y :&nbsp;<input type="text" :value="previewX" disabled />
              </div>
              <div>
                Width :&nbsp;<input
                  type="text"
                  :value="previewWidth"
                  disabled
                />
              </div>
              <div>
                Height :&nbsp;<input
                  type="text"
                  :value="previewHeight"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <color-picker
          ref="123"
          disableHistory="true"
          disableAlpha="true"
          @update:pureColor="test"
        />
        <div id="color01"></div>
      </div>
      <canvas ref="MyChart" />
      <TimelineControllerSetting></TimelineControllerSetting>
      <div>
        <div
          id="parent"
          style="
            width: 600px;
            height: 300px;
            border: 1px solid black;
            margin: 20px;
          "
        >
          <draggable-resizable-vue
            v-model:x="element.x"
            v-model:y="element.y"
            v-model:h="element.height"
            v-model:w="element.width"
            v-model:active="element.isActive"
          >
            <textarea style="display: block; width: 100%; resize: none">
value="가나다라마바사아"</textarea
            >
          </draggable-resizable-vue>
        </div>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap" style="display: none">
    <!-- error 알림 -->
    <div
      class="radiusbox modal_contents message_alert message_modal"
      ref="modalAlert"
      style="display: none"
    >
      <div class="message_box" v-html="alertMessege"></div>
      <button type="button" class="btn_check mcbtn" @click="closeModal()">
        확인
      </button>
      <button type="button" class="btn_close" @click="closeModal()"></button>
    </div>
    <!-- 선택구간 추가 알림 -->
    <div
      class="radiusbox modal_contents message_check message_modal"
      ref="modalAddAlert"
      style="display: none"
    >
      <div class="message_box">선택 구간을 추가하시겠습니까?</div>
      <button type="button" class="btn_close" @click="closeModal()"></button>
      <button type="button" class="btn_check mcbtn" @click="addDrivRecBtn()">
        확인
      </button>
    </div>
  </div>

  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import { Cropper, Preview } from "vue-advanced-cropper";
import { Chart, registerables } from "chart.js/auto";
import "vue-advanced-cropper/dist/style.css";
import TimelineControllerSetting from "@/components/TimelineControllerSetting.vue";
import papa from "papaparse";
import DraggableResizableVue from "draggable-resizable-vue3";

Chart.register(...registerables);

export default {
  name: "TestView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    Cropper,
    Preview,
    TimelineControllerSetting,
    ColorPicker,
    DraggableResizableVue,
    //DraggableResizableContainer,
  },
  data() {
    return {
      element: {
        x: 20,
        y: 20,
        width: 200,
        height: 200,
        isActive: false,
      },
      csvData: [],
      fileObj: null,
      isDragging: false,
      isClicking: false,
      currentTime: 0,
      duration: 0,
      videoDuration: 10000,
      videoTest: null,
      nowMilliseconds: 0,
      timeInterval: null,
      interverNum: 10,
      isTimeEnd: false,
      videoMinunumFrame: 0.042,
      img: "",
      imgFileName: "",
      result: {
        coordinates: null,
        image: null,
      },
      imageWidth: 0,
      imageHeight: 0,
      recList: [],
      sectionCnt: 1,
      sectionCntEx: 5,
      alertMessege: "시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.",
      color: [
        "#80BDFF",
        "#409DFF",
        "#187BFF",
        "#0F5CBF",
        "#073E80",
        "#B7A1E0",
        "#9371D1",
        "#6F42C1",
        "#533191",
        "#382261",
        "#EE9AA2",
        "#E56874",
        "#DC3545",
        "#A52833",
        "#6E1A23",
        "#94D3A2",
        "#5EBD74",
        "#27A745",
        "#1F7D35",
        "#145423",
        "#FDBE89",
        "#FE9F4F",
        "#FD7E14",
        "#BE5F0F",
        "#7F3F0B",
        "#DDD8D6",
        "#CBC5C2",
        "#BAB1AD",
        "#8C8582",
        "#5D5957",
        "#B388F9",
        "#8C4CF5",
        "#660FF2",
        "#4D0DB6",
        "#330879",
        "#F49FC6",
        "#EE6EA9",
        "#E83E8C",
        "#AE2F69",
        "#741F46",
        "#FEE083",
        "#FED145",
        "#FEC106",
        "#C09106",
        "#806106",
        "#90E4CB",
        "#5AD7B1",
        "#20C997",
        "#189771",
        "#10654C",
        "#8BD1DC",
        "#51B9CA",
        "#17A2B8",
        "#117A8A",
        "#0D525B",
      ],
      type: "bar",
      data: {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  },
  watch: {
    recList: function () {
      return this.recList;
    },
  },
  computed: {
    previewX: function () {
      return this.result?.coordinates?.left;
    },
    previewY: function () {
      return this.result?.coordinates?.top;
    },
    previewWidth: function () {
      return this.result?.coordinates?.width;
    },
    previewHeight: function () {
      return this.result?.coordinates?.height;
    },
  },
  created: function () {},
  mounted() {
    const video = document.getElementById("video_1");
    const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
    const rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");

    video.addEventListener("loadeddata", () => {
      video.currentTime = 0;
      rangeBtn.value = 0;
      rangeGage.style.width = 0;
      this.nowMilliseconds = 0;
      this.$refs.timeInputBox.querySelector("strong").innerText = "00:00:00:00";
    });

    rangeBtn.addEventListener("mousedown", () => {
      this.isClicking = true;
    });
    rangeBtn.addEventListener("input", () => {
      this.rangeGageEvent(rangeBtn.value);
    });
    rangeBtn.addEventListener("mousemove", () => {
      if (this.isClicking) {
        this.isDragging = true;
        this.stopInterval("videoPause");
      }
    });
    rangeBtn.addEventListener("mouseup", () => {
      if (this.isClicking) {
        this.isClicking = false;
        this.isDragging = false;
        this.stopInterval("videoPause");
      }
    });
    this.createChart();
  },
  methods: {
    createChart() {
      new Chart(this.$refs.MyChart, {
        type: "bar",
        data: this.data,
        options: this.options,
      });
    },
    test(e) {
      document.getElementById("color01").style.width = "500px";
      document.getElementById("color01").style.height = "300px";
      document.getElementById("color01").style.backgroundColor = e;
    },
    selectStartTime(e) {
      this.stopInterval("videoPause");
      const vm = this;
      /*
      const startTime = e.target.closest("tr").getAttribute("data-st");
      const stMilliseconds = vm.timeStringToMilliseconds(startTime);
      const maxTime = this.timeSet.max;
      const $nowTime = this.$refs.timeInputBox.querySelector("strong");
      */
      const video = document.getElementById("video_1");
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      const $selectBtn = vm.$refs.selectBtn;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $rangeItem = vm.$refs.rangeItem;
      const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
      const startTimeValue = e.target
        .closest("tr")
        .getAttribute("data-start-value");

      // 인터벌 종료
      // 정지 모드로 만들기
      vm.status = "pause";
      vm.$refs.playPauseBtn.classList.remove("play");
      vm.$refs.playPauseBtn.classList.add("pause");
      $fPointBtn.style.display = "block";
      $ePointBtn.style.display = "block";
      $selecTimeBox.style.display = "block";
      if ($fPointBtn.classList.contains("active")) {
        $rangeItem.querySelector(".f_point").style.display = "block";
      }
      if ($ePointBtn.classList.contains("active")) {
        $rangeItem.querySelector(".e_point").style.display = "block";
      }
      if (
        $fPointBtn.classList.contains("active") &&
        $ePointBtn.classList.contains("active")
      ) {
        $selectBtn.style.display = "block";
      }

      //시간 이동
      //
      /*
      let nowTime = vm.convertMillisecondsToTime(stMilliseconds);
      $nowTime.innerText = String(nowTime);
      vm.rangeGageEvent(stMilliseconds, maxTime);
      vm.$refs.rangeItem.querySelector("#exIn").value = stMilliseconds;
      */

      //alert(this.startCurrentTime * video.playbackRate);

      video.currentTime =
        (startTimeValue / 1000) *
        ((video.duration * 1000) / this.videoDuration);
      rangeBtn.value = startTimeValue;
      this.rangeGageEvent(rangeBtn.value);
    },
    getRandomColor() {
      const vm = this;
      const min = 0;
      const max = vm.color.length - 1;
      const randomDecimal = Math.random();
      const randomNumber = Math.floor(randomDecimal * (max - min + 1)) + min;

      return vm.color[randomNumber];
    },
    addDrivRecBtn() {
      if (this.sectionCnt > this.sectionCntEx) return false;

      const vm = this;
      const $rangeItem = vm.$refs.rangeItem;
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      // const $modalWrap = vm.$refs.modalWrap;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $selectBtn = vm.$refs.selectBtn;
      const color = vm.getRandomColor();
      /*
      this.recList.start_time = vm.fPoint / 1000;
      this.recList.end_time = vm.ePoint / 1000;
      this.recList.record_color = color;
      this.recList.no = 1;
      */
      const obj = {
        start_time: vm.fPoint,
        end_time: vm.ePoint,
        record_color: color,
        no: this.sectionCnt,
        start_timestamp: this.startTimestamp,
        end_timestamp: this.endTimestamp,
        /*
        start_current_time: this.startCurrentTime,
        end_current_time: this.endCurrentTime,
        */
        start_time_value: this.startTimeValue,
        end_time_value: this.endTimeValue,
      };
      this.recList.push(obj);
      this.sectionCnt++;

      $rangeItem.querySelector(".f_point").style.display = "none";
      $rangeItem.querySelector(".e_point").style.display = "none";
      $selectBtn.style.display = "none";

      $ePointBtn.classList.remove("active");
      $fPointBtn.classList.remove("active");
      $selecTimeBox
        .querySelector(".start_point_time")
        .querySelector("span").innerText = "00:00:00:00";
      $selecTimeBox
        .querySelector(".end_point_time")
        .querySelector("span").innerText = "00:00:00:00";
      vm.fPoint = 0;
      vm.ePoint = 0;

      vm.closeModal();
    },
    showmodalAddAlert() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAddAlert;
      $modal.style.display = "block";
      $modalAlert.style.display = "block";
    },

    addDrivRecBtnModal() {
      /*
      const allowAccess = await this.checkRoleToAccessBtn();
      if (!allowAccess) {
        alert("SM, Project Manager, Project Researcher만 접근 가능");
        return;
      }
      */
      const vm = this;
      const $rangeItem = vm.$refs.rangeItem;
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      const $modalWrap = vm.$refs.modalWrap;
      const $modalAlert = vm.$refs.modalAlert;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $selectBtn = vm.$refs.selectBtn;

      console.log(Number(vm.fPoint));
      console.log(Number(vm.ePoint));

      console.log(Number(vm.fPoint) > Number(vm.ePoint));
      if (Number(vm.fPoint) > Number(vm.ePoint)) {
        console.log("시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.");
        $modalWrap.style.display = "block";
        $modalAlert.style.display = "block";
        $rangeItem.querySelector(".f_point").style.display = "none";
        $rangeItem.querySelector(".e_point").style.display = "none";
        $selectBtn.style.display = "none";
        $ePointBtn.classList.remove("active");
        $fPointBtn.classList.remove("active");
        $selecTimeBox
          .querySelector(".start_point_time")
          .querySelector("span").innerText = "00:00:00:00";
        $selecTimeBox
          .querySelector(".end_point_time")
          .querySelector("span").innerText = "00:00:00:00";
        vm.fPoint = 0;
        vm.ePoint = 0;
      } else {
        vm.showmodalAddAlert();
        // const color = vm.getRandomColor();

        // console.log(color);
        // console.log(vm.drawDrivingRecordd(vm.fPoint, vm.ePoint, color));
        // 확인 팝업 ui만들어야함
        // vm.addDriRecData(10, color, vm.convertMillisecondsToTime(vm.fPoint), vm.convertMillisecondsToTime(vm.ePoint));
      }
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      const $modalAddAlert = this.$refs.modalAddAlert;

      $modal.style.display = "none";
      $modalAlert.style.display = "none";
      $modalAddAlert.style.display = "none";
    },
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      };
      console.log("coordinates :: ", coordinates);
      /*
      this.coordinatesPreviewWidth = coordinates.width;
      this.coordinatesPreviewHeight = coordinates.height;

      let scaleX = image.transforms.scaleX;
      let scaleY = image.transforms.scaleY;
      */
      console.log("image :: ", image);
      //this.imageWidth = image.width;
      //this.imageHeight = image.height;
      console.log("resultttt :: ", this.result.image);

      console.log("preview :: ", this.$refs.preview);

      let w = document.querySelector(".vue-rectangle-stencil").style.width;
      let h = document.querySelector(".vue-rectangle-stencil").style.height;

      this.imageWidth = Number(w.split("px")[0]);
      this.imageHeight = Number(h.split("px")[0]);

      console.log("99999999999999", this.imageHeight);
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      console.log(canvas.toDataURL());
    },
    chgImg() {
      const inputFile = document.getElementById("chgImg");
      const file = inputFile.files[0];

      // 파일 로드가 완료되면 실행될 콜백 함수를 정의합니다.

      const image = new Image();
      //let imgUrl;
      if (file) {
        image.src = URL.createObjectURL(file);
        this.imgFileName = file.name;
        this.img = image.src;
      }
      /*
      image.onload = function () {
        this.newRatio = image.width / image.height;
      };
      */
    },
    rangePoint(e, type) {
      const video = document.getElementById("video_1");
      const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
      const $fPointBtn = this.$refs.fPointBtn;
      const $ePointBtn = this.$refs.ePointBtn;
      const $selectBtn = this.$refs.selectBtn;
      const $selecTimeBox = this.$refs.selecTimeBox;
      const $rangeItem = this.$refs.rangeItem;

      if (!video.src || video.src == "") return false;

      if (this.status == "play") {
        $fPointBtn.style.display = "none";
        $ePointBtn.style.display = "none";
        $selectBtn.style.display = "none";
        $selecTimeBox.style.display = "none";
        $rangeItem.querySelector(".f_point").style.display = "none";
        $rangeItem.querySelector(".e_point").style.display = "none";
      } else if (this.status == "pause") {
        $fPointBtn.style.display = "block";
        $ePointBtn.style.display = "block";
        $selecTimeBox.style.display = "block";
        if ($fPointBtn.classList.contains("active")) {
          $rangeItem.querySelector(".f_point").style.display = "block";
        }
        if ($ePointBtn.classList.contains("active")) {
          $rangeItem.querySelector(".e_point").style.display = "block";
        }
        if (
          $fPointBtn.classList.contains("active") &&
          $ePointBtn.classList.contains("active")
        ) {
          $selectBtn.style.display = "block";
        }
      }

      if (type == "fPoint") {
        //const video = document.getElementById("video_1");
        //const rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");
        //const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
        //this.fPoint = 0;
        this.fPoint = (this.nowMilliseconds / this.videoDuration) * 100;
        this.startTimestamp = this.convertMillisecondsToTime(
          this.nowMilliseconds / 1000
        );
        this.startCurrentTime = video.currentTime;
        this.startTimeValue = rangeBtn.value;

        //let nowTime = (this.fPoint / this.timeSet.max) * 100;

        if ($fPointBtn.classList.contains("active")) {
          $fPointBtn.classList.remove("active");
          $ePointBtn.classList.remove("active");
          $selecTimeBox
            .querySelector(".start_point_time")
            .querySelector("span").innerText = "00:00:00:00";
          $selecTimeBox
            .querySelector(".end_point_time")
            .querySelector("span").innerText = "00:00:00:00";
          $rangeItem.querySelector(".f_point").style.display = "none";
          $rangeItem.querySelector(".e_point").style.display = "none";
        } else {
          $fPointBtn.classList.add("active");
          $selecTimeBox
            .querySelector(".start_point_time")
            .querySelector("span").innerText = this.convertMillisecondsToTime(
            this.nowMilliseconds / 1000
          );
          $rangeItem.querySelector(".f_point").style.display = "block";
          $rangeItem.querySelector(".f_point").style.left = this.fPoint + "%";
        }

        // 선택구간 검사
        if (
          $fPointBtn.classList.contains("active") &&
          $ePointBtn.classList.contains("active")
        ) {
          $selectBtn.style.display = "block";
        } else {
          $selectBtn.style.display = "none";
        }

        console.log(this.nowMilliseconds);
      } else if (type == "ePoint") {
        this.ePoint = (this.nowMilliseconds / this.videoDuration) * 100;
        this.endTimestamp = this.convertMillisecondsToTime(
          this.nowMilliseconds / 1000
        );
        this.endCurrentTime = video.currentTime;
        this.endTimeValue = rangeBtn.value;

        if ($ePointBtn.classList.contains("active")) {
          $ePointBtn.classList.remove("active");
          $fPointBtn.classList.remove("active");
          $selecTimeBox
            .querySelector(".start_point_time")
            .querySelector("span").innerText = "00:00:00:00";
          $selecTimeBox
            .querySelector(".end_point_time")
            .querySelector("span").innerText = "00:00:00:00";
          $rangeItem.querySelector(".e_point").style.display = "none";
          $rangeItem.querySelector(".f_point").style.display = "none";
        } else {
          $ePointBtn.classList.add("active");
          $selecTimeBox
            .querySelector(".end_point_time")
            .querySelector("span").innerText = this.convertMillisecondsToTime(
            this.nowMilliseconds / 1000
          );
          $rangeItem.querySelector(".e_point").style.display = "block";
          $rangeItem.querySelector(".e_point").style.left = this.ePoint + "%";
        }
        // 선택구간 검사
        if (
          $fPointBtn.classList.contains("active") &&
          $ePointBtn.classList.contains("active")
        ) {
          $selectBtn.style.display = "block";
        } else {
          $selectBtn.style.display = "none";
        }
      }
    },
    csv_test(event) {
      const reader = new FileReader();

      this.fileObj = event.target.files[0];
      const videourl = URL.createObjectURL(this.fileObj);
      reader.readAsText(this.fileObj);
      reader.onloadend = () => {
        console.log(this.csvToJson(videourl));
      };
    },
    csvToJson() {
      /*
      const rows = csvString.split("\n");

      const headers = rows[0].split(",");

      const jsonData = [];
      for (let i = 1; i < rows.length; i++) {
        const values = rows[i].split(",");

        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          const key = headers[j]?.trim();
          const value = values[j]?.trim();

          obj[key] = value;
        }

        jsonData.push(obj);
      }
      return JSON.stringify(jsonData);
      */

      console.log("123123 :: ", this.fileObj);
      papa.parse(this.fileObj, {
        complete: function (results) {
          // executed after all files are complete

          this.csvData = results.data;
          console.log("csvData :: ", this.csvData);
          console.log("csvData :: ", this.csvData[300928]);
        },
      });
    },
    successFunction(data) {
      var allRows = data;

      var table = "<table>";
      for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
        if (singleRow === 0) {
          table += "<thead>";
          table += "<tr>";
        } else {
          table += "<tr>";
        }
        var rowCells = allRows[singleRow].split(",");
        for (var rowCell = 0; rowCell < rowCells.length; rowCell++) {
          if (singleRow === 0) {
            table += "<th>";
            table += rowCells[rowCell];
            table += "</th>";
          } else {
            table += "<td>";
            table += rowCells[rowCell];
            table += "</td>";
          }
        }
        if (singleRow === 0) {
          table += "</tr>";
          table += "</thead>";
          table += "<tbody>";
        } else {
          table += "</tr>";
        }
      }
      table += "</tbody>";
      table += "</table>";
      document.getElementById("test").innerHTML = table;
    },

    change_1() {
      const inputFile = document.getElementById("file_1");
      const video = document.getElementById("video_1");
      const file = inputFile.files[0];
      this.stopInterval("videoPause");
      this.$refs.endTime.querySelector("strong").innerText =
        this.convertMillisecondsToTime(this.videoDuration / 1000);

      let videourl;
      if (file) {
        videourl = URL.createObjectURL(file);
        video.setAttribute("src", videourl);
      }
    },
    startInterval() {
      const video = document.getElementById("video_1");
      const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
      const rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");

      video.playbackRate = (video.duration * 1000) / this.videoDuration;

      if (Math.round(this.nowMilliseconds / 10) * 10 >= this.videoDuration) {
        this.nowMilliseconds = 0;
        this.isTimeEnd = false;
      }
      if (!this.isTimeEnd) {
        video.play();
      }

      document.querySelector(".play_pause").classList.add("play");
      this.timeInterval = setInterval(() => {
        if (this.videoDuration - this.nowMilliseconds < this.interverNum) {
          this.stopInterval();
          this.nowMilliseconds = this.videoDuration;
        } else {
          this.nowMilliseconds =
            Number(this.nowMilliseconds) + Number(this.interverNum);
        }
        rangeBtn.value = this.nowMilliseconds;

        rangeGage.style.width =
          (this.nowMilliseconds / this.videoDuration) * 100 + "%";
        console.log(rangeGage.style.width);
        this.$refs.timeInputBox.querySelector("strong").innerText =
          this.convertMillisecondsToTime(this.nowMilliseconds / 1000);
      }, 10);
    },
    stopInterval(isVideoPause) {
      const video = document.getElementById("video_1");
      if (isVideoPause == "videoPause") video.pause();
      clearInterval(this.timeInterval);
      document.querySelector(".play_pause").classList.remove("play");
    },
    change_2() {
      /*
      const inputFile = document.getElementById("file_2");

      const video01 = document.getElementById("video_1");
      const video = document.getElementById("video_2");
      //alert(video01.currentTime);
      video.currentTime = video01.currentTime;
      const file = inputFile.files[0];
      let du = video01.duration;
      //alert(du);
      //du /
      const videourl = URL.createObjectURL(file);
      video.setAttribute("src", videourl);
      //video.playbackRate = 0.1;
      video.play();
      let du2;
      video.onloadedmetadata = function () {
        //alert(this.duration); //this refers to myVideo
        du2 = this.duration;
        if (du > du2) {
          //alert((1 / du) * du2);
          video.playbackRate = (1 / du) * du2;
        } else if (du < du2) {
          video.playbackRate = du2 / du;
        }
      };
      */
    },

    rangeGageEvent(rangeGageValue) {
      const video = document.getElementById("video_1");
      const rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");

      this.nowMilliseconds = Number(rangeGageValue);

      rangeGage.style.width =
        (Number(rangeGageValue) / Number(this.videoDuration)) * 100 + "%";

      video.currentTime =
        (rangeGageValue / 1000) *
        ((video.duration * 1000) / this.videoDuration);
      if (video.duration - video.currentTime < this.videoMinunumFrame) {
        this.isTimeEnd = true;
        video.currentTime = video.duration;
        video.pause();
      } else {
        this.isTimeEnd = false;
      }

      if (this.videoDuration > rangeGageValue) {
        this.$refs.timeInputBox.querySelector("strong").innerText =
          this.convertMillisecondsToTime(rangeGageValue / 1000);
      } else {
        this.$refs.timeInputBox.querySelector("strong").innerText =
          this.convertMillisecondsToTime(this.videoDuration / 1000);
      }
    },
    controlBtn(e, type) {
      /*
      if (type == "playPause") alert(123);
      else return false;
      */
      const video = document.getElementById("video_1");
      const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
      const rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");
      let $videoTime = 0;

      if (video.src && video.src != "") {
        if (type == "playPause") {
          if (
            document.querySelector(".play_pause").classList.contains("play")
          ) {
            this.stopInterval("videoPause");
          } else {
            this.startInterval();
          }
        } else {
          if (type == "fTime") {
            rangeBtn.value = 0;
            rangeGage.style.width = "0";
            video.currentTime = 0;
            this.nowMilliseconds = 0;
            $videoTime = video.currentTime;
          } else if (type == "eTime") {
            rangeBtn.value = Math.ceil(video.duration) * 1000;
            rangeGage.style.width = "100%";
            video.currentTime = video.duration;
            this.nowMilliseconds = this.videoDuration;
            $videoTime = this.videoDuration / 1000;
          } else if (type == "back3") {
            rangeBtn.value = rangeBtn.value - 3000;
            video.currentTime =
              Number(rangeBtn.value / 1000) * video.playbackRate;
            rangeGage.style.width =
              Number(rangeBtn.value / this.videoDuration) * 100 + "%";
            $videoTime = Number(rangeBtn.value) / 1000;
            this.nowMilliseconds = rangeBtn.value;
          } else if (type == "forward3") {
            rangeBtn.value = Number(rangeBtn.value) + 3000;
            video.currentTime =
              Number(rangeBtn.value / 1000) * video.playbackRate;
            rangeGage.style.width =
              (rangeBtn.value / this.videoDuration) * 100 + "%";
            $videoTime = Number(rangeBtn.value) / 1000;
            this.nowMilliseconds = rangeBtn.value;
          }
          this.stopInterval("videoPause");
          this.$refs.timeInputBox.querySelector("strong").innerText =
            this.convertMillisecondsToTime($videoTime);
        }
      }
    },
    timeStringToMilliseconds(timeString) {
      // HH:MM:SS:ss > ss
      if (timeString != undefined) {
        const [hours, minutes, seconds, milliseconds] = timeString
          .split(":")
          .map(Number);
        const totalMilliseconds =
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000 +
          milliseconds * 10;
        return totalMilliseconds;
      }
    },
    convertMillisecondsToTime(milliseconds, type) {
      //  milliseconds > HH:MM:SS:ss
      //const seconds = milliseconds;
      let formattedTime;
      if (type != "timeInterval") {
        const hours = Math.floor(milliseconds / 3600);
        const minutes = Math.floor((milliseconds % 3600) / 60);
        const seconds =
          Number(milliseconds.toFixed(2).toString().split(".")[0]) % 60;

        //console.log("aaaaaaaaaaaaaaaa ::");
        const remainingSeconds = milliseconds
          .toFixed(2)
          .toString()
          .split(".")[1];

        //const forMilliseconds = formattedMilliseconds.slice(0, 2);

        formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}:${remainingSeconds}`;
      } else {
        const seconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedMilliseconds = (milliseconds % 1000)
          .toString()
          .padStart(2, "0");

        const forMilliseconds = formattedMilliseconds.slice(0, 2);

        formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${remainingSeconds
          .toString()
          .padStart(2, "0")}:${forMilliseconds}`;
      }

      return formattedTime;
    },
  },
};
</script>
<style>
.img-layer {
  padding-bottom: 50px;
}
.imgTxt {
}
.crop-wrap {
  display: flex;
  flex-wrap: wrap;
}
.crop-wrap > div {
  width: 50%;
}
.crop-wrap .preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-wrap .preview .vue-preview {
  height: 100%;
}
/*
.line {
  border: 4px solid red;
}
*/
.preview-controller {
  position: absolute;
  bottom: -30px;
  display: flex;
}
.preview-controller > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-controller input {
  width: 60%;
}
.item_info > .float_box {
  height: auto !important;
}
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark {
  width: 20%;
  height: 10px;
  position: absolute;
  bottom: 4px;
  left: 20%;
  z-index: 2;
}
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark
  div {
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark
  .mark_index {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 20px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
#controller {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  height: 30px;
}
</style>
<style scoped lang="scss">
.item_info {
  & > .float_box {
    width: 100%;
    height: 800px;
    & > div {
      float: left;
    }
    .driving_record {
      width: 300px;
      height: 100%;
      background-color: $whiteColor;
      margin-right: 20px;
      box-sizing: border-box;
      padding: 20px 0;
      .title_box {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        strong {
          font-weight: $bold;
        }
        button {
          width: 86px;
          height: 40px;
          background-repeat: no-repeat;
          background-image: url($baseURL + "common/add_15_wh.svg");
          background-position: 15px center;
          box-sizing: border-box;
          padding-left: 20px;
          border-radius: 10px;
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
      .filter_box {
        height: 40px;
        box-sizing: border-box;

        .date_filter {
          width: 100%;
          height: 100%;
          padding-left: 0;
          &::after {
            display: none;
          }
          .date_period {
            width: 240px;
            left: 10px;
          }
        }
      }
      .driving_record_list {
        width: 100%;
        height: 670px;
        margin-top: 20px;
        overflow-y: auto;
        table {
          width: 100%;
          thead {
            border-bottom: 1px solid $line01Color;
            th {
              padding: 0 0 10px;
              color: #6e7380;
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid $line01Color;
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                background-color: #edfbff;
                td {
                  .description_box {
                    display: block;
                  }
                }

                // color: $whiteColor;
                // td{
                //     color: $whiteColor;
                // }
                // span{
                //     color: $whiteColor;
                // }
                // div{
                //     color: $whiteColor;
                // }
                .btn_evt_group {
                  background-image: url($baseURL + "/common/moremenu_clicklist.svg");
                }
              }
              &.active {
                background-color: $mainColor;
                color: $whiteColor;
                td {
                  color: $whiteColor;
                }
                span {
                  color: $whiteColor;
                }
                div {
                  color: $whiteColor;
                }
                .btn_evt_group {
                  background-image: url($baseURL + "/common/moremenu_clicklist.svg");
                }
              }
            }
            td {
              padding: 10px 0 10px 10px;
              vertical-align: middle;
              position: relative;
              .name_box {
                display: inline-block;
                width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              div {
                margin-bottom: 5px;
                &:last-child {
                  margin-bottom: 0;
                }
                span {
                  display: inline-block;
                  color: #6e7380;
                  margin-right: 5px;
                }
                &.description_box {
                  width: 215px;
                  min-height: 66px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  background-color: #edfbff;
                  padding: 10px;
                  box-sizing: border-box;
                  word-wrap: break-word;
                  display: none;
                  strong {
                    display: block;
                    color: #6e7380;
                    margin-bottom: 10px;
                  }
                  p {
                    font-size: $fontSub;
                  }
                }
              }
              .btn_evt_group {
                width: 18px;
                height: 18px;
                background-size: cover;
                position: relative;
                top: -2px;
                margin-left: 2px;
                margin-right: 6px;
                &.active {
                  background-image: url($baseURL + "/common/moremenu_list_click.svg");
                }
              }
              .evt_btn_box {
                right: 5px;
              }
            }
          }
        }
      }
    }
    .data_set_mon {
      width: 956px;
      height: 100%;
      position: relative;
      .none_data {
        width: 100%;
        height: 100%;
        background-color: $line02Color;
        .text_box {
          position: absolute;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-top: 100px;
          background-image: url($baseURL + "/common/info_guide_list.svg");
          background-repeat: no-repeat;
          background-position: center 0;
          span {
            color: $placeHolderColor;
            line-height: 20px;
          }
        }
      }
      .data_set {
        .time_controller {
          width: 100%;
          height: 144px;
          margin-bottom: 20px;
          position: relative;
          .controller_box {
            width: 350px;
            height: 40px;
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            .btn_point {
              width: 50px;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
              &.start_point {
                position: absolute;
                top: 0;
                left: 8px;
                background-image: url($baseURL + "/common/edit_start.svg");
                &:hover {
                  background-image: url($baseURL + "/common/edit_start_over.svg");
                }
                &.active {
                  background-image: url($baseURL + "/common/edit_click_start.svg");
                }
              }
              &.end_point {
                position: absolute;
                top: 0;
                right: 8px;
                background-image: url($baseURL + "/common/edit_end.svg");
                &:hover {
                  background-image: url($baseURL + "/common/edit_end_over.svg");
                }
                &.active {
                  background-image: url($baseURL + "/common/edit_click_end.svg");
                }
              }
            }
            .btn_wrap {
              width: 250px;
              height: 100%;
              margin: 0 auto 0;
              background-color: #edfbff;
              border-radius: 20px;
              position: relative;
              button {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                position: absolute;
                top: 5px;
                background-repeat: no-repeat;
                background-position: center;
                background-color: #edfbff;
                &:hover {
                  background-color: $line01Color;
                }
                &.first_point {
                  left: 15px;
                  background-image: url($baseURL + "/common/start.svg");
                }
                &.back_seconds {
                  left: 55px;
                  background-image: url($baseURL + "/common/3sleft.svg");
                }
                &.play_pause {
                  width: 60px;
                  height: 60px;
                  top: 50%;
                  left: 50%;
                  border-radius: 30px;
                  transform: translate(-50%, -50%);
                  box-shadow: 0px 0px 6px #00b4ed99;
                  &.pause {
                    background-image: url($baseURL + "/common/pause.svg");
                  }
                  &.play {
                    background-image: url($baseURL + "/common/play.svg");
                  }
                }
                &.forward_seconds {
                  right: 55px;
                  background-image: url($baseURL + "/common/3sright.svg");
                }
                &.end_point {
                  right: 15px;
                  background-image: url($baseURL + "/common/end.svg");
                }
              }
            }
          }
          .add_select_section {
            width: 135px;
            height: 40px;
            border-radius: 10px;
            box-sizing: border-box;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            padding-left: 30px;
            color: $mainColor;
            position: absolute;
            top: 20px;
            right: 20px;
            background-repeat: no-repeat;
            background-position: 10px center;
            background-image: url($baseURL + "/common/add_section.svg");
            &:disabled {
              background-color: $line02Color;
              border: 1px solid $disable02Color;
              background-image: url($baseURL + "/common/add_section_dis.svg");
            }
          }
          .start_end {
            width: 300px;
            position: absolute;
            top: 20px;
            left: 10px;
            & > div {
              div {
                color: $placeHolderColor;
                display: inline-block;
                font-family: $montserrat;
                padding-left: 20px;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: 10px center;
              }
              span {
                font-family: $montserrat;
              }
              &.start_point_time {
                div {
                  background-image: url($baseURL + "/common/icon_starttime.svg");
                }
              }
              &.end_point_time {
                div {
                  background-image: url($baseURL + "/common/icon_endtime.svg");
                }
              }
            }
          }
          .range_box {
            width: 100%;
            height: 50px;
            position: absolute;
            left: 0;
            bottom: 20px;
            .range_item {
              width: 620px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%);
              input.slider {
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 5px;
                left: 0;
                z-index: 3;
                background: transparent;
                outline: none;
                -webkit-appearance: none;
                margin: 0;
                border-radius: 10px;
                &::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  // appearance: none;
                  width: 20px;
                  height: 20px;
                  background-color: $mainColor;
                  box-sizing: border-box;
                  border: 5px solid #fff;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px #00000029;
                  cursor: pointer;
                }
                &::-moz-range-thumb {
                  appearance: none;
                  width: 20px;
                  height: 20px;
                  background-color: $mainColor;
                  box-sizing: border-box;
                  border: 5px solid #fff;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px #00000029;
                  cursor: pointer;
                }
              }
              .flow_bg {
                width: 0;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 0;
                background: $mainColor;
                border-radius: 10px;
                z-index: 1;
              }
              .range_bg {
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 0;
                background: #efefef;
                border-radius: 10px;
              }
              .pin_point {
                width: 2px;
                height: 32px;
                background-color: #4791ff;
                position: absolute;
                bottom: -6px;
                left: 20%;
                z-index: 2;
              }
              .time_mark {
                width: 20%;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 20%;
                z-index: 2;
                div {
                  width: 100%;
                  height: 100%;
                  opacity: 0.7;
                }
                .mark_index {
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  color: $whiteColor;
                  text-align: center;
                  font-weight: 700;
                  font-family: $montserrat;
                  font-size: $fontSub;
                  line-height: 20px;
                  position: absolute;
                  top: -25px;
                  left: 50%;
                  transform: translateX(-50%);
                  opacity: 1;
                }
              }
            }
            .time_move_box {
              position: absolute;
              bottom: 0;
              left: 25px;
              strong {
                font-size: $fontMenuTitle;
                font-family: $montserrat;
                font-weight: 700;
                color: $secondaryColor;
                // color: #0f0;
                display: inline-block;
                border-bottom: 1px solid $secondaryColor;
              }
              .time_input_box {
                // display: none;
                width: 125px;
                font-size: $fontMenuTitle;
                font-family: $montserrat;
                font-weight: 700;
                color: $secondaryColor;
                position: absolute;
                top: -11px;
                left: -6px;
                background-color: $whiteColor;
                border: 1px solid $mainColor;
                padding: 7px 5px;
                border-radius: 10px;
                input {
                  width: 27px;
                  height: 21px;
                  font-size: $fontMenuTitle;
                  font-family: $montserrat;
                  font-weight: 700;
                  outline: none;
                  text-align: center;
                  padding: 0;
                  border: none;
                  color: $secondaryColor;
                  border-bottom: 1px solid $secondaryColor;
                }
              }
            }
            .end_time {
              position: absolute;
              bottom: 0;
              right: 50px;
              strong {
                color: #6e7380;
                font-family: $montserrat;
              }
            }
          }
        }
        & > .float_box {
          & > div {
            width: 305px;
            height: 308px;
            float: left;
            overflow: hidden;
            &.list_box {
              &.task_list {
                table {
                  table-layout: fixed;
                }
              }
              .mando_table_wrap {
                height: 268px;
              }
            }
            &:nth-child(1) {
              margin-bottom: 20px;
            }
            &:nth-child(2) {
              margin-bottom: 20px;
              margin-left: 20px;
            }
            &:nth-child(3) {
              // margin-right: 20px;
            }
            &:nth-child(4) {
              margin-bottom: 20px;
              margin-left: 20px;
              margin-right: 20px;
            }
            .title_box {
              width: 100%;
              height: 40px;
              position: relative;
              box-sizing: border-box;
              padding: 20px 20px 0;
              strong {
                font-weight: $bold;
              }
              .setting_icon {
                position: absolute;
                top: 10px;
                right: 20px;
              }
            }
            .mando_table {
              th {
                &:first-child {
                  min-width: 100px;
                }
              }
            }
            .select_section {
              table-layout: fixed;
              th,
              td {
                padding: 10px 0;
              }
              td {
                position: relative;
                vertical-align: middle;
                &:first-child {
                  font-weight: $bold;
                }
                .btn_evt_group {
                  width: 18px;
                  height: 18px;
                  background-size: cover;
                  position: relative;
                  top: -2px;
                  margin-left: 0;
                  &.active {
                    background-image: url($baseURL + "/common/moremenu_list_click.svg");
                  }
                }
                .evt_btn_box {
                  right: 10px;
                }
                .list_item_icon {
                  width: 13px;
                  height: 13px;
                  display: inline-block;
                  border: 1px solid;
                  border-color: #000;
                  border-radius: 7px;
                  position: relative;
                  top: 2px;
                  overflow: hidden;
                  & > div {
                    width: 0;
                    height: 0;
                    border-top: 3px solid transparent;
                    border-bottom: 3px solid transparent;
                    border-left: 4px solid #000;
                    border-right: 3px solid transparent;
                    position: absolute;
                    top: 4px;
                    left: 5px;
                  }
                }
              }
            }
            .chart_item_data {
              th,
              td {
                padding: 10px 10px;
              }
              td {
                vertical-align: middle;
                &:first-child {
                  text-align: left;
                }
                .list_item_icon {
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  display: inline-block;
                  margin-right: 10px;
                  position: relative;
                  top: -2px;
                }
              }
            }
            .chart_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              box-sizing: border-box;
              padding: 20px;
              padding-bottom: 10px;
              position: relative;
              top: 0;
              left: 0;
              z-index: 1;
              canvas {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }
              .chart_time {
                width: 560px;
                height: 30px;
                position: absolute;
                bottom: 0;
                left: 25px;
                z-index: 0;
                div {
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 180, 237, 0.5);
                }
              }
            }
            .map_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              background-color: #ff0;
            }
            .video_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              background-color: #ff0;
            }
          }
        }
      }
    }
  }
}
// 선택 구간 사용 메뉴얼

// 선택구간 추가 알림
</style>
