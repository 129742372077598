<template>
  <div class="selectClass">
    <select
      v-model="componentType"
      @change="sendDataToParent"
      class="selectStyle"
    >
      <option :value="''">Type Select</option>
      <option :value="'calendar'">Calendar</option>
      <option :value="'select'">Select box</option>
      <option :value="'search'">Search</option>
      <option :value="'select_value'">Multi Select</option>
      <option :value="'range'">Range</option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      componentType: "",
    };
  },
  methods: {
    sendDataToParent() {
      this.$emit("component-type", this.componentType);
    },
  },
};
</script>

<style>
.selectClass {
  display: flex;
}
.selectStyle {
  width: 155px;
  background-position: center right 15px;
}
</style>
