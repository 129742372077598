<template>
  <div
    class="timeline"
    :id="otherProp != null ? 'timeline_' + otherProp.key : 'timeline'"
  >
    <div class="item_info">
      <div class="float_box">
        <div class="data_set_mon">
          <div class="data_set">
            <div class="time_controller radiusbox">
              <div class="controller_box">
                <button
                  v-if="selectSectionYn === 'Y' || showSelection === 'Y'"
                  type="button"
                  class="start_point btn_point"
                  :class="eTime === 0 ? 'prohibit' : ''"
                  ref="fPointBtn"
                  @click="rangePoint($event, 'fPoint')"
                ></button>
                <div class="btn_wrap" ref="controlBtnBox">
                  <button
                    type="button"
                    class="first_point"
                    :class="eTime === 0 ? 'prohibit' : ''"
                    @click="controlBtn($event, 'fTime')"
                  ></button>
                  <button
                    type="button"
                    class="back_seconds"
                    :class="eTime === 0 ? 'prohibit' : ''"
                    @click="controlBtn($event, 'back3')"
                  ></button>
                  <button
                    type="button"
                    class="play_pause pause"
                    :class="eTime === 0 ? 'prohibit' : ''"
                    ref="playPauseBtn"
                    @click="controlBtn($event, 'playPause')"
                  ></button>
                  <button
                    type="button"
                    class="forward_seconds"
                    :class="eTime === 0 ? 'prohibit' : ''"
                    @click="controlBtn($event, 'forward3')"
                  ></button>
                  <button
                    type="button"
                    class="end_point"
                    :class="eTime === 0 ? 'prohibit' : ''"
                    @click="controlBtn($event, 'eTime')"
                  ></button>
                </div>
                <button
                  v-if="selectSectionYn === 'Y' || showSelection === 'Y'"
                  type="button"
                  class="end_point btn_point"
                  :class="eTime === 0 ? 'prohibit' : ''"
                  ref="ePointBtn"
                  @click="rangePoint($event, 'ePoint')"
                ></button>
              </div>
              <button
                v-if="selectSectionYn === 'Y' || showSelection === 'Y'"
                type="button"
                class="add_select_section"
                ref="selectBtn"
                @click="addDrivRecBtnModal()"
                style="display: none"
              >
                선택구간 추가
              </button>
              <div class="start_end" ref="selecTimeBox">
                <div class="start_point_time">
                  <div>시작 시간</div>
                  <span>00:00:00:00</span>
                </div>
                <div class="end_point_time">
                  <div>종료 시간</div>
                  <span>00:00:00:00</span>
                </div>
              </div>
              <div class="range_box">
                <div class="time_move_box" ref="timeInputBox">
                  <strong>00:00:00:00</strong>
                  <div
                    class="time_input_box"
                    :style="{ opacity: isFocused ? '1' : '0' }"
                  >
                    <input
                      type="text"
                      name=""
                      id="hh"
                      maxlength="2"
                      :value="timeSet.HH"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @keyup.enter="handleBlur"
                    />:<input
                      type="text"
                      name=""
                      id="mm"
                      maxlength="2"
                      :value="timeSet.MM"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @keyup.enter="handleBlur"
                    />:<input
                      type="text"
                      name=""
                      id="ss"
                      maxlength="2"
                      :value="timeSet.SS"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @keyup.enter="handleBlur"
                    />:<input
                      type="text"
                      name=""
                      id="ms"
                      maxlength="2"
                      :value="timeSet.ss"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @keyup.enter="handleBlur"
                    />
                  </div>
                </div>
                <div class="range_item" ref="rangeItem">
                  <input
                    id="exIn"
                    type="range"
                    min="0"
                    :max="timeSet.max"
                    value="0"
                    class="slider"
                  />
                  <div class="flow_bg"></div>
                  <div class="range_bg"></div>
                  <div class="pin_point f_point" style="display: none"></div>
                  <div class="pin_point e_point" style="display: none"></div>
                  <div
                    class="time_mark"
                    v-for="(item, index) in recList"
                    :key="index"
                    :style="{
                      display: 'block',
                      width:
                        ((Number(item.end_time) - Number(item.start_time)) /
                          Number(this.timeSet.max)) *
                          100 +
                        '%',
                      left:
                        (Number(item.start_time) / Number(this.timeSet.max)) *
                          100 +
                        '%',
                    }"
                  >
                    <div :style="{ backgroundColor: item.record_color }"></div>
                    <div
                      class="mark_index"
                      :style="{ backgroundColor: item.record_color }"
                    >
                      {{ item.no }}
                    </div>
                  </div>
                </div>
                <div class="end_time" ref="endTime">
                  <strong>00:00:00:00</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none">
      <!-- error 알림 -->
      <div
        class="radiusbox modal_contents message_alert message_modal"
        ref="modalAlert"
        style="display: none"
      >
        <div class="message_box" v-html="alertMessege"></div>
        <button type="button" class="btn_check mcbtn" @click="closeModal()">
          확인
        </button>
        <button type="button" class="btn_close" @click="closeModal()"></button>
      </div>
      <!-- 선택구간 추가 알림 -->
      <div
        class="radiusbox modal_contents message_check message_modal"
        ref="modalAddAlert"
        style="display: none"
      >
        <div class="message_box">선택 구간을 추가하시겠습니까?</div>
        <button type="button" class="btn_close" @click="closeModal()"></button>
        <button type="button" class="btn_check mcbtn" @click="addDrivRecBtn()">
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/assets/js/common";
export default {
  name: "TimelineController2",
  computed: {},
  props: {
    selectSectionYn: {
      type: String,
      default: "N",
    },
    startTime: {
      type: String,
      default: "00:00:00:00",
    },
    endTime: {
      type: String,
      default: "00:00:00:00",
    },
    src: {
      type: String,
      default: "",
    },

    isReset: {
      type: Boolean,
      default: false,
    },
    gpsMapDataTimestamps: {
      type: Array,
      default: null,
    },
    gpsMapDataTime: {
      type: Array,
      default: null,
    },
    otherProp: {
      type: Object,
    },
  },

  components: {},
  data() {
    return {
      isFocused: false,
      isDragging: false,
      interval: null,
      status: "pause",
      nowMilliseconds: 0,
      eTime: 0,
      fPoint: 0,
      ePoint: 0,
      alertMessege: "시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.",
      color: [
        "#80BDFF",
        "#409DFF",
        "#187BFF",
        "#0F5CBF",
        "#073E80",
        "#B7A1E0",
        "#9371D1",
        "#6F42C1",
        "#533191",
        "#382261",
        "#EE9AA2",
        "#E56874",
        "#DC3545",
        "#A52833",
        "#6E1A23",
        "#94D3A2",
        "#5EBD74",
        "#27A745",
        "#1F7D35",
        "#145423",
        "#FDBE89",
        "#FE9F4F",
        "#FD7E14",
        "#BE5F0F",
        "#7F3F0B",
        "#DDD8D6",
        "#CBC5C2",
        "#BAB1AD",
        "#8C8582",
        "#5D5957",
        "#B388F9",
        "#8C4CF5",
        "#660FF2",
        "#4D0DB6",
        "#330879",
        "#F49FC6",
        "#EE6EA9",
        "#E83E8C",
        "#AE2F69",
        "#741F46",
        "#FEE083",
        "#FED145",
        "#FEC106",
        "#C09106",
        "#806106",
        "#90E4CB",
        "#5AD7B1",
        "#20C997",
        "#189771",
        "#10654C",
        "#8BD1DC",
        "#51B9CA",
        "#17A2B8",
        "#117A8A",
        "#0D525B",
      ],
      timeSet: {
        max: "",
        HH: "00",
        MM: "00",
        SS: "00",
        ss: "00",
      },
      drivList: null,
      thisDdivListId: null,
      recList: [],
      sectionCnt: 1,
      sectionCntEx: 5,
      //testList: null,
      activeDrivID: "",
      //---
      drivStartTime: "",
      drivEndTime: "",
      // catalog list
      dataMoniterList: [],
      catalogList: [],
      catalogListSelectedItems: [],
      activeCatalog: [],
      activeCatalogSelectedItems: [],

      //
      motTaskList: [],
      action: "",
      mapTimestamps: [],
      mapTransTimestamps: [],
      showSelection: "N",
    };
  },
  watch: {
    endTime() {
      this.init();
      if (this.gpsMapDataTimestamps.length > 0) {
        this.initMap();
      }
    },
    action() {
      if (this.action.txt == "mouseup") {
        this.emitter.emit("videoTime", this.action);
      }
    },
    gpsMapDataTimestamps() {
      this.initMap();
    },
  },
  created() {},
  mounted() {
    const vm = this;
    if (this.otherProp != undefined) {
      this.showSelection = this.otherProp?.components[0]?.selectSectionYn;
      /*
      this.$emit("updateParent", {
        index: this.componentIndex,
        value: event.target.value,
      });
      */
    }

    this.init();
    this.emitter.on("selecTime", (startTime) => {
      vm.selectStartTime(startTime);
    });
    this.emitter.on("setDuration", (param) => {
      const key = vm.otherProp.key;
      if (param.parentKey === key) {
        vm.eTime = param.duration;
        if (vm.$refs.endTime != null) this.init();
      }
    });
  },
  methods: {
    initMap() {
      this.mapTimestamps = this.gpsMapDataTimestamps;

      const mapEnd = this.mapTimestamps[this.mapTimestamps.length - 1];
      const mapTimeEnd = parseFloat(mapEnd).toFixed(3) * 1000;
      this.mapTransTimestamps = [];

      this.mapTimestamps.forEach((e) => {
        const transInt = parseInt(parseFloat(e).toFixed(3) * 1000);
        let transTime = parseInt((this.timeSet.max * transInt) / mapTimeEnd);
        transTime = Math.floor(transTime / 10) * 10;

        if (transTime > this.timeSet.max) {
          transTime = this.timeSet.max;
        }

        this.mapTransTimestamps.push(transTime);
      });
    },
    init() {
      const vm = this;

      //vm.activeDrivID = id;

      const startTime = this.startTime;
      const endTime = this.eTime != 0 ? this.eTime : this.endTime;

      vm.drivStartTime = startTime;
      vm.drivEndTime = endTime;
      /*
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      */
      const timeDifference = this.eTime !== 0 ? this.eTime : this.endTime;
      if (this.eTime !== 0) {
        this.emitter.emit("setVideoDuration", timeDifference);
      }

      this.timeSet.max = timeDifference;

      console.log(timeDifference);
      const $rangeItem = this.$refs.rangeItem;
      const maxTime = this.timeSet.max;

      const $endTime = this.$refs.endTime.querySelector("strong");
      console.log(maxTime);
      if (typeof maxTime != "string") {
        let totalTime = this.convertMillisecondsToTime(maxTime);

        if (!timeDifference) {
          $endTime.innerText = "00:00:00:00";
        } else {
          $endTime.innerText = String(totalTime);
        }
      }

      // ui
      const $exIn = this.$refs.rangeItem.querySelector("#exIn");
      const $nowTime = this.$refs.timeInputBox.querySelector("strong");
      let timeSet = this.timeSet;

      // 초기화
      $exIn.value = 0;
      vm.rangeGageEvent(0, vm.timeSet.max);
      vm.rangeDataClearInterval();

      const timeMark = $rangeItem.querySelectorAll(".time_mark");

      timeMark.forEach((mark) => {
        console.log(mark.style.display);
        mark.style.display = "none";
      });
      //HH
      vm.timeSet.HH = "00";
      //MM
      vm.timeSet.MM = "00";
      //SS
      vm.timeSet.SS = "00";
      //ss
      vm.timeSet.ss = "00";
      $nowTime.innerText =
        vm.timeSet.HH +
        ":" +
        vm.timeSet.MM +
        ":" +
        vm.timeSet.SS +
        ":" +
        vm.timeSet.ss;

      $exIn.addEventListener("mousedown", function () {
        vm.isDragging = true;
        // console.log(vm.isDragging);
        vm.status = "pause";
        vm.rangeDataClearInterval();
        const param = {
          txt: "mousedown",
          val: $exIn.value,
        };
        vm.action = param;
      });
      $exIn.addEventListener("mousemove", function (e) {
        if (vm.isDragging) {
          vm.rangeGageEvent(e.target.value, vm.timeSet.max);
          vm.nowMilliseconds = e.target.value;
          vm.action = "mousemove";
          let nowTime = vm.convertMillisecondsToTime(e.target.value);
          $nowTime.innerText = String(nowTime);
          const param = {
            txt: "mousemove",
            val: $exIn.value,
          };
          vm.action = param;
        }
      });
      $exIn.addEventListener("mouseup", function (e) {
        const eTime = vm.eTime;
        if (eTime === 0) {
          return false;
        }
        if (vm.isDragging) {
          vm.isDragging = false;
          // console.log('마우스 드래그 종료');
          // console.log('최종 값:', e.target.value);
          vm.action = "mouseup";
          console.log("vm.timeSet.max");
          console.log(vm.timeSet.max);
          console.log("--------");
          console.log(e.target.value);
          vm.rangeGageEvent(e.target.value, vm.timeSet.max);
          vm.nowMilliseconds = e.target.value;
          let nowTime = vm.convertMillisecondsToTime(e.target.value);
          $nowTime.innerText = String(nowTime);

          // 일시정지 화면

          vm.status = "pause";

          vm.$refs.playPauseBtn.classList.remove("play");
          vm.$refs.playPauseBtn.classList.add("pause");
          if (vm.$refs.fPointBtn) vm.$refs.fPointBtn.style.display = "block";
          if (vm.$refs.ePointBtn) vm.$refs.ePointBtn.style.display = "block";
          if (vm.$refs.selecTimeBox)
            vm.$refs.selecTimeBox.style.display = "block";

          // [0]:HH, [1]:MM, [2]:SS, [3]:ss
          let timeData = nowTime.split(":");
          //HH
          timeSet.HH = timeData[0];
          //MM
          timeSet.MM = timeData[1];
          //SS
          timeSet.SS = timeData[2];
          //ss
          timeSet.ss = timeData[3];
          // playPauseClick();
          // secondsChange(e.target.value);
          // 일시정지 상태에서 드래그 끝
          // if(rangeStatus == "pause"){
          //     // playPauseClick();
          // }
          const param = {
            txt: "mouseup",
            val: $exIn.value,
          };
          vm.action = param;
        }
      });
    },

    addCatalog() {
      // 5개이상 추가 안됨
      const vm = this;
      let counst = vm.catalogListSelectedItems.length + vm.activeCatalog.length;
      if (counst > 5) {
        alert("선택 항목은 5개를 초과 할수 없습니다.");
      } else {
        // console.log(vm.catalogListSelectedItems);
        // console.log(vm.catalogList);
        setTimeout(() => {
          vm.catalogListSelectedItems.forEach(async (index) => {
            // console.log(index);
            if (vm.catalogList[index] != undefined) {
              const item = await vm.catalogList[index];
              vm.activeCatalog.push(item);
            }
            vm.catalogList.splice(index, 1);
          });
          vm.catalogListSelectedItems = [];
          // console.log("---------add------");
          // console.log(vm.catalogList);
          // console.log(vm.activeCatalog);
        }, 400);
      }
    },
    async delCatalog() {
      const vm = this;

      setTimeout(() => {
        vm.activeCatalogSelectedItems.forEach(async (index) => {
          if (vm.activeCatalog[index] != undefined) {
            const item = await vm.activeCatalog[index];
            vm.catalogList.push(item);
          }
          vm.activeCatalog.splice(index, 1);
        });
        vm.activeCatalogSelectedItems = [];
      }, 400);

      console.log(vm.catalogList);
    },

    // call Api
    async setCatalog() {
      const headers = {
        // startTime: this.picStartDate + ' 00:00:00',
        // endTime: this.picEndDate + ' 00:00:00',
        startTime: this.drivStartTime,
        endTime: this.drivEndTime,
        vehicle: this.prjData.edgeDeviceId,
        // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01'
      };

      const _data = await this.getDLcatalogData(headers);

      //  console.log("========catalogList========");
      //  console.log(_data);
      //  console.log("========");

      this.catalogList = _data;
    },
    async setMoniter(list) {
      const headers = {
        // startTime: this.picStartDate + ' 00:00:00',
        // endTime: this.picEndDate + ' 00:00:00',
        startTime: this.drivStartTime,
        endTime: this.drivEndTime,
        vehicle: this.prjData.edgeDeviceId,
        // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01',
        measureList: list,
      };

      const _data = await this.getDLMonitorData(headers);
      console.log("========dataMoniterList========");
      console.log(_data);
      console.log("========");
      this.dataMoniterList = _data;
    },

    // ------ API
    async delCatalogData(drivId) {
      try {
        await common.apiDelete("/driving-record-data-catalog/" + drivId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setCatalogData(postData) {
      try {
        const response = await common.apiPost(
          "/driving-record-data-catalog",
          postData
        );
        // return response.data.code;
        return response;
      } catch (error) {
        console.error("Error posting data:", error);
      }
    },
    async getDrivCatalogData(drivId) {
      try {
        const response = await common.apiGet(
          "/driving-record-data-catalog/" + drivId
        );
        // console.log(response);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async getMonTaskData(edgeId) {
      try {
        const _edgeId = encodeURIComponent(edgeId);
        const response = await common.apiGet(
          "/monitoring-task/edge-device/" + _edgeId
        );

        console.log(response);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDLcatalogData(headers) {
      try {
        const response = await common.apiDlGet("/dl/can/catalog-list", headers);
        console.log(response);
        return response.data.queryResult.results;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDLMonitorData(headers) {
      console.log(headers);
      try {
        const response = await common.apiDlGet("/dl/can/stddev", headers);
        console.log(response);
        return response.data.queryResult.results;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    // -------- Time Control
    handleFocus() {
      const vm = this;
      this.isFocused = true;
      vm.rangeDataClearInterval();
    },
    handleBlur() {
      this.isFocused = false;
      const $exIn = this.$refs.rangeItem.querySelector("#exIn");
      const $nowTime = this.$refs.timeInputBox.querySelector("strong");
      const $inpuTime = this.$refs.timeInputBox.querySelectorAll("input");
      const vm = this;
      // timeInputBox 값 업데이트
      this.timeSet.HH = $inpuTime[0].value;
      this.timeSet.MM = $inpuTime[1].value;
      this.timeSet.SS = $inpuTime[2].value;
      this.timeSet.ss = $inpuTime[3].value;

      $nowTime.innerText =
        this.timeSet.HH +
        ":" +
        this.timeSet.MM +
        ":" +
        this.timeSet.SS +
        ":" +
        this.timeSet.ss;

      vm.nowMilliseconds = vm.timeStringToMilliseconds($nowTime.innerText);
      if (vm.nowMilliseconds > this.timeSet.max) {
        console.log("최대시간을 초과 합니다.");
      } else {
        $exIn.value = vm.nowMilliseconds;
        vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);
      }
    },

    controlBtn(e, type) {
      if (
        (this.eTime === 0 && this.endTime == null) ||
        (this.eTime === 0 && this.endTime == "") ||
        (this.eTime === 0 && this.endTime == "00:00:00:00")
      ) {
        return false;
      }
      const $exIn = this.$refs.rangeItem.querySelector("#exIn");
      const $nowTime = this.$refs.timeInputBox.querySelector("strong");

      const vm = this;
      vm.nowMilliseconds = vm.timeStringToMilliseconds($nowTime.innerText);

      vm.rangeDataClearInterval();
      if (type == "fTime") {
        console.log("fTime");
        const param = {
          txt: "fTime",
          val: 0,
          timeDifference: this.timeDifference,
        };
        this.emitter.emit("videoTime", param);
        //this.$store.dispatch("setVideoAction", "pause");
        let stringTime = vm.convertMillisecondsToTime(0);
        $exIn.value = 0;
        this.timeSet.HH = "00";
        this.timeSet.MM = "00";
        this.timeSet.SS = "00";
        this.timeSet.ss = "00";
        vm.rangeGageEvent(0, this.timeSet.max);
        $nowTime.innerText = stringTime;

        vm.status = "pause";
        vm.$refs.playPauseBtn.classList.remove("play");
        vm.$refs.playPauseBtn.classList.add("pause");
      } else if (type == "back3") {
        console.log("back3");
        const param = {
          txt: "back3",
          val: $exIn.value,
          timeDifference: 3000,
        };
        this.emitter.emit("videoTime", param);

        vm.nowMilliseconds = vm.nowMilliseconds - 3000;
        if (vm.nowMilliseconds < 0) {
          vm.nowMilliseconds = 0;
          $exIn.value = vm.nowMilliseconds;
          this.timeSet.HH = "00";
          this.timeSet.MM = "00";
          this.timeSet.SS = "00";
          this.timeSet.ss = "00";
        }
        let stringTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);
        const fomatTime = stringTime.split(":");
        this.timeSet.HH = fomatTime[0];
        this.timeSet.MM = fomatTime[1];
        this.timeSet.SS = fomatTime[2];
        this.timeSet.ss = fomatTime[3];
        $nowTime.innerText = stringTime;
        $exIn.value = vm.nowMilliseconds;
        vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);
        vm.status = "pause";
        vm.$refs.playPauseBtn.classList.remove("play");
        vm.$refs.playPauseBtn.classList.add("pause");
      } else if (type == "playPause") {
        if (vm.status == "pause") {
          vm.status = "play";
          // console.log(vm.status);
          e.target.classList.remove("pause");
          e.target.classList.add("play");
          const param = {
            txt: "play",
            val: 0,
            timeDifference: this.timeDifference,
          };
          this.emitter.emit("videoTime", param);
          rangeSliderIntervalStartTask();
        } else if (vm.status == "play") {
          vm.status = "pause";
          // console.log(vm.status);
          e.target.classList.remove("play");
          e.target.classList.add("pause");
          //document.getElementById("video").pause();
          const param = {
            txt: "pause",
            val: 0,
            timeDifference: this.timeDifference,
          };
          this.emitter.emit("videoTime", param);
          console.log("play");
        }
      } else if (type == "forward3") {
        console.log("forward3");
        const param = {
          txt: "forward3",
          val: $exIn.value,
          timeDifference: 3000,
        };
        this.emitter.emit("videoTime", param);
        vm.nowMilliseconds = vm.nowMilliseconds + 3000;
        if (vm.nowMilliseconds > this.timeSet.max) {
          vm.nowMilliseconds = this.timeSet.max;
        }
        let stringTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);
        $nowTime.innerText = stringTime;
        const fomatTime = stringTime.split(":");
        this.timeSet.HH = fomatTime[0];
        this.timeSet.MM = fomatTime[1];
        this.timeSet.SS = fomatTime[2];
        this.timeSet.ss = fomatTime[3];
        $exIn.value = vm.nowMilliseconds;
        vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);

        vm.status = "pause";
        vm.$refs.playPauseBtn.classList.remove("play");
        vm.$refs.playPauseBtn.classList.add("pause");
      } else if (type == "eTime") {
        // console.log("eTime")
        const param = {
          txt: "eTime",
          val: 0,
          timeDifference: this.timeDifference,
        };
        this.emitter.emit("videoTime", param);
        let stringTime = vm.convertMillisecondsToTime(this.timeSet.max);
        $nowTime.innerText = stringTime;
        const fomatTime = stringTime.split(":");
        this.timeSet.HH = fomatTime[0];
        this.timeSet.MM = fomatTime[1];
        this.timeSet.SS = fomatTime[2];
        this.timeSet.ss = fomatTime[3];
        $exIn.value = this.timeSet.max;

        vm.rangeGageEvent(this.timeSet.max, this.timeSet.max);
        vm.status = "pause";
        vm.$refs.playPauseBtn.classList.remove("play");
        vm.$refs.playPauseBtn.classList.add("pause");
      }

      vm.rangePoint();
      // intaval

      function rangeSliderIntervalStartTask() {
        //document.getElementById("video").play();

        vm.interval = setInterval(() => {
          // txt

          vm.nowMilliseconds = vm.nowMilliseconds + 10;

          // console.log(vm.nowMilliseconds);
          let textTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);

          $nowTime.innerText = String(textTime);
          $exIn.value = vm.nowMilliseconds;
          vm.rangeGageEvent(vm.nowMilliseconds, vm.timeSet.max);

          if (Number(vm.nowMilliseconds) > vm.timeSet.max) {
            vm.rangeDataClearInterval();
            $nowTime.innerText = vm.convertMillisecondsToTime(vm.timeSet.max);
            vm.$refs.playPauseBtn.classList.remove("play");
            vm.$refs.playPauseBtn.classList.add("pause");
            if (vm.$refs.fPointBtn != undefined) {
              vm.$refs.fPointBtn.style.display = "block";
              vm.$refs.ePointBtn.style.display = "block";
              // 2024-10-18 주석처리
              //vm.$refs.selectBtn.style.display = "block";
              vm.$refs.selecTimeBox.style.display = "block";
            }
          }
        }, 10);
      }
      // 타임 게이지/ 타임 움직이는값
      // now text
    },
    rangeDataClearInterval() {
      const vm = this;
      const $nowTime = this.$refs.timeInputBox?.querySelector("strong");
      clearInterval(vm.interval);
      const fomatTime = $nowTime?.innerText.split(":");
      if (fomatTime?.length > 0) {
        this.timeSet.HH = fomatTime[0];
        this.timeSet.MM = fomatTime[1];
        this.timeSet.SS = fomatTime[2];
        this.timeSet.ss = fomatTime[3];
      }
    },
    convertMillisecondsToTime(milliseconds) {
      //  milliseconds > HH:MM:SS:ss
      const seconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedMilliseconds = (milliseconds % 1000)
        .toString()
        .padStart(2, "0");

      const forMilliseconds = formattedMilliseconds.slice(0, 2);

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}:${forMilliseconds}`;

      return formattedTime;
    },
    timeStringToMilliseconds(timeString) {
      // HH:MM:SS:ss > ss
      if (timeString != undefined) {
        const [hours, minutes, seconds, milliseconds] = timeString
          .split(":")
          .map(Number);
        const totalMilliseconds =
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000 +
          milliseconds * 10;
        return totalMilliseconds;
      }
    },
    rangeGageEvent(newTime, maxTime) {
      //const $exIn = this.$refs.rangeItem.querySelector("#exIn");

      const $rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");
      //const $chartBar = this.$refs.chartBar;
      let nowTime = (newTime / maxTime) * 100;
      $rangeGage.style.width = nowTime + "%";
      //$chartBar.style.width = nowTime + "%";
    },
    rangePoint(e, type) {
      if (this.endTime == null || this.endTime == "") {
        return false;
      }
      const vm = this;
      if (vm.$refs.fPointBtn != undefined) {
        const $fPointBtn = vm.$refs.fPointBtn;
        const $ePointBtn = vm.$refs.ePointBtn;
        const $selectBtn = vm.$refs.selectBtn;
        const $selecTimeBox = vm.$refs.selecTimeBox;
        const $rangeItem = vm.$refs.rangeItem;

        if (vm.status == "play") {
          $fPointBtn.style.display = "none";
          $ePointBtn.style.display = "none";
          $selectBtn.style.display = "none";
          $selecTimeBox.style.display = "none";
          $rangeItem.querySelector(".f_point").style.display = "none";
          $rangeItem.querySelector(".e_point").style.display = "none";
        } else if (vm.status == "pause") {
          $fPointBtn.style.display = "block";
          $ePointBtn.style.display = "block";
          $selecTimeBox.style.display = "block";
          if ($fPointBtn.classList.contains("active")) {
            $rangeItem.querySelector(".f_point").style.display = "block";
          }
          if ($ePointBtn.classList.contains("active")) {
            $rangeItem.querySelector(".e_point").style.display = "block";
          }
          if (
            $fPointBtn.classList.contains("active") &&
            $ePointBtn.classList.contains("active")
          ) {
            $selectBtn.style.display = "block";
          }
        }
        if (type == "fPoint") {
          vm.fPoint = 0;
          vm.fPoint = vm.nowMilliseconds;
          let nowTime = (vm.fPoint / vm.timeSet.max) * 100;
          this.startTimestamp = this.convertMillisecondsToTime(
            vm.nowMilliseconds
          );
          const rangeBtn = this.$refs.rangeItem.querySelector("#exIn");
          this.startTimeValue = this.convertMillisecondsToTime(rangeBtn.value);

          if ($fPointBtn.classList.contains("active")) {
            $fPointBtn.classList.remove("active");
            $ePointBtn.classList.remove("active");
            $selecTimeBox
              .querySelector(".start_point_time")
              .querySelector("span").innerText = "00:00:00:00";
            $rangeItem.querySelector(".f_point").style.display = "none";
            $rangeItem.querySelector(".e_point").style.display = "none";
          } else {
            $fPointBtn.classList.add("active");
            $selecTimeBox
              .querySelector(".start_point_time")
              .querySelector("span").innerText = vm.convertMillisecondsToTime(
              vm.fPoint
            );
            $rangeItem.querySelector(".f_point").style.display = "block";
            $rangeItem.querySelector(".f_point").style.left = nowTime + "%";
          }

          // 선택구간 검사
          if (
            $fPointBtn.classList.contains("active") &&
            $ePointBtn.classList.contains("active")
          ) {
            $selectBtn.style.display = "block";
          } else {
            $selectBtn.style.display = "none";
          }

          console.log(vm.nowMilliseconds);
        } else if (type == "ePoint") {
          vm.ePoint = 0;
          vm.ePoint = vm.nowMilliseconds;
          let nowTime = (vm.ePoint / vm.timeSet.max) * 100;
          this.endTimestamp = this.convertMillisecondsToTime(
            vm.nowMilliseconds
          );

          if ($ePointBtn.classList.contains("active")) {
            $ePointBtn.classList.remove("active");
            $fPointBtn.classList.remove("active");
            $selecTimeBox
              .querySelector(".end_point_time")
              .querySelector("span").innerText = "00:00:00:00";
            $rangeItem.querySelector(".e_point").style.display = "none";
            $rangeItem.querySelector(".f_point").style.display = "none";
          } else {
            $ePointBtn.classList.add("active");
            $selecTimeBox
              .querySelector(".end_point_time")
              .querySelector("span").innerText = vm.convertMillisecondsToTime(
              vm.ePoint
            );
            $rangeItem.querySelector(".e_point").style.display = "block";
            $rangeItem.querySelector(".e_point").style.left = nowTime + "%";
          }

          // 선택구간 검사
          if (
            $fPointBtn.classList.contains("active") &&
            $ePointBtn.classList.contains("active")
          ) {
            $selectBtn.style.display = "block";
          } else {
            $selectBtn.style.display = "none";
          }
        }

        console.log(vm.nowMilliseconds);
      }
    },
    getRandomColor() {
      const vm = this;
      const min = 0;
      const max = vm.color.length - 1;
      const randomDecimal = Math.random();
      const randomNumber = Math.floor(randomDecimal * (max - min + 1)) + min;

      return vm.color[randomNumber];
    },
    drawDrivingRecordd(fp, ep, color) {
      const vm = this;
      const boxWidth = ((ep - fp) / vm.timeSet.max) * 100;
      const position = (fp / vm.timeSet.max) * 100;
      const colorCode = color;
      console.log(boxWidth);
      console.log(position);

      const timeMarkHTML = `
                <div class="time_mark" id="${colorCode}" style="width:${boxWidth}%; left:${position}%"; background-color:${colorCode}>
                    <div style="background-color: #000;"></div>
                    <div class="mark_index" style="background-color: #000;">1</div>
                </div>
            `;

      vm.$refs.rangeItem.innerHTML += timeMarkHTML;
    },

    // DL Data Call

    async addDrivRecBtnModal() {
      /*
      const allowAccess = await this.checkRoleToAccessBtn();
      if (!allowAccess) {
        alert("SM, Project Manager, Project Researcher만 접근 가능");
        return;
      }
        */

      const vm = this;
      const $rangeItem = vm.$refs.rangeItem;
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      const $modalWrap = vm.$refs.modalWrap;
      const $modalAlert = vm.$refs.modalAlert;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $selectBtn = vm.$refs.selectBtn;

      console.log(Number(vm.fPoint));
      console.log(Number(vm.ePoint));

      console.log(Number(vm.fPoint) > Number(vm.ePoint));
      if (Number(vm.fPoint) > Number(vm.ePoint)) {
        console.log("시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.");
        $modalWrap.style.display = "block";
        $modalAlert.style.display = "block";
        $rangeItem.querySelector(".f_point").style.display = "none";
        $rangeItem.querySelector(".e_point").style.display = "none";
        $selectBtn.style.display = "none";
        $ePointBtn.classList.remove("active");
        $fPointBtn.classList.remove("active");
        $selecTimeBox
          .querySelector(".start_point_time")
          .querySelector("span").innerText = "00:00:00:00";
        $selecTimeBox
          .querySelector(".end_point_time")
          .querySelector("span").innerText = "00:00:00:00";
        vm.fPoint = 0;
        vm.ePoint = 0;
      } else {
        vm.showmodalAddAlert();
        // const color = vm.getRandomColor();

        // console.log(color);
        // console.log(vm.drawDrivingRecordd(vm.fPoint, vm.ePoint, color));
        // 확인 팝업 ui만들어야함
        // vm.addDriRecData(10, color, vm.convertMillisecondsToTime(vm.fPoint), vm.convertMillisecondsToTime(vm.ePoint));
      }
    },
    async checkRoleToAccessBtn() {
      const memberRole = await this.getMemberRole();
      const projectRole = await this.getProjectRole();

      if (memberRole != null && memberRole.role == "SM") {
        return true;
      } else if (
        projectRole != null &&
        (projectRole.projectRole == "Manager" ||
          projectRole.projectRole == "Researcher")
      ) {
        return true;
      } else {
        return false;
      }
    },

    async addDrivRecBtn() {
      const vm = this;
      const $rangeItem = vm.$refs.rangeItem;
      if (this.sectionCnt > this.sectionCntEx) {
        alert("선택 구간은 5개를 초과 할수 없습니다.");
        vm.closeModal();
        $rangeItem.querySelector(".f_point").style.display = "none";
        $rangeItem.querySelector(".e_point").style.display = "none";
        return false;
      }
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      // const $modalWrap = vm.$refs.modalWrap;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $selectBtn = vm.$refs.selectBtn;
      const color = vm.getRandomColor();
      /*
      this.recList.start_time = vm.fPoint / 1000;
      this.recList.end_time = vm.ePoint / 1000;
      this.recList.record_color = color;
      this.recList.no = 1;
      */

      const obj = {
        start_time: vm.fPoint,
        end_time: vm.ePoint,
        record_color: color,
        no: this.sectionCnt,
        start_timestamp: this.startTimestamp,
        end_timestamp: this.endTimestamp,
        /*
        start_current_time: this.startCurrentTime,
        end_current_time: this.endCurrentTime,
        */
        start_time_value: this.startTimeValue,
        end_time_value: this.endTimeValue,
      };
      this.recList.push(obj);
      this.emitter.emit("recList", this.recList);
      this.sectionCnt++;

      $rangeItem.querySelector(".f_point").style.display = "none";
      $rangeItem.querySelector(".e_point").style.display = "none";
      $selectBtn.style.display = "none";

      $ePointBtn.classList.remove("active");
      $fPointBtn.classList.remove("active");
      $selecTimeBox
        .querySelector(".start_point_time")
        .querySelector("span").innerText = "00:00:00:00";
      $selecTimeBox
        .querySelector(".end_point_time")
        .querySelector("span").innerText = "00:00:00:00";
      vm.fPoint = 0;
      vm.ePoint = 0;

      vm.closeModal();
    },
    // --- 선택 구간 선택 시작 시간 이동
    selectStartTime(e) {
      const vm = this;
      const startTime = e;

      const stMilliseconds = vm.timeStringToMilliseconds(startTime);
      const maxTime = this.timeSet.max;
      const $nowTime = this.$refs.timeInputBox?.querySelector("strong");
      const $fPointBtn = vm.$refs.fPointBtn;
      const $ePointBtn = vm.$refs.ePointBtn;
      const $selectBtn = vm.$refs.selectBtn;
      const $selecTimeBox = vm.$refs.selecTimeBox;
      const $rangeItem = vm.$refs.rangeItem;

      // 인터벌 종료
      vm.rangeDataClearInterval();
      // 정지 모드로 만들기
      vm.status = "pause";
      vm.$refs.playPauseBtn?.classList.remove("play");
      vm.$refs.playPauseBtn?.classList.add("pause");
      if ($fPointBtn != undefined) {
        $fPointBtn.style.display = "block";
        $ePointBtn.style.display = "block";
        $selecTimeBox.style.display = "block";
      }

      if ($fPointBtn?.classList.contains("active")) {
        $rangeItem.querySelector(".f_point").style.display = "block";
      }
      if ($ePointBtn?.classList.contains("active")) {
        $rangeItem.querySelector(".e_point").style.display = "block";
      }
      if (
        $fPointBtn?.classList.contains("active") &&
        $ePointBtn?.classList.contains("active")
      ) {
        $selectBtn.style.display = "block";
      }

      //시간 이동
      //
      let nowTime = vm.convertMillisecondsToTime(stMilliseconds);

      if ($nowTime != undefined) {
        $nowTime.innerText = String(nowTime);
        vm.rangeGageEvent(stMilliseconds, maxTime);
        vm.$refs.rangeItem.querySelector("#exIn").value = stMilliseconds;
      }

      const param = {
        txt: "mouseup",
        val: stMilliseconds,
        timeDifference: this.timeDifference,
      };
      this.emitter.emit("videoTime", param);
    },

    //---- API
    async getPJTData(prjId, edgeId) {
      try {
        const _pjtName = encodeURIComponent(prjId);
        const _edgeDevId = encodeURIComponent(edgeId);
        const response = await common.apiGet(
          "/edge-device/" + _pjtName + "/" + _edgeDevId
        );

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async getDriPrjListData(prjId, edgeId, startD, endD) {
      try {
        const _pjtName = encodeURIComponent(prjId);
        const _edgeDevId = encodeURIComponent(edgeId);
        const _startD = encodeURIComponent(startD + " 00:00");
        const _endD = encodeURIComponent(endD + " 23:59");
        // console.log(_startD)
        const response = await common.apiGet(
          "/driving-record/project/" +
            _pjtName +
            "/" +
            _edgeDevId +
            "?" +
            "start-time=" +
            _startD +
            "&end-time=" +
            _endD
        );
        // ?start-time=2022-12-10%2000%3A00&end-time=2023-11-10%2000%3A00
        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async deleteDriv(driId) {
      try {
        await common.apiDelete(
          "/driving-record/" + driId + "/" + this.projectId
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async addDriRecData(driRecId, color, sT, eT) {
      const data = {
        driving_record_id: driRecId,
        record_color: color,
        start_time: sT,
        end_time: eT,
      };
      try {
        const response = await common.apiPost(
          "/driving-record/select-range",
          data
        );

        console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },

    // dl
    // async getDLVicData() {
    //     try {
    //         const response = await dlcommon.apiGet('/dl/can/catalog-list');

    //         console.log(response.data.data);
    //         return response.data.data;

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // },
    // -------- DATE PICKER -----

    showmodalAddAlert() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAddAlert;
      $modal.style.display = "block";
      $modalAlert.style.display = "block";
    },

    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      const $modalAddAlert = this.$refs.modalAddAlert;
      //const $modalMomber = this.$refs.modalMomber;

      $modal.style.display = "none";
      $modalAlert.style.display = "none";
      //$modalMomber.style.display = "none";
      $modalAddAlert.style.display = "none";
    },
  },
};
</script>
<style>
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark {
  width: 20%;
  height: 10px;
  position: absolute;
  bottom: 4px;
  left: 20%;
  z-index: 2;
}
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark
  div {
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.item_info
  > .float_box
  .data_set_mon
  .data_set
  .time_controller
  .range_box
  .range_item
  .time_mark
  .mark_index {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 20px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
</style>
<style scoped lang="scss">
.timeline {
  height: 100%;
}
.item_info {
  height: 100%;
  & > .float_box {
    width: 100%;
    height: 100% !important;
    margin-top: 0 !important;
    & > div {
      float: left;
    }
    .driving_record {
      width: 300px;
      height: 100%;
      background-color: $whiteColor;
      margin-right: 20px;
      box-sizing: border-box;
      padding: 20px 0;
      .title_box {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        strong {
          font-weight: $bold;
        }
        button {
          width: 86px;
          height: 40px;
          background-repeat: no-repeat;
          background-image: url($baseURL + "common/add_15_wh.svg");
          background-position: 15px center;
          box-sizing: border-box;
          padding-left: 20px;
          border-radius: 10px;
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
      .filter_box {
        height: 40px;
        box-sizing: border-box;

        .date_filter {
          width: 100%;
          height: 100%;
          padding-left: 0;
          &::after {
            display: none;
          }
          .date_period {
            width: 240px;
            left: 10px;
          }
        }
      }
      .driving_record_list {
        width: 100%;
        height: 670px;
        margin-top: 20px;
        overflow-y: auto;
        table {
          width: 100%;
          thead {
            border-bottom: 1px solid $line01Color;
            th {
              padding: 0 0 10px;
              color: #6e7380;
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid $line01Color;
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                background-color: #edfbff;
                td {
                  .description_box {
                    display: block;
                  }
                }

                // color: $whiteColor;
                // td{
                //     color: $whiteColor;
                // }
                // span{
                //     color: $whiteColor;
                // }
                // div{
                //     color: $whiteColor;
                // }
                .btn_evt_group {
                  background-image: url($baseURL + "/common/moremenu_clicklist.svg");
                }
              }
              &.active {
                background-color: $mainColor;
                color: $whiteColor;
                td {
                  color: $whiteColor;
                }
                span {
                  color: $whiteColor;
                }
                div {
                  color: $whiteColor;
                }
                .btn_evt_group {
                  background-image: url($baseURL + "/common/moremenu_clicklist.svg");
                }
              }
            }
            td {
              padding: 10px 0 10px 10px;
              vertical-align: middle;
              position: relative;
              .name_box {
                display: inline-block;
                width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              div {
                margin-bottom: 5px;
                &:last-child {
                  margin-bottom: 0;
                }
                span {
                  display: inline-block;
                  color: #6e7380;
                  margin-right: 5px;
                }
                &.description_box {
                  width: 215px;
                  min-height: 66px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  background-color: #edfbff;
                  padding: 10px;
                  box-sizing: border-box;
                  word-wrap: break-word;
                  display: none;
                  strong {
                    display: block;
                    color: #6e7380;
                    margin-bottom: 10px;
                  }
                  p {
                    font-size: $fontSub;
                  }
                }
              }
              .btn_evt_group {
                width: 18px;
                height: 18px;
                background-size: cover;
                position: relative;
                top: -2px;
                margin-left: 2px;
                margin-right: 6px;
                &.active {
                  background-image: url($baseURL + "/common/moremenu_list_click.svg");
                }
              }
              .evt_btn_box {
                right: 5px;
              }
            }
          }
        }
      }
    }
    .data_set_mon {
      width: 100%;
      height: 100%;
      position: relative;
      .none_data {
        width: 100%;
        height: 100%;
        background-color: $line02Color;
        .text_box {
          position: absolute;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-top: 100px;
          background-image: url($baseURL + "/common/info_guide_list.svg");
          background-repeat: no-repeat;
          background-position: center 0;
          span {
            color: $placeHolderColor;
            line-height: 20px;
          }
        }
      }
      .data_set {
        height: 100%;
        .time_controller {
          width: 100%;
          height: 100%;
          position: relative;
          .controller_box {
            width: 350px;
            height: 40px;
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            .btn_point {
              width: 50px;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
              &.start_point {
                position: absolute;
                top: 0;
                left: 8px;
                background-image: url($baseURL + "/common/edit_start.svg");
                &:hover {
                  background-image: url($baseURL + "/common/edit_start_over.svg");
                }
                &.active {
                  background-image: url($baseURL + "/common/edit_click_start.svg");
                }
              }
              &.end_point {
                position: absolute;
                top: 0;
                right: 8px;
                background-image: url($baseURL + "/common/edit_end.svg");
                &:hover {
                  background-image: url($baseURL + "/common/edit_end_over.svg");
                }
                &.active {
                  background-image: url($baseURL + "/common/edit_click_end.svg");
                }
              }
              &.prohibit {
                cursor: not-allowed;
              }
            }
            .btn_wrap {
              width: 250px;
              height: 100%;
              margin: 0 auto 0;
              background-color: #edfbff;
              border-radius: 20px;
              position: relative;
              button {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                position: absolute;
                top: 5px;
                background-repeat: no-repeat;
                background-position: center;
                background-color: #edfbff;
                &:hover {
                  background-color: $line01Color;
                }
                &.first_point {
                  left: 15px;
                  background-image: url($baseURL + "/common/start.svg");
                }
                &.back_seconds {
                  left: 55px;
                  background-image: url($baseURL + "/common/3sleft.svg");
                }
                &.play_pause {
                  width: 60px;
                  height: 60px;
                  top: 50%;
                  left: 50%;
                  border-radius: 30px;
                  transform: translate(-50%, -50%);
                  box-shadow: 0px 0px 6px #00b4ed99;
                  &.pause {
                    background-image: url($baseURL + "/common/pause.svg");
                  }
                  &.play {
                    background-image: url($baseURL + "/common/play.svg");
                  }
                }
                &.forward_seconds {
                  right: 55px;
                  background-image: url($baseURL + "/common/3sright.svg");
                }
                &.end_point {
                  right: 15px;
                  background-image: url($baseURL + "/common/end.svg");
                }
                &.prohibit {
                  cursor: not-allowed;
                }
              }
            }
          }
          .add_select_section {
            width: 135px;
            height: 40px;
            border-radius: 10px;
            box-sizing: border-box;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            padding-left: 30px;
            color: $mainColor;
            position: absolute;
            top: 20px;
            right: 20px;
            background-repeat: no-repeat;
            background-position: 10px center;
            background-image: url($baseURL + "/common/add_section.svg");
            &:disabled {
              background-color: $line02Color;
              border: 1px solid $disable02Color;
              background-image: url($baseURL + "/common/add_section_dis.svg");
            }
          }
          .start_end {
            width: 184px;
            position: absolute;
            top: 20px;
            left: 10px;
            & > div {
              div {
                color: $placeHolderColor;
                display: inline-block;
                font-family: $montserrat;
                padding-left: 20px;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: 10px center;
              }
              span {
                font-family: $montserrat;
              }
              &.start_point_time {
                div {
                  background-image: url($baseURL + "/common/icon_starttime.svg");
                }
              }
              &.end_point_time {
                div {
                  background-image: url($baseURL + "/common/icon_endtime.svg");
                }
              }
            }
          }
          .range_box {
            width: 100%;
            height: 50px;
            position: absolute;
            left: 0;
            bottom: 20px;
            .range_item {
              width: calc(100% - 340px);
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%);
              input.slider {
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 5px;
                left: 0;
                z-index: 3;
                background: transparent;
                outline: none;
                -webkit-appearance: none;
                margin: 0;
                border-radius: 10px;
                &::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  // appearance: none;
                  width: 20px;
                  height: 20px;
                  background-color: $mainColor;
                  box-sizing: border-box;
                  border: 5px solid #fff;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px #00000029;
                  cursor: pointer;
                }
                &::-moz-range-thumb {
                  appearance: none;
                  width: 20px;
                  height: 20px;
                  background-color: $mainColor;
                  box-sizing: border-box;
                  border: 5px solid #fff;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px #00000029;
                  cursor: pointer;
                }
              }
              .flow_bg {
                width: 0;
                max-width: 100%;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 0;
                background: $mainColor;
                border-radius: 10px;
                z-index: 1;
              }
              .range_bg {
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 0;
                background: #efefef;
                border-radius: 10px;
              }
              .pin_point {
                width: 2px;
                height: 32px;
                background-color: #4791ff;
                position: absolute;
                bottom: -6px;
                left: 20%;
                z-index: 2;
              }
              .time_mark {
                width: 20%;
                height: 10px;
                position: absolute;
                bottom: 4px;
                left: 20%;
                z-index: 2;
                div {
                  width: 100%;
                  height: 100%;
                  opacity: 0.7;
                }
                .mark_index {
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  color: $whiteColor;
                  text-align: center;
                  font-weight: 700;
                  font-family: $montserrat;
                  font-size: $fontSub;
                  line-height: 20px;
                  position: absolute;
                  top: -25px;
                  left: 50%;
                  transform: translateX(-50%);
                  opacity: 1;
                }
              }
            }
            .time_move_box {
              position: absolute;
              bottom: 0;
              left: 25px;
              strong {
                font-size: $fontMenuTitle;
                font-family: $montserrat;
                font-weight: 700;
                color: $secondaryColor;
                // color: #0f0;
                display: inline-block;
                border-bottom: 1px solid $secondaryColor;
              }
              .time_input_box {
                // display: none;
                width: 125px;
                font-size: $fontMenuTitle;
                font-family: $montserrat;
                font-weight: 700;
                color: $secondaryColor;
                position: absolute;
                top: -11px;
                left: -6px;
                background-color: $whiteColor;
                border: 1px solid $mainColor;
                padding: 7px 5px;
                border-radius: 10px;
                input {
                  width: 27px;
                  height: 21px;
                  font-size: $fontMenuTitle;
                  font-family: $montserrat;
                  font-weight: 700;
                  outline: none;
                  text-align: center;
                  padding: 0;
                  border: none;
                  color: $secondaryColor;
                  border-bottom: 1px solid $secondaryColor;
                }
              }
            }
            .end_time {
              position: absolute;
              bottom: 0;
              right: 50px;
              strong {
                color: #6e7380;
                font-family: $montserrat;
              }
            }
          }
        }
        & > .float_box {
          & > div {
            width: 305px;
            height: 308px;
            float: left;
            overflow: hidden;
            &.list_box {
              &.task_list {
                table {
                  table-layout: fixed;
                }
              }
              .mando_table_wrap {
                height: 268px;
              }
            }
            &:nth-child(1) {
              /*margin-bottom: 20px;*/
            }
            &:nth-child(2) {
              margin-bottom: 20px;
              margin-left: 20px;
            }
            &:nth-child(3) {
              // margin-right: 20px;
            }
            &:nth-child(4) {
              margin-bottom: 20px;
              margin-left: 20px;
              margin-right: 20px;
            }
            .title_box {
              width: 100%;
              height: 40px;
              position: relative;
              box-sizing: border-box;
              padding: 20px 20px 0;
              strong {
                font-weight: $bold;
              }
              .setting_icon {
                position: absolute;
                top: 10px;
                right: 20px;
              }
            }
            .mando_table {
              th {
                &:first-child {
                  min-width: 100px;
                }
              }
            }
            .select_section {
              table-layout: fixed;
              th,
              td {
                padding: 10px 0;
              }
              td {
                position: relative;
                vertical-align: middle;
                &:first-child {
                  font-weight: $bold;
                }
                .btn_evt_group {
                  width: 18px;
                  height: 18px;
                  background-size: cover;
                  position: relative;
                  top: -2px;
                  margin-left: 0;
                  &.active {
                    background-image: url($baseURL + "/common/moremenu_list_click.svg");
                  }
                }
                .evt_btn_box {
                  right: 10px;
                }
                .list_item_icon {
                  width: 13px;
                  height: 13px;
                  display: inline-block;
                  border: 1px solid;
                  border-color: #000;
                  border-radius: 7px;
                  position: relative;
                  top: 2px;
                  overflow: hidden;
                  & > div {
                    width: 0;
                    height: 0;
                    border-top: 3px solid transparent;
                    border-bottom: 3px solid transparent;
                    border-left: 4px solid #000;
                    border-right: 3px solid transparent;
                    position: absolute;
                    top: 4px;
                    left: 5px;
                  }
                }
              }
            }
            .chart_item_data {
              th,
              td {
                padding: 10px 10px;
              }
              td {
                vertical-align: middle;
                &:first-child {
                  text-align: left;
                }
                .list_item_icon {
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  display: inline-block;
                  margin-right: 10px;
                  position: relative;
                  top: -2px;
                }
              }
            }
            .chart_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              box-sizing: border-box;
              padding: 20px;
              padding-bottom: 10px;
              position: relative;
              top: 0;
              left: 0;
              z-index: 1;
              canvas {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }
              .chart_time {
                width: 560px;
                height: 30px;
                position: absolute;
                bottom: 0;
                left: 25px;
                z-index: 0;
                div {
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 180, 237, 0.5);
                }
              }
            }
            .map_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              background-color: #ff0;
            }
            .video_box {
              width: 100%;
              height: 250px;
              margin-top: 18px;
              background-color: #ff0;
            }
          }
        }
      }
    }
  }
}
// 선택 구간 사용 메뉴얼

// 선택구간 추가 알림
</style>
