<template>
    <div v-if="readOnlyProp===false" style="float:right;">
      <input type="hidden" v-model="remove">
      <button style="  background: #00B4ED; color:white;"  @click="removeCalender">
        X</button>
    </div>
  <div class="filter_box">
    <div v-if="readOnlyProp===false" class="date_filter">                                                   
      <div class="date_period_first"> Handler Key</div>
      <div class="date_period_second" ref="handlerKey" > {{handlerKey}}  </div>
    </div>
    <div v-if="readOnlyProp===false" class="date_filter">                                                   
      <div class="date_period_first"> Filter Type </div>
      <div class="date_period_second"> Calendar  </div>
    </div>
    <div v-if="readOnlyProp===false" class="date_filter">                                                   
      <div class="date_period_first"> Calender Type </div>
      <div class="date_period_second">
        <select v-model="calenderType" @change="(e) =>(calenderType = e.target.value)">
          <option :value="null" disabled>Calender Type</option>
          <option :value="'single'">single calendar</option>
          <option :value="'period'">period calendar</option>
        </select>
      </div>
    </div>
    <div class="date_filter">
      <div v-if="readOnlyProp===false" class="date_period_first"> Default Date </div>
      <div class="date_period_second">
        <div class="checkbox-container">
          <input type="checkbox" id="today2" v-model="todayCheck" @change="updateToday">
          <label for="today2">today</label>
        </div>

        <div class="date_period">
            <div class="date_box">
                  <span
                    @click="dateAction('E')"
                    style="
                      display: inline-block;
                      cursor: pointer;
                      padding: 0 0 0 10px;
                    "
                    :style="picEndTxtStyle"
                    ref="picEndTxt"
                    >{{ picEndDate }}</span
                  >
            </div>
                <button type="button" ref="datePicToggle"></button>
                <div
                  class="calender_box radiusbox"
                  ref="calenderBox"
                  style="display: none"
                >
                  <div 
                    class="end_date date_box"
                    ref="endDateBox"
                    style="display: none"
                  >
                    <VCalendarUi
                      :dateInterface="'end'"
                      v-model="picEndDateCalendar"
                      @dayclick="onDatePic('E', picEndDateCalendar)"
                    />
                  </div>
                </div>
          </div>

      </div>
    </div>
    <div v-if="readOnlyProp===false&&calenderType==='period'" class="date_filter">                                                   
      <div class="date_period_first"> Period </div>
      <div class="date_period_second"> 
        <input type="number" v-model="period" @change="changePeriod" >  
      </div>
    </div>
  </div>
</template>

<script>
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";

export default {
    props: {
      interface: {
        type: Object,
        required: true,
      },
      otherProp: {
        type: Object,
        required: true,
      },
      readOnlyProp:{
        type: Boolean,
        require:true,
      },
      componentIndex:{
        type:Number
      },
    },
    components:{
        VCalendarUi
    },
    data() {
    return {
      handlerKey :'',
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picEndDate: null,
      start_cal_key:0,
      end_cal_key:0,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead : false,
      remove : false,
      todayCheck:false,
      startDay:'',
      endDay:'',
      period:0,
      startViewDate:null,
      startDate:null,
      endViewDate:null,
      endDate:null,
      calenderType:null
    };
  },
  created() {
    //handler 키 
    // this.generateUUID();
  },
  mounted() {
    const vm = this;
    let handlerKey = vm.otherProp.handlerKey;
    vm.onlyRead = vm.readOnlyProp; //읽기전용 체크
    let etoday = vm.otherProp.picEndDateCalendar;
    if(etoday==null){
      etoday = new Date();
    }
    const eyear = etoday.getFullYear();
    const emonth = etoday.getMonth()+1;
    const eday = etoday.getDate();
    const end_formattedDate =
      eyear +
      "-" +
      (emonth < 10 ? "0" : "") +
      emonth +
      "-" +
      (eday < 10 ? "0" : "") +
      eday;
    vm.picEndDate = end_formattedDate;
    vm.handlerKey = handlerKey;
    window.selectDate.endDate = end_formattedDate;
    
    if(vm.otherProp.period!=null){
      vm.period = vm.otherProp.period  
    }
    
    if(vm.otherProp.picEndDate!=null){
      vm.picEndDate = vm.otherProp.picEndDate 
    }

    if(vm.otherProp.picEndDateCalendar!=null){
      vm.picEndDateCalendar = vm.otherProp.picEndDateCalendar 
    }

    if(vm.otherProp.calenderType!=null){
      vm.calenderType = vm.otherProp.calenderType 
    }

    if(vm.otherProp.startDate!=null){
      vm.startDate = vm.otherProp.startDate;
    }
    
    if(vm.otherProp.startViewDate!=null){
      vm.startViewDate = vm.otherProp.startViewDate;
    }

    if(vm.otherProp.endDate!=null){
      vm.endDate = vm.otherProp.endDate;
    }

    if(vm.otherProp.endViewDate!=null){
      vm.endViewDate = vm.otherProp.endViewDate;
    }

    const startDate = vm.startDate;
    const startViewDate = vm.startViewDate;
    const endDate = vm.endDate;
    const endViewDate = vm.endViewDate;
    const calenderType = vm.calenderType;
    const singleDate = vm.picEndDate;
    const singleViewDate = vm.picEndDateCalendar;
    const period = vm.period;
    const picEndDate = vm.picEndDate;
    const picEndDateCalendar = vm.picEndDateCalendar;
    const object ={
          startDate,
          startViewDate,
          endDate,
          endViewDate,
          calenderType,
          singleDate,
          singleViewDate,
          period,
          picEndDate,
          picEndDateCalendar
        }
    this.$emit('updateCalendar',{ index:this.componentIndex ,value:object})
    


   },

  methods: {
    setDate(day) {
      const vm = this;
      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picEndDate = end_formattedDate;
      window.selectDate.endDate = end_formattedDate;
      this.selectDate = day;

     const startDate = vm.startDate;
     const startViewDate = vm.startViewDate;
     const endDate = vm.endDate;
     const endViewDate = vm.endViewDate;
     const calenderType = vm.calenderType;
     const singleDate = vm.picEndDate;
     const singleViewDate = vm.picEndDateCalendar;
     const picEndDate = vm.picEndDate;
     const picEndDateCalendar = vm.picEndDateCalendar;
     const period = vm.period;
     const object = {
       startDate,
       startViewDate,
       endDate,
       endViewDate,
       calenderType,
       singleDate,
       singleViewDate,
       period,
       picEndDate,
       picEndDateCalendar
     }
     this.$emit('updateCalendar',{ index:this.componentIndex , value:object})
    },
        // -------- DATE PICKER END -----

    nowTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // -------- DATE PICKER -----
    dateAction() {
      if(this.readOnlyProp===false){
          const display = this.$refs.endDateBox.style.display;
          if (display === "none") {
            this.$refs.endDateBox.style.display = "block";
            this.$refs.calenderBox.style.display = "block";
            this.picColor.startStatus = false;
            this.picColor.endStatus = true;
          } else {
            this.picColor.endStatus = false;
            this.$refs.endDateBox.style.display = "none";
            this.$refs.calenderBox.style.display = "none";
          }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);
      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    async getDriList() {
      const vm = this;
      // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
      // 켈린더 display처리
      const $calenderBox = vm.$refs.calenderBox;
      const $startDateBox = vm.$refs.startDateBox;
      const $endDateBox = vm.$refs.endDateBox;
      //const $drivReclistBox = vm.$refs.drivReclistBox;
      $calenderBox.style.display = "none";
      $startDateBox.style.display = "block";
      $endDateBox.style.display = "none";
      //$drivReclistBox.style.display = "block";

      vm.picColor.startStatus = false;
      vm.picColor.endStatus = false;
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if(this.readOnlyProp === false){
       if (flag === "E") {
          this.picEndDate = formattedDate;
          this.$refs.endDateBox.style.display = "none";
          this.picColor.endStatus = false;
        }
      }
    },
    pickerNext() {
      this.$refs.endDateBox.style.display = "block";
      this.picColor.startStatus = false;
      this.picColor.endStatus = true;
    },
    pickerPrev() {
      this.$refs.endDateBox.style.display = "none";
      this.picColor.startStatus = true;
      this.picColor.endStatus = false;
    },
    removeCalender(){
      this.$emit('removeItem');
    },
    updateToday(){
      if(this.todayCheck===true){
        const vm = this;
        const etoday = new Date();
        const eyear = etoday.getFullYear();
        const emonth = etoday.getMonth() + 1;
        const eday = etoday.getDate();
        const end_formattedDate =
          eyear +
          "-" +
          (emonth < 10 ? "0" : "") +
          emonth +
          "-" +
          (eday < 10 ? "0" : "") +
          eday;
        // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
        vm.picEndDateCalendar = new Date(end_formattedDate);
        vm.picEndDate = end_formattedDate;
        
        const startDate = vm.startDate;
        const startViewDate = vm.startViewDate;
        const endDate = vm.endDate;
        const endViewDate = vm.endViewDate;
        const calenderType = vm.calenderType;
        const singleDate = vm.picEndDate;
        const singleViewDate = vm.picEndDateCalendar;
        const period = vm.period;
        const picEndDate = vm.picEndDate;
        const picEndDateCalendar = vm.picEndDateCalendar;
        const object ={
          startDate,
          startViewDate,
          endDate,
          endViewDate,
          calenderType,
          singleDate,
          singleViewDate,
          period,
          picEndDate,
          picEndDateCalendar
        }
        this.$emit('updateCalendar',{ index:this.componentIndex ,value:object})
      }
    },
    changePeriod(){
      const vm =this;
      if(vm.picEndDate !=null && vm.picEndDate !=''){
        const defaultDate =  vm.picEndDate;
        const defaultDateView = vm.picEndDateCalendar;

        const etoday = new Date(defaultDate);
        etoday.setDate(etoday.getDate() + this.period);
        const eyear = etoday.getFullYear();
        const emonth = etoday.getMonth() + 1;
        const eday = etoday.getDate();
        const end_formattedDate =
          eyear +
          "-" +
          (emonth < 10 ? "0" : "") +
          emonth +
          "-" +
          (eday < 10 ? "0" : "") +
          eday;

          if(vm.period>0){
            vm.startDate = defaultDate;
            vm.startViewDate = defaultDateView;
            vm.endDate = end_formattedDate;
            vm.endViewDate = new Date(end_formattedDate);
          }
          else if(this.period<0){
            vm.startDate =end_formattedDate;
            vm.startViewDate = new Date(end_formattedDate);
            vm.endDate =  defaultDate;
            vm.endViewDate = defaultDateView;
          }
          else{
            vm.startDate =end_formattedDate;
            vm.startViewDate = new Date(end_formattedDate);
          }

          
        const startDate = vm.startDate;
        const startViewDate = vm.startViewDate;
        const endDate = vm.endDate;
        const endViewDate = vm.endViewDate;
        const calenderType = vm.calenderType;
        const singleDate = vm.picEndDate;
        const singleViewDate = vm.picEndDateCalendar;
        const period = vm.period;
        const picEndDate = vm.picEndDate;
        const picEndDateCalendar = vm.picEndDateCalendar;
        const object ={
          startDate,
          startViewDate,
          endDate,
          endViewDate,
          calenderType,
          singleDate,
          singleViewDate,
          period,
          picEndDate,
          picEndDateCalendar
        }
        this.$emit('updateCalendar',{ index:this.componentIndex ,value:object})
      }

    }
    // generateUUID() {
    //     if(this.otherProp.handlerKey ==null || this.otherProp.handlerKey===''){
    //       this.handlerKey =  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //           var r = Math.random() * 16 | 0,
    //               v = c === 'x' ? r : (r & 0x3 | 0x8);
    //           return v.toString(16);
    //       });  
    //     }
    //     else{
    //       this.handlerKey = this.otherProp.handlerKey;
    //     }
    // }
  },
  computed: {
      picStartTxtStyle() {
        return {
          color: this.picColor.startStatus
            ? this.picColor.active
            : this.picColor.default,
        };
      },
      picEndTxtStyle() {
        return {
          color: this.picColor.endStatus
            ? this.picColor.active
            : this.picColor.default,
        };
      },
      picStartTxtStyleModal() {
        return {
          color: this.picColorModal.startStatus
            ? this.picColorModal.active
            : this.picColorModal.default,
        };
      },
      picEndTxtStyleModal() {
        return {
          color: this.picColorModal.endStatus
            ? this.picColorModal.active
            : this.picColorModal.default,
        };
      }
    },
  watch:{
    picEndDate(){
        const item  = this.otherProp;
        item.valueEnd   = this.picEndDate;
        this.$emit('updateValue', item); //
    },
    calenderType(){
        const vm = this;
        const startDate = vm.startDate;
        const startViewDate = vm.startViewDate;
        const endDate = vm.endDate;
        const endViewDate = vm.endViewDate;
        const calenderType = vm.calenderType;
        const singleDate = vm.picEndDate;
        const singleViewDate = vm.picEndDateCalendar;
        const period = vm.period;
        const picEndDate = vm.picEndDate;
        const picEndDateCalendar = vm.picEndDateCalendar;
        const object ={
          startDate,
          startViewDate,
          endDate,
          endViewDate,
          calenderType,
          singleDate,
          singleViewDate,
          period,
          picEndDate,
          picEndDateCalendar
        }
        this.$emit('updateCalendar',{ index:this.componentIndex ,value:object})
    }
  }
};
</script>
<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height:100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    margin-bottom:15px;
    width: 100%;
    height: 100%;
    display: flex;
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first{
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

    }

  }
  .checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px; /* label과 input 사이 간격 */
}
}

</style>

 