<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents" style="padding-bottom: 40px">
      <BreadcrumbLayout
        pageId="monTASK_drivingDataMonitoring"
        :name="'[TASK_AD] ' + taskData.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ prjData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ prjData.project_name }}</td>
                <td>{{ prjData.aws_id }}</td>
                <td>{{ prjData.pm }}</td>
                <td>{{ prjData.project_type }}</td>
                <td>{{ prjData.team_name }}</td>
                <td>{{ prjData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ prjData.project_description }}</div>
          </div>
        </div>
      </div>
      <div id="monCont" class="moa_dete">
        <div class="item_info">
          <div
            class="float_box"
            style="position: relative; padding-bottom: 20px"
          >
            <div
              class="result_box radiusbox"
              style="height: 250px; float: left"
            >
              <!-- <h2>파일 선택</h2> -->
              <div class="flex">
                <div class="flex_box">
                  <div class="title_box">
                    <strong>▶ 데이터 파일 선택</strong>
                    <span class="title_ea">{{ fileList_01.length }}EA</span>
                  </div>
                  <div class="file_container" style="margin-bottom: 28px">
                    <div
                      class="file_upload_list"
                      @dragenter="onDragenter(1)"
                      @dragover="onDragover"
                      @dragleave="onDragleave(1)"
                      @drop="onDrop($event, 1)"
                      style="height: 122px"
                    >
                      <!-- 업로드된 리스트 -->
                      <template v-if="fileList_01.length > 0">
                        <div
                          class="file_upload_list__item"
                          v-for="(file, index) in fileList_01"
                          :key="index"
                          @click="selectUploadCSV(index, 1)"
                        >
                          <div class="file_upload_list__item__data">
                            <div class="file_upload_list__item__data-name">
                              <input
                                type="checkbox"
                                id="fileList_01"
                                :checked="file.check"
                                disabled
                              /><label
                                for="fileList_01"
                                style="top: 1px; vertical-align: bottom"
                              >
                              </label>
                              <span>{{ file.name }}</span>
                            </div>
                          </div>
                          <div
                            class="file_upload_list__item__btn_remove"
                            @click="handleRemove($event, index, 1)"
                          >
                            삭제
                          </div>
                        </div>
                      </template>
                      <!-- <div
                        class="file-upload"
                        :class="isDragged_01 ? 'dragged' : ''"
                      >
                        {{ fileName_01 }}
                      </div> -->
                      <!-- <button
                        type="button"
                        @click="uploadList($event, 1)"
                        :class="{ active: isUploadlistShow_01 }"
                      >
                        ▼
                      </button> -->
                    </div>
                    <!-- 파일 업로드 -->
                    <label for="file_input_01">파일 찾기</label>
                    <input
                      id="file_input_01"
                      type="file"
                      ref="fileInput_01"
                      class="file_upload_input"
                      @change="onFileChange($event, 1)"
                      multiple
                      accept=".csv"
                    />
                  </div>
                </div>
                <div class="flex_box">
                  <div class="title_box">
                    <strong>▶ 영상 파일 선택</strong>
                    <span class="title_ea">{{ fileList_02.length }}EA</span>
                  </div>
                  <div class="file_container" style="margin-bottom: 28px">
                    <div
                      class="file_upload_list"
                      @dragenter="onDragenter(2)"
                      @dragover="onDragover"
                      @dragleave="onDragleave(2)"
                      @drop="onDrop($event, 2)"
                      style="height: 122px"
                    >
                      <!-- 업로드된 리스트 -->
                      <template v-if="fileList_02.length > 0">
                        <div
                          class="file_upload_list__item"
                          v-for="(file, index) in fileList_02"
                          :key="index"
                          @click="selectUploadVideo(index)"
                        >
                          <div class="file_upload_list__item__data">
                            <div class="file_upload_list__item__data-name">
                              <input
                                type="checkbox"
                                id="fileList_02"
                                :checked="fileList_02_check[index].check"
                                disabled
                              /><label
                                for="fileList_02"
                                style="top: 1px; vertical-align: bottom"
                              >
                              </label>
                              <span>{{ file.name }}</span>
                            </div>
                          </div>
                          <div
                            class="file_upload_list__item__btn_remove"
                            @click="handleRemove($event, index, 2)"
                          >
                            삭제
                          </div>
                        </div>
                      </template>
                      <!-- <div
                        class="file-upload"
                        :class="isDragged_02 ? 'dragged' : ''"
                      >
                        {{ fileName_02 }}
                      </div>
                      <button
                        type="button"
                        @click="uploadList($event, 2)"
                        :class="{ active: isUploadlistShow_02 }"
                      >
                        ▼
                      </button> -->
                    </div>
                    <!-- 파일 업로드 -->
                    <label for="file_input_02">파일 찾기</label>
                    <input
                      id="file_input_02"
                      type="file"
                      ref="fileInput_02"
                      class="file_upload_input"
                      @change="onFileChange($event, 2)"
                      multiple
                      accept=".avi,.mp4"
                    />
                  </div>
                </div>
                <div class="flex_box">
                  <div class="title_box">
                    <strong>▶ GPS 파일 선택</strong>
                    <span class="title_ea">{{ fileList_03.length }}EA</span>
                  </div>
                  <div class="file_container">
                    <div
                      class="file_upload_list"
                      @dragenter="onDragenter(3)"
                      @dragover="onDragover"
                      @dragleave="onDragleave(3)"
                      @drop="onDrop($event, 3)"
                      style="height: 122px"
                    >
                      <!-- 업로드된 리스트 -->
                      <template v-if="fileList_03.length > 0">
                        <div
                          class="file_upload_list__item"
                          v-for="(file, index) in fileList_03"
                          :key="index"
                          @click="selectUploadCSV(index, 3)"
                        >
                          <div class="file_upload_list__item__data">
                            <div class="file_upload_list__item__data-name">
                              <input
                                type="checkbox"
                                id="fileList_03"
                                :checked="file.check"
                                disabled
                              /><label
                                for="fileList_03"
                                style="top: 1px; vertical-align: bottom"
                              >
                              </label>
                              <span>{{ file.name }}</span>
                            </div>
                          </div>
                          <div
                            class="file_upload_list__item__btn_remove"
                            @click="handleRemove($event, index, 3)"
                          >
                            삭제
                          </div>
                        </div>
                      </template>

                      <!-- <button
                        type="button"
                        @click="uploadList($event, 3)"
                        :class="{ active: isUploadlistShow_03 }"
                      >
                        ▼
                      </button> -->
                    </div>
                    <!-- 파일 업로드 -->
                    <label for="file_input_03">파일 찾기</label>
                    <input
                      id="file_input_03"
                      type="file"
                      ref="fileInput_03"
                      class="file_upload_input"
                      @change="onFileChange($event, 3)"
                      multiple
                      accept=".csv"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="result_box2 radiusbox"
              style="
                float: right;
                width: calc(35% - 20px);
                height: 414px;
                padding: 0 20px;
                box-sizing: border-box;
              "
            >
              <h2>Message / Signal 선택</h2>
              <!-- <div class="">
                    <p>검색</p>
                    <div></div>
                  </div> -->
              <div class="title_box">
                <strong>Message</strong>
              </div>
              <div class="file_container" style="margin-bottom: 28px">
                <div class="file_upload_list">
                  <template v-if="searchMessageAddData.length > 0">
                    <div
                      class="file_upload_list__item"
                      v-for="(item, index) in searchMessageAddData"
                      :key="index"
                    >
                      <div class="file_upload_list__item__data">
                        <div class="file_upload_list__item__data-name">
                          {{ item.message }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <label @click="showModal(1)">메세지 선택</label>

                <!-- <button
                        type="button"
                        @click="uploadList($event, 4)"
                        :class="{ active: isMessageListShow }"
                      >
                        ▼
                      </button> -->
                <!-- <button
                    type="button"
                    @click="uploadList($event, 6)"
                    :class="{ active: isUploadlistShow_04 }"
                  >
                    ▼
                  </button> -->
              </div>
              <div class="title_box">
                <strong>Signal</strong>
              </div>
              <div class="file_container" style="margin-bottom: 28px">
                <div class="file_upload_list" style="overflow-y: auto">
                  <template v-if="searchSignalAddData.length > 0">
                    <div
                      class="file_upload_list__item"
                      v-for="(item, index) in searchSignalAddData"
                      :key="index"
                    >
                      <div class="file_upload_list__item__data">
                        <div class="file_upload_list__item__data-name">
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- <div
                    class="file-upload"
                    :class="isSignalClicked ? 'dragged' : ''"
                  >
                    <template v-for="(item, index) in signalList" :key="index">
                      <div>
                        {{ index < signalList.length - 1 ? item + "," : item }}
                      </div>
                    </template>
                  </div> -->
                  <!-- <button
                    type="button"
                    @click="uploadList($event, 7)"
                    :class="{ active: isUploadlistShow_05 }"
                  >
                    ▼
                  </button> -->
                </div>
                <label @click="showModal(2)">시그널 선택</label>
              </div>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  margin-top: -4%;
                  border-radius: 10px;
                "
              >
                <button
                  :class="{ buttonRed: isActive }"
                  :style="{
                    'border-radius': '10px',
                    'font-size': '110%',
                    padding: '2% 4% 2% 4%',
                    background: buttonColor,
                    color: buttonTextColor,
                  }"
                  @click="showSignalChart"
                >
                  메세지/시그널 적용
                </button>
              </div>
            </div>

            <div
              class="timeline_video_wrap float_box"
              style="width: 65% !important; margin-top: 20px !important"
            >
              <div class="timeline_controller_wrap">
                <TimelineController
                  :selectSectionYn="selectSectionYn"
                  :startTime="timelineStartTime"
                  :endTime="timelineEndTime"
                  :gpsMapDataTimestamps="gpsMapDataTimestamps"
                  :gpsMapDataTime="gpsMapDataTime"
                  style="height: 144px"
                  ref="timelineController"
                />
              </div>
              <div style="display: flex; gap: 20px; margin-top: 20px">
                <TimelineSection style="flex: 1" />
                <div class="video_controller_wrap radiusbox float_box">
                  <VideoController
                    :src="src"
                    :timeDifference="timeDifference"
                    :whSize="whSize"
                    ref="videoController"
                  />
                </div>
              </div>
            </div>
            <div
              class="map_controller_wrap radiusbox"
              style="
                width: calc(35% - 20px);
                margin-top: 20px;
                height: 308px;
                box-sizing: border-box;
                padding: 0 20px;
              "
            >
              <div class="title_box" style="margin: 20px 0">
                <strong style="font-size: 16px; font-weight: 600"
                  >GPS 지도</strong
                >
              </div>
              <MapController
                style="height: calc(100% - 80px)"
                :mapPage="'vector'"
                :timeDifference="timeDifference"
                :gpsMapDataArray="gpsMapDataTime"
                :mapType="'normal'"
              />
            </div>

            <div
              class="table_controller_wrap float_box radiusbox"
              style="width: calc(50% - 20px); float: right; margin: 0"
            >
              <div class="title_box">
                <strong>데이터 테이블</strong>
              </div>
              <TableController :tblData="tblDataArray" />
            </div>
            <div
              class="chart_controller_wrap float_box radiusbox"
              style="width: 50%; float: left; margin: 0"
            >
              <div class="title_box">
                <strong>Signal 차트</strong>
              </div>
              <ChartController
                :labelsArray="labelsArray"
                :datasArray="datasArray"
                :chartType="chartType"
              />
            </div>
            <!-- <div class="table_chart_wrap">
                <div class="table_controller_wrap table_gps radiusbox" style="">
                  <div class="title_box">
                    <strong>GPS 데이터 테이블</strong>
                  </div>
                  <TableController :tblData="tblGpsDataArray" />
                </div>
                <div class="chart_controller_wrap chart_gps radiusbox">
                  <div class="title_box">
                    <strong>GPS 데이터 차트</strong>
                  </div>
                  <ChartController
                    :labelsArray="labelsGpsArray"
                    :datasArray="datasGpsArray"
                    :chartType="chartGpsType"
                  />
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="modalWrap" ref="modalWrap_01" style="display: none">
    <div class="radiusbox modal_contents message_list">
      <strong class="modal_title">Message 항목 추가</strong>
      <div class="filter_box">
        <input
          type="text"
          name="messageKeyword"
          ref="MessageKeyword"
          placeholder="Message Name 검색"
        />
        <button
          type="button"
          class="btn_search"
          @click="searchMessage"
        ></button>
      </div>
      <div class="float_box table_list">
        <div class="select_box mando_table_wrap">
          <table class="mando_table">
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>
                  <div class="all_chk">
                    <input
                      type="checkbox"
                      id="allChkMsg"
                      v-model="isAllChkMsg"
                      @change="allChkMsg"
                    /><label for="allChkMsg"></label><span>Message Name</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="searchMessageData < 1">
                <tr>
                  <td class="no_data">
                    <div>데이터가 없습니다.</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, index) in searchMessageData" :key="index">
                  <td>
                    <div class="chk_item">
                      <input
                        type="checkbox"
                        :id="'message_' + index"
                        :value="item.message"
                        :checked="chkSelectedMsgTemp.includes(item.message)"
                        @change="chkMsg(item, index)"
                      /><label :for="'message_' + index"></label
                      ><span>{{ item.message }}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="choose_box">
          <div class="btn_wrap">
            <button type="button" class="btn_add" @click="clickAddMsg">
              추가</button
            ><button type="button" class="btn_add_del" @click="clickDelMsg">
              삭제
            </button>
          </div>
        </div>
        <div class="table_title">
          <strong>선택 항목</strong>
        </div>
        <div class="select_box mando_table_wrap">
          <table class="mando_table">
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>
                  <div class="all_chk">
                    <input
                      type="checkbox"
                      id="allChkAddMsg"
                      v-model="isAllChkAddMsg"
                      @change="allChkAddMsg"
                    /><label for="allChkAddMsg"></label
                    ><span>Message Name</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="searchMessageAddData < 1">
                <tr>
                  <td class="no_data">
                    <div>데이터가 없습니다.</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, index) in searchMessageAddData" :key="index">
                  <td>
                    <div class="chk_item">
                      <input
                        type="checkbox"
                        :id="'message_add_' + index"
                        :value="item.message"
                        :checked="chkSelectedAddMsgTemp.includes(item.message)"
                        @change="chkAddMsg(item)"
                      /><label :for="'message_add_' + index"></label
                      ><span>{{ item.message }}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="check_wrap">
        <button
          type="button"
          class="btn_check mcbtn"
          @click="selectMessageData"
        >
          완료
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(1)"></button>
    </div>
  </div>

  <div id="modalWrap" ref="modalWrap_02" style="display: none">
    <div class="radiusbox modal_contents message_list">
      <strong class="modal_title">Signal 편집</strong>
      <div class="filter_box">
        <input
          type="text"
          name="signalKeyword"
          ref="SignalKeyword"
          placeholder="Signal Name 검색"
        />
        <button type="button" class="btn_search" @click="searchSignal"></button>
      </div>
      <div class="float_box table_list">
        <div class="select_box mando_table_wrap">
          <table class="mando_table">
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>
                  <div class="all_chk">
                    <input
                      type="checkbox"
                      id="allChkSgl"
                      v-model="isAllChkSgl"
                      @change="allChkSgl"
                    /><label for="allChkSgl"></label><span>Signal Name</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="searchSignalData < 1">
                <tr>
                  <td class="no_data">
                    <div>데이터가 없습니다.</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, index) in searchSignalData" :key="index">
                  <td>
                    <div class="chk_item">
                      <input
                        type="checkbox"
                        :id="'signal_' + index"
                        :value="item"
                        :checked="chkSelectedSglTemp.includes(item)"
                        @change="chkSglMsg(item)"
                      /><label :for="'signal_' + index"></label
                      ><span>{{ item }}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="choose_box">
          <div class="btn_wrap">
            <button type="button" class="btn_add" @click="clickAddSgl">
              추가</button
            ><button type="button" class="btn_add_del" @click="clickDelSgl">
              삭제
            </button>
          </div>
        </div>
        <div class="table_title">
          <strong>선택 항목</strong>
        </div>
        <div class="select_box mando_table_wrap">
          <table class="mando_table">
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>
                  <div class="all_chk">
                    <input
                      type="checkbox"
                      id="allChkAddSgl"
                      v-model="isAllChkAddSgl"
                      @change="allChkAddSgl"
                    /><label for="allChkAddSgl"></label><span>Signal Name</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="searchSignalAddData < 1">
                <tr>
                  <td class="no_data">
                    <div>데이터가 없습니다.</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, index) in searchSignalAddData" :key="index">
                  <td>
                    <div class="chk_item">
                      <input
                        type="checkbox"
                        :id="'signal_add_' + index"
                        :value="item"
                        :checked="chkSelectedAddSglTemp.includes(item)"
                        @change="chkAddSgl(item)"
                      /><label :for="'signal_add_' + index"></label
                      ><span>{{ item }}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="check_wrap">
        <button type="button" class="btn_check mcbtn" @click="signalDelEnd()">
          완료
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(2)"></button>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";

import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import TimelineController from "@/components/TimelineController.vue";
import TimelineSection from "@/components/TimelineSection.vue";
import VideoController from "@/components/VideoController.vue";
import ChartController from "@/components/ChartController.vue";
import MapController from "@/components/MapController.vue";
import TableController from "@/components/TableController.vue";
import common from "@/assets/js/common";
import apiCallVectorLogger from "@/assets/js/apiCallVectorLogger";
import papa from "papaparse";
import { Chart, registerables } from "chart.js/auto";
import { chunk } from "lodash";
Chart.register(...registerables);

export default {
  name: "DrivingDataMonitoringView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    TimelineController,
    TimelineSection,
    VideoController,
    ChartController,
    MapController,
    TableController,
  },
  data() {
    return {
      //dataReady: false,
      // project , task 정보
      modalMgsCnt: 0,
      modalSglCnt: 0,
      whSize: "height",
      taskData: {
        projectId: null,
        projectName: null,
        awsId: null,
        taskName: null,
        creatorName: null,
        type: null,
        teamName: null,
        description: null,
      },

      //DTC Map

      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      prjData: {
        project_name: "",
        aws_id: "",
        pm: "",
        project_type: "",
        team_name: "",
        last_activity: "",
        project_description: "",
      },

      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      carNumList: [],
      fileList_01: [],
      fileList_02: [],
      fileList_02_check: [],
      fileList_03: [],

      fileName_01: "데이터",
      fileName_02: "영상 데이터",
      fileName_03: "GPS 데이터",
      messageName: "Message",
      signalName: "Signal",

      isUploadlistShow_01: false,
      isUploadlistShow_02: false,
      isUploadlistShow_03: false,
      isUploadlistShow_04: false,
      isUploadlistShow_05: false,

      isMessageListShow: false,
      isSignalListShow: false,
      isMessageClicked: false,
      isSignalClicked: false,

      csvData: [],
      gpsData: [],
      csvMessageData: [],
      csvSignalData: [],
      csvMessage: [],
      csvMessageReduce: [],
      csvSignal: [],
      csvObjData: [],
      csvObjSelectedData: [],
      signalChartData: [],
      signalList: ["Signal"],
      signalOrgList: ["Signal"],
      searchMessageData: [],
      searchSignalData: [],
      searchMessageOrgData: [],
      searchSignalOrgData: [],

      chkSelectedMsg: [],
      chkSelectedSgl: [],
      chkSelectedMsgTemp: [],
      chkSelectedSglTemp: [],
      searchMessageAddData: [],
      searchSignalAddData: [],
      chkSelectedAddMsg: [],
      chkSelectedAddSgl: [],
      chkSelectedAddMsgTemp: [],
      chkSelectedAddSglTemp: [],

      maxFileUploadLength: 99,
      selectSectionYn: "Y",
      timelineStartTime: "",
      timelineEndTime: "",
      orgTimelineStartTime: "",
      src: "",
      preVideoIndex: null,
      timeDifference: 0,
      labelsArray: [],
      datasArray: [],
      datasetsArray: [],
      tblDataArray: [],
      gpsMapDataArray: [],
      gpsMapDataTimestamps: [],

      labelsGpsArray: [],
      datasGpsArray: [],
      tblGpsDataArray: [],
      chartGpsType: "",
      chartType: "",
      selectTab: "",

      isAllChkMsg: false,
      isAllChkAddMsg: false,
      isAllChkSgl: false,
      isAllChkAddSgl: false,

      buttonColor: "#D9D9D9",
      buttonTextColor: "black",
    };
  },

  computed: {
    activeTab() {
      return (message) => {
        return this.selectTab === message ? "active" : "";
      };
    },

    /*
    isChecked() {
      return this.chkSelectedMsg.includes(this.value);
    },
    */
  },
  async created() {
    this.selectSectionYn = "Y";
  },
  async mounted() {
    // Role
    await this.setRole();

    //this.setDate(this.selectDuration);

    this.$refs.lodingWrap.style.display = "block";
    console.log("신규 API 결과 ::===============");
    try {
      const param = {
        edgeIds: [1, 2],
      };
      const response = await apiCallVectorLogger.post(
        "/vl/vl_car_list/",
        param
      );
      console.log("신규 API 결과 :: ", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    this.$refs.lodingWrap.style.display = "none";
    // prj info
    const prjId = this.$route.query.prjId;
    const prjData = await this.getPrjData(prjId);
    if (typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
      this.prjData = prjData.project;
    }

    try {
      // monitoring-task 정보
      await common
        .apiGet(
          "/monitoring-task/" +
            this.$route.query.taskId +
            "?viewRole=" +
            this.viewRole
        )
        .then((r) => {
          console.log("0. monitoring-task 정보");
          console.log(r.data.data);
          this.taskData.projectId = r.data.data.projectId;
          this.taskData.projectName = r.data.data.projectName;
          this.taskData.awsId = r.data.data.awsId;
          this.taskData.taskName = r.data.data.taskName;
          this.taskData.creatorName = r.data.data.creatorName;
          this.taskData.type = r.data.data.type;
          this.taskData.teamName = r.data.data.teamName;
          this.taskData.lastVisit = r.data.data.lastVisit;
          this.taskData.description = r.data.data.description;
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // mando api call
    await this.apiCallMando();

    //projectId
    this.projectId = this.$route.query.prjId;
    // await this.allowAccess();
    //console.log("nnnnnnnnnnnnnnn :: ", this.getPrjDetailData(this.projectId));
  },
  watch: {
    // 선택된 CSV 파일 Message / Signal 분류
    async csvData() {
      let message = "";
      let signal = "";
      let obj = {};
      let csvMessageData = [];
      let csvSignalData = [];
      let csvMessageReduce = [];
      await this.csvData[0].forEach((e) => {
        const column = e.split(".")[1];

        if (column != undefined && column != null && column != "") {
          csvMessageData.push(e.split(".")[1]);
          csvSignalData.push(e.split(".")[2]);
        }
      });
      // 동일 Message 삭제
      csvMessageReduce = csvMessageData.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      csvMessageReduce.forEach((o) => {
        obj.message = o;
        obj.signal = [];
        this.csvData[0].forEach((e) => {
          message = e.split(".")[1];
          signal = e.split(".")[2];
          if (o === message) {
            obj.signal.push(signal);
          }
        });
        this.csvObjData.push({ ...obj });
      });
      console.log("[...this.csvObjData :: ", this.csvObjData);
      let endTime = "";
      this.csvData.forEach((e) => {
        //console.log("index :: ", index);
        if (e[0] != "") {
          endTime = e[0];
        }
      });
      this.timelineStartTime = 0;
      this.timelineEndTime = parseFloat(endTime).toFixed(3) * 1000;
      this.timeDifference = this.timelineEndTime;

      this.searchMessageData = [...this.csvObjData];
    },
    async gpsData() {
      let indexGPS = [];
      let indexLength = this.gpsData[0].length;
      let timestamps = [];
      let values = [];
      let tblData = [];
      let gpsMapData = [];
      let gpsMapTimestamps = [];
      let gpsDataArray = [];

      this.gpsData[0].forEach((item, index) => {
        const itemSplit = item.split(".")[2] ? item.split(".")[2] : item;
        if (
          itemSplit.toUpperCase().includes("GPS_X") ||
          itemSplit.toUpperCase().includes("GPS_Y")
        ) {
          indexGPS.push(index);
        }
      });

      this.gpsData.forEach((item, index) => {
        let cnt = 0;
        let timestampsTemp = [];
        let valuesTemp = [];
        let gpsMapDataTemp = [];
        let gpsCnt = 0;
        let splitItem = [];
        if (item.length === indexLength) {
          item.forEach((e, i) => {
            if (index === 0) {
              const itemSplit = e.split(".")[2] ? e.split(".")[2] : e;
              splitItem.push(itemSplit);
            }
            if (index > 0) {
              if (e === "" || e === null || e === undefined) {
                cnt++;
              }

              if (i === 0) {
                timestampsTemp.push(e);
              }

              if (i > 0) {
                valuesTemp.push(e);
              }

              indexGPS.forEach((g) => {
                if (i === g && (e === "" || e === null || e === undefined)) {
                  gpsCnt++;
                }

                if (i === g) {
                  gpsMapDataTemp.push(e);
                }
              });
            }
          });

          if (index === 0) {
            tblData.push([...splitItem]);
          }

          if ((cnt < indexLength - 1 && index > 0) || valuesTemp.length > 0) {
            timestamps.push(...timestampsTemp);
            values.push([...valuesTemp]);
            tblData.push([...timestampsTemp, ...valuesTemp]);
            if (gpsCnt < indexGPS.length) {
              gpsMapData.push([...gpsMapDataTemp]);
              gpsMapTimestamps.push([timestampsTemp[0]]);

              gpsDataArray.push([...gpsMapDataTemp]);
            }
          }
        }
      });

      const rowOfOrigin = tblData.length;
      const colOfOrigin = tblData[0].length;
      const transposed = Array.from({ length: colOfOrigin }, () =>
        new Array(rowOfOrigin).fill(0)
      );

      for (let i = 0; i < rowOfOrigin; i++) {
        for (let j = 0; j < colOfOrigin; j++) {
          [transposed[j][i]] = [tblData[i][j]]; // destructuring
        }
      }
      const a = [];
      console.log("oooooooooooooo :: ", transposed); // [ [ 1, 4 ], [ 2, 5 ], [ 3, 6 ] ]

      //timestamps.shift();
      transposed.shift();
      for (let i = 0; i < transposed.length; i++) {
        const title = transposed[i].shift();
        const obj = {
          label: title,
          data: transposed[i],
          fill: false,
        };
        a.push(obj);
      }

      this.chartGpsType = "line";
      this.tblGpsDataArray = [...tblData];
      this.datasGpsArray = a;
      this.labelsGpsArray = timestamps;
      this.gpsMapDataTime = gpsDataArray;
      this.gpsMapDataTimestamps = gpsMapTimestamps;
      this.gpsMapDataArray = [...gpsMapTimestamps, ...gpsDataArray];
      const lastData =
        this.gpsMapDataTimestamps[this.gpsMapDataTimestamps.length - 1];

      this.timelineStartTime = 0;
      this.timelineEndTime = parseFloat(lastData).toFixed(3) * 1000;
      this.timeDifference = this.timelineEndTime;
    },

    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },

  methods: {
    allChkMsg() {
      const isChecked = this.isAllChkMsg;
      this.chkSelectedMsgTemp = [];
      this.chkSelectedMsg = [];
      if (isChecked) {
        this.searchMessageData.forEach((e) => {
          this.chkSelectedMsgTemp.push(e.message);
          this.chkSelectedMsg.push(e);
        });
      }
    },
    allChkAddMsg() {
      const isChecked = this.isAllChkAddMsg;
      this.chkSelectedAddMsgTemp = [];
      this.chkSelectedAddMsg = [];
      if (isChecked) {
        this.searchMessageAddData.forEach((e) => {
          this.chkSelectedAddMsgTemp.push(e.message);
          this.chkSelectedAddMsg.push(e);
        });
      }
    },
    allChkSgl() {
      const isChecked = this.isAllChkSgl;
      this.chkSelectedSglTemp = [];
      this.chkSelectedSgl = [];
      if (isChecked) {
        this.searchSignalData.forEach((e) => {
          this.chkSelectedSglTemp.push(e);
          this.chkSelectedSgl.push(e);
        });
      }
    },
    allChkAddSgl() {
      const isChecked = this.isAllChkAddSgl;
      this.chkSelectedAddSglTemp = [];
      this.chkSelectedAddSgl = [];
      if (isChecked) {
        this.searchSignalAddData.forEach((e) => {
          this.chkSelectedAddSglTemp.push(e);
          this.chkSelectedAddSgl.push(e);
        });
      }
    },
    chkMsg(item, index) {
      if (this.chkSelectedMsgTemp.includes(item.message)) {
        this.chkSelectedMsgTemp = this.chkSelectedMsgTemp.filter(
          (e) => e !== item.message
        );
      } else {
        this.chkSelectedMsgTemp.push(item.message);
      }

      let isDuplicated = false;
      this.chkSelectedMsg.forEach((e) => {
        if (e.message === item.message) {
          isDuplicated = true;
        }
      });
      if (isDuplicated) {
        this.chkSelectedMsg = this.chkSelectedMsg.filter(
          (e) => e.message !== item.message
        );
      } else {
        item.idx = index;
        this.chkSelectedMsg.push(item);
      }
    },
    chkSglMsg(item) {
      if (this.chkSelectedSglTemp.includes(item)) {
        this.chkSelectedSglTemp = this.chkSelectedSglTemp.filter(
          (e) => e !== item
        );
      } else {
        this.chkSelectedSglTemp.push(item);
      }

      let isDuplicated = false;
      this.chkSelectedSgl.forEach((e) => {
        if (e === item) {
          isDuplicated = true;
        }
      });
      if (isDuplicated) {
        this.chkSelectedSgl = this.chkSelectedSgl.filter((e) => e !== item);
      } else {
        this.chkSelectedSgl.push(item);
      }
    },
    chkAddMsg(item) {
      if (this.chkSelectedAddMsgTemp.includes(item.message)) {
        this.chkSelectedAddMsgTemp = this.chkSelectedAddMsgTemp.filter(
          (e) => e !== item.message
        );
      } else {
        this.chkSelectedAddMsgTemp.push(item.message);
      }

      let isDuplicated = false;
      this.chkSelectedAddMsg.forEach((e) => {
        if (e.message === item.message) {
          isDuplicated = true;
        }
      });
      if (isDuplicated) {
        this.chkSelectedAddMsg = this.chkSelectedAddMsg.filter(
          (e) => e.message !== item.message
        );
      } else {
        this.chkSelectedAddMsg.push(item);
      }
    },
    chkAddSgl(item) {
      if (this.chkSelectedAddSglTemp.includes(item)) {
        this.chkSelectedAddSglTemp = this.chkSelectedAddSglTemp.filter(
          (e) => e !== item
        );
      } else {
        this.chkSelectedAddSglTemp.push(item);
      }

      let isDuplicated = false;
      this.chkSelectedAddSgl.forEach((e) => {
        if (e === item) {
          isDuplicated = true;
        }
      });
      if (isDuplicated) {
        this.chkSelectedAddSgl = this.chkSelectedAddSgl.filter(
          (e) => e !== item
        );
      } else {
        this.chkSelectedAddSgl.push(item);
      }
    },

    clickAddMsg() {
      const vm = this;
      const arr = [];

      this.isAllChkMsg = false;
      this.searchMessageAddData.push(...this.chkSelectedMsg);

      this.searchMessageData.forEach((e) => {
        let isDuplicated = false;
        vm.chkSelectedMsg.forEach((o) => {
          if (e.message === o.message) {
            isDuplicated = true;
          }
        });
        if (!isDuplicated) arr.push(e);
      });
      this.searchMessageData = [...arr];

      this.chkSelectedMsg = [];
      this.chkSelectedMsgTemp = [];

      //this.searchMessage();
      console.log("this.searchMessageData :: ", this.searchMessageData);
    },
    clickAddSgl() {
      const vm = this;
      const arr = [];

      this.isAllChkSgl = false;
      this.searchSignalAddData.push(...this.chkSelectedSgl);

      this.searchSignalData.forEach((e) => {
        let isDuplicated = false;
        vm.chkSelectedSgl.forEach((o) => {
          if (e === o) {
            isDuplicated = true;
          }
        });
        if (!isDuplicated) arr.push(e);
      });
      this.searchSignalData = [...arr];

      this.chkSelectedSgl = [];
      this.chkSelectedSglTemp = [];
    },

    clickDelMsg() {
      const vm = this;
      const arr = [];
      this.isAllChkAddMsg = false;
      const searchWord = this.$refs.MessageKeyword.value.toUpperCase();

      //this.searchMessageData.push(...this.chkSelectedAddMsg);

      this.chkSelectedAddMsg.forEach((e) => {
        vm.searchMessageData.push(e);
      });

      vm.searchMessageData = [...this.sortList(vm.searchMessageData, 2)];

      this.searchMessageAddData.forEach((e) => {
        let isDuplicated = false;
        vm.chkSelectedAddMsg.forEach((o) => {
          if (e.message === o.message) {
            isDuplicated = true;
          }
        });
        if (!isDuplicated) arr.push(e);
      });
      this.searchMessageAddData = [...arr];

      this.chkSelectedAddMsg = [];
      this.chkSelectedAddMsgTemp = [];
      if (searchWord != "") {
        this.searchMessage();
      }
    },
    clickDelSgl() {
      const vm = this;
      const arr = [];
      this.isAllChkAddSgl = false;
      const searchWord = this.$refs.SignalKeyword.value.toUpperCase();

      //this.searchMessageData.push(...this.chkSelectedAddMsg);

      this.chkSelectedAddSgl.forEach((e) => {
        vm.searchSignalData.push(e);
      });
      vm.searchSignalData = [...this.sortList(vm.searchSignalData, 1)];

      this.searchSignalAddData.forEach((e) => {
        let isDuplicated = false;
        vm.chkSelectedAddSgl.forEach((o) => {
          if (e === o) {
            isDuplicated = true;
          }
        });
        if (!isDuplicated) arr.push(e);
      });
      this.searchSignalAddData = [...arr];

      this.chkSelectedAddSgl = [];
      this.chkSelectedAddSglTemp = [];
      if (searchWord != "") {
        this.searchSignal();
      }
    },
    async selectSignal(e, signal) {
      let isDuplicated = false;

      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active");
      } else {
        e.target.classList.add("active");
      }

      this.signalList.forEach((item, index) => {
        if (item.includes(e.target.textContent)) {
          //isActive = true;
        }
        if (this.signalList.length === 1 && item === "Signal") {
          this.signalList = [];
        }

        if (item === signal) {
          isDuplicated = true;
          this.signalList.splice(index, 1);
          index--;
        }
      });

      if (isDuplicated === false) {
        this.signalList.push(signal);
      } else if (isDuplicated === true && this.signalList.length < 1) {
        this.signalList.push("Signal");
      }
    },
    // Signal 항목 클릭 - 차트 데이터 표시
    async showSignalChart() {
      if (this.searchSignalAddData.length < 1) {
        alert("Signal Data를 선택해 주세요.");
        return false;
      }
      this.buttonColor = "red";
      this.buttonTextColor = "white";
      setTimeout(() => {
        this.buttonColor = "#F2F2F2";
        this.buttonTextColor = "black";
      }, 200);
      const vm = this;
      const loading = vm.$refs.lodingWrap;
      loading.style.display = "block";
      await this.selectSignalChartData();
      loading.style.display = "none";
      this.isSignalListShow = false;
    },
    async selectSignalChartData() {
      return new Promise((resolve) => {
        setTimeout(() => {
          let $index = [0];

          this.csvData[0].forEach((item_01, index_01) => {
            this.searchSignalAddData.forEach((item_02) => {
              if (item_01.includes(item_02)) {
                $index.push(index_01);
              }
            });
          });
          console.log("$index :: ", $index);
          let timestamps = [];
          let values = [];
          let tblData = [];

          this.csvData.forEach((item) => {
            let cnt = 0;
            let timestampsTemp = [];
            let valuesTemp = [];

            item.forEach((o, index) => {
              $index.forEach((e) => {
                if (
                  e === index &&
                  (o === "" || o === null || o === undefined)
                ) {
                  cnt++;
                }

                if (e === index && e === 0 && o != "") {
                  timestampsTemp.push(o);
                }
                if (e === index && e != 0) {
                  valuesTemp.push(o);
                }
              });
            });

            if (cnt < $index.length - 1) {
              timestamps.push(...timestampsTemp);
              values.push(...valuesTemp);
              tblData.push(...timestampsTemp, ...valuesTemp);
            }
            //this.signalChartData.push(arr);
          });
          console.log("timestamps :: ", timestamps);
          console.log("tblData123 :: ", tblData);

          let arr1 = chunk(values, $index.length - 1);
          let arr2 = chunk(tblData, $index.length);
          //console.log("tblData :: ", arr2);
          arr2[0].forEach((item, i) => {
            const itemSplit = item.split(".")[2] ? item.split(".")[2] : item;
            arr2[0][i] = itemSplit;
          });

          this.tblDataArray = arr2;

          //arr1.shift();
          const rowOfOrigin = arr1.length;
          const colOfOrigin = arr1[0].length;
          const transposed = Array.from({ length: colOfOrigin }, () =>
            new Array(rowOfOrigin).fill(0)
          );

          for (let i = 0; i < rowOfOrigin; i++) {
            for (let j = 0; j < colOfOrigin; j++) {
              [transposed[j][i]] = [arr1[i][j]]; // destructuring
            }
          }
          const a = [];
          console.log(transposed); // [ [ 1, 4 ], [ 2, 5 ], [ 3, 6 ] ]
          timestamps.shift();
          for (let i = 0; i < $index.length - 1; i++) {
            const title = transposed[i].shift();
            const obj = {
              label: title.split(".")[2] ? title.split(".")[2] : title,
              data: transposed[i],
              fill: false,
            };
            a.push(obj);
          }
          console.log("tblDataArray !!! :: ", this.tblDataArray);
          this.datasArray = a;
          this.labelsArray = timestamps;
          console.log("datasArray :: ", this.datasArray);
          console.log("labelsArray :: ", this.labelsArray);
          this.chartType = "line";
          resolve();
        }, 1000);
      });
    },

    // Message 항목 클릭 - Signal 항목 표시
    /*
    async selectMessage(message) {
      this.csvObjSelectedData = [];
      this.csvObjData.forEach((item) => {
        if (message === item.message) {
          item.signal.forEach((e) => {
            this.csvObjSelectedData.push(e);
          });
        }
      });

      this.isMessageListShow = false;
      this.messageName = message;
      this.chartType = "line";
      console.log("csvObjSelectedData :: ", this.csvObjSelectedData);
    },
    */
    sortList(list, type) {
      return list.sort(function (a, b) {
        if (type === 1) {
          return a.localeCompare(b);
        } else if (type === 2) {
          return a.message.localeCompare(b.message);
        }
      });
    },
    async selectMessageData() {
      const arr = [];
      this.searchSignalData = [];
      this.searchSignalAddData = [];
      this.searchMessageAddData.forEach((e) => {
        e.signal.forEach((o) => {
          arr.push(o);
        });
      });

      this.searchSignalData = [...this.sortList(arr, 1)];

      console.log("this.searchSignalData :: ", this.searchSignalData);
      //if (this.selectTab != "") this.selectMessage(this.selectTab);
      this.closeModal(1);
    },
    //초기화
    resetCSV() {
      //this.csvData = [];
      this.csvMessageData = [];
      this.csvSignalData = [];
      this.csvMessage = [];
      this.csvMessageReduce = [];
      this.csvSignal = [];
      this.csvObjData = [];
      this.csvObjSelectedData = [];
      this.signalChartData = [];
      this.signalList = ["Signal"];
      this.signalOrgList = [];
      this.searchMessageData = [];
      this.searchSignalData = [];
      this.searchMessageOrgData = [];
      this.searchSignalOrgData = [];
      this.chkSelectedMsg = [];
      this.chkSelectedSgl = [];
      this.chkSelectedMsgTemp = [];
      this.chkSelectedSglTemp = [];
      this.searchMessageAddData = [];
      this.searchSignalAddData = [];
      this.chkSelectedAddMsg = [];
      this.chkSelectedAddSgl = [];
      this.chkSelectedAddMsgTemp = [];
      this.chkSelectedAddSglTemp = [];
    },
    // 데이터 추가 CSV 항목 클릭
    async selectUploadCSV(index, kind) {
      const vm = this;
      let file = null;

      const loading = vm.$refs.lodingWrap;
      if (kind === 1) {
        vm.resetCSV();
        vm.fileList_01 = vm.fileList_01.map((ele) => {
          ele.check = false;
          return ele;
        });
        vm.fileList_01[index].check = true;
        file = vm.fileList_01[index];
        vm.fileName_01 = file.name;
      } else if (kind === 3) {
        vm.fileList_03 = vm.fileList_03.map((ele) => {
          ele.check = false;
          return ele;
        });
        vm.fileList_03[index].check = true;
        file = vm.fileList_03[index];
        vm.fileName_03 = file.name;
      }

      loading.style.display = "block";
      await papa.parse(file, {
        complete: function (results) {
          // executed after all files are complete
          if (kind === 1) {
            vm.csvData = results.data;
            console.log("csvData :: ", vm.csvData);
          } else if (kind === 3) {
            vm.gpsData = results.data;
            console.log("gpsData :: ", vm.gpsData);
          }
          loading.style.display = "none";
        },
      });
      if (kind === 1) {
        this.isUploadlistShow_01 = false;
      } else if (kind === 3) {
        this.isUploadlistShow_03 = false;
      }
    },

    // 영상 추가 Video 항목 클릭
    selectUploadVideo(index) {
      const file = this.fileList_02[index];
      this.fileList_02_check = this.fileList_02_check.map((ele) => {
        ele.check = false;
        return ele;
      });
      this.fileList_02_check[index].check = true;
      if (this.preVideoIndex == index) {
        return false;
      } else {
        this.preVideoIndex = index;
      }

      this.preVideoIndex = index;
      this.preVideoFile = file;
      this.fileName_02 = file.name;
      console.log(file);
      if (file) {
        this.src = URL.createObjectURL(file);
        this.$refs.timelineController.init();
      }
      this.isUploadlistShow_02 = false;
    },
    /*
    timelineSelect(e) {
      this.timelineStartTime =
        e.target.options[e.target.options.selectedIndex].getAttribute(
          "data-startTime"
        );
      this.orgTimelineStartTime = this.timelineStartTime;
      this.timelineEndTime =
        e.target.options[e.target.options.selectedIndex].getAttribute(
          "data-endTime"
        );

      const startTime = this.timelineStartTime;
      const endTime = this.timelineEndTime;

      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const timeDifference = endDate - startDate;
      this.timeDifference = timeDifference;


    },
    */
    createUrl(file) {
      const url = URL.createObjectURL(file);
      console.log("URL :: =>   ", url);
    },
    onClick(index) {
      if (index == 1) {
        this.$refs.fileInput_01.click();
      } else if (index == 2) {
        this.$refs.fileInput_02.click();
      } else if (index == 3) {
        this.$refs.fileInput_03.click();
      }
    },
    onDragenter(index) {
      if (index == 1) {
        this.isDragged_01 = true;
      } else if (index == 2) {
        this.isDragged_02 = true;
      } else if (index == 3) {
        this.isDragged_03 = true;
      }
    },
    onDragleave(index) {
      if (index == 1) {
        this.isDragged_01 = false;
      } else if (index == 2) {
        this.isDragged_02 = false;
      } else if (index == 3) {
        this.isDragged_03 = false;
      }
    },
    onDragover(event) {
      // 드롭을 허용하도록 prevetDefault() 호출
      event.preventDefault();
    },
    onDrop(event, index) {
      // 기본 액션을 막음 (링크 열기같은 것들)
      event.preventDefault();
      if (index == 1) {
        this.isDragged_01 = false;
      } else if (index == 2) {
        this.isDragged_02 = false;
      } else if (index == 3) {
        this.isDragged_03 = false;
      }
      const files = event.dataTransfer.files;
      this.addFiles(files, index);
    },
    async onFileChange(event, index) {
      const $files = event.target.files;
      const $id = event.target.id;
      const $fileCnt = $files.length;

      if (
        ($id == "file_input_01" &&
          this.fileList_01.length + $fileCnt > this.maxFileUploadLength) ||
        ($id == "file_input_02" &&
          this.fileList_02.length + $fileCnt > this.maxFileUploadLength) ||
        ($id == "file_input_03" &&
          this.fileList_03.length + $fileCnt > this.maxFileUploadLength) ||
        $files.length > this.maxFileUploadLength
      ) {
        alert("파일 업로드 갯수는 3개를 초과하실 수 없습니다.");
        return false;
      }
      {
        this.$refs.lodingWrap.style.display = "block";
        await this.addFiles($files, index);
        this.$refs.lodingWrap.style.display = "none";
      }
    },
    async addFiles(files, index) {
      for (let i = 0; i < files.length; i++) {
        const src = await this.readFiles(files[i]);
        files[i].src = src;
        if (index === 1) {
          files[i].check = false;
          this.fileList_01.push(files[i]);
        } else if (index === 2) {
          this.fileList_02.push(files[i]);
          this.fileList_02_check.push({});
        } else if (index === 3) {
          files[i].check = false;
          this.fileList_03.push(files[i]);
        }
      }
    },
    // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
    async readFiles(files) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(files);
      });
    },
    handleRemove(e, index, listIndex) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      if (listIndex == 1) {
        this.fileList_01.splice(index, 1);
      } else if (listIndex == 2) {
        this.fileList_02.splice(index, 1);
      } else if (listIndex == 3) {
        this.fileList_03.splice(index, 1);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;

      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },

    /**************
     * select0 box
     **************/
    async getOemSelect() {
      try {
        const response = await common.apiGet(
          "/edge-device-group/domain/oem/vehicle/1"
        );
        const o = [];
        response.data.data.forEach((e) => {
          o.push({ id: e.id, value: e.name });
        });
        this.oemOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/1");
      }
    },
    async getVehicleSelect(id) {
      try {
        const o = [];
        if (id != 0) {
          const response = await common.apiGet(
            "/edge-device-group/domain/oem/vehicle/" + id
          );
          response.data.data.forEach((e) => {
            o.push({ id: e.id, value: e.name });
          });
        }
        this.vehicleOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/" + id);
      }
    },
    async getDriPrjListData(prjId, edgeId) {
      try {
        const _pjtName = encodeURIComponent(prjId);
        const _edgeDevId = encodeURIComponent(edgeId);

        // console.log(_startD)
        const response = await common.apiGet(
          "/driving-record/project/" + _pjtName + "/" + _edgeDevId
        );
        if (response.status === 200 || response.status === "200") {
          //this.carOption = response.data.car_numbers;
          this.timelineOption = response.data.data;
        }

        console.log("dddddddddd :: ", this.timelineOption);

        // ?start-time=2022-12-10%2000%3A00&end-time=2023-11-10%2000%3A00

        // console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async apiCallMando() {
      // init
      //this.initAnomaly();

      const vm = this;

      // $drivReclistBox.style.display = 'block';

      // 로딩 시작
      vm.$refs.lodingWrap.style.display = "block";

      // OEM 선택
      await this.getOemSelect();

      // 로딩 시작 끝
      vm.$refs.lodingWrap.style.display = "none";
    },

    // 프로젝트정보 , 부가기능
    async hideProjectInfo() {
      // 프로젝트 정보 - 접기 , 펼치기
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },
    hideUtils() {
      // 부가기능 - 보이기 숨기기
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },
    async clipCoppy() {
      // 공유하기
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.error(error);
      }
    },
    async taskModify() {
      // 수정
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddVehicleTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async getPrjData(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjDetailData(prjId, edgeId) {
      try {
        const _pjtName = encodeURIComponent(prjId);
        const _edgeDevId = encodeURIComponent(edgeId);
        const response = await common.apiGet(
          "/edge-device/" + _pjtName + "/" + _edgeDevId
        );

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    uploadList(e, index) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      if (index === 1) {
        this.isUploadlistShow_01 === true
          ? (this.isUploadlistShow_01 = false)
          : (this.isUploadlistShow_01 = true);
      } else if (index === 2) {
        this.isUploadlistShow_02 === true
          ? (this.isUploadlistShow_02 = false)
          : (this.isUploadlistShow_02 = true);
      } else if (index === 3) {
        this.isUploadlistShow_03 === true
          ? (this.isUploadlistShow_03 = false)
          : (this.isUploadlistShow_03 = true);
      } else if (index === 4) {
        this.isMessageListShow === true
          ? (this.isMessageListShow = false)
          : (this.isMessageListShow = true);
      } else if (index === 5) {
        this.isSignalListShow === true
          ? (this.isSignalListShow = false)
          : (this.isSignalListShow = true);
      } else if (index === 6) {
        this.isUploadlistShow_04 === true
          ? (this.isUploadlistShow_04 = false)
          : (this.isUploadlistShow_04 = true);
      } else if (index === 7) {
        this.isUploadlistShow_05 === true
          ? (this.isUploadlistShow_05 = false)
          : (this.isUploadlistShow_05 = true);
      }
    },
    showModal(index) {
      let $modal = null;
      if (index === 1) {
        this.modalMgsCnt++;

        $modal = this.$refs.modalWrap_01;
        this.$refs.MessageKeyword.value = "";
        this.selectTab = this.messageName;
        if (this.modalMgsCnt === 1) {
          this.searchMessageData = [...this.sortList(this.csvObjData, 2)];
          this.searchMessageOrgData = this.searchMessageData;
        }
      } else if (index === 2) {
        this.modalSglCnt++;
        $modal = this.$refs.modalWrap_02;
        if (this.modalSglCnt === 1) {
          this.signalOrgList = [...this.signalList];
          this.searchSignalOrgData = this.searchSignalData;
        }
      }

      $modal.style.display = "block";
    },
    closeModal(index) {
      let $modal = null;
      if (index === 1) {
        $modal = this.$refs.modalWrap_01;
      } else if (index === 2) {
        $modal = this.$refs.modalWrap_02;
      }
      $modal.style.display = "none";
    },
    signalDel(index) {
      this.signalOrgList.splice(index, 1);
    },
    signalDelEnd() {
      this.signalList = [...this.signalOrgList];
      this.closeModal(2);
    },
    clickTab(message) {
      this.selectTab = message;
    },
    searchMessage() {
      const searchWord = this.$refs.MessageKeyword.value.toUpperCase();
      const searchData = [];
      //const orgData = this.searchMessageOrgData;

      if (searchWord != "") {
        this.searchMessageData = this.nonSearchMessage();
        this.searchMessageData.forEach((item) => {
          if (item.message.includes(searchWord)) searchData.push(item);
        });
        this.searchMessageData = [...searchData];
      } else {
        this.searchMessageData = this.nonSearchMessage();
      }

      //console.log("searchData : ", searchDat0a);
    },
    searchSignal() {
      const searchWord = this.$refs.SignalKeyword.value.toUpperCase();
      const searchData = [];
      //const orgData = this.searchMessageOrgData;

      if (searchWord != "") {
        this.searchSignalData = this.nonSearchSignal();
        this.searchSignalData.forEach((item) => {
          if (item.includes(searchWord)) searchData.push(item);
        });
        this.searchSignalData = [...searchData];
      } else {
        this.searchSignalData = this.nonSearchSignal();
      }

      //console.log("searchData : ", searchDat0a);
    },
    nonSearchMessage() {
      const arr = [];
      this.searchMessageOrgData.forEach((e) => {
        let isArrayExist = false;
        this.searchMessageAddData.forEach((o) => {
          if (e.message === o.message) {
            isArrayExist = true;
          }
        });
        if (!isArrayExist) {
          arr.push(e);
        }
      });
      return arr;
    },
    nonSearchSignal() {
      const arr = [];
      this.searchSignalOrgData.forEach((e) => {
        let isArrayExist = false;
        this.searchSignalAddData.forEach((o) => {
          if (e === o) {
            isArrayExist = true;
          }
        });
        if (!isArrayExist) {
          arr.push(e);
        }
      });
      return arr;
    },
    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_info {
  .item_title {
    .btn_fold {
      right: 90px;
    }
  }
}

.moa_dete {
  overflow: hidden;
  .filter_box {
    .select_box {
      float: left;
      select {
        width: 185px;
        background-position: right 10px center;
        &:last-child {
          margin: 0;
        }
      }
    }
    .date_filter {
      float: right;
      position: relative;
      & select {
        position: relative;
        background-position: right 10px center;
      }
      display: flex;
      align-items: center;
      .search_box {
        margin-right: 10px;
        input[type="text"] {
          width: 210px;
          border: 1px solid #d5dae3;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 10px 15px;
          font-size: 15px;
          color: rgb(2, 10, 10);
        }
      }
    }
  }
  .float_box {
    width: 100%;
    float: left;
    margin-top: 20px;
    .log_filter {
      float: left;
      width: 300px;
      height: 100%;
      background-color: #fff;
      margin-right: 20px;
      box-sizing: border-box;
      position: relative;
    }
    .log_data_box {
      // width: 300px;
      // height: 479px;
      margin-right: 20px;
      background-color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      position: relative;
      .mando_table_wrap {
        .mando_table {
          position: static;
        }
        .list_wrap {
          .table_list {
            box-shadow: none;
            .list_table_footer {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .status_box {
    height: 144px;
    margin: 20px 0;
    & > div {
      height: 100%;
      float: left;
      position: relative;
      &.status {
        // width: 303px;
        width: 412px;
        button {
          width: 64px;
          height: 40px;
          box-sizing: border-box;
          border: 1px solid $disable02Color;
          border-radius: 10px;
          position: absolute;
          top: 15px;
          right: 15px;
        }
        &:hover {
          &.normal {
            button {
              background-color: $okColor;
              color: #fff;
              border: none;
            }
          }
          &.warning {
            button {
              background-color: #eabe6e;
              color: #fff;
              border: none;
            }
          }
          &.danger {
            button {
              background-color: $failedColor;
              color: #fff;
              border: none;
            }
          }
        }
        div {
          box-sizing: border-box;
          // padding: 40px 0 0 20px;
          padding: 40px 0 0 40px;
          span {
            color: #6e7380;
            display: block;
          }
          strong {
            display: block;
            font-size: 30px;
            line-height: 52px;
          }
        }
        &.normal {
          strong {
            color: $okColor;
          }
        }
        &.warning {
          strong {
            color: #eabe6e;
          }
        }
        &.danger {
          strong {
            color: $failedColor;
          }
        }
      }
      &.time_distance {
        // width: 630px;
        width: 412px;
        margin: 0 20px;
        box-sizing: border-box;
        padding: 30px 0;
        .float_box {
          & > div {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 10px 0 0 40px;
            span {
              color: #6e7380;
              display: block;
            }
            strong {
              font-size: 30px;
              line-height: 52px;
              font-family: $montserrat;
              font-weight: 500;
            }
            sup {
              font-family: $montserrat;
              display: inline-block;
              margin-left: 5px;
            }

            // &:last-child{
            //     border-left: 1px solid $line02Color;
            // }
          }
        }
      }
      &.update {
        // width: 303px;
        width: 412px;
        box-sizing: border-box;
        // padding: 20px 0 10px 15px;
        padding: 20px 0 10px 40px;
        strong {
          font-weight: $bold;
        }
        p {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .img_box {
          width: 14px;
          height: 14px;
          display: inline-block;
          position: relative;
          top: 3px;
          margin-right: 5px;
          background-image: url($baseURL + "/common/refresh_icon.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
        span {
          color: $disable01Color;
          font-size: 13px;
        }
        .toggle_box {
          position: absolute;
          top: 15px;
          right: 20px;
        }
      }
    }
  }
  .result_box {
    width: 65%;

    h2 {
      text-align: center;
      font-size: 20px;
      padding: 26px 0 10px;
      font-weight: 800;
    }
    .title_box {
      position: relative;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 15px;
      strong {
        font-weight: $bold;
      }
    }
    .mando_table {
      tr {
        &:hover {
          background: none;
        }
      }
      td {
        padding: 25px 20px;
      }
      .result {
        font-size: 25px;
        font-weight: 700;
        font-family: $montserrat;
        &.ok {
          color: $okColor;
        }
        &.ng {
          color: $failedColor;
        }
      }
    }
  }
  .result_box2 {
    float: right;
    width: 23%;
    height: 414px;

    h2 {
      text-align: center;
      font-size: 20px;
      padding: 26px 0 10px;
      font-weight: 800;
    }
    .title_box {
      position: relative;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 15px;
      strong {
        font-weight: $bold;
      }
    }
    .mando_table {
      tr {
        &:hover {
          background: none;
        }
      }
      td {
        padding: 25px 20px;
      }
      .result {
        font-size: 25px;
        font-weight: 700;
        font-family: $montserrat;
        &.ok {
          color: $okColor;
        }
        &.ng {
          color: $failedColor;
        }
      }
    }
  }
  .sub_data_box {
    margin-top: 20px;
    & > div {
      width: 304px;
      height: 308px;
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      .title_box {
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 15px;
        strong {
          font-weight: $bold;
        }
      }
      &.anomaly_status {
        .float_box {
          width: 100%;
          height: 255px;
          box-sizing: border-box;
          padding: 0 10px 10px 10px;
          & > div {
            width: 137px;
            height: 119px;
            float: left;
            text-align: center;
            background-color: $line01Color;
            box-sizing: border-box;
            border-radius: 10px;
            color: $whiteColor;
            box-sizing: border-box;
            padding-top: 50px;
            cursor: pointer;
            &:nth-child(1) {
              margin-right: 10px;
              margin-bottom: 10px;
            }
            &:nth-child(2) {
              margin-bottom: 10px;
            }
            &:nth-child(3) {
              margin-right: 10px;
            }
            &.normal {
              &.active {
                background-color: #00b96b;
              }
            }
            &.mild {
              &.active {
                background-color: #4791ff;
              }
            }
            &.caution {
              &.active {
                background-color: #eabe6e;
              }
            }
            &.servere {
              &.active {
                background-color: #f4514d;
              }
            }
          }
        }
      }
      &.anomaly_count {
        ul {
          width: 100%;
          height: 255px;
          li {
            width: 100%;
            height: 63px;
            border-bottom: 1px solid $line01Color;
            box-sizing: border-box;
            padding: 20px 15px;
            vertical-align: middle;
            position: relative;
            .icon {
              width: 7px;
              height: 7px;
              display: inline-block;
              border-radius: 7px;
              vertical-align: middle;
            }
            & > span {
              display: inline-block;
              border-radius: 7px;
              vertical-align: middle;
              margin-left: 10px;
            }
            .txt_box {
              display: inline-block;
              position: absolute;
              top: 20px;
              right: 30px;
              strong {
                font-size: 20px;
                font-family: $montserrat;
                font-weight: 500;
              }
              span {
                display: inline-block;
                margin-left: 4px;
              }
            }
            &:nth-child(1) {
              .icon {
                background-color: #00b96b;
                box-shadow: 0px 0px 10px #00b96b;
              }
            }
            &:nth-child(2) {
              .icon {
                background-color: #4791ff;
                box-shadow: 0px 0px 10px #4791ff;
              }
            }
            &:nth-child(3) {
              .icon {
                background-color: #eabe6e;
                box-shadow: 0px 0px 10px #eabe6e;
              }
            }
            &:nth-child(4) {
              .icon {
                background-color: #f4514d;
                box-shadow: 0px 0px 10px #f4514d;
              }
            }
          }
        }
      }
      &.anomaly_history {
        position: relative;
        .detail_btn {
          position: absolute;
          top: 7px;
          right: 15px;
          padding: 14px 20px 10px 10px;
          background-image: url($baseURL + "common/external_link.svg");
          background-repeat: no-repeat;
          background-position-x: right;
          background-position-y: center;
        }
        .mando_table_wrap {
          height: 255px;
          .item.active {
            background-color: #edfbff;
          }
        }
      }
      &.dtc_map {
        overflow: hidden;
        .map {
          width: 100%;
          height: 255px;
          background-color: #ff0;
        }
      }
    }
  }
}

#modalWrap {
  .message_list,
  .signal_list {
    height: 70%;
    padding: 20px;
    .float_box {
      .table_title {
        position: absolute;
        top: 84px;
        right: 0;
        width: 520px;

        strong {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    .filter_box {
      width: 430px;
    }
  }
  .message_list {
    width: 1150px;
    .table_list {
      height: calc(100% - 182px);
      .mando_table {
        tr {
          height: 50px;
          th {
            text-align: left;
          }
          td {
            cursor: pointer;
            &.active {
              background-color: #12a1d5;
              span {
                color: #fff;
              }
            }
            &.no_data {
              cursor: default;
              div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
  .signal_list {
    .table_list {
      height: calc(100% - 120px);
      .mando_table {
        th {
          &:first-child {
            //text-align: left;
          }
        }
        .btn_del {
          padding: 8px 16px;
        }
      }
    }
  }
  .float_box {
    height: auto;
  }
  .filter_modal {
    width: 1200px;
    height: 690px;
    padding: 25px;
    box-sizing: border-box;
    #recentFilter {
      display: none;
      width: 100%;
      min-height: 70px;
      border: 1px solid $line01Color;
      border-radius: 10px;
      box-sizing: border-box;

      .filter_list_box {
        width: 100%;
        height: 100%;
        position: relative;

        .float_box {
          width: 100%;
          box-sizing: border-box;
          position: relative;
          margin-top: 0;
          padding: 5px 10px;
          li {
            display: inline-block;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            border-radius: 10px;
            padding: 10px 30px 10px 15px;
            position: relative;
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
          .btn_close_item {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 9px;
            right: 4px;
            &::before {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(45deg);
            }
            &::after {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .list_wrap {
      width: 100%;
      height: 570px;

      //스크롤 기능 추가
      // overflow: auto;
      .table_list {
        box-shadow: none;
        border: 1px solid $line01Color;
        .filter_box {
          padding: 10px 20px;
        }
        .date_filter {
          padding-left: 20px;
          top: 10px;
          right: 20px;
          &:after {
            display: none;
          }
        }
        .total_num {
          box-sizing: border-box;
          padding: 20px;
          padding-bottom: 0;
          font-size: $fontSub;
          span {
            color: $mainColor;
            font-weight: $bold;
            font-size: $fontSub;
          }
        }
      }
      .mando_table_wrap {
        .mando_table {
          position: relative;
        }
      }
    }
  }
  .mando_table_wrap {
    height: 450px;
  }
  .btn_filter {
    display: none;
  }
}
.file_container {
  position: relative;
  & label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -40px;
    right: 0;
    width: 80px;
    height: 30px;
    color: #fff;
    background-color: #00b4ed;
    cursor: pointer;
  }
  .file_upload_container {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 15px;
    color: #9ea5b2;
    border: 1px solid #d5dae3;
    background-color: #fff;
    cursor: pointer;
    .file-upload {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      width: 82%;
      height: 100%;
    }
    button {
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translateY(-50%);
      width: 50px;
      height: 90%;
      font-size: 20px;
      color: #fff;
      background-color: #00b4ed;
      border-radius: 6px;
      &.active {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .file_upload_list {
    top: calc(100% + 5px);
    width: 100%;
    height: 82px;
    overflow: auto; /* 세로 스크롤만 나타남 */
    // background-color: #fff;
    border: 1px solid #d5dae3;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 9;
    &.message_list {
      max-height: 122px;

      overflow: auto;
    }
    .file_upload_list__item {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 40px;
      padding-left: 13px;
      padding-right: 42px;
      &:first-child {
        &:hover {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      &:last-child {
        &:hover {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &:not(.data_empty) {
        cursor: pointer;
        &:hover,
        &.active {
          background-color: #00b4ed;
          .file_upload_list__item__data-name {
            color: #fff;
          }
        }
      }
      .file_upload_list__item__btn_remove {
        position: absolute;
        right: 3px;
        width: 45px;
        height: 85%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #dc7171;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
  .file_upload_input {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: -9999px;
  }
}
.timeline_video_wrap {
  position: relative;
  width: calc(100% - 320px) !important;
  margin: 0 0 20px !important;
  .timeline_controller_wrap {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    & > div {
      height: 100%;
    }
  }
  .video_controller_wrap {
    width: 490px;
    height: 276px;
    padding: 16px 15px;
    margin: 0;
    .video_wrap {
      height: 100%;
    }
  }
}
.map_controller_wrap {
  float: right;
  width: 293px;
  height: 472px;
  padding: 6px;
  box-sizing: border-box;

  .map {
    height: calc(100% - 40px);
  }
}
.map_chart_wrap {
  width: calc(100% - 320px) !important;
  margin: 0 !important;
  .title_box {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 20px;
    strong {
      font-weight: 700;
    }
  }
}
.table_chart_wrap {
  display: flex;
  gap: 20px;
  width: 100%;
  position: relative;
  top: 20px;
}
.table_controller_wrap {
  width: 50%;
  height: 400px;
  padding-top: 20px;
  box-sizing: border-box;
  //overflow: auto;
  .title_box {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .table {
    height: 340px;
    overflow-y: auto;
  }
  &.table_gps {
    width: calc(100% - 820px) !important;
    margin: 0 !important;
  }
}
.chart_controller_wrap {
  width: 100%;
  .title_box {
    padding: 20px 20px 0;
  }
  .chart {
    height: 360px;
    padding-top: 0;
  }
  &.chart_gps {
    width: 800px !important;
    margin: 0 !important;
  }
}

.flex {
  display: flex;
}
.flex_box {
  width: 27%;
  margin: 3%;
}
.title_ea {
  margin: 1%;
  color: #00b4ed;
  font-size: 111%;
}
</style>
