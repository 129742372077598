<template>
  <div class="chart">
    <canvas id="myChart" ref="myChart" width="100%" height="100%"> </canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomoPlugin from "chartjs-plugin-zoom";
import { toRaw } from "vue";

Chart.register(zoomoPlugin);
export default {
  name: "ChartController",
  computed: {},
  props: {
    labelsArray: {
      type: Array,
      default: null,
    },
    datasArray: {
      type: Array,
      default: null,
    },
    chartTitle: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "line",
    },
  },
  data() {
    return {
      chartApiCall: null,
      chart: null,
    };
  },
  watch: {
    datasArray() {
      try {
        const existing_chart = this.chartApiCall;

        //existing_chart.destroy();
        toRaw(existing_chart).destroy();
        //this.renderChart()
        console.log("trying to destory then redraw chart");
        this.createChart();
      } catch (e) {
        console.log("chart does not exist yet to destroy");
      }
    },
  },
  created() {},
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const vm = this;
      vm.chart = this.$refs.myChart;
      console.log("ddddddddddddddd :: ", vm.labelsArray);
      console.log("mmmmmmmmmmmmm :: ", vm.datasArray);
      this.chartApiCall = new Chart(vm.chart, {
        type: vm.chartType,
        data: {
          labels: vm.labelsArray,
          datasets: vm.datasArray,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: "top",
              //display: false,
            },
            title: {
              display: true,
              text: this.chartTitle,
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "xy",
              },
              pan: {
                enabled: true,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    addData(chart, label, newData) {
      chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(newData);
      });
      chart.update();
    },
    removeData(chart) {
      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      chart.update();
    },
  },
};
</script>
<style scoped lang="scss">
.chart {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
