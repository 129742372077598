<template>
  <DashBoardComp ref="dashboard" :style="{ marginTop: '5px', marginBottom: '5px' }" ></DashBoardComp>
  <!-- The AG Grid component -->
  <!--  <div style="padding: 20px"> -->
  <!--    <button @click="addData()" border="1">AddData@GridComp</button> -->
  <!--  </div> -->
  <!--  <div style="padding: 20px">-->
  <!--    <button @click="clearTableData" border="1">clearTableData@GridComp</button>-->
  <!--  </div>-->
  <div class="exp_button">
    <button @click="exportCSV" class="btn">Export to CSV</button>
  </div>
  <ag-grid-vue
      :rowData="rowData"
      :columnDefs="colDefs"
      :theme="theme"
      style="height: 500px; width: 1250px;"
      @grid-ready="onGridReady"
      ref="agGrid">
  </ag-grid-vue>
</template>

<script>
import {reactive, ref} from 'vue';
import { AgGridVue } from "ag-grid-vue3";
import axios from 'axios';

import { AllCommunityModule, ModuleRegistry, themeBalham } from 'ag-grid-community';
import DashBoardComp from "@/views/componentType/DashBoardComp.vue";
import conf from "@/assets/js/conf";
ModuleRegistry.registerModules([AllCommunityModule]);

export default {
  name: "GridComponent",
  components: {
    DashBoardComp,
    AgGridVue,
  },
  data () {
    return {}
  },
  setup() {
    const colDefs = reactive([
      { field: "Project" },
      { field: "ObjectText" },
      { field: "Category" },
      { field: "Domain" },
      { field: "TypeLLM" },
      { field: "CategoryLLM" },
    ]);

    const rowData = reactive([]);

    let gridApi = null;
    const onGridReady = (params) => {
      gridApi = params.api;
    };

    const callAPI = async (row, payload) => {
      // const API = axios.create({
      //   baseURL: 'http://172.20.102.115:8000',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     "Accept": "application/json"
      //   },
      // });
      // const response = await API.post('/categorization/statement', payload);

      const baseURL = conf.getAIDTBaseURL();
      const url = baseURL + "/categorization/statement";
      const headerParams = {
        "Content-Type": "application/json",
        "Accept": "application/json"
      };
      const response = await axios.post(url, payload, {headers: headerParams});

      row.TypeLLM = response.data.content;

      return row;
    }

    // param_row sample
    const addData = async (row) => {
      const payload = {
        "messages":
            [
              {
                "content": "A heading is not an explanatory sentence. A heading must be a single-line sentence that represents the title of a section or document. customer_requirement is paragraph usually sentences with two or more lines that describes the vehicle parts requirements. A comment consists of one or more lines and serves as a note or explanatory sentence. Please classify the paragraph as either a 'Heading', 'Customer requirement' or 'Comment'. Respond with only the category name. Must respond only 15characters.",
                "role": "system"
              },
              {
                "role": "user",
                "content": "paragraph \n" + row.ObjectText
              }
            ]
      }
      const newItem = await callAPI(row, payload);
      gridApi.applyTransaction({ add: [newItem] });

      // Status Update "Sentences, LLM Type Result, Heading, Customer requirement, Comment, Accuracy"
      updateStatus(newItem);
    };

    const dashboard = ref(null);
    const updateStatus = (item) => {
      if(item){
        if(dashboard.value) {
          // llm result count
          dashboard.value.set_llmTypeResult();

          // item["Category"] 처리
          if(item["Category"] == "Heading"){
            dashboard.value.set_heading_ref();
          } else if (item["Category"] == "Customer requirement"){
            dashboard.value.set_customerRequirement_ref();
          } else if (item["Category"] == "Comment"){
            dashboard.value.set_comment_ref();
          }
          // 결과값인 item["TypeLLM"] 처리
          if (item["TypeLLM"] == "Heading"){
            dashboard.value.set_heading();
          } else if(item["TypeLLM"] == "Customer requirement"){
            dashboard.value.set_customerRequirement();
          } else if(item["TypeLLM"] == "Comment"){
            dashboard.value.set_comment();
          }
          // success count
          if (item["Category"] == item["TypeLLM"]){
            dashboard.value.set_success_count();
          }
        }
      }
    }

    const clearTableData = () => {
      if(gridApi) {
        // gridApi.setNewRowData([]);
        gridApi.setGridOption('rowData', []);
      }
    }

    const exportCSV = () => {
      if (gridApi) {
        const rowDataCount = gridApi.getDisplayedRowCount();
        if (rowDataCount === 0) {
          alert("Export 데이터가 없습니다.");
          return;
        }
        gridApi.exportDataAsCsv({
          fileName: "export.csv",
          columnSeparator: ",",
          skipColumnHeaders: false,
          onlySelected: false,
        });
      }
    };

    return {
      rowData,
      colDefs,
      onGridReady,
      addData,
      clearTableData,
      updateStatus,
      dashboard,
      theme: themeBalham,
      exportCSV,
    }
  },
  methods: {
    updateStatusDataLength(dataLength){
      // console.log(dataLength);
      const sentences_length = dataLength - 1; // except header
      this.$refs.dashboard.set_sentences_length(sentences_length);
    },
    clearStateData(){
      this.$refs.dashboard.clearStateData();
      this.$refs.dashboard.set_sentances_length_reset();
    }
  }
}
</script>

<style scoped>
.exp_button {
  width: 1240px;
  display: flex;
  justify-content: flex-end;
  padding-right: 70px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.btn {
  display: inline-block;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #c7c7c7;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
}

.btn:hover {
  background-color: #12a1d5;
}

.btn:active {
  transform: scale(0.95);
}
</style>