<template>
  <div v-html="marked(ans_content)"></div>
  <div v-if="sources.length > 0" class="accordion">
    <div v-for="(source, index) in sources" :key="index" class="accordion-item">
      <button class="accordion-header" @click="toggle(index)">
        <span>📑️ {{ source.source + " (page " + source.page + ")" }}</span>
        <span :class="{'rotated': activeIndex === index}" class="arrow">&#9662;</span>
      </button>
      <div v-if="activeIndex === index" class="accordion-content" v-html="marked(source.content)"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { marked } from "marked";

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  ans_content: {
    type: String,
    required: false,
  },
  sources: {
    type: Array,
    required: false,
  }
});

const activeIndex = ref(null);

const toggle = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index;
};
</script>

<style scoped>
.accordion {
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  margin-left: auto; /* 오른쪽 정렬 */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  width: 100%;
  text-align: left;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.accordion-header:hover {
  background: #e0e0e0;
}

.accordion-content {
  padding: 7px;
  background: white;
  border-top: 1px solid #ddd;
}

.arrow {
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(180deg);
}
</style>
