<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Auto Labeling 검토 상세</span>
                </div>
                <h2>[TASK_VALM] {{taskInfo.taskName}}</h2>
            </div> -->
      <BreadcrumbLayout
        pageId="monTASK_taskVAMS"
        :name="'[TASK_VALM] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ pjtData.project_description }}</strong
          >
          <div class="item_info_btn_box">
            <button
              type="button"
              class="btn_fold"
              v-bind:style="infoToggle ? 'display:none' : ''"
              @click="clickToggle()"
            >
              접기
            </button>
            <button
              type="button"
              class="btn_fold active"
              v-bind:style="!infoToggle ? 'display:none' : ''"
              @click="clickToggle()"
            >
              펼치기
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="item_table radiusbox"
          v-bind:style="infoToggle ? 'display:none' : ''"
        >
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ pjtData.project_name }}</td>
                <td>{{ pjtData.aws_id }}</td>
                <td>{{ pjtData.pm }}</td>
                <td>{{ pjtData.project_type }}</td>
                <td>{{ pjtData.team_name }}</td>
                <td>{{ pjtData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ pjtData.project_description }}</div>
          </div>
        </div>
      </div>
      <div id="monCont" class="auto_label">
        <div class="filter_box">
          <select
            name="car"
            id="car"
            style="width: 150px; background-position: 120px center"
            v-model="carSelect"
          >
            <option value="0">차종 선택</option>
            <option v-for="car in carData" :key="car.id" :value="car.id">
              {{ car.value }}
            </option>
          </select>
          <select
            name="point"
            style="width: 150px; background-position: 120px center"
            v-model="pointSelect"
          >
            <option value="0">포인트 선택</option>
            <option
              v-for="point in pointData"
              :key="point.id"
              :value="point.id"
            >
              {{ point.value }}
            </option>
          </select>
          <select
            name="label"
            style="width: 150px; background-position: 120px center"
            v-model="labelSelect"
          >
            <option value="l" selected>Labeled</option>
            <option value="m">Manual</option>
          </select>
          Labeled Result
          <select
            name="result"
            style="width: 90px; background-position: 60px center"
            v-model="resultSelect"
          >
            <option value="fail" selected>실패</option>
            <option value="success">성공</option>
          </select>
          <select
            name="pred"
            style="width: 110px; background-position: 80px center"
            v-model="predSelect"
          >
            <option value="0">Pred 선택</option>
            <option value="ok">OK</option>
            <option value="ng">NG</option>
          </select>
          <div class="date_filter">
            <select
              name="datelist"
              style="width: 110px; background-position: 80px center"
              @change="changeRange"
              v-model="rangeSelect"
            >
              <option value="0" selected>기간선택</option>
              <option value="1">1일</option>
              <option value="7">7일</option>
              <option value="14">14일</option>
              <option value="30">30일</option>
            </select>
            <div class="date_period">
              <div class="date_box">
                <span
                  style="display: inline-block; padding: 0 10px 0 10px"
                  :style="picStartTxtStyle"
                  ref="picStartTxt"
                  >{{ paramData["start_date"] }}</span
                >
                ~
                <span
                  style="display: inline-block; padding: 0 0 0 10px"
                  :style="picEndTxtStyle"
                  ref="picEndTxt"
                  >{{ paramData["end_date"] }}</span
                >
              </div>
              <button
                type="button"
                ref="datePicToggle"
                @click="datePicToggle()"
              ></button>
              <div
                class="calender_box radiusbox"
                ref="calenderBox"
                style="display: none"
              >
                <div
                  class="start_date date_box"
                  ref="startDateBox"
                  @click="onDatePic('S')"
                  style="display: block"
                >
                  <VCalendarUi :dateInterface="'start'" v-model="startDate" />
                  <button type="button" @click="pickerNext()">다음</button>
                </div>
                <div
                  class="end_date date_box"
                  ref="endDateBox"
                  @click="onDatePic('E')"
                >
                  <VCalendarUi
                    :dateInterface="'end'"
                    v-model="endDate"
                    :minDate="this.paramData['start_date']"
                  />
                  <button type="button" @click="pickerPrev()">이전</button>
                  <button type="button" class="mcbtn" @click="getSearch()">
                    조회
                  </button>
                </div>
              </div>
            </div>
            <button type="button" class="btn_send mcbtn" @click="getSearch()">
              조회
            </button>
          </div>
        </div>
        <div class="label_list radiusbox">
          <div class="box_header">
            <strong
              >Total
              <span
                >( {{ saveLableingData.size }}/
                {{ lableingData.length }} )</span
              >건 처리 진행</strong
            >
            <div class="btn_box">
              <!-- <button type="button" @click="autoLabeling">Auto Labeling 실행</button> -->
              <button
                type="button"
                v-if="manageRole"
                class="mcbtn"
                @click="saveManualAll"
              >
                일괄 저장
              </button>
            </div>
          </div>
          <div class="label_item">
            <ul>
              <li v-for="(item, index) in lableingData" :key="index">
                <div class="img_box" @click="showModalItem(item)">
                  <img :src="item.image_path" alt="" />
                </div>
                <div class="item_info">
                  <table>
                    <tbody>
                      <tr>
                        <th style="height: 80px">IMG Name</th>
                        <td style="height: 80px; vertical-align: middle">
                          {{ item.file_name }}
                        </td>
                      </tr>
                      <tr>
                        <th>Legacy</th>
                        <td>{{ item.result }}</td>
                      </tr>
                      <tr>
                        <th>Pred</th>
                        <td>{{ item.pred_class }}</td>
                      </tr>
                      <tr>
                        <th>Auto labeling</th>
                        <td>{{ item.labeled_result }}</td>
                      </tr>
                      <tr v-if="manageRole">
                        <th>Manual</th>
                        <td>
                          <input
                            type="radio"
                            :name="'manual_val' + index"
                            value="ok"
                            v-model="manual_vals[index]"
                            :id="'manualOK' + index"
                            class="manual_OK"
                            @change="changeManual(index)"
                          />
                          <label :for="'manualOK' + index">OK</label>
                          <input
                            type="radio"
                            :name="'manual_val' + index"
                            value="ng"
                            v-model="manual_vals[index]"
                            :id="'manualNG' + index"
                            class="manual_NG"
                            @change="changeManual(index)"
                          />
                          <label :for="'manualNG' + index">NG</label>
                          <input
                            type="radio"
                            :name="'manual_val' + index"
                            value="None"
                            v-model="manual_vals[index]"
                            :id="'manualNOT' + index"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button
                  type="button"
                  v-if="manageRole"
                  :disabled="manual_button[index]"
                  class="btn_save"
                  @click="saveManual(index)"
                >
                  저장
                </button>
              </li>
            </ul>
          </div>
          <div id="detailModal" ref="modalWrap" style="display: none">
            <div class="modal_contents radiusbox" ref="modalItem">
              <strong class="modal_title">상세보기</strong>
              <div class="modal_contents_box">
                <div class="img_box">
                  <div class="detail_img">
                    <img :src="lableingDetailData.image_path" alt="" />
                  </div>
                  <div>
                    <span>{{ lableingDetailData.file_name }}</span>
                  </div>
                </div>
                <div class="info_box">
                  <table>
                    <tbody>
                      <tr>
                        <th>Legacy result</th>
                        <td>
                          {{ lableingDetailData.result }} l
                          {{ lableingDetailData.legacy_time }}
                        </td>
                      </tr>
                      <tr>
                        <th rowspan="2">Inference result</th>
                        <td>
                          {{ lableingDetailData.ml_result }} l
                          {{ lableingDetailData.inference_time }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- 모델 버전<br> -->
                          {{ lableingDetailData.inference_ver }}
                        </td>
                        <!-- <td>{{lableingDetailData.inference_ver}}</td> -->
                      </tr>
                      <tr>
                        <th rowspan="2">
                          Auto labeling<br />
                          result
                        </th>
                        <td>
                          Fail / NG (accuracy) <br />
                          {{ lableingDetailData.autolabeling_time }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- 모델 버전<br> -->
                          {{ lableingDetailData.autolabeling_ver }}
                        </td>
                        <!-- <td>{{lableingDetailData.autolabeling_ver}}</td> -->
                      </tr>
                      <tr>
                        <th rowspan="2">Manual result</th>
                        <td v-if="manageRole">
                          <input
                            type="radio"
                            name="manual_detail_val"
                            value="ok"
                            id="detailManualOK"
                            v-model="manual_val"
                          />
                          <label for="detailManualOK">OK</label>
                          <input
                            type="radio"
                            name="manual_detail_val"
                            value="ng"
                            id="detailManualNG"
                            v-model="manual_val"
                          />
                          <label for="detailManualNG">NG</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- 날짜 <br> -->
                          {{ lableingDetailData.manual_time }}
                        </td>
                        <!-- <td>{{lableingDetailData.manual_time}}</td> -->
                      </tr>
                      <tr>
                        <th>Plant</th>
                        <td>{{ lableingDetailData.plant }}</td>
                      </tr>
                      <tr>
                        <th>Wc</th>
                        <td>{{ lableingDetailData.wc }}</td>
                      </tr>
                      <tr>
                        <th>Op_no</th>
                        <td>{{ lableingDetailData.op_no }}</td>
                      </tr>
                      <tr>
                        <th>차종</th>
                        <td>{{ lableingDetailData.model_no }}</td>
                      </tr>
                      <tr>
                        <th>Vision<br />포인트</th>
                        <td>{{ lableingDetailData.point }}</td>
                      </tr>
                      <tr>
                        <th>Serial</th>
                        <td>{{ lableingDetailData.serial_no }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button
                type="button"
                class="btn_modal_close"
                @click="closeModal()"
              ></button>
              <button
                type="button"
                v-if="manageRole"
                class="mcbtn btn_save"
                :disabled="manualValCheck"
                @click="saveManualDetail(lableingDetailData.uid)"
              >
                저장
              </button>
            </div>
          </div>
        </div>
        <div class="more_btn_box">
          <!-- 20 add -->
          <button type="button" class="btn_call_itme" @click="addLablingData()">
            더보기
          </button>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

import common from "@/assets/js/common";
import dayjs from "dayjs";

export default {
  name: "AutoLabe",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    BreadcrumbLayout,
  },
  data() {
    const paramData = {
      start_date: "",
      end_date: "",
      model_no: "",
      point: "",
      status: "l",
      labeled_result: "fail",
      pred_class: "",
    };

    const saveLableingData = new Set();

    return {
      // project
      prjId: "",
      taskId: "",
      pjtData: {},
      carData: {},
      pointData: {},
      lableingData: {},
      manual_vals: [],
      manual_val: "",
      manual_button: [],
      lableingDetailData: {},

      lableingArray: [],
      saveLableingData,

      paramData,
      carSelect: "0",
      pointSelect: "0",
      labelSelect: "l",
      resultSelect: "fail",
      predSelect: "0",
      rangeSelect: "1",

      listCnt: 12,
      listOffset: 0,

      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      startDate: null,
      endDate: null,

      infoToggle: false,

      taskInfo: {
        taskName: "",
        taskType: "",
      },

      InferenceUtil: false,
      projectId: "",

      manualValCheck: true,

      // role
      viewRole: false,
      manageRole: false,
    };
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  async mounted() {
    // Role
    await this.setRole();

    const stoday = new Date();

    this.paramData["start_date"] = "2023-06-01"; // dayjs(stoday).subtract(1, 'day').format('YYYY-MM-DD');
    this.paramData["end_date"] = dayjs(stoday).format("YYYY-MM-DD");

    this.startDate = this.paramData["start_date"];
    this.endDate = this.paramData["end_date"];

    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();

    this.setTaskInfo();

    this.setFilter();

    this.projectId = this.$route.query.prjId;
  },
  watch: {
    carSelect() {
      if (this.carSelect == "0") {
        this.paramData["model_no"] = "";
      } else this.paramData["model_no"] = this.carSelect;
    },
    pointSelect() {
      if (this.pointSelect == "0") {
        this.paramData["point"] = "";
      } else this.paramData["point"] = this.pointSelect;
    },
    labelSelect() {
      this.paramData["status"] = this.labelSelect;
    },
    resultSelect() {
      this.paramData["labeled_result"] = this.resultSelect;
    },
    predSelect() {
      if (this.predSelect == "0") {
        this.paramData["pred_class"] = "";
      } else this.paramData["pred_class"] = this.predSelect;
    },
    manual_val() {
      if (this.manual_val === "ok" || this.manual_val === "ng") {
        this.manualValCheck = false;
      } else {
        this.manualValCheck = true;
      }
    },
  },
  methods: {
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },

    clickToggle() {
      const toggle = !this.infoToggle;
      this.infoToggle = toggle;
    },

    hideUtils() {
      // 부가기능 - 보이기 숨기기
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },
    async clipCoppy() {
      // 공유하기
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.error(error);
      }
    },
    async taskModify() {
      // 수정
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        console.log(response);
        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },

    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.pjtData = await getPjtData.project;
    },

    changeRange() {
      if (this.rangeSelect == "0") return;

      this.paramData["start_date"] = dayjs(new Date())
        .subtract(this.rangeSelect, "day")
        .format("YYYY-MM-DD");

      this.startDate = this.paramData["start_date"];

      this.setFilter();
    },

    setDetail(item) {
      this.lableingDetailData = item;
      this.manual_val = item.manual_result;
    },

    // ----- API
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async getSearch() {
      let response;
      this.listOffset = 0;

      try {
        const param = {
          start_date: this.paramData["start_date"],
          end_date: this.paramData["end_date"],
          model_no: this.paramData["model_no"],
          point: this.paramData["point"],
          status: this.paramData["status"],
          labeled_result: this.paramData["labeled_result"],
          pred_class: this.paramData["pred_class"],
          list_count: this.listCnt,
          list_offset: this.listOffset,
        };

        response = await common.apiDl2Post("/SF/al_002", param, "F");
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      this.lableingData = response.data.result;
      this.listOffset += this.listCnt;

      console.log(this.lableingData);

      const o = [];
      (this.manual_vals = []),
        (this.manual_button = []),
        this.lableingData.forEach((item) => {
          o.push({ uid: item.uid, result: item.manual_result });
          this.manual_vals.push(item.manual_result);
          this.manual_button.push(true);
        });

      this.lableingArray = o;
      this.saveLableingData = new Set();
    },

    async addLablingData() {
      console.log("더보기 클릭");

      if (this.listOffset == 0) {
        return;
      }

      let response;
      try {
        const param = {
          start_date: this.paramData["start_date"],
          end_date: this.paramData["end_date"],
          model_no: this.paramData["model_no"],
          point: this.paramData["point"],
          status: this.paramData["status"],
          labeled_result: this.paramData["labeled_result"],
          pred_class: this.paramData["pred_class"],
          list_count: this.listCnt,
          list_offset: this.listOffset,
        };

        response = await common.apiDl2Post("/SF/al_002", param, "F");
        const addData = response.data.result;

        if (addData.length == 0) {
          alert("더이상 데이터가 존재하지 않습니다.");
          return;
        }

        this.listOffset += this.listCnt;

        addData.forEach((item) => {
          this.lableingData.push(item);
          this.lableingArray.push({
            uid: item.uid,
            result: item.manual_result,
          });
          this.manual_vals.push(item.manual_result);
          this.manual_button.push(true);
        });
        console.log(this.listOffset);
      } catch (error) {
        console.error("Error fetching data:", error);
        this.listOffset -= this.listCnt;
      }
    },

    async saveManualAll() {
      if (this.manageRole) {
        if (this.saveLableingData.size < 1) return;

        try {
          const param = [];
          const mr_uid_list = [];

          this.saveLableingData.forEach((item) => {
            const o = [];
            o.push(item.manual_result);
            o.push(item.uid);

            mr_uid_list.push(o);
          });

          const request = {
            mr_uid_list: mr_uid_list,
          };

          console.log(param);
          const response = await common.apiDl2Post("/SF/al_003", request, "F");

          console.log(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },

    async saveManual(index) {
      if (this.manageRole) {
        try {
          const o = [];
          const mr_uid_list = [];
          o.push(this.manual_vals[index]);
          o.push(this.lableingData[index]["uid"]);

          mr_uid_list.push(o);

          const request = {
            mr_uid_list: mr_uid_list,
          };

          const response = await common.apiDl2Post("/SF/al_003", request, "F");

          console.log(response);

          this.saveLableingData.delete(this.lableingData[index]);
          this.manual_button[index] = true;
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    async saveManualDetail(uid) {
      if (this.manageRole) {
        try {
          const o = [];
          const mr_uid_list = [];

          o.push(this.manual_val);
          o.push(uid);

          mr_uid_list.push(o);

          const request = {
            mr_uid_list: mr_uid_list,
          };

          const response = await common.apiDl2Post("/SF/al_003", request, "F");

          console.log(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },

    async setFilter() {
      try {
        const set1 = new Set();
        const set2 = new Set();
        const o = [];
        const o2 = [];

        const param = {
          start_date: this.paramData["start_date"],
          end_date: this.paramData["end_date"],
        };

        const response = await common.apiDl2Post("/SF/al_001", param, "F");

        response.data.result.forEach((item) => {
          set1.add(item.model_no);
          set2.add(item.point);
        });

        set1.forEach((item) => {
          o.push({ id: item, value: item });
        });
        set2.forEach((item) => {
          o2.push({ id: item, value: item });
        });
        this.carData = o;
        this.pointData = o2;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    changeManual(index) {
      const o = this.lableingData[index];

      if (this.lableingData[index].manual_result != this.manual_vals[index]) {
        o.manual_result = this.manual_vals[index];
        this.saveLableingData.add(o);
        this.manual_button[index] = false;
      } else {
        this.saveLableingData.delete(this.lableingData[index]);
        this.manual_button[index] = true;
      }
    },

    onDatePic(flag) {
      if (flag == "S") {
        if (this.startDate !== null) {
          this.paramData["start_date"] = dayjs(this.startDate).format(
            "YYYY-MM-DD"
          );
        }
      } else if (flag == "E") {
        if (this.endDate !== null) {
          this.paramData["end_date"] = dayjs(this.endDate).format("YYYY-MM-DD");
        }
      }
    },
    pickerNext() {
      this.$refs.startDateBox.style.display = "none";
      this.$refs.endDateBox.style.display = "block";

      this.picColor.startStatus = false;
      this.picColor.endStatus = true;
      console.log(this.picColor.endStatus);
      console.log(this.picColor.target);
    },
    pickerPrev() {
      this.$refs.startDateBox.style.display = "block";
      this.$refs.endDateBox.style.display = "none";

      this.picColor.startStatus = true;
      this.picColor.endStatus = false;
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;

        this.setFilter();
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        vm.$refs.startDateBox.style.display = "block";
        vm.$refs.endDateBox.style.display = "none";
        console.log(_startDateBox);
      }
    },

    showModalItem(item) {
      this.setDetail(item);
      const $modal = this.$refs.modalWrap;
      const $modalItem = this.$refs.modalItem;
      $modal.style.display = "block";
      $modalItem.style.display = "block";
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalItem = this.$refs.modalItem;
      $modal.style.display = "none";
      $modalItem.style.display = "none";
    },

    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.label_list {
  background-color: $whiteColor;
  box-sizing: border-box;
  padding: 30px 20px;
  margin-top: 14px;
  .box_header {
    height: 40px;
    line-height: 40px;
    position: relative;
    strong {
      font-weight: $bold;
      span {
        color: $mainColor;
      }
    }
    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      border: 1px solid $disable02Color;
      font-size: $fontBasic;
      margin-right: 10px;
      &.mcbtn {
        border: none;
        margin-right: 0;
      }
    }
    .btn_box {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .label_item {
    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }
      li {
        width: 300px;
        height: 552px;
        overflow: hidden;
        // display: inline-block;
        float: left;

        padding: 20px 18px 20px 0;
        border-bottom: 1px solid $line02Color;
        position: relative;
        &::after {
          width: 1px;
          height: 396px;
          background-color: $line02Color;
          content: "";
          display: block;
          position: absolute;
          top: 20px;
          right: 20px;
        }
        &:nth-child(4n) {
          width: 280px;
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
        .img_box {
          width: 280px;
          height: 200px;
          position: relative;
          overflow: hidden;
          background-color: #aaa;
          img {
            width: 100%;
          }
          &::after {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            content: "";
            display: block;
            background-color: rgba(0, 0, 0, 0.8);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url($baseURL + "/common/external_link.svg");
          }
        }
        .item_info {
          width: 280px;
          table {
            width: 100%;

            th {
              width: 30%;
              color: #6e7380;
              vertical-align: middle;
              padding-top: 15px;
            }
            td {
              width: 65%;
              word-break: break-all;
              padding-top: 15px;
              padding-left: 15px;
              input[type="radio"] {
                display: none;
                &.manual_OK + label {
                  border-radius: 10px 0 0 10px;
                  border-right: none;
                }
                &.manual_OK:checked + label {
                  height: 39px;
                  background-color: $okColor;
                  color: $whiteColor;
                  font-weight: $bold;
                  border-color: $okColor;
                }
                &.manual_NG + label {
                  border-radius: 0 10px 10px 0;
                  border-left: none;
                }
                &.manual_NG:checked + label {
                  height: 39px;
                  background-color: $failedColor;
                  color: $whiteColor;
                  font-weight: $bold;
                  border-color: $failedColor;
                }
              }
              label {
                width: 75px;
                height: 40px;
                line-height: 40px;
                display: inline-block;
                text-align: center;
                background-color: $whiteColor;
                border: 1px solid $disable02Color;
              }
            }
          }
          div {
            float: left;
            margin: 10px 0 0;
            overflow-wrap: break-word;
            display: table-cell;
            vertical-align: middle;
            &.item_key {
              width: 35%;
              color: #6e7380;
            }
            &.item_val {
              width: 55%;
              input[type="radio"] {
                display: none;
                &#manualOK + label {
                  border-radius: 10px 0 0 10px;
                  border-right: none;
                }
                &#manualOK:checked + label {
                  background-color: $okColor;
                  color: $whiteColor;
                  font-weight: $bold;
                  border-color: $okColor;
                }
                &#manualNG + label {
                  border-radius: 0 10px 10px 0;
                  border-left: none;
                }
                &#manualNG:checked + label {
                  background-color: $failedColor;
                  color: $whiteColor;
                  font-weight: $bold;
                  border-color: $failedColor;
                }
              }
              label {
                width: 75px;
                height: 40px;
                line-height: 40px;
                display: inline-block;
                text-align: center;
                background-color: $whiteColor;
                border: 1px solid $disable02Color;
              }
            }
            &.item_toggle {
              height: 40px;
              line-height: 40px;
            }
          }
          &::after {
            content: "";
            display: block;
            clear: both;
          }
        }
        .btn_save {
          width: 280px;
          height: 40px;
          border-radius: 10px;
          border: 1px solid $disable02Color;
          background-color: $whiteColor;
          margin-top: 20px;
          font-size: $fontBasic;
          &[disabled] {
            background-color: $line02Color;
            color: $disable01Color;
            border-color: $disable02Color;
          }
        }
      }
    }
  }
}
#detailModal {
  display: none;
  .modal_contents {
    width: 1200px;
    box-sizing: border-box;
    padding: 25px 25px 30px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .img_box {
      width: 700px;
      height: 640px;
      border: 1px solid $disable02Color;
      box-sizing: border-box;
      float: left;
      text-align: center;
      .detail_img {
        padding-top: 50px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
    }
    .info_box {
      width: 450px;
      float: left;
      box-sizing: border-box;
      padding-left: 25px;
      table {
        width: 100%;
        text-align: left;
        th,
        td {
          font-size: $fontBasic;
          border-bottom: 1px solid $line02Color;
        }
        th {
          padding: 10px 20px;
          color: #6e7380;
          vertical-align: middle;
        }
        td {
          padding: 10px 0;
          vertical-align: middle;
        }
      }
      input[type="radio"] {
        display: none;
        &#detailManualOK + label {
          border-radius: 10px 0 0 10px;
          border-right: none;
        }
        &#detailManualOK:checked + label {
          background-color: $okColor;
          color: $whiteColor;
          font-weight: $bold;
          border-color: $okColor;
        }
        &#detailManualNG + label {
          border-radius: 0 10px 10px 0;
          border-left: none;
        }
        &#detailManualNG:checked + label {
          background-color: $failedColor;
          color: $whiteColor;
          font-weight: $bold;
          border-color: $failedColor;
        }
      }
      label {
        width: 75px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        background-color: $whiteColor;
        border: 1px solid $disable02Color;
      }
    }
  }
  .btn_save {
    float: right;
    margin-top: 40px;
  }
}
.auto_label {
  .more_btn_box {
    width: 100%;
    height: 60px;
    .btn_call_itme {
      width: 100%;
      height: 100%;
      line-height: 60px;
      font-weight: $bold;
      text-align: center;
      background-repeat: no-repeat;
      background-image: url($baseURL + "/common/select_arrowdown.svg");
      background-position: 665px 27px;
    }
  }
}
</style>
