<template>
  <HeaderLayout/>
  <GlobalNavBar/>
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="monTASK_taskAIDT" :name="'[TASK_RD] ' + taskData.taskName"/>
      <!-- Contents Here -->
      <TabComponent :tabs="tabs" :defaultActive="2">
        <template #tab1>
          <!-- ⚙️ Chat -->
          <ChatDiagComp></ChatDiagComp>
        </template>
        <template #tab2>
          <!-- ⚙️ REQ PDF Chunking-->
          <DataParseCompForPdfChunk ref="dataCompForPdfChunk"
                                    v-on:callDrawMDText="drawMDTextFunc"
                                    v-on:callClearMdText="clearMdTextFunc"></DataParseCompForPdfChunk>
          <MarkDownView ref="markDownView"></MarkDownView>
        </template>
        <template #tab3>
          <!-- ⚙️ Statement Categorization -->
          <DataParseComp ref="dataParseComp"
                         v-bind:comp_type="props_data.sts_type"
                         v-on:callAddData="addDataFunc"
                         v-on:csvDataLength="csvDataLengthFunc"
                         v-on:callClearTableData="clearTableDataFunc"
                         v-on:callClearStateData="clearStateDataFunc"></DataParseComp>
          <GridComponent ref="gridComp"></GridComponent>
        </template>
        <template #tab4>
          <!-- ⚙️ Requirements Categorization -->
          <DataParseCompForReqCat ref="dataParseCompForReqCat"
                                  v-bind:comp_type="props_data.req_type"
                                  v-on:callAddData="addDataForReqCatFunc"
                                  v-on:csvDataLength="csvDataLengthForReqCatFunc"
                                  v-on:callClearTableData="clearTableDataForReqCatFunc"
                                  v-on:callClearStateData="clearStateDataForReqCatFunc"></DataParseCompForReqCat>
          <GridComponentForReqCat ref="gridCompForReqCat"></GridComponentForReqCat>
        </template>
        <template #tab5>
          <!-- ⚙️ Requirements RAG Chatbot -->
          <RagChatDiagComp></RagChatDiagComp>
        </template>
      </TabComponent>
      <!-- Contents Here end -->
    </div>
  </div>
  <FooterLayout/>
</template>

<script>
import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
import common from "@/assets/js/common";
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";

import TabComponent from "@/views/componentType/TabComponent.vue";
import DataParseComp from "@/views/componentType/DataParseComp.vue";
import GridComponent from "@/views/componentType/GridComponent.vue";
import DataParseCompForReqCat from "@/views/componentType/DataParseCompForReqCat.vue";
import GridComponentForReqCat from "@/views/componentType/GridComponentForReqCat.vue";
import DataParseCompForPdfChunk from "@/views/componentType/DataParseCompForPdfChunk.vue";
import MarkDownView from "@/views/componentType/MarkDownView.vue";
import ChatDiagComp from "@/views/componentType/ChatDiagComp.vue";
import RagChatDiagComp from "@/views/componentType/RagChatDiagComp.vue";
import {reactive} from "vue";

export default {
  name: "AIDigitalTransformation",
  components: {
    RagChatDiagComp,
    DataParseCompForPdfChunk,
    FooterLayout,
    GlobalNavBar,
    HeaderLayout,
    BreadcrumbLayout,
    TabComponent,
    DataParseComp,
    DataParseCompForReqCat,
    GridComponent,
    GridComponentForReqCat,
    MarkDownView,
    ChatDiagComp
  },
  data(){
    const prjId = null;
    const taskId = null;
    const prjData = {
      project_name: "",
      aws_id: "",
      pm: "",
      project_type: "",
      team_name: "",
      last_activity: "",
      project_description: "",
    };
    const taskData = ({});

    const props_data = reactive({
      sts_type: "statement_type",
      req_type: "requirement_type",
    })

    return {
      prjId,
      taskId,
      prjData,
      taskData,
      // role
      viewRole: false,
      manageRole: false,
      props_data
    };
  },
  computed: {
    virtualBoats () {
      return [...Array(10000).keys()].map(i => {
        const boat = { ...this.boats[i % this.boats.length] }
        boat.name = `${boat.name} #${i}`
        return boat
      })
    },
  },
  setup() {
    const tabs = [
      { label: 'Chat', slotName: 'tab1' },
      { label: 'REQ PDF Chunking', slotName: 'tab2' },
      { label: 'Statements Categorization', slotName: 'tab3' },
      { label: 'Requirements Categorization', slotName: 'tab4' },
      { label: 'Requirements RAG Chatbot', slotName: 'tab5' },
    ];

    return {
      tabs
    };
  },
  async mounted(){
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    // const vm = this;

    // Role
    await this.setRole();

    // prj info
    const prjData = await this.getPrjData(this.prjId);
    // console.log("prjData : " + prjData);
    if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
      this.prjData = prjData.project;
    }
    // project info
    this.taskData = await this.getTaskData(this.taskId);

  },
  methods: {
    async getPrjData(prjId){
      try {
        const response = await common.apiGet('/project/' + prjId );
        return response.data.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async getTaskData(taskId){
      try {
        const _taskId = encodeURIComponent(taskId);

        const response = await common.apiGet(`/monitoring-task/${_taskId}?viewRole=${this.viewRole}`);
        // const response = await common.apiGet('/monitoring-task/project/' + _taskId );
        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
      this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type1', prjId) );
      if ( !this.viewRole ) {
        common.goHome();
      }
    },
    addDataFunc(param) {
      this.$refs.gridComp.addData(param);
    },
    addDataForReqCatFunc(param) {
      this.$refs.gridCompForReqCat.addData(param);
    },
    csvDataLengthFunc(dataLength) {
      this.$refs.gridComp.updateStatusDataLength(dataLength);
    },
    csvDataLengthForReqCatFunc(dataLength) {
      this.$refs.gridCompForReqCat.updateStatusDataLength(dataLength);
    },
    clearTableDataFunc() {
      this.$refs.gridComp.clearTableData();
    },
    clearTableDataForReqCatFunc() {
      this.$refs.gridCompForReqCat.clearTableData();
    },
    clearStateDataFunc() {
      this.$refs.gridComp.clearStateData();
    },
    clearStateDataForReqCatFunc() {
      this.$refs.gridCompForReqCat.clearStateData();
    },
    drawMDTextFunc(param) {
      this.$refs.markDownView.drawMDText(param);
    },
    clearMdTextFunc(){
      this.$refs.markDownView.clearMdText();
    }
  },
}
</script>

<style scoped>

</style>