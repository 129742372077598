<template>
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callAddDataTest" border="1">callAddDataTest@FileDragForReqCat</button>-->
  <!--  </div>-->
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callClearTableData" border="1">callClearTableData@FileDragForReqCat</button>-->
  <!--  </div>-->
  <!--  <div style="padding: 5px">-->
  <!--    <button @click="callClearStateData" border="1">callClearStateData@FileDragForReqCat</button>-->
  <!--  </div>-->
  <div
      class="drop-zone"
      @click="openFileDialog"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
      :class="{ 'drag-over': isDragging }"
  >
    <div v-if="!files.length">Drag & Drop files here or click to upload</div>
    <div v-else>
      <p v-for="(file, index) in files" :key="index">
        {{ file.name }} ({{ (file.size / 1024).toFixed(2) }} KB)
      </p>
    </div>
    <input type="file" ref="fileInput" multiple @change="onFileSelect" hidden />
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import papa from "papaparse";

export default {
  name: "FileDragAndDropForReqCat",
  emits: ['callAddData','csvDataLength','callClearTableData', 'callClearStateData'],
  props: ['comp_type'],
  data() {
    return {
    }
  },
  setup(props, { emit }) {
    const files = ref([]);
    const isDragging = ref(false);
    const fileInput = ref(null);
    let csvData = ref([]);

    onMounted(() => {
      fileInput.value = fileInput.value || document.querySelector('input[type="file"]');
    });

    const onDragOver = () => {
      isDragging.value = true;
    };

    const onDragLeave = () => {
      isDragging.value = false;
    };

    const onDrop = (event) => {
      isDragging.value = false;
      handleFiles(event.dataTransfer.files);
    };

    const onFileSelect = (event) => {
      handleFiles(event.target.files);
    };

    const openFileDialog = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleFiles = (fileList) => {
      files.value = [...fileList];
      const file = files.value[0];
      // emit('files-added', files.value);
      csvFileParseAndAddData(file);
    };

    const csvFileParseAndAddData = (file) => {
      papa.parse(file, {
        // header: true,
        skipEmptyLines: true,
        complete: function(results) {
          csvData = results.data;
          // 기존 Data Grid 초기화
          callClearTableData();
          // 기존 State 초기화
          callClearStateData();
          // Call API and Data Insert to Grid
          callAddData(csvData);
        }
      });
    }

    const callAddData = (csvData) => {
      const filteredData = dataFilter(csvData);
      const csvDataLength = filteredData.length;
      emit('csvDataLength', csvDataLength);

      filteredData.forEach((each_row, index) => {
        if (index === 0) return; // header skip
        // console.log(each_row);
        const keys = ["Project", "ObjectText", "Category", "Domain", "CategoryLLM", "CategoryLLMReason"];
        const row = keys.reduce((obj, key, index) => {
          obj[key] = each_row[index];
          return obj;
        }, {});
        emit('callAddData', row);
      })
    }

    const dataFilter = (csvData) => {
      const filterWord = "Customer requirement";
      let filteredData = [csvData[0], ...csvData.slice(1).filter(row => row[2] === filterWord)];
      return filteredData;
    }

    const callAddDataTest = () => {
      const keys = ["Project", "ObjectText", "Category", "Domain", "CategoryLLM", "CategoryLLMReason"];
      const each_row_case = [
        "/Brake/IDB_FORD_GE2",
        "2.6.1 External publications FMVSS101 Controls and Displays FMVSS135 Light Vehicle Brake Systems ECE R13-H Passenger Car Braking VDA Version 3 of Recommendation 305-100",
        "Customer requirement",
        "8-normal",
        "",
        ""
      ];
      // array to json object (map array)
      const row = keys.reduce((obj, key, index) => {
        obj[key] = each_row_case[index];
        return obj;
      }, {});

      emit('callAddData', row);
    }

    const callClearTableData = () => {
      emit('callClearTableData');
    }

    const callClearStateData = () => {
      emit('callClearStateData');
    }

    return {
      files,
      isDragging,
      fileInput,
      onDragOver,
      onDragLeave,
      onDrop,
      onFileSelect,
      openFileDialog,
      callAddData,
      callAddDataTest,
      callClearTableData,
      callClearStateData
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.drop-zone {
  width: 1210px;
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}
.drag-over {
  background: #f0f0f0;
}
</style>
