<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="monTASK_mlopsList" :checkRole="manageRole" />
      <div class="list_wrap">
        <div id="listFilter" class="list_filter_wrap float_box">
          <div class="filter_list_box">
            <select style="min-width: 150px" v-model="pipelineType">
              <option value="0">선택해 주세요.</option>
              <option value="2">ml</option>
              <option value="1">inference</option>
            </select>
            <select style="min-width: 150px" v-model="pipelineTask">
              <option value="">선택해 주세요.</option>
              <option value="dtv01">dtv01</option>
              <option value="dtv02">dtv02</option>
            </select>

            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="search_box">
                <input type="text" v-model="pipelineNm" />
              </div>
              <button type="button" class="btn_send mcbtn" @click="clickSearch">
                검색
              </button>
            </div>
          </div>
        </div>
        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ dataList.length }}</span> Contents
            </div>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <thead>
                <tr>
                  <th>
                    <div class="col_name">Pipeline</div>
                  </th>
                  <th>
                    <div class="col_name">Current<br />Version</div>
                  </th>
                  <th>
                    <div class="col_name">Update Date</div>
                  </th>
                  <th>
                    <div class="col_name">Task</div>
                  </th>
                  <th>
                    <div class="col_name">Type</div>
                  </th>
                  <th>
                    <div class="col_name">Create Date</div>
                  </th>
                  <th>
                    <div class="col_name">Use Y/N</div>
                  </th>
                  <th>
                    <div class="col_name">Monitoring</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataListSliced"
                  :key="index"
                  @click="goDetail(item)"
                >
                  <td>{{ item.pipelineNm }}</td>
                  <td>{{ item.pipelineVersion }}</td>
                  <td>{{ item.updateDate }}</td>
                  <td>{{ item.pipelineTask }}</td>
                  <td>{{ item.pipelineType == 1 ? "inference" : "ml" }}</td>
                  <td>{{ item.createDate }}</td>
                  <td>{{ item.useYn.toUpperCase() }}</td>
                  <td>
                    <button
                      class="mcbtn btn_go"
                      type="button"
                      @click="goMonitoring($event, item)"
                    >
                      이동
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

import common from "@/assets/js/common";
export default {
  name: "fleetGroupList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    PaginationUi,
    VCalendarUi,
    BreadcrumbLayout,
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  warch: {
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },
  data() {
    return {
      perPageList: [],
      perPage: 0,

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      dataList: [],
      dataListSliced: [],

      // role
      viewRole: false,
      manageRole: false,

      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 1825,
      pipelineType: 0,
      pipelineTask: "",
      pipelineNm: "",
    };
  },
  mounted() {
    // Role
    this.setRole();
    this.setDate(this.selectDuration);
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();

    for (const entry of entries) {
      if (entry[0] === "pipelineNm" && entry[1] != null) {
        this.pipelineNm = entry[1];
      } else if (
        entry[0] === "pipelineType" &&
        entry[1] != null &&
        entry[1] != 0
      ) {
        this.pipelineType = entry[1];
      } else if (
        entry[0] === "pipelineTask" &&
        entry[1] != null &&
        entry[1] != ""
      ) {
        this.pipelineTask = entry[1];
      } else if (
        entry[0] === "dateFrom" &&
        entry[1] != null &&
        entry[1] != ""
      ) {
        this.picStartDate = entry[1];
      } else if (entry[0] === "dateTo" && entry[1] != null && entry[1] != "") {
        this.picEndDate = entry[1];
      }
    }
    this.setMlopsList();
  },
  methods: {
    goMonitoring(e, item) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      console.log(item);

      this.$router.push({
        name: "MlopsMonitoring",
        params: {
          id: item.pipelineId,
          version: item.pipelineVersion,
          type: item.pipelineType,
        },
      });
    },
    goDetail(item) {
      const pipelineNm = this.pipelineNm;
      const pipelineType = this.pipelineType;
      const pipelineTask = this.pipelineTask;
      const dateFrom = this.picStartDate;
      const dateTo = this.picEndDate;

      this.$router.push(
        "/monTASK/modMlops?id=" +
          item.pipelineId +
          "&version=" +
          item.pipelineVersion +
          "&pipelineNm=" +
          pipelineNm +
          "&pipelineType=" +
          pipelineType +
          "&pipelineTask=" +
          pipelineTask +
          "&dateFrom=" +
          dateFrom +
          "&dateTo=" +
          dateTo
      );
    },
    async clickSearch() {
      await this.setMlopsList();
    },
    async setRole() {
      this.manageRole = await common.getUserRole("type1");
      this.viewRole = await common.getUserRole("type1");

      if (!this.viewRole) {
        common.goHome();
      }
    },

    async setMlopsList() {
      const pipelineNm = this.pipelineNm;
      const pipelineType = this.pipelineType;
      const pipelineTask = this.pipelineTask;
      const dateFrom = this.picStartDate;
      const dateTo = this.picEndDate;

      const param = {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
      if (pipelineNm !== "") {
        param.pipelineNm = pipelineNm;
      }

      if (pipelineType != 0) {
        param.pipelineType = pipelineType;
      }

      if (pipelineTask !== "") {
        param.pipelineTask = pipelineTask;
      }

      try {
        const response = await common.apiGet("/pipeline", param);
        this.dataList = response.data.data;
        this.currentPage = 1;
        this.totalItems = this.dataList.length;
        this.displayDataPerPage(this.dataList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1 해주고 2자리로 포맷팅
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");

      const nowDt = year + month + day + hours;

      return nowDt;
    },

    onPageChanged(page) {
      this.currentPage = page;
      this.displayDataPerPage(this.dataList);
    },

    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.dataListSliced = rowData.slice(startIndex, endIndex);
    },
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      etoday.setDate(etoday.getDate() + 1);

      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    // ----- API
    async getMlopsTaskList() {
      try {
        const response = await common.apiGet("/project/mlops-task");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getMlList(dateTime) {
      try {
        const response = await common.apiDlGet(
          "/rds/vehicle/ml_list?" + dateTime
        );
        return response.data.queryResult.results;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  position: relative;
  .table_list {
    margin-top: 110px;
    height: 715px;
  }
  .list_filter_wrap {
    position: absolute;
    top: -90px;
    overflow: visible;
    .filter_list_box {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 15px;
      box-sizing: border-box;
      .filter_box {
        display: flex;
        align-items: center;
        height: 40px;
        .date_filter {
          position: static;
          height: 100%;
          padding-left: 0;
          &::after {
            display: none;
          }
          .date_period {
            width: 240px;
          }
        }
        .search_box {
          input[type="text"] {
            padding: 0 10px;
            height: 40px;
            box-sizing: border-box;
            border: 1px solid #d5dae3;
            border-radius: 10px;
          }
        }
        .btn_send {
          width: 60px;
          height: 40px;
          margin-left: 10px;
          padding: 0 15px;
          border-radius: 10px;
          line-height: 37px;
          font-size: 15px;
          cursor: pointer;
        }
      }
      select {
        background-position: center right 15px;
      }
      &::after {
        display: none;
      }
    }
  }
  .mando_table {
    tr {
      cursor: pointer;
      td {
        .btn_go {
          width: 60px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
#modalWrap {
  .message_modal {
    width: 80%;
    height: 80%;
  }
}
</style>
