import axios from "axios";
// Vector Logger API
const axiosInstanceMlops = axios.create({
  baseURL: "https://v7icaj3qa3.execute-api.ap-northeast-2.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    "X-API-Key": "mlops_key_1",
  },
});

const apiMlops = {
  get(url, params = {}) {
    return axiosInstanceMlops.get(url, { params });
  },
  post(url, data = {}) {
    return axiosInstanceMlops.post(url, data);
  },
  patch(url, data = {}) {
    return axiosInstanceMlops.patch(url, data);
  },
  delete(url) {
    return axiosInstanceMlops.delete(url);
  },
  put(url, data = {}) {
    return axiosInstanceMlops.put(url, data);
  },
};

export default apiMlops;
