<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_apiDashboard" :checkRole="manageRole" />
      <div class="list_filter_wrap float_box">
        <div class="filter_list_box">
          <div class="code_search_table">
            <div>
              <select v-model="division" @change="setDivison">
                <option value="0">Divison 선택</option>
                <option
                  v-for="(item, index) in divisionList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div>
              <select v-model="team" @change="setTeam">
                <option value="0">Team 선택</option>
                <option
                  v-for="item in teamList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div>
              <select v-model="project">
                <option value="0">Project 선택</option>
                <option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.projectName }}
                </option>
              </select>
            </div>

            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn_send mcbtn"
                    @click="clickSearch"
                  >
                    조회
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas
            ref="chartApiCall"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
        <div class="chart">
          <canvas
            ref="chartTopApi"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas
            ref="chartApiSuccess"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
        <div class="chart">
          <canvas
            ref="chartApiFail"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
      </div>
      <div class="chart_wrap">
        <div class="chart">
          <canvas
            ref="chartDivision"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
        <div class="chart">
          <canvas
            ref="chartTeam"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
        <div class="chart">
          <canvas
            ref="chartProject"
            style="
              background-color: white;
              box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
            "
          />
        </div>
      </div>

      <!-- <div style="background: aqua">
        <button type="button" @click="clickAction">버튼</button>
      </div> -->
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import { Chart, registerables } from "chart.js/auto";
import { toRaw } from "vue";
Chart.register(...registerables);

export default {
  name: "apiDashboard",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    BreadcrumbLayout,
  },
  data() {
    return {
      division: 0,
      divisionList: [],
      team: 0,
      teamList: [],
      project: 0,
      projectList: [],
      chartApiCall: null,
      chartTopApi: null,
      chartApiSuccess: null,
      chartApiFail: null,
      chartDivision: null,
      chartTeam: null,
      chartProject: null,
      chartFixedData: null,
      chartData: {
        division: {},
        project: {},
        team: {},
      },
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 7,
    };
  },
  created() {},
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  warch: {
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },

  async mounted() {
    //BreadcrumbLayout
    //this.setRole();
    await this.getDivisionList();
    await this.getTeamList(0);
    await this.getProjectList(0, 0);
    this.setDate(this.selectDuration);
    //await this.clickSearch();
    await this.getChartData();
    await this.getFixedChartData();
    this.createChart();
    this.createFixedChart();
  },

  methods: {
    async getFixedChartData() {
      try {
        const start = this.picStartDate;
        const end = this.picEndDate;
        const param = {
          startDate: start,
          endDate: end,
        };

        const response = await common.apiGet("/apidash", param);
        this.chartFixedData = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getChartData() {
      try {
        const start = this.picStartDate;
        const end = this.picEndDate;
        const division = this.division;
        const team = this.team;
        const project = this.project;
        let param = null;
        if (division === 0 && team === 0 && project === 0) {
          param = {
            startDate: start,
            endDate: end,
          };
        } else {
          param = {
            startDate: start,
            endDate: end,
            divisionId: division,
            teamId: team,
            projectId: project,
          };
        }

        const response = await common.apiGet("/apidash/apisub", param);
        this.chartData = response.data.data;
        console.log("######## :: ", this.chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async clickSearch() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      await this.getChartData();
      loading.style.display = "none";
    },
    async setDivison() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      await this.getTeamList(this.division);
      await this.getProjectList(this.division, this.team);
      loading.style.display = "none";
    },
    async setTeam() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      this.team = 0;
      await this.getProjectList(this.division, this.team);
      loading.style.display = "none";
    },
    async getProjectList(division, team) {
      this.project = 0;
      try {
        let response = null;
        if (division === 0 && team === 0) {
          response = await common.apiGet("/project/list");
        } else if (division !== 0 && team === 0) {
          const param = {
            "division-id": division,
          };
          response = await common.apiGet("/project/list", param);
        } else if (division === 0 && team !== 0) {
          const param = {
            "team-id": team,
          };
          response = await common.apiGet("/project/list", param);
        } else {
          const param = {
            "division-id": division,
            "team-id": team,
          };
          response = await common.apiGet("/project/list", param);
        }
        this.projectList = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDivTeamData() {
      try {
        const response = await common.apiGet(
          "/team/division-team?division_id=2"
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDivisionList() {
      try {
        const response = await common.apiGet("/division");
        this.divisionList = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTeamList(division) {
      try {
        let response = null;
        if (division == 0) {
          response = await common.apiGet("/team");
        } else {
          response = await common.apiGet("/team/" + division);
        }
        this.teamList = response.data.data;
        console.log("@@@@@@@@@ :: ", this.teamList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    createFixedChart() {
      const vm = this;
      this.chartDivision = new Chart(this.$refs.chartDivision, {
        type: "pie",
        data: {
          labels: vm.chartFixedData.division.labels,
          datasets: vm.chartFixedData.division.datasets,
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "DIVISION",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
      this.chartTeam = new Chart(this.$refs.chartTeam, {
        type: "pie",
        data: {
          labels: vm.chartFixedData.team.labels,
          datasets: vm.chartFixedData.team.datasets,
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "TEAM",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
      this.chartProject = new Chart(this.$refs.chartProject, {
        type: "pie",
        data: {
          labels: vm.chartFixedData.project.labels,
          datasets: vm.chartFixedData.project.datasets,
        },

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
              text: "PROJECT",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
        },
      });
    },
    createChart() {
      const vm = this;
      let existing_chart = null;
      existing_chart = this.chartApiCall;
      if (this.chartApiCall != null) {
        toRaw(existing_chart).destroy();
      }
      existing_chart = this.chartTopApi;
      if (this.chartTopApi != null) {
        toRaw(existing_chart).destroy();
      }
      existing_chart = this.chartApiSuccess;
      if (this.chartApiSuccess != null) {
        toRaw(existing_chart).destroy();
      }
      existing_chart = this.chartApiFail;
      if (this.chartApiFail != null) {
        toRaw(existing_chart).destroy();
      }
      this.chartApiCall = new Chart(this.$refs.chartApiCall, {
        type: "line",
        data: {
          labels: vm.chartData.chartApiCall.labels,
          datasets: [
            {
              title: "총 호출 합계",
              label: "총 호출 합계",
              data: vm.chartData.chartApiCall.datasets[0]?.data,
              fill: true,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "총 호출 합계",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartTopApi = new Chart(this.$refs.chartTopApi, {
        type: "bar",
        data: {
          labels: vm.chartData.chartTopApi.labels,
          datasets: [
            {
              label: "TOP API",
              data: vm.chartData.chartTopApi.datasets[0]?.data,
              backgroundColor: this.color,
            },
          ],
        },
        options: {
          indexAxis: "y",
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "TOP API",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartApiSuccess = new Chart(this.$refs.chartApiSuccess, {
        type: "line",
        data: {
          labels: vm.chartData.chartApiSuccess.labels,
          datasets: [
            {
              label: "SUCCESS",
              data: vm.chartData.chartApiSuccess.datasets[0]?.data,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "SUCCESS",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
      this.chartApiFail = new Chart(this.$refs.chartApiFail, {
        type: "line",
        data: {
          labels: vm.chartData.chartApiFail.labels,
          datasets: [
            {
              label: "FAIL",
              data: vm.chartData.chartApiFail.datasets[0]?.data,
              borderColor: ["#ff6384"],
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "FAIL",
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
    },
    // -------- DATE PICKER -----
    // -------- DATE PICKER -----
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    async getDriList() {
      const vm = this;
      // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
      // 켈린더 display처리
      const $calenderBox = vm.$refs.calenderBox;
      const $startDateBox = vm.$refs.startDateBox;
      const $endDateBox = vm.$refs.endDateBox;
      //const $drivReclistBox = vm.$refs.drivReclistBox;

      $calenderBox.style.display = "none";
      $startDateBox.style.display = "block";
      $endDateBox.style.display = "none";
      //$drivReclistBox.style.display = "block";

      vm.picColor.startStatus = false;
      vm.picColor.endStatus = false;

      console.log(vm.picStartDate);
      console.log(vm.picEndDate);
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },

    // ------------
    clickAction() {
      console.log(this.chartApiCall.data.datasets[0].data);

      //this.chart111.update();
      const newData = [3, 2, 9, 5, 2, 3];
      this.chartApiCall.data.datasets[0].data = [];
      /*
      chart.data.datasets.forEach((data) => {
        data.data.push(3);
      });
      */
      this.chartApiCall.data.datasets[0].data = newData;
      this.chartApiCall.update();
    },
  },
};
</script>
<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height: 40px;
  box-sizing: border-box;

  .date_filter {
    width: 100%;
    height: 100%;
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      .btn_send {
        width: auto;
        height: 40px;
        margin-right: -72px;
        padding: 0 15px;
        border-radius: 10px;
        line-height: 37px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
