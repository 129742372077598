<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_realtimeDirectrateMonitoring"
        :name="'[TASK_AD] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <!-- <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li> -->
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div class="item_search">
          <select v-model="edgeId" @change="chgEdgeId">
            <option value="0">Edge ID</option>
            <option
              v-for="(item, index) in searchData"
              :key="index"
              :value="item.edge_id"
            >
              {{ item.edge_id }}
            </option>
          </select>
          <select v-model="searchSite">
            <option value="" selected>Site</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.wc"
            >
              {{ item.wc }}
            </option>
          </select>
          <select v-model="searchWc">
            <option value="">WC</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.wc"
            >
              {{ item.wc }}
            </option>
          </select>
          <select v-model="searchModel">
            <option value="">Model</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.models"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <button type="button" class="mcbtn" @click="getMonitoringData">
            선택
          </button>
        </div>
        <div id="monCont" class="contents">
          <div class="radiusbox row row_01">
            <div class="rate left">
              <div class="title">실시간 진행률</div>
              <div class="percentage">
                {{
                  monitoringData
                    ? transNumberFormat(
                        monitoringData.fpy_overall_realtime * 100
                      )
                    : 0
                }}%
              </div>
            </div>
            <div class="chart right" style="height: 200px">
              <ChartController
                :labelsArray="labelsArray"
                :datasArray="datasArray"
                :displayLegend="'N'"
                :customTooltip="'Y'"
                :chartTooltip="chartTooltip"
                :chartType="'line'"
              />
            </div>
          </div>
          <div class="radiusbox row row_02">
            <div class="model left">
              <div class="title">현재 생산 모델</div>
              <div class="txt">
                {{ this.monitoringData ? this.monitoringData.model : "ㅡ" }}
              </div>
            </div>
            <div class="pointRate right">
              <p>검사 포인트 별 직행률</p>
              <ul>
                <li
                  v-for="(item, index) in monitoringData.fpy_per_point"
                  :key="index"
                >
                  <div class="num">
                    <button
                      type="button"
                      @click="
                        showDetail(
                          item.point,
                          item.fpy,
                          item.fpy_before,
                          item.update_date,
                          item.operation_time
                        )
                      "
                    >
                      {{ item.point }}
                    </button>
                  </div>
                  <div class="rate color_01">
                    <button type="button" @click="goPage">
                      {{ transNumberFormat(item.fpy * 100) }}%
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="radiusbox row row_03" ref="detail" style="display: none">
            <div class="tbl_wrap">
              <div class="tbl tbl_th">
                <div>{{ point }}</div>
                <div>모델 생성일</div>
                <div>운영 시간</div>
                <div>수정전 직행률</div>
              </div>
              <div class="tbl tbl_td">
                <div class="color_01">{{ transNumberFormat(fpy * 100) }}%</div>
                <div>{{ updateDate.replace("T", " ") }}</div>
                <div>
                  {{
                    operationTime
                      .replace("D", "일")
                      .replace("H", "시간")
                      .replace("M", "분")
                      .replace("S", "초")
                  }}
                </div>
                <div>
                  {{
                    typeof fpyBefore === "Number" && fpyBefore != ""
                      ? transNumberFormat(fpyBefore * 100) + "%"
                      : fpyBefore
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import common from "@/assets/js/common";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import apiVision from "@/assets/js/apiVision";
import ChartController from "@/components/ChartController.vue";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    ChartController,
  },
  data() {
    return {
      taskId: "",
      prjId: "",
      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      edgeDeviceList: [],
      edgeId: 0,
      searchData: [],
      searchSite: "",
      searchWc: "",
      searchModel: "",

      monitoringDate: "",
      monitoringData: "",

      percentage: "",
      point: "",
      fpy: null,
      fpyBefore: null,
      updateDate: "",
      operationTime: "",

      labelsArray: [],
      datasArray: [],
      chartTooltip: [],
    };
  },
  computed: {},

  async mounted() {
    this.$refs.lodingWrap.style.display = "block";
    // Role
    await this.setRole();
    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();
    this.setTaskInfo();
    await this.getEdgeDeviceList();
    await this.getSearchItem();
    this.monitoringDate = this.getToday();
    this.$refs.lodingWrap.style.display = "none";
  },
  methods: {
    goPage() {
      const prjId = this.$route.query.prjId;
      const taskId = this.$route.query.taskId;
      const teamId = this.$route.query.teamId;
      this.$router.push({
        name: "ModelPerformanceMonitoring",
        query: { prjId: prjId, taskId: taskId, teamId: teamId, taskTypeId: 19 },
      });
    },
    showDetail(point, fpy, fpyBefore, updateDate, operationTime) {
      this.point = point;
      this.fpy = fpy;
      this.fpyBefore = fpyBefore;
      this.updateDate = updateDate;
      this.operationTime = operationTime;
      this.$refs.detail.style.display = "block";
    },
    transNumberFormat(targetNumber) {
      const number = targetNumber;
      let transNumber = number;
      const splitNum = number.toString().split(".")[1];
      if (splitNum != undefined && splitNum.length > 4) {
        transNumber = number.toFixed(5);
      }
      return transNumber;
    },
    getToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = year + "-" + month + "-" + day;

      return formattedDate;
    },
    chgEdgeId() {
      this.searchSite = "";
      this.searchWc = "";
      this.searchModel = "";
      this.searchPoint = "";
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getSearchItem() {
      const array = [];
      for (let item of this.edgeDeviceList) {
        array.push(item.id);
      }
      const param = {
        edgeids: array,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_check_edge/",
          param
        );
        this.searchData = [...response.data.edge];
        console.log("this.searchData :: ", this.searchData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    // ----- API
    async getMonitoringData() {
      this.$refs.lodingWrap.style.display = "block";
      /*
      const site = this.searchSite;
      const wc = this.searchWc;
      const model = this.searchModel;
      const monitoringDate = this.monitoringDate;
      const param = {
        site: site,
        wc: wc,
        model: model,
        monitoring_date: monitoringDate,
      };
      */
      const param = {
        site: 5,
        wc: 7,
        model: "16",
        monitoring_date: "2024-10-10",
      };

      try {
        const response = await apiVision.post(
          "/vision/vision_ml_performance_realtime/",
          param
        );
        this.monitoringData = response.data;
        console.log("??????? :: ", this.monitoringData);
        this.labelsArray = [];
        //this.datasArray = [{ data: [], label: "TEST" }];
        const array = [];
        const label = [];
        this.monitoringData.fpy_per_hour.forEach((e) => {
          label.push(e.time);
          const a = this.transNumberFormat(e.fpy * 100);
          array.push(a);
        });
        const dataset = {
          data: array,
        };
        this.chartTooltip = this.monitoringData.fpy_per_point_and_time;
        this.labelsArray = label;
        this.datasArray = [dataset];

        console.log(this.labelsArray);
        console.log(this.datasArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.$refs.lodingWrap.style.display = "none";
    },
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },
    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.projectData = await getPjtData.project;
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_search {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 12px 0 20px;
  select {
    min-width: 170px;
    background-position: center right 15px;
  }
  button {
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
}
.contents {
  .row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    .left {
      width: 250px;
    }
    .right {
      flex: 1;
    }
    &.row_01 {
      .rate {
        height: 200px;
        background-color: #405261;
        box-sizing: border-box;
        padding: 0 15px 15px;
        & > div {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }
        .title {
          height: 35%;
          color: #fff;
          font-size: 20px;
        }
        .percentage {
          height: 65%;
          font-size: 34px;
          background-color: #fff;
        }
      }
      .chart {
        .chart {
          padding: 0;
        }
      }
    }
    &.row_02 {
      .model {
        height: 200px;
        & > div {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }
        .title {
          height: 35%;
          font-size: 20px;
          background-color: #00b4ed;
          color: #fff;
        }
        .txt {
          height: 65%;
          font-size: 34px;
          border: 1px solid #d5dae3;
          box-sizing: border-box;
        }
      }
      .pointRate {
        height: 202px;
        & > p {
          height: 34px;
          display: inline-flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
        }
        & > ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 5px;
          row-gap: 10px;
          height: 158px;
          overflow-y: auto;
          & > li {
            min-width: 140px;
            & > div {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 36px;
              font-weight: 600;
              box-sizing: border-box;
            }
            .num {
              background-color: #00b4ed;
              button {
                display: block;
                width: 100%;
                height: 100%;
                color: #fff;
              }
            }
            .rate {
              border: 1px solid #d5dae3;
              &.color_01 {
                button {
                  color: #02a744;
                }
              }
              &.color_02 {
                button {
                  color: #f15046;
                }
              }
            }
          }
        }
      }
    }
    &.row_03 {
      display: block;
      .tbl_wrap {
        & > .tbl {
          display: flex;
          align-self: center;
          width: 100%;
          & > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          & > div:nth-child(1) {
            width: 15%;
          }
          & > div:nth-child(2) {
            width: 35%;
          }
          & > div:nth-child(3) {
            width: 30%;
          }
          & > div:nth-child(4) {
            width: 20%;
          }
          &.tbl_th {
            height: 45px;
            background-color: #405261;
            & > div {
              height: 100%;
              font-size: 16px;
              color: #fff;
              &:not(:last-child) {
                border-right: 1px solid #d5dae3;
              }
            }
          }
          &.tbl_td {
            height: 70px;
            border: 1px solid #d5dae3;
            box-sizing: border-box;
            & > div {
              height: 100%;
              font-size: 18px;
              &:not(:last-child) {
                border-right: 1px solid #d5dae3;
              }
              &.color_01 {
                color: #02a744;
              }
              &.color_02 {
                color: #f15046;
              }
            }
          }
        }
      }
    }
  }
}
</style>
