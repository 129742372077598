<template>
  <div class="setting_pop_cell_contents_wrap">
    <div class="input-block">
      <div class="divide">
        <div class="first">Component ID</div>
        <div class="second">{{ items[0].value }}</div>
      </div>
      <!-- <div class="divide">
        <div class="first">Display Title</div>
        <div class="second">
          <input type="text" v-model="displayTitle" @input="writeTitle" />
        </div>
      </div> -->
      <div class="divide">
        <div class="first">Map Type</div>
        <div class="second">
          <div>
            <input
              type="radio"
              name="map"
              id="map_01"
              value="normal"
              v-model="mapType"
              @change="chgMapType"
            />
            <label for="map_01">Noraml</label>
          </div>
          <div>
            <input
              type="radio"
              name="map"
              id="map_02"
              value="satellite"
              v-model="mapType"
              @change="chgMapType"
            />
            <label for="map_02">satellite</label>
          </div>
        </div>
      </div>
      <div class="divide" v-if="dataSetData">
        <div class="first">Dataset</div>
        <div class="second">
          <select v-model="setMapData" @change="chgMapData">
            <option value="null">선택해 주세요.</option>
            <option
              v-for="(item, index) in dataSetData.map"
              :key="index"
              :value="JSON.stringify(item.format)"
            >
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div>
    <MapController
      style="height: 250px"
      :mapType="mapType"
      :latlngArray="latlngArray"
      :searchResult="searchResult"
    />
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import MapController from "./MapController.vue";
import _ from "lodash";
export default {
  components: {
    MapController,
  },
  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex: {
      type: Number,
      required: true,
    },
    handlerList: {
      //handler
      type: Object,
      required: true,
    },
    dataSet: {
      type: Object,
    },
    layout: {
      type: Object,
    },
  },
  data() {
    return {
      gpsMapDataArray: null,
      mapType: "normal",
      displayTitle: "",
      latlngArray: null,
      searchResult: null,
      items: [
        { label: "Component ID", value: "" },
        { label: "Display Title", value: "" },
        { label: "Controller 추가", value: {} },
      ],
      nowindex: 0,
      componentType: "",
      components: [],
      layoutComponent: {},
      setMapData: null,
    };
  },
  watch: {},
  async mounted() {
    this.$refs.lodingWrap.style.display = "block";
    //await this.callApi();

    this.dataSetData = this.dataSet;
    this.dataLayout = this.layout;
    console.log("this.dataSetData :: ", this.dataSetData);
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);

    if (this.components[this.nowindex]?.mapType != undefined) {
      this.mapType = this.components[this.nowindex]?.mapType;
    } else {
      this.mapType = "normal";
    }

    if (this.components[this.nowindex]?.displayTitle != undefined) {
      this.displayTitle = this.components[this.nowindex]?.displayTitle;
    } else {
      this.displayTitle = "";
    }
    const components_cp = this.components;
    for (let i = 0; i < components_cp.length; i++) {
      let valueStart = components_cp[i].valueStart;
      let valueEnd = components_cp[i].valueEnd;
      if (valueStart !== null) {
        if (typeof valueStart === "string") {
          valueStart = new Date(valueStart);
        }
      }
      if (valueEnd !== null) {
        if (typeof valueEnd === "string") {
          valueEnd = new Date(valueEnd);
        }
      }
    }

    this.components = _.cloneDeep(components_cp);
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    const type = this.componentType;
    const handlerCount = String(this.handlerList.length + 1).padStart(3, "0");
    const component = {
      type: type,
      labelText: "",
      value: "",
      valueStart: "",
      valueEnd: "",
      key: this.components.length + 1 + "-" + Math.floor(Date.now()),
      handlerKey: "Handler" + handlerCount,
      remove: false,
      child: {},
      parentHandlerKey: "",
      controlHandlerLabelText: "",
      keyValueList: [],
      keyValueType: "",
      parameter: "",
      range: {
        startRange: 0,
        endRange: 0,
        step: 0,
      },
    };
    this.components.push(component);
    this.component = component;
    this.$emit("updateHandler", component);
    this.component = _.cloneDeep(this.component);
    this.$refs.lodingWrap.style.display = "none";
  },
  methods: {
    writeTitle() {
      this.components[0].displayTitle = this.displayTitle;
      this.components = _.cloneDeep(this.components);
    },
    chgMapType() {
      this.components[0].mapType = this.mapType;
      this.components = _.cloneDeep(this.components);
    },
    updateParent({ index, value }) {
      this.components[index].parentHandlerKey = value;
      this.components = _.cloneDeep(this.components);
    },
    updateItem(index, newValue) {
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    removeItem(index) {
      this.$emit("removeHandler", this.components[index]);
      this.components.splice(index, 1); // 해당 인덱스의 객체를 배열에서 제거
    },
    chgMapData(e) {
      this.components[0].mapSetData = e.target.value;
      this.components = _.cloneDeep(this.components);
    },
  },
};
</script>
<style scoped lang="scss">
.second {
  gap: 10px;
  input[type="radio"] {
    margin-top: 0;
    cursor: pointer;
  }
  input[type="text"] {
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border: 1px solid #d5dae3;
    border-radius: 10px;
    box-sizing: border-box;
  }
  label {
    cursor: pointer;
  }
}
.divide {
  min-height: 50px;
  margin: 0;
  &:first-child {
    .second {
      border-top: 1px solid #d5dae3;
    }
  }
  &:last-child {
    .first {
      border: 0;
    }
  }
  .first {
    width: 40%;
    background-color: #00b4ed;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 600;
  }
  .second {
    border-right: 1px solid #d5dae3;
    border-bottom: 1px solid #d5dae3;
    &.upload {
      gap: 10px;
      label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 12px;
        border-radius: 10px;
        color: #fff;
        background-color: #405261;
        box-sizing: border-box;
        cursor: pointer;
      }
      input[type="text"] {
        height: 40px;
        padding: 0 10px;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        box-sizing: border-box;
      }
    }
  }
}
select {
  width: 146px;
  background-position: center right 15px;
}
</style>
