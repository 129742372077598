<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>프로젝트 운영</span>
                    <span class="active">My 프로젝트(Home)</span>
                </div>
                <h2>My 프로젝트(Home)</h2>
                <div class="title_btn">
                    <button type="button" class="add_project bgicon" v-if="checkRole" @click="addProject">프로젝트 등록</button>
                </div>
            </div> -->
      <BreadcrumbLayout pageId="project_home" :checkRole="manageRole" />
      <div class="float_box">
        <div class="left_box">
          <section>
            <h3>My Project List</h3>
            <div class="total_num">
              <span>{{ list === undefined ? 0 : list.length }}</span> PRJs
            </div>
            <ul id="myProjectList">
              <li class="m_pointer" v-for="(item, index) in list" :key="index">
                <a @click="projectOps($event, item.project_id)">
                  <div class="project_icon">
                    <img :src="projectImage(item.project_type)" alt="" />
                  </div>
                  <strong>{{ item.project_name }}</strong>
                  <div class="project_level">
                    <span>LV.</span>
                    <span id="projLevel">{{ item.project_role_name }}</span>
                  </div>
                  <div class="project_update">
                    <span>최종 업데이트 : </span>
                    <span class="project_date">{{
                      timeSet(item.updated)
                    }}</span>
                  </div>
                  <button type="button" class="setting_btn"></button>
                  <div class="data_status">
                    <img
                      v-if="item.monitoring_task_cnt > 0"
                      src="@/assets/images/common/project_data.svg"
                      alt=""
                    />
                    <!-- <img src="@/assets/images/common/project_processing.svg" alt="" class="processing"> -->
                  </div>
                </a>
              </li>
            </ul>
          </section>
        </div>
        <div class="right_box">
          <section>
            <h3>Recent Projects Activity</h3>
            <div class="total_num">
              (Today : <span>{{ activityLogDataSize }}</span> EA)
            </div>
            <div class="grid_box">
              <div class="mando_table_wrap">
                <table class="mando_table">
                  <thead>
                    <tr>
                      <th v-for="(txt, index) in columnDefs" :key="index">
                        {{ txt.field }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in activityLogDataLimit10"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.date_time }}</td>
                      <td>{{ item.project_name }}</td>
                      <td>{{ item.user_name }}</td>
                      <td>{{ item.activity_code }}</td>
                      <td style="text-align: left">{{ item.activity_log }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button type="button" class="detail_btn" @click="showModal()">
              자세히 보기
            </button>
          </section>
          <section>
            <h3>Recent Menu</h3>
            <!-- class none_item -->
            <div class="history_box">
              <ul class="history_list">
                <!-- <li class="project_icon">
                                    <a href="#"> CS/공지사항 관리 </a>
                                </li> -->
                <li class="datatask_icon">
                  <a href="/list/dataList"> 데이터 목록 </a>
                </li>
                <li class="datatask_icon">
                  <a href="/list/taskList"> 모니터링 TASK 목록 </a>
                </li>
                <li class="datatask_icon">
                  <a href="/list/mlopsList"> MLOps TASK 목록 </a>
                </li>
                <li class="system_icon" v-if="manageRole">
                  <a :href="teamUrl"> Team 관리 </a>
                </li>
                <!-- <li class="datatask_icon">
                                    <a href="#"> Division 관리 </a>
                                </li> -->
              </ul>
              <!-- <div class="history_btn">
                                <button type="button" class="uplist"></button>
                                <button type="button" class="downlist"></button>
                            </div>  -->
              <!---->
            </div>
          </section>
        </div>
      </div>
      <div id="modalWrap" ref="modalWrap" style="display: none">
        <div class="modal_contents radiusbox">
          <strong class="modal_title">Recent Projects Activity</strong>
          <div id="recentFilter">
            <div class="filter_box">
              <select
                name="a"
                style="width: 600px; background-position: 570px center"
                v-model="selectProject"
              >
                <option disabled selected :value="null">
                  프로젝트를 선택해 주세요.
                </option>
                <option
                  v-for="(item, index) in projectData"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.projectName }}
                </option>
              </select>
              <div class="date_filter">
                <select
                  name="datelist"
                  @change="dateSelectChange"
                  v-model="selectDate"
                  style="width: 150px; background-position: 120px center"
                >
                  <option :value="null" selected>1일</option>
                  <option value="7">7일</option>
                  <option value="14">14일</option>
                  <option value="30">30일</option>
                </select>
                <div class="date_period">
                  <div class="date_box">
                    <span :style="picStartTxtStyle" ref="picStartTxt">{{
                      picStartDate
                    }}</span>
                    ~
                    <span :style="picEndTxtStyle" ref="picEndTxt">{{
                      picEndDate
                    }}</span>
                  </div>
                  <button
                    type="button"
                    ref="datePicToggle"
                    @click="datePicToggle()"
                  ></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      @click="onDatePic('S')"
                      style="display: block"
                    >
                      <VCalendarUi :dateInterface="'start'" />
                      <button type="button" @click="pickerNext()">다음</button>
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      @click="onDatePic('E')"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                      />
                      <button type="button" @click="pickerPrev()">이전</button>
                      <button type="button" class="mcbtn" @click="getDriList()">
                        검색
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn_send mcbtn"
                  @click="getDriList()"
                >
                  조회
                </button>
              </div>

              <div class="filter_list_box" ref="filterListBox">
                <ul class="float_box" ref="filterBox">
                  <li
                    v-if="filterList.length === 0"
                    style="padding-right: 20px"
                  >
                    <span>필터</span> : <span>전체</span>
                  </li>
                  <li
                    v-for="(item, index) in filterList"
                    :key="index"
                    ref="filterItem"
                  >
                    <span>{{ item.keyName }}</span> :
                    <span>{{ item.value }}</span>
                    <button
                      class="btn_close_item"
                      @click="removeFilter(index)"
                    ></button>
                  </li>
                </ul>
              </div>
              <button
                style="display: none"
                class="btn_all_filter"
                ref="filterAllBtn"
                @click="filterToggle"
              >
                전체보기
              </button>
            </div>
          </div>
          <div class="list_wrap">
            <div class="table_list">
              <div class="total_num">
                총 <span>{{ rPA.length }}</span
                >항목
              </div>
              <div class="mando_table_wrap">
                <table class="mando_table">
                  <thead>
                    <tr>
                      <th v-for="(item, index) in rpaColumnDefs" :key="index">
                        <div class="col_name">
                          {{ item.field }}
                          <button
                            class="btn_filter"
                            @click="showFilter"
                          ></button>
                        </div>
                      </th>
                    </tr>
                    <tr id="filterWrap" ref="filterInputWrap">
                      <th
                        class="filter_input"
                        v-for="(item, index) in rpaColumnId"
                        :key="index"
                      >
                        <div class="input_box" :data-id="item.id">
                          <input
                            type="text"
                            @focus="setActive"
                            @keydown.enter="addFilter"
                          />
                          <button
                            class="btn_add"
                            ref="btnAddfilter"
                            @click="addFilter"
                            :data-index="index"
                          ></button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in rpaSliced" :key="index">
                      <td>{{ item.date_time }}</td>
                      <td>{{ item.project_name }}</td>
                      <td>{{ item.user_name }}</td>
                      <td>{{ item.activity_code }}</td>
                      <td style="text-align: left">{{ item.activity_log }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="list_table_footer">
                <!-- <button class="btn_list_delete"> 삭제 </button> -->
                <PaginationUi
                  :totalItems="totalItems"
                  :itemsPerPage="itemsPerPage"
                  @page-changed="onPageChanged"
                ></PaginationUi>
                <!-- <div id="listPagination" class="list_pagination">
                                    <button type="button" id="btnPrevious" disabled="disabled"></button>
                                    <div id="listNum">
                                        <button type="button">1</button>
                                        <button type="button">2</button>
                                        <button type="button">3</button>
                                    </div>
                                    <button type="button" id="btnNext"></button>
                                </div> -->
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn_close"
            @click="closeModal()"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

import common from "@/assets/js/common";
// import Cookies from 'js-cookie';
// import axios from 'axios';

import gsap from "gsap";

export default {
  name: "HomeView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    PaginationUi,
    BreadcrumbLayout,
  },
  data() {
    const columnDefs = [
      { field: "No" },
      { field: "Date time" },
      { field: "Project Name" },
      { field: "User Name" },
      { field: "Activity Code" },
      { field: "Activity Log" },
    ];
    const picStartDate = null;
    const picEndDate = null;
    const rPA = [];
    const dateSet = 1;
    return {
      // -- VCalendarUi / .date_period
      picStartDate,
      picEndDate,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      //----
      filterList: [],
      rpaColumnDefs: [
        { field: "Date time" },
        { field: "Project Name" },
        { field: "User Name" },
        { field: "Activity Code" },
        { field: "Activity Log" },
      ],
      rpaColumnId: [
        { id: "date_time" },
        { id: "project_name" },
        { id: "user_name" },
        { id: "activity_code" },
        { id: "activity_log" },
      ],
      isfilter: true,
      filterDataList: {
        origin: {},
      },
      list: [],
      date: "",
      recentProjectsActivities: [],
      columnDefs,
      activityLogData: [],
      activityLogDataLimit10: [],
      projectData: [],
      rPA,
      rpaSliced: [],
      selectProject: "",
      selectDate: "1",

      totalItems: 100,
      itemsPerPage: 10,
      currentPage: 1,

      uri: "https://api.dlfe.dev.micosa.io",

      checkRole: false,
      dateSet,

      // role
      viewRole: false,
      manageRole: false,

      teamUrl: "#",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.path === "/showActivityLog") {
      next((vm) => {
        vm.showModal();
      });
    } else {
      next();
    }
  },
  computed: {
    totalItemSize() {
      return this.rPA.length;
    },

    activityLogDataSize() {
      // console.log(this.activityLogData.length);
      return this.activityLogData.length;
    },
    // datepic
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
  },
  async mounted() {
    // Role
    this.setRole();

    //check login user role
    // await this.checkLoginUserRole();

    // set date
    const vm = this;
    const stoday = new Date();
    stoday.setDate(stoday.getDate() - 1);
    const syear = stoday.getFullYear();
    const smonth = stoday.getMonth() + 1;
    const sday = stoday.getDate();
    const start_formattedDate =
      syear +
      "-" +
      (smonth < 10 ? "0" : "") +
      smonth +
      "-" +
      (sday < 10 ? "0" : "") +
      sday;

    const etoday = new Date();
    const eyear = etoday.getFullYear();
    const emonth = etoday.getMonth() + 1;
    const eday = etoday.getDate();
    const end_formattedDate =
      eyear +
      "-" +
      (emonth < 10 ? "0" : "") +
      emonth +
      "-" +
      (eday < 10 ? "0" : "") +
      eday;

    vm.picStartDate = start_formattedDate;
    vm.picEndDate = end_formattedDate;
    window.selectDate.startDate = start_formattedDate;
    window.selectDate.endDate = end_formattedDate;
    //-------
    await this.getProject();
    // this.getPrjData(),
    await this.recentProjectsActivity();
    await this.getProjectOptions();
    // ---------
  },

  methods: {
    async setRole() {
      this.manageRole = await common.getUserRole("type2");
      this.viewRole = await common.getUserRole("type1");

      if (!this.viewRole) {
        common.goHome();
      }

      if (this.manageRole) {
        this.teamUrl = "/sysOps/teamList";
      }
    },
    // --------- API
    // async getLoginUserRole() {
    //     try {
    //         let url = 'member/check-role';
    //         const response = await this.getData(url);
    //         return response;
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // },
    // //check user role
    // async checkLoginUserRole() {
    //     this.checkRole = true;
    //     let response;

    //     try {
    //         response = await common.apiGet("/member/check-role");
    //     } catch(error) {
    //         console.log("error check Role : ", error);
    //         this.$router.go(0);
    //     }
    //     const role = response.data.data;
    //     console.log(response);

    //         if(role.role == 'SM') {
    //             this.checkRole = true;
    //         } else if(role.memberRole == 'Division Manager' || role.memberRole == 'Team Manager') {
    //             this.checkRole = true;
    //         } else {
    //             this.checkRole = false;
    //         }
    //         console.log('this.checkRole');
    //         console.log(this.checkRole);

    // },

    // -------- DATE PICKER ----
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);
      console.log(vm.$refs.startDateBox);
      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },

    memAccordion(event) {
      const _this = event.target;
      const hasShowClass = _this.classList.contains("show_list");
      const $listWrap = _this.parentNode.nextElementSibling;
      if (hasShowClass) {
        console.log("hasClass");
        gsap.to($listWrap, {
          onStart: () => {
            _this.classList.remove("show_list");
          },
          height: 0,
          duration: 0.3,
        });
      } else {
        console.log("noClass");
        gsap.to($listWrap, {
          onStart: () => {
            _this.classList.add("show_list");
          },
          height: "auto",
          duration: 0.3,
        });
      }
    },
    async getDriList() {
      const vm = this;
      // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
      // 켈린더 display처리
      const $calenderBox = vm.$refs.calenderBox;
      const $startDateBox = vm.$refs.startDateBox;
      const $endDateBox = vm.$refs.endDateBox;

      $calenderBox.style.display = "none";
      $startDateBox.style.display = "block";
      $endDateBox.style.display = "none";

      vm.picColor.startStatus = false;
      vm.picColor.endStatus = false;

      console.log(vm.picStartDate);
      console.log(vm.picEndDate);

      await this.clickRPAButton();

      // vm.drivList = await vm.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', '2022-12-10', '2023-11-10');

      // console.log(vm.drivList);
    },
    onDatePic(flag) {
      if (flag == "S") {
        if (window.selectDate.startDate !== null) {
          this.picStartDate = window.selectDate.startDate;
        }
      } else if (flag == "E") {
        if (window.selectDate.picEndDate !== null) {
          this.picEndDate = window.selectDate.endDate;
        }
      }
    },
    pickerNext() {
      this.$refs.startDateBox.style.display = "none";
      this.$refs.endDateBox.style.display = "block";

      this.picColor.startStatus = false;
      this.picColor.endStatus = true;
      console.log(this.picColor.endStatus);
      console.log(this.picColor.target);
    },
    pickerPrev() {
      this.$refs.startDateBox.style.display = "block";
      this.$refs.endDateBox.style.display = "none";

      this.picColor.startStatus = true;
      this.picColor.endStatus = false;
    },

    //filter=========
    filterToggle() {
      const $listFilter = this.$refs.listFilter;
      const $filterBox = this.$refs.filterListBox;
      const $filterBtn = this.$refs.filterAllBtn;
      const hasOpenClass = $filterBox.classList.contains("open");
      if (hasOpenClass) {
        gsap.to($listFilter, {
          onStart: () => {
            $filterBox.classList.remove("open");
            $filterBtn.classList.remove("active");
            $filterBtn.innerText = "전체보기";
          },
          height: "70px",
          duration: 0.3,
        });
      } else {
        gsap.to($listFilter, {
          onStart: () => {
            $filterBox.classList.add("open");
            $filterBtn.classList.add("active");
            $filterBtn.innerText = "접기";
          },
          height: "auto",
          duration: 0.3,
        });
      }
    },
    removeFilter(index) {
      const $filterItem = this.$refs.filterItem;
      const $filterInputWrap = this.$refs.filterInputWrap;
      const dataId = this.filterList[index].keyId.trim();
      console.log(dataId);
      console.log(
        $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList
      );
      $filterInputWrap
        .querySelector(`[data-id='${dataId}']`)
        .classList.remove("disable");
      $filterInputWrap
        .querySelector(`[data-id='${dataId}']`)
        .querySelector("input").disabled = false;
      this.filterList.splice(index, 1);
      if ($filterItem.length <= 1) {
        this.$refs.filterAllBtn.style.display = "none";
      }
      this.filterAction(true);
    },
    showFilter(event) {
      const _this = event.target;

      const $filterBtn =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
      const $filterWrap = document.querySelector("#filterWrap");
      const $filterInput = $filterWrap.querySelectorAll(".filter_input");

      const hasShowClass = $filterWrap.classList.contains("show");
      const hasActiveClass = _this.classList.contains("active");

      for (var i = 0; i < $filterBtn.length; i++) {
        $filterBtn[i].classList.remove("active");
      }
      _this.classList.add("active");
      if (hasShowClass) {
        if (hasActiveClass) {
          $filterWrap.classList.remove("show");
          _this.classList.remove("active");
        }
      } else {
        $filterWrap.classList.add("show");
      }
      let activeIndex = Array.from($filterBtn).findIndex((element) =>
        element.classList.contains("active")
      );
      // console.log(activeIndex);
      // input
      for (var j = 0; j < $filterInput.length; j++) {
        $filterInput[j].querySelector("div").classList.remove("active");
      }
      // console.log($filterInput[activeIndex]);
      if (activeIndex > -1) {
        $filterInput[activeIndex].querySelector("div").classList.add("active");
      }
    },
    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },
    async addFilter(event) {
      console.log("addFilter");

      const _this = event.target;
      const $thTag = _this.parentNode.parentNode;
      const $addBtn = $thTag.querySelector("button");
      const $input = $thTag.querySelector("input");
      // input 글자수
      const trimVal = $input.value.trim();

      // 컬럼 이름 가져오기
      const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode
        .querySelector("tr:first-child")
        .querySelectorAll(".btn_filter");
      let activeIndex = Array.from(keyNameArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyName = String(keyNameArr[activeIndex].parentNode.textContent);

      const dataKeyArr =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".input_box");
      const dataKeyindex = Array.from(dataKeyArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyId = String(dataKeyArr[dataKeyindex].getAttribute("data-id"));

      const obj = {};
      console.log();
      // console.log(this.getAttribute('data-index'))
      // console.log($input);

      window.filterData.taget = _this.getAttribute("data-index");

      if (0 < trimVal.length) {
        obj["keyId"] = keyId;
        obj["value"] = trimVal;
        obj["keyName"] = keyName;
        $input.value = "";
        this.filterList.push(obj);
        this.$refs.filterAllBtn.style.display = "block";
        $addBtn.parentNode.classList.add("disable");
        $addBtn.previousElementSibling.disabled = true;
        this.filterAction();
      }
    },
    filterAction(remove) {
      const vm = this;
      // 필터 리스트
      const _filterLsit = vm.filterList;
      // 테이블 리스트
      let _tableList = vm.rPA;
      if (vm.isfilter) {
        vm.filterDataList.origin = _tableList;
        vm.isfilter = false;
      }

      if (_filterLsit.length > 0) {
        const lodash = require("lodash");
        if (remove) {
          // 필터 삭제
          for (var j = 0; j < _filterLsit.length; j++) {
            const searchString = _filterLsit[j].value;

            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[j].keyId;
            const filteredData = lodash.filter(
              vm.filterDataList.origin,
              (item) => regexPattern.test(item[keyid])
            );
            vm.rPA = filteredData;
            // vm.currentPage = 1;
            // vm.totalItems = vm.rPA.length;
            // vm.displayActivityLogInModal(vm.rPA);
          }
        } else {
          // 필터 추가
          for (var i = 0; i < _filterLsit.length; i++) {
            const searchString = _filterLsit[i].value;

            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[i].keyId;
            const filteredData = lodash.filter(_tableList, (item) => {
              return regexPattern.test(item[keyid]);
            });
            vm.rPA = filteredData;
            // vm.currentPage = 1;
            // vm.totalItems = vm.rPA.length;
            // vm.displayActivityLogInModal(vm.rPA);
          }
        }
        console.log("필터 동작 시작");
      } else {
        vm.rPA = vm.filterDataList.origin;
        // vm.currentPage = 1;
        // vm.totalItems = vm.rPA.length;
        // vm.displayActivityLogInModal(vm.rPA);
      }
      vm.currentPage = 1;
      vm.totalItems = vm.rPA.length;
      vm.displayActivityLogInModal(vm.rPA);
      console.log(vm.currentPage);
    },
    //=========

    async getData(url) {
      // const jwt = Cookies.get('jwt');
      try {
        // const headers = {
        //     'Authorization': `Bearer ${jwt}`
        // }
        // const response = await axios.get(`https://api.dlfe.dev.micosa.io/${url}`, {headers:headers});
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getDataWithParams(url, params) {
      // const jwt = Cookies.get('jwt');
      try {
        // const headers = {
        //     'Authorization': `Bearer ${jwt}`
        // }
        // const response = await axios.get(`https://api.dlfe.dev.micosa.io/${url}`, {
        //     params: params,
        //     headers: headers
        // });
        // console.log(response);
        const response = await common.apiGet(`/${url}`, params);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
      }
    },

    //내가 가진 프로젝트 조회
    async getProject() {
      // const jwt = Cookies.get('jwt');

      try {
        // const response = await axios.get(`${this.uri}/project/home`, {
        //     headers: {Authorization: `Bearer ${jwt}`}
        // });
        const response = await common.apiGet("/project/home");
        const projectData = response.data.data;
        this.list = projectData.projectList;
      } catch (error) {
        console.error("error get project data : ", error);
      }
    },
    //recent projects activity(RPsA)
    async recentProjectsActivity() {
      // const jwt = Cookies.get('jwt');

      try {
        // const response = await axios.get(`${this.uri}/activitylog/project/home`, {
        //     headers: {Authorization: `Bearer ${jwt}`}
        // });
        const response = await common.apiGet("/activitylog/project/home");
        this.activityLogData = response.data.data;
        this.activityLogDataLimit10 = this.activityLogData.filter(
          (item) =>
            (item.activity_log = item.activity_log.substring(0, 30) + " ...")
        );
        this.activityLogDataLimit10 = this.activityLogDataLimit10.slice(0, 10);
        window.recentProjectsActivityLength = response.data.data.length;
      } catch (error) {
        console.error("error recentProjectsActivity : ", error);
      }
    },
    async getProjectOptions() {
      try {
        let url = "project";
        const response = await this.getData(url);
        this.projectData = response;
      } catch (error) {
        console.error("error getProjectOptions : ", error);
      }
    },
    //recent project activity 모달 디폴트 화면
    async defaultRpa() {
      try {
        let url = "activitylog/project/home";
        const response = await this.getData(url);
        this.selectProject = null;
        this.selectDate = null;
        this.rPA = response;
        this.totalItems = this.rPA.length;
        this.displayActivityLogInModal(this.rPA);
      } catch (error) {
        console.error("error defaultRpa : ", error);
      }
    },
    //recent project activity 조회 버튼 클릭시 나오는 리스트
    async clickRPAButton() {
      console.log("clickRPAButton() start");
      this.currentPage = 1;

      console.log(this.selectProject);

      const projectId = this.selectProject == null ? 0 : this.selectProject;
      // const startDate = null;
      // const endDate = null;
      console.log(projectId);
      const params = {
        "start-date": this.picStartDate,
        "end-date": this.picEndDate,
      };

      try {
        let url = `activitylog/project/${projectId}`;
        const response = await this.getDataWithParams(url, params);
        this.rPA = response;
        this.currentPage = 1;
        this.totalItems = this.rPA.length;
        this.displayActivityLogInModal(this.rPA);

        // filter 확인 해서 제거
        this.removeAllFilter();
      } catch (error) {
        console.error("error clickRPAButton : ", error);
      }
    },

    displayActivityLogInModal(rpa) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.rpaSliced = rpa.slice(startIndex, endIndex);
      console.log(this.currentPage);
      console.log(this.rpaSliced);
    },

    removeAllFilter() {
      const $filterItem = this.$refs.filterItem;
      const $filterInputWrap = this.$refs.filterInputWrap;

      if (common.nullCheck($filterItem) !== "") {
        this.$refs.filterAllBtn.style.display = "none";
        this.filterList = [];
        this.filterAction(true);

        const list = $filterInputWrap.querySelectorAll("[data-id]");
        list.forEach((item) => {
          item.classList.remove("disable");
          item.querySelector("input").disabled = false;
        });
      }
    },

    timeSet(unixTimestamp) {
      const date = new Date(unixTimestamp);

      // Extract year, month, and day components
      const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
      const day = date.getDate().toString().padStart(2, "0");
      const yyMmDd = `${year}.${month}.${day}`;

      return yyMmDd;
    },
    projectImage(type) {
      if (type == "Vehicle")
        return require("@/assets/images/common/project_vehicledata.svg");
      else if (type == "SmartFactory")
        return require("@/assets/images/common/project_smartfactory.svg");
      else if (type == "IoT")
        return require("@/assets/images/common/project_iot.svg");
      // else if(type == 'Robot') return '@/assets/images/common/project_.svg';
      else return require("@/assets/images/common/project_etc.svg");
    },

    // -------pagination

    onPageChanged(page) {
      this.currentPage = page;
      this.displayActivityLogInModal(this.rPA);
    },

    // ------- ui
    addProject() {
      window.location.href = "/projectOps/addProject";
    },
    showModal() {
      console.log("open modal");

      const $modal = this.$refs.modalWrap;
      $modal.style.display = "block";
      this.defaultRpa();
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "none";
    },

    //--------------projectOps url
    async projectOps(event, projectId) {
      event.preventDefault();
      try {
        this.$router.push({
          name: "Workspace",
          params: { index: projectId },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    dateSelectChange() {
      switch (this.selectDate) {
        case "7":
          this.dateSet = 7;
          break;
        case "14":
          this.dateSet = 14;
          break;
        case "30":
          this.dateSet = 30;
          break;
        default:
          this.dateSet = 1;
          break;
      }

      const todayDate = new Date();

      todayDate.setDate(todayDate.getDate() - this.dateSet);
      const year1 = todayDate.getFullYear();
      const month1 = todayDate.getMonth() + 1;
      const day1 = todayDate.getDate();
      const formattedDate1 =
        year1 +
        "-" +
        (month1 < 10 ? "0" : "") +
        month1 +
        "-" +
        (day1 < 10 ? "0" : "") +
        day1;

      this.picStartDate = formattedDate1;
      window.selectDate.startDate = formattedDate1;
    },

    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.float_box {
  & > div {
    float: left;
    &.left_box {
      width: 40%;
      section {
        .total_num {
          position: absolute;
          top: 0;
          right: 30px;
          color: #6e7380;
          span {
            color: $mainColor;
            font-weight: $bold;
          }
        }
        ul {
          width: 100%;
          height: 590px;
          overflow-y: scroll;
          position: relative;
          left: -10px;
          top: -10px;
          // -ms-overflow-style: none;
          // &::-webkit-scrollbar {
          //     display: none;
          // }
          li {
            width: 100%;
            box-sizing: border-box;
            padding: 20px 20px 20px 105px;
            background-color: $whiteColor;
            margin-bottom: 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #002b6833;
            position: relative;
            left: 10px;
            top: 10px;
            .project_icon {
              width: 60px;
              height: 60px;
              background-color: #fff;
              border-radius: 20px;
              position: absolute;
              top: 20px;
              left: 30px;
            }
            strong {
              display: block;
              font-size: $fontMenuTitle;
              font-weight: $bold;
            }
            .project_update,
            .project_level {
              margin-top: 4px;

              span {
                color: $placeHolderColor;
              }
            }
            .setting_btn {
              width: 16px;
              height: 16px;
              position: absolute;
              top: 15px;
              right: 20px;
              background-color: $whiteColor;
              background-image: url($baseURL + "common/projectedit_icon.svg");
              background-position: center;
            }
            .data_status {
              width: 28px;
              height: 19px;
              position: absolute;
              right: 20px;
              bottom: 15px;
            }
            @keyframes rotate {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            .processing {
              position: absolute;
              top: 5px;
              right: 0;
              animation: rotate 2s linear infinite;
            }
          }
        }
      }
    }
    &.right_box {
      width: 60%;
      section {
        .total_num {
          position: absolute;
          top: 0;
          left: 200px;
          color: #6e7380;
          span {
            color: $mainColor;
            font-weight: $bold;
          }
        }
        .grid_box {
          width: 100%;
          height: 430px;
          background-color: $whiteColor;
          border-radius: 10px;
          box-shadow: 0px 0px 10px #002b6833;
        }
        .history_box {
          width: 100%;
          height: 110px;
          position: relative;
          border-radius: 10px;
          background-color: transparent;
          &.none_item {
            background-color: $line02Color;
          }
          .history_list {
            display: flex;
            flex-wrap: wrap;
            // flex-direction: column;
            li {
              width: 220px;
              height: 50px;
              justify-content: space-between;
              background-color: #fdfeff;
              margin-bottom: 10px;
              margin-right: 14px;
              box-sizing: border-box;
              border: 1px solid #cad3e2;
              border-radius: 10px;
              background-repeat: no-repeat;
              background-position-y: center;
              background-position-x: 15px;
              &.project_icon {
                background-image: url($baseURL + "common/leftm_project_press.svg");
              }
              &.datatask_icon {
                background-image: url($baseURL + "common/leftm_task_press.svg");
              }
              &.system_icon {
                background-image: url($baseURL + "common/leftm_system_press.svg");
              }
              a {
                width: 100%;
                height: 100%;
                display: block;
                line-height: 50px;
                text-align: center;
                text-indent: 20px;
              }
            }
          }
          .history_btn {
            width: 25px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            button {
              width: 25px;
              height: 25px;
              background-color: $line02Color;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              border-radius: 5px;
              &.uplist {
                top: 0;
                left: 0;
                background-image: url($baseURL + "common/textbox_arrowup.svg");
                transform: rotate(90deg);
              }
              &.downlist {
                bottom: 0;
                left: 0;
                background-image: url($baseURL + "common/textbox_arrowdown.svg");
                transform: rotate(90deg);
              }
            }
          }
          &.none_item {
            &::after {
              display: block;
              width: 100%;
              height: 110px;
              line-height: 110px;
              content: "최근에 사용한 메뉴가 보여집니다.";
              color: $placeHolderColor;
              font-size: $fontMenuTitle;
              background-image: url($baseURL + "common/guide_small.svg");
              background-repeat: no-repeat;
              background-position: 225px center;
              text-align: center;
              text-indent: 50px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        &:last-child {
          margin-top: 18px;
        }
      }
    }
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
#modalWrap {
  .float_box {
    height: auto;
  }
  .modal_contents {
    width: 1200px;
    height: 800px;
    padding: 25px;
    box-sizing: border-box;
    #recentFilter {
      width: 100%;
      min-height: 130px;
      border: 1px solid $line01Color;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 15px 20px;
      .date_filter {
        padding-left: 20px;
      }
      .filter_list_box {
        width: 100%;
        height: 100%;
        position: relative;
        .float_box {
          width: 100%;
          box-sizing: border-box;
          position: relative;
          top: -10px;
          li {
            display: inline-block;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            border-radius: 10px;
            padding: 10px 30px 10px 15px;
            position: relative;
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
              margin-right: 0;
            }
            span {
              &:last-child {
                color: #4791ff;
              }
            }
          }
          .btn_close_item {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 9px;
            right: 4px;
            &::before {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(45deg);
            }
            &::after {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .btn_all_filter {
        height: 45px;
        padding: 0 20px 0 10px;
        font-size: $fontSub;
        color: $placeHolderColor;
        position: absolute;
        top: 55px;
        right: 0;
        text-align: left;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url($baseURL + "/common/selectdropdown_small.svg");
        display: none;
        &.active {
          background-image: url($baseURL + "/common/selectdropup_small.svg");
        }
      }
    }
    .list_wrap {
      width: 100%;
      height: 570px;

      //스크롤 기능 추가
      // overflow: auto;
      .table_list {
        box-shadow: none;
        border: 1px solid $line01Color;
        .total_num {
          box-sizing: border-box;
          padding: 20px;
          padding-bottom: 0;
          font-size: $fontSub;
          span {
            color: $mainColor;
            font-weight: $bold;
            font-size: $fontSub;
          }
        }
      }
      .mando_table_wrap {
        .mando_table {
          position: relative;
        }
      }
    }
  }
}
#modalWrap {
  .mando_table_wrap {
    height: 450px;
  }
}
</style>
