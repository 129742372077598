<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_siteSetList" :checkRole="manageRole" />
      <div class="title_btn">
        <button
          type="button"
          class="add_project bgicon"
          @click="setSite('insert')"
        >
          신규 Site 등록
        </button>
      </div>
      <div class="list_wrap float_box">
        <div class="list_filter_wrap float_box">
          <div class="filter_list_box">
            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input_text">
                <input
                  type="text"
                  placeholder="KeyWord"
                  v-model="searchKeyword"
                />
              </div>
              <div class="search_form_box">
                <button
                  type="button"
                  class="btn_send mcbtn"
                  @click="clickSearch()"
                >
                  조회
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ dataList.length }}</span> Site
            </div>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <colgroup></colgroup>
              <thead>
                <tr>
                  <th>
                    <div class="col_name">국가명(Nation)</div>
                  </th>
                  <th>
                    <div class="col_name">지역명(Region)</div>
                  </th>
                  <th>
                    <div class="col_name">Site 명</div>
                  </th>
                  <th>
                    <div class="col_name">GPS 중심 좌표</div>
                  </th>
                  <th>
                    <div class="col_name">등록자명</div>
                  </th>
                  <th>
                    <div class="col_name">등록 일자</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="m_pointer"
                  v-for="(item, index) in dataList"
                  :key="index"
                  @click="setSite('view', item.site_id)"
                >
                  <td>{{ item.location_nation }}</td>
                  <td>{{ item.location_region }}</td>
                  <td>{{ item.location_detail }}</td>
                  <td>{{ item.gps_lat }} / {{ item.gps_lng }}</td>
                  <td>{{ item.register_name }}</td>
                  <td>{{ item.create_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal" ref="modalWrap" style="display: none">
    <div class="info_modal radiusbox">
      <strong class="modal_title">작업 장소 - Site 등록</strong>
      <div class="info">
        <div class="row">
          <div class="title">등록자명 / 등록일자</div>
          <div class="txt">
            <input type="text" v-model="siteSet.registerName" disabled />
            <input type="text" v-model="siteSet.createDate" disabled />
          </div>
          <div class="title">Site 접근성</div>
          <div class="txt">
            <select v-model="siteSet.siteAccess">
              <option value="">선택해 주세요</option>
              <option
                v-for="item in accessLevel"
                :key="item.dtl_code"
                :value="item.code + '|' + item.dtl_code"
              >
                {{ item.dtl_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="title">Site 위치</div>
          <div class="txt">
            <select v-model="siteSet.siteNation">
              <option value="">선택해 주세요</option>
              <option
                v-for="item in locationNation"
                :key="item.dtl_code"
                :value="item.code + '|' + item.dtl_code"
              >
                {{ item.dtl_name }}
              </option>
            </select>
            <select v-model="siteSet.siteRegion">
              <option value="">선택해 주세요</option>
              <option
                v-for="item in locationRegion"
                :key="item.dtl_code"
                :value="item.code + '|' + item.dtl_code"
              >
                {{ item.dtl_name }}
              </option>
            </select>
          </div>
          <div class="title">상세 위치</div>
          <div class="txt" style="display: block">
            <input type="text" v-model="siteSet.siteSetName" />
            <div class="txt_already" v-if="siteSet.already">
              <p>● 기존에 존재하는 상세 위치명 입니다.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="title">중심 GPS 좌표</div>
          <div class="txt">
            <span>위도</span>
            <input type="number" v-model="latlng.lat" @keyup="setLatlng" />
            <span>경도</span>
            <input type="number" v-model="latlng.lng" @keyup="setLatlng" />
          </div>
        </div>
        <div class="row">
          <div class="title">Description</div>
          <div class="txt">
            <textarea v-model="siteSet.description"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="title">OSM Map 등록<br />&amp; Region 설정</div>
          <div class="txt">
            <MapController
              ref="map"
              :pointList="pointList"
              :siteCenterSpot="siteCenterSpot"
              :page="'site'"
              :mapType="'normal'"
              @updatePoint="chgPoint"
              style="width: 350px; height: 300px"
            />
            <div class="map_setting">
              <!-- <div class="upload">
                <label for="file_input">OSM Map 파일 업로드</label>
                <div class="file_name">OSM Map 파일명 : <span></span></div>
                <input
                  id="file_input"
                  type="file"
                  ref="fileInput"
                  class="blind"
                  @change="onFileChange($event)"
                  accept=".osm"
                />
              </div> -->
              <ul>
                <li>
                  <div class="point"><i>s</i></div>
                  <div class="max_txt">Start Point :</div>
                  <div class="input">
                    <input
                      type="text"
                      :value="
                        sPoint.lat !== ''
                          ? sPoint.lat + '  /  ' + sPoint.lng
                          : ''
                      "
                      disabled
                    />
                    <button type="button" @click="removePoint('s')">
                      <font-awesome-icon :icon="['fas', 'xmark']" />
                    </button>
                  </div>
                </li>
                <li>
                  <div class="point"><i>f</i></div>
                  <div class="max_txt">Finish Point :</div>
                  <div class="input">
                    <input
                      type="text"
                      :value="
                        fPoint.lat !== ''
                          ? fPoint.lat + '  /  ' + fPoint.lng
                          : ''
                      "
                      disabled
                    />
                    <button type="button" @click="removePoint('f')">
                      <font-awesome-icon :icon="['fas', 'xmark']" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="topic_area">
        <button type="button" class="btn_close" @click="closeModal"></button>
        <div class="save_box">
          <button type="button" class="close_btn" @click="closeModal">
            취소
          </button>
          <button type="button" class="mcbtn" @click="registSite">등록</button>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import "@/assets/js/filter";
import apiRobot from "@/assets/js/apiRobot";
import common from "@/assets/js/common";
import { mapGetters } from "vuex";
import MapController from "@/components/MapController.vue";
import parsePbf from "osm-read/osm-read-pbf";

export default {
  name: "topicSetList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    PaginationUi,
    BreadcrumbLayout,
    VCalendarUi,
    MapController,
  },
  data() {
    return {
      filterList: [],
      filterRowData: [],
      perPageList: [],
      perPage: 0,

      searchKeyword: "",

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      dataList: [],
      dataListSliced: [],
      isfilter: true,
      filterDataList: {
        origin: {},
      },

      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 50,
      siteSet: {
        id: 0,
        registerName: "",
        createDate: "",
        siteSetName: "",
        siteAccess: "",
        siteNation: "",
        siteRegion: "",
        already: false,
        sites: [],
        description: "",
      },
      today: "",
      latlng: {
        lat: common.getDefaultLat(),
        lng: common.getDefaultLong(),
      },
      sPoint: {
        lat: "",
        lng: "",
      },
      fPoint: {
        lat: "",
        lng: "",
      },

      accessLevel: [],
      locationNation: [],
      locationRegion: [],
      pointList: [],
      siteCenterSpot: {},
    };
  },
  created() {},
  async mounted() {
    this.setRole();
    this.setDate(this.selectDuration);
    this.$refs.lodingWrap.style.display = "block";
    await this.getMetaInfo();
    await this.getSiteList();
    this.$refs.lodingWrap.style.display = "none";
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    ...mapGetters(["getUserData"]),
  },
  methods: {
    async getMetaInfo() {
      try {
        const param = {
          type: "",
        };
        const response = await apiRobot.post("/site/site_meta", param);

        this.accessLevel = response.data.access_level;
        this.locationNation = response.data.location_nation;
        this.locationRegion = response.data.location_region;

        console.log("response :: ", response);
      } catch (error) {
        console.error("error", error);
      }
    },
    onFileChange(e) {
      const files = e.target.files;

      parsePbf.parse({
        file: files,
        endDocument: function () {
          console.log("document end");
        },
        node: function (node) {
          console.log("node: " + JSON.stringify(node));
        },
        way: function (way) {
          console.log("way: " + JSON.stringify(way));
        },
        relation: function (relation) {
          console.log("relation: " + JSON.stringify(relation));
        },
        error: function (msg) {
          console.error("error: " + msg);
          throw msg;
        },
      });
    },

    setLatlng() {
      const lat = this.latlng.lat;
      const lng = this.latlng.lng;

      this.$refs.map.updateLatlng(lat, lng);
    },
    removePoint(sort) {
      if (sort === "s") {
        this.sPoint = {
          lat: "",
          lng: "",
        };
      } else {
        this.fPoint = {
          lat: "",
          lng: "",
        };
      }
      this.$refs.map.removePoint(sort);
    },
    chgPoint(point) {
      for (let item of point) {
        if (item.point === "s") {
          this.sPoint = item;
        } else {
          this.fPoint = item;
        }
      }
    },
    async registSite() {
      try {
        const siteId = this.siteSet.id;
        const userId = this.getUserData.memId;
        const userName = this.getUserData.name;
        const siteAccess = this.siteSet.siteAccess;
        const siteNation = this.siteSet.siteNation;
        const siteRegion = this.siteSet.siteRegion;
        const siteSetName = this.siteSet.siteSetName;
        const description = this.siteSet.description;
        const sPointLat = this.sPoint.lat;
        const sPointLng = this.sPoint.lng;
        const fPointLat = this.fPoint.lat;
        const fPointLng = this.fPoint.lng;

        if (siteAccess === "") {
          alert("Site 접근성을 선택해 주세요.");
          return false;
        }

        if (siteNation === "" || siteRegion === "") {
          alert("Site 위치를 선택해 주세요.");
          return false;
        }

        if (siteSetName === "") {
          alert("상세위치를 기재해 주세요.");
          return false;
        }

        if (description === "") {
          alert("Site 설명을 기재해 주세요.");
          return false;
        }

        if (sPointLat === "" && sPointLng === "") {
          alert("Start Point를 입력해 주세요.");
          return false;
        }

        if (fPointLat === "" && fPointLng === "") {
          alert("Finish Point를 입력해 주세요.");
          return false;
        }

        const insertParam = {
          access_level: siteAccess,
          location_nation: siteNation,
          location_region: siteRegion,
          location_detail: siteSetName,
          gps_lat: this.latlng.lat.toString(),
          gps_lng: this.latlng.lng.toString(),
          description: description,
          osm_map_type: 0,
          //osm_map_url: "",
          osm_gps_start_lat: sPointLat.toString(),
          osm_gps_start_lng: sPointLng.toString(),
          osm_gps_finish_lat: fPointLat.toString(),
          osm_gps_finish_lng: fPointLng.toString(),
          register_id: userId,
          register_name: userName,
        };
        const updateParam = {
          site_id: siteId,
          access_level: siteAccess,
          location_nation: siteNation,
          location_region: siteRegion,
          location_detail: siteSetName,
          gps_lat: this.latlng.lat.toString(),
          gps_lng: this.latlng.lng.toString(),
          description: description,
          osm_map_type: 0,
          //osm_map_url: "",
          osm_gps_start_lat: sPointLat.toString(),
          osm_gps_start_lng: sPointLng.toString(),
          osm_gps_finish_lat: fPointLat.toString(),
          osm_gps_finish_lng: fPointLng.toString(),
          updater_id: userId,
          updater_name: userName,
        };
        let response = null;
        if (siteId !== 0) {
          response = await apiRobot.post("/site/site_update", updateParam);
        } else {
          response = await apiRobot.post("/site/site_insert", insertParam);
        }
        if (response.status === 200 || response.status === "200") {
          if (response.data.result === "already") {
            this.siteSet.already = true;
            return false;
          } else {
            alert("등록되었습니다.");
            this.closeModal();
            this.getSiteList();
          }
        }
      } catch (error) {
        console.error("error", error);
      }
    },

    async setSite(status, siteId) {
      if (status === "insert") {
        this.siteSet.id = 0;
        this.siteSet.registerName = this.getUserData.name;
        this.siteSet.createDate = this.today;
        this.siteSet.siteAccess = "";
        this.siteSet.siteNation = "";
        this.siteSet.siteRegion = "";
        this.siteSet.siteSetName = "";
        this.siteSet.description = "";
        this.sPoint.lat = "";
        this.sPoint.lng = "";
        this.fPoint.lat = "";
        this.fPoint.lng = "";
        this.$refs.map.resetPoint();
        this.latlng.lat = common.getDefaultLat();
        this.latlng.lng = common.getDefaultLong();
      } else {
        await this.setSiteDetail(siteId);
      }
      this.setLatlng();
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "block";
    },
    async setSiteDetail(siteId) {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      try {
        const param = {
          site_id: siteId,
        };
        const response = await apiRobot.post("/site/site_select", param);
        if (response.status === 200 || response.status === "200") {
          const data = response.data.item;
          this.siteSet.id = data.site_id;
          this.siteSet.registerName = data.register_name;
          this.siteSet.createDate = data.create_date;
          this.siteSet.siteAccess = data.access_level;
          this.siteSet.siteNation = data.location_nation;
          this.siteSet.siteRegion = data.location_region;
          this.siteSet.siteSetName = data.location_detail;
          this.siteSet.description = data.description;
          this.sPoint.lat = data.osm_gps_start_lat;
          this.sPoint.lng = data.osm_gps_start_lng;
          this.fPoint.lat = data.osm_gps_finish_lat;
          this.fPoint.lng = data.osm_gps_finish_lng;
          this.siteCenterSpot = {
            lat: data.gps_lat,
            lng: data.gps_lng,
          };
          this.pointList = [];
          let param = {
            lat: this.sPoint.lat,
            lng: this.sPoint.lng,
            point: "s",
          };
          this.pointList.push(param);
          param = {
            lat: this.fPoint.lat,
            lng: this.fPoint.lng,
            point: "f",
          };
          this.pointList.push(param);
          this.$refs.map.updatePoint(this.pointList);
          this.latlng.lat = Number(data.gps_lat);
          this.latlng.lng = Number(data.gps_lng);
          loading.style.display = "none";
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "none";
      this.siteSet.already = false;
    },
    clickSearch() {
      this.getSiteList();
    },
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.today = vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },

    async getSiteList() {
      try {
        const keyword = this.searchKeyword;
        const startDate = this.picStartDate;
        const endDate = this.picEndDate;
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const limit = this.itemsPerPage;
        const param = {
          date_from: startDate,
          date_to: endDate,
          keyword: keyword,
          order_name: "create_date",
          order_value: "desc",
          limit: limit,
          offset: offset,
        };
        const response = await apiRobot.post("/site/site_select_list", param);
        if (response.status === 200 || response.status === "200") {
          this.dataList = response.data.items;
          for (const data of this.dataList) {
            for (const nation of this.locationNation) {
              const nationCode = nation.code + "|" + nation.dtl_code;
              if (data.location_nation === nationCode) {
                data.location_nation = nation.dtl_name;
              }
            }
            for (const region of this.locationRegion) {
              const regionCode = region.code + "|" + region.dtl_code;
              if (data.location_region === regionCode) {
                data.location_region = region.dtl_name;
              }
            }
          }
          console.log("%%%%%%%%%%%%% :: ", this.dataList);
        }
      } catch (error) {
        console.error("error", error);
      }
      this.currentPage = 1;
      this.totalItems = this.dataList.length;
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getSiteList();
    },

    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  .list_filter_wrap {
    overflow: visible;
    .filter_list_box {
      width: 100%;
      height: 100%;
      &::after {
        border-radius: 10px;
      }
      .filter_box {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        padding: 0 30px;
        height: 100%;
        box-sizing: border-box;
        .date_filter {
          position: relative;
          .date_period {
            margin: 0;
          }
          &::after {
            display: none;
          }
        }
        .input_text {
          input {
            width: 220px;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
        }
        .search_form_box {
          .btn_send {
            height: 40px;
            padding: 0 15px;
            border-radius: 10px;
            line-height: 37px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .table_list {
    margin-top: 20px;
    height: 715px;
  }
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
.title_btn {
  position: absolute;
  top: 30px;
  right: 42px;
  display: flex;
  gap: 15px;
  button {
    border-radius: 10px;
    font-weight: $bold;
    background-repeat: no-repeat;
    &.bgicon {
      padding: 13px 15px 13px 35px;
      color: $whiteColor;
      background-color: $mainColor;
      background-position-y: center;
      background-position-x: 10px;
      &.add_project {
        background-image: url($baseURL + "common/add_15_wh.svg");
      }
      &.setting_project {
        background-image: url($baseURL + "common/contents_btnicon_settings.svg");
      }
    }
    &.btn_view_log {
      background-color: $whiteColor;
      border: 1px solid #dedede;
      border-radius: 10px;
      padding: 13px 17px;
      font-weight: $regular;
    }
    &:disabled {
      background-color: $line02Color;
    }
  }
}
#modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .info_modal {
    width: 80%;
    height: 80vh;
    overflow: auto;
    padding: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal_title {
      display: block;
      font-size: $fontMenuTitle;
      font-weight: $bold;
      margin-bottom: 30px;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border-bottom: 1px solid #d5dae3;
      padding-bottom: 25px;
      .row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        margin-top: 10px;
        .title {
          width: 15%;
          min-width: 100px;
        }
        .txt {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 10px;
          .map_setting {
            flex: 1;
            .upload {
              display: flex;
              gap: 15px;
              align-items: center;
              margin-bottom: 25px;
              .file_name {
                font-weight: 600;
              }
              label {
                padding: 6px 20px;
                background-color: #405261;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
                border-radius: 10px;
              }
            }
            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              li {
                display: flex;
                gap: 10px;
                align-items: center;
                .point {
                  width: 25px;
                  i {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    border: 2px solid #00b4ed;
                    border-radius: 50%;
                    color: #00b4ed;
                    font-weight: 600;
                    font-size: 20px;
                  }
                }
                .max_txt {
                  width: 90px;
                }
                .input {
                  width: 60%;
                  position: relative;
                  input {
                    padding-right: 35px;
                  }
                  button {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    width: 25px;
                    height: 25px;
                    font-size: 18px;
                    transform: translateY(-50%);
                    background-color: #00b4ed;
                    color: #fff;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
          textarea {
            width: 100%;
            height: 100px;
            padding: 10px;
            resize: none;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
          span {
            width: 120px;
            text-align: center;
          }
          input {
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
          select {
            width: 100%;
            background-position: center right 15px;
          }
        }
        .txt_already {
          width: 100%;
          padding-top: 15px;
          p {
            color: #f15046;
            font-weight: 600;
          }
        }
      }
    }
    .save_box {
      width: 100%;
      height: 45px;
      text-align: right;
      position: relative;
      margin: 20px 0;
      button {
        width: 90px;
        height: 45px;
        text-align: center;
        border-radius: 10px;
        margin: 0 10px;
        &.btn_font_s {
          width: 80px;
          height: 40px;
          font-size: 13px;
        }
      }
      .close_btn {
        border: 1px solid #dedede;
        &.btn_red {
          border: 0;
          color: #fff;
          background-color: #f15046;
        }
      }
    }
    .btn_close {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-image: url($baseURL + "/common/popup_cancel.svg");
      background-position: center;
      position: absolute;
      top: 6px;
      right: 6px;
    }
    .topic_area {
      padding-top: 25px;
      .title {
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: 600;
      }
      .topic {
        padding: 20px 0 25px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        .row {
          display: flex;
          padding-bottom: 10px;
          align-items: center;
          .left {
            width: 85px;
            text-align: center;
            font-weight: 600;
          }
          .right {
            flex: 1;
            text-align: center;
            font-weight: 600;
          }
          &.row_content {
            margin-top: 10px;
            padding-bottom: 0;
            .left {
              font-weight: 500;
            }
            .right {
              position: relative;
              text-align: left;
              margin-right: 15px;
              font-weight: 500;
              .topic_box {
                width: 100%;
                padding: 0 10px;
                height: 35px;
                border: 1px solid #d5dae3;
                border-radius: 10px;
                box-sizing: border-box;
              }
              .topic_remove {
                position: absolute;
                display: inline-flex;
                top: 50%;
                right: 7px;
                width: 30px;
                height: 30px;
                transform: translateY(-50%);
                justify-content: center;
                align-items: center;
                background-color: #405261;
                border-radius: 50%;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
