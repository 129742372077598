<template>
  <div>
    <div class="markdown-container">
      <Markdown :source="mdText" />
    </div>
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it'

export default {
  components: {
    Markdown
  },
  data() {
    let mdText = "";
    return { mdText }
  },
  setup() {
    return {}
  },
  methods: {
    drawMDText(mdText){
      this.mdText = mdText;
    },
    clearMdText(){
      this.mdText = "";
    }
  }
}
</script>

<style scoped>
::v-deep(*)
{
  all: revert;
}
</style>

<style>
/* 📌 스크롤 가능한 Markdown 컨테이너 */
.markdown-container {
  width: 100%;
  max-width: 1220px;
  height: 450px; /* 고정 높이 설정 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
  margin-top: 10px;
}

/* 📌 Markdown 스타일 (테이블 가독성 개선) */
.markdown-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.markdown-container th,
.markdown-container td {
  padding: 10px;
  border: 1px solid #ddd; /** default border line **/
}

.markdown-container th {
  background-color: #f4f4f4;
  font-weight: bold;
}
</style>