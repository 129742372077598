<!-- eslint-disable no-undef -->
<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents" style="padding-bottom: 40px">
      <BreadcrumbLayout
        pageId="monTASK_multiAgentMonitoring"
        :name="'[TASK_AD] ' + taskData.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ prjData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ prjData.project_name }}</td>
                <td>{{ prjData.aws_id }}</td>
                <td>{{ prjData.pm }}</td>
                <td>{{ prjData.project_type }}</td>
                <td>{{ prjData.team_name }}</td>
                <td>{{ prjData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ prjData.project_description }}</div>
          </div>
        </div>
      </div>
      <div id="monCont" class="moa_dete">
        <div class="mid_btn_area">
          <button type="button" class="btn_control mcbtn" @click="showModal(9)">
            개별관제 ↗
          </button>
        </div>
        <!-- <button
          type="button"
          @click="socketClose"
          class="mcbtn"
          style="position: absolute; right: 160px"
        >
          소켓 Close
        </button> -->
        <div class="data_wrap">
          <div class="radiusbox status_wrap">
            <div class="status_top">
              <div class="title">Status</div>
              <div class="status status_01">
                <span class="item">Idle : </span>
                <span class="cnt">{{ device.status.cnt.idle }}</span>
              </div>
              <div class="status status_02">
                <span class="item">On Mission : </span>
                <span class="cnt">{{ device.status.cnt.onMission }}</span>
              </div>
              <div class="status status_03">
                <span class="item">Off-line : </span>
                <span class="cnt">{{ device.status.cnt.offLine }}</span>
              </div>
              <div class="status status_04">
                <span class="item">Failed : </span>
                <span class="cnt">{{ device.status.cnt.fail }}</span>
              </div>
            </div>
            <div class="mando_table_wrap" style="height: 322px">
              <table class="mando_table">
                <thead>
                  <tr>
                    <th>
                      <div class="col_name">Dvc_id</div>
                    </th>
                    <th>
                      <div class="col_name">Operation_Status</div>
                    </th>
                    <th>
                      <div class="col_name">Mission Status</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="robotData.length > 0">
                    <tr v-for="(item, index) in robotData" :key="index">
                      <td>
                        <div>
                          <div>
                            {{ item.device_id }}
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn_status"
                              :class="
                                item.mission_status
                                  ?.replaceAll('_', '')
                                  ?.replaceAll('-', '')
                                  ?.replaceAll(' ', '')
                                  .toUpperCase() === 'IDLE'
                                  ? 'btn_status_01'
                                  : item.mission_status
                                      ?.replaceAll('_', '')
                                      ?.replaceAll('-', '')
                                      ?.replaceAll(' ', '')
                                      .toUpperCase() === 'ONMISSION'
                                  ? 'btn_status_02'
                                  : item.mission_status
                                      ?.replaceAll('_', '')
                                      ?.replaceAll('-', '')
                                      ?.replaceAll(' ', '')
                                      .toUpperCase() === 'OFFLINE'
                                  ? 'btn_status_03'
                                  : 'btn_status_04'
                              "
                              @click="
                                showModal(
                                  1,
                                  item.device_id,
                                  item.mission_status,
                                  item.mission_id
                                )
                              "
                            >
                              {{ item.mission_status }}
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>Battery : {{ item.battery }}%</div>
                        <div>Connection : {{ item.connection }}%</div>
                      </td>
                      <td>
                        <template v-if="item.mission_id != null">
                          <div>mission_id : {{ item.mission_id }}</div>
                          <div>Progress : {{ item.progress.toFixed(2) }}%</div>
                        </template>
                        <template v-else>
                          <div>No Mission</div>
                        </template>
                        <div>
                          <button
                            type="button"
                            class="btn_status"
                            :class="
                              item.mission_id != null
                                ? 'btn_status_05'
                                : 'btn_status_06'
                            "
                            @click="
                              showModal(
                                3,
                                item.device_id,
                                item.mission_status,
                                item.mission_id,
                                item.progress
                              )
                            "
                          >
                            {{
                              item.mission_id != null
                                ? "View Mission"
                                : "Add Misson"
                            }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="3">데이터가 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="radiusbox map_controller_wrap">
            <MapController
              :mapPage="'robot'"
              :mapType="mapType"
              :gpsMapDataArray="map.path"
              :currentPose="map.currentPose"
              :init="map.init"
            />
          </div>
        </div>
        <div class="data_wrap" style="position: relative; width: 100%">
          <div
            class="data_wrap_inner"
            style="
              position: absolute;
              height: 100%;
              overflow: auto;
              border-radius: 10px;
            "
          >
            <div class="radiusbox status_wrap" style="height: 100%">
              <table
                class="mando_table log_table"
                style="position: static; background: #fff"
              >
                <thead>
                  <tr>
                    <th>
                      <div class="col_name">Log Contents</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="logData.length > 0">
                    <tr v-for="(item, index) in logData" :key="index">
                      <td>
                        <div class="log_title">
                          <div class="log_time">
                            {{
                              item.stamp.substr(0, item.stamp.lastIndexOf("."))
                            }}
                          </div>
                          <div class="log_level">
                            {{ item.log_level }}
                          </div>
                        </div>
                        <div class="log_content">
                          {{ item.log_content }}
                        </div>
                      </td>
                    </tr>
                    <tr></tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6" style="text-align: center">
                        데이터가 없습니다.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="radiusbox"
            style="position: relative; left: 50%; margin-left: 10px"
          >
            <div class="video_wrap" style="background: none">
              <div class="btn_area">
                <button
                  type="button"
                  class="mcbtn"
                  @click="selectChannel('stop')"
                >
                  Stop
                </button>
                <button
                  type="button"
                  class="mcbtn btn_camera_topic"
                  @click="showModal(8)"
                >
                  Camera Topics
                </button>
              </div>

              <div class="video_wrap radiusbox">
                <video
                  id="remote_view"
                  class="video"
                  ref="video"
                  playsinline
                  muted
                  preload="none"
                  autoplay
                  controls
                ></video>
              </div>

              <!-- <div
                    id="remote_log"
                    style="
                      width: 640px;
                      height: 480px;
                      border: solid black 1px;
                      overflow: auto;
                      float: left;
                      margin-left: 5px;
                    "
                  ></div>
                  <button id="connect_button" @click="connect">Connect</button>
                  <button id="open_button" @click="connection_open">open</button>
                  <button id="disconnect_button" @click="disconnect">
                    Disconnect
                  </button> -->
            </div>
          </div>
        </div>
        <div class="data_wrap">
          <div class="data_wrap_inner">
            <div class="radiusbox status_wrap" style="height: 100%">
              <div class="status_top status_top_02">
                <div class="title">Data Recording</div>
                <div class="status_inner">
                  <div class="status">
                    <span class="item">Selected : </span>
                    <span class="cnt">{{ selectedRecordIds.length }} EA</span>
                  </div>

                  <div class="status">
                    <button
                      type="button"
                      class="btn_start_stop btn_start"
                      @click="
                        showModal(
                          5,
                          null,
                          null,
                          null,
                          null,
                          null,
                          selectedRecordIds
                        )
                      "
                    >
                      Start
                    </button>
                  </div>
                  <div class="status"></div>
                </div>
              </div>
              <div></div>
              <div class="mando_table_wrap" style="height: 100%">
                <table class="mando_table">
                  <thead>
                    <tr>
                      <th>
                        <div class="col_name">Dvc_id</div>
                      </th>
                      <th>
                        <div class="col_name">Recording Status</div>
                      </th>
                      <th>
                        <div class="col_name">Button</div>
                      </th>
                      <th>
                        <div class="col_name">Topics</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in topicDataList"
                      :key="item.edge_id"
                    >
                      <td>
                        <div>
                          <input
                            type="checkbox"
                            :checked="
                              item.recording_on === 'false'
                                ? selectedRecordIds.includes(item.edge_id)
                                : false
                            "
                            :id="item.edge_id"
                            @change="chkRecord(item.edge_id)"
                            :disabled="
                              item.recording_on === 'false' ? false : true
                            "
                          />
                          <label :for="item.edge_id"></label>
                          <span>{{ item.edge_id }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="record_on_off_wrap">
                          <div
                            class="txt_on_off"
                            :class="
                              item.recording_on === 'false'
                                ? 'txt_off'
                                : 'txt_on'
                            "
                          >
                            {{ item.recording_on === "false" ? "Off" : "On" }}
                          </div>
                          <div :id="'record_' + index" class="record_txt">
                            {{
                              item.stopWatchTime === null ||
                              item.stopWatchTime == undefined
                                ? "-:-:-"
                                : item.stopWatchTime
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            v-if="item.recording_on === 'false'"
                            type="button"
                            class="btn_start_stop btn_start"
                            @click="showModal(5, null, null, null, null, item)"
                          >
                            Start
                          </button>
                          <button
                            v-else
                            type="button"
                            class="btn_start_stop btn_stop"
                            @click="showModal(4, item.edge_id)"
                          >
                            Stop
                          </button>
                        </div>
                      </td>
                      <td>
                        <div>
                          <template v-if="item.recording_on === 'false'">
                            <span class="txt_on_off txt_off_detail"
                              >Detail</span
                            >
                          </template>
                          <template v-else>
                            <span
                              class="txt_on_off txt_on_detail"
                              @click="
                                showModal(
                                  5,
                                  null,
                                  null,
                                  null,
                                  null,
                                  item,
                                  null,
                                  'record_derail'
                                )
                              "
                              >Detail</span
                            >
                          </template>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="data_wrap_inner">
            <div class="radiusbox chart_box" style="height: 100%">
              <div class="title_box">
                <strong>Signal</strong>
              </div>
              <ApexChartController
                ref="chartWrap"
                :signalData="signalData"
                :signalLabels="signalLabels"
              />
              <div class="btn_area">
                <button
                  type="button"
                  class="mcbtn btn_stop"
                  @click="signalGraph('stop')"
                >
                  Stop
                </button>
                <button
                  type="button"
                  class="mcbtn btn_more"
                  @click="showModal(2)"
                >
                  ● ● ●
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_01" style="display: none">
    <div class="radiusbox modal_contents message_modal">
      <div class="message_box">아래 Device의 상태를 변경 하시겠습니까?</div>
      <div class="input_box">
        <input type="text" disabled :value="'- ' + selectedDevice.id" />
      </div>
      <div class="button_box">
        <ul>
          <li>
            <div>Off-line</div>
          </li>
          <li>
            <div
              :class="selectedDevice.status === 'FAILED' ? 'active_idle' : ''"
            >
              Idle
            </div>
          </li>
          <li>
            <div>On Mission</div>
          </li>
          <li>
            <div
              :class="selectedDevice.status !== 'FAILED' ? 'active_fail' : ''"
            >
              Fail
            </div>
          </li>
        </ul>
      </div>
      <div class="button_bottom_box">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(1)"
        >
          취소
        </button>
        <button
          type="button"
          class="btn_check mcbtn"
          @click="chgDeviceStatus()"
        >
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(1)"></button>
    </div>
  </div>

  <div id="modalWrap" ref="modalWrap_02" style="display: none">
    <div class="radiusbox modal_contents signal_modal">
      <strong class="modal_title">Data Signal 항목 선택</strong>
      <div class="table_list">
        <div class="float_box" style="margin: 0; height: 100%">
          <div class="float_box table_list_common">
            <!-- <div class="filter_box">
              <select name="" id="">
                <option value="" selected>그래프 유형을 선택하시오</option>
              </select>
              <button type="button" class="btn_option btn_search mcbtn">
                옵션
              </button>
            </div> -->
            <div class="count_box">Device > Signal 선택</div>
            <div class="select_box mando_table_wrap">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="all_chk">
                        <input
                          type="checkbox"
                          id="allChk"
                          v-model="isAllChkDevice"
                          @change="allChkDevice"
                        /><label for="allChk"></label><span>Device ID</span>
                      </div>
                    </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in edgeDeviceList" :key="item.id">
                    <td>
                      <div class="chk_item">
                        <input
                          type="checkbox"
                          :id="'item_' + item.id"
                          :checked="selectedDeviceList.includes(item.id)"
                          @change="chkEdgeDevice(item.id, item.edge_device_id)"
                        /><label :for="'item_' + item.id"></label
                        ><span>{{ item.edge_device_id }}</span>
                      </div>
                    </td>
                    <td>{{ item.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="select_box mando_table_wrap" style="float: right">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="all_chk">
                        <input
                          type="checkbox"
                          id="allChkSgl"
                          v-model="isAllChkSgl"
                          @change="allChkSgl"
                        /><label for="allChkSgl"></label><span>Device ID</span>
                      </div>
                    </th>
                    <th>Signal ID</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="signalList.length > 0">
                    <tr v-for="(item, index) in signalList" :key="index">
                      <td>
                        <div class="chk_item">
                          <input
                            type="checkbox"
                            :id="'item_' + index"
                            :checked="
                              selectedSignalList.includes(item.signal_id)
                            "
                            @change="
                              chkSignal(
                                item.device_id,
                                item.signal_id,
                                item.action_type
                              )
                            "
                          /><label :for="'item_' + index"></label
                          ><span>{{ item.device_id }}</span>
                        </div>
                      </td>
                      <td>
                        {{ item.signal_id }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="2">데이터가 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="choose_box">
            <div class="btn_wrap">
              <button type="button" class="btn_add" @click="addSignal">
                추가</button
              ><button type="button" class="btn_add_del" @click="removeSignal">
                삭제
              </button>
            </div>
          </div>

          <div
            class="float_box table_list_common"
            style="float: right; width: calc(45% - 150px)"
          >
            <!-- <div class="filter_box"></div> -->
            <div class="count_box">Device의 Signal 선택목록</div>
            <div class="select_box mando_table_wrap" style="width: 100%">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="all_chk">
                        <input
                          type="checkbox"
                          id="allChkSglRemove"
                          v-model="isAllChkSglRemove"
                          @change="allChkSglRemove"
                        /><label for="allChkSglRemove"></label
                        ><span>Device ID</span>
                      </div>
                    </th>
                    <th>Signal ID</th>
                    <th>Action Type</th>
                    <th>Color</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="this.selectedGraphList.length > 0">
                    <tr
                      v-for="(item, index) in this.selectedGraphList"
                      :key="index"
                    >
                      <td>
                        <div class="chk_item">
                          <input
                            type="checkbox"
                            :id="'graph_' + index"
                            @change="chkSglRemove(item.data_spec)"
                            :checked="
                              selectedGraphRemoveList.includes(item.data_spec)
                            "
                          /><label :for="'graph_' + index"></label
                          ><span>{{ item.device_id }}</span>
                        </div>
                      </td>
                      <td>{{ item.data_spec }}</td>
                      <td>{{ item.data_type }}</td>
                      <td>
                        <color-picker
                          disableHistory="true"
                          disableAlpha="true"
                          format="hex"
                          :pureColor="item.color"
                          @update:pureColor="colorPick($event, item)"
                        />
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="4">데이터가 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="check_wrap">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(2)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="signalGraph">
          완료
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(2)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_03" style="display: none">
    <div class="radiusbox modal_contents mission_modal">
      <h2>Misson 추가</h2>
      <div class="info_box">
        <div class="row row_01">
          <div class="title">Misson Type</div>
          <div class="info">
            <select v-model="missionType" @change="selectMissionType($event)">
              <option
                v-for="item in mission.mission"
                :key="item.mission_type"
                :data-id="item.item_id"
                :value="item.mission_type"
              >
                {{ item.mission_type_name }}
              </option>
            </select>
          </div>
          <div class="title">Item Name</div>
          <div class="info">
            <input type="text" :value="missionItem" disabled />
          </div>
        </div>
        <div class="row row_02">
          <div class="title">Selected Device</div>
          <div class="tbl">
            <div class="mando_table_wrap">
              <table class="mando_table">
                <thead>
                  <tr>
                    <th>
                      <div class="col_name">Edge ID</div>
                    </th>
                    <th>
                      <div class="col_name">Edge Group</div>
                    </th>
                    <th>
                      <div class="col_name">Edge Name</div>
                    </th>
                    <th>
                      <div class="col_name">Status</div>
                    </th>
                    <!-- <th>
                      <div class="col_name"></div>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="item in edgeDeviceList"
                    :key="item.edge_device_id"
                  >
                    <tr v-if="item.edge_device_id === selectedDevice.id">
                      <td>
                        <div>
                          <!-- <input type="checkbox" :id="item.edge_device_id" />
                          <label :for="item.edge_device_id"></label> -->
                          <img
                            src="@/assets/images/common/vehicle_sample.svg"
                            alt=""
                          />
                          <span>{{ item.edge_device_id }}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <img
                            src="@/assets/images/common/gateway_sample.svg"
                            alt=""
                          />
                          <span>{{ item.edge_device_group }}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{{ item.edge_device_name }}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{{ item.status }}</span>
                        </div>
                      </td>
                      <!-- <td>
                        <div>
                          <button type="button" class="btn_del"></button>
                        </div>
                      </td> -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row row_03">
          <div class="btn">
            <button
              :disabled="selectedDevice.missionId ? false : true"
              type="button"
              @click="
                showModal(
                  7,
                  selectedDevice.id,
                  selectedDevice.status,
                  selectedDevice.missionId,
                  selectedDevice.progress
                )
              "
              class="btn_mission"
              :class="selectedDevice.missionId ? 'btn_mission_cancel' : ''"
            >
              Cancel Mission
            </button>
          </div>
          <div class="title">* Failure Action</div>
          <div class="info">
            <select v-modal="fail" @change="selectFailType($event)">
              <option
                v-for="item in mission.fail_action"
                :key="item.item_id"
                :value="item.item_id"
              >
                {{ item.item_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="goal_point_box">
        <h3>
          Goal Point Setting
          <span class="ico_info"
            ><font-awesome-icon :icon="['fas', 'circle-info']"
          /></span>
        </h3>
        <div class="goal_point_wrap">
          <div class="goal_point_inner">
            <div class="title">
              <div>Point</div>
              <div>위도</div>
              <div>경도</div>
              <div></div>
            </div>
            <VueDraggable
              ref="el"
              v-model="pointList"
              class="goal_point"
              animation="500"
              filter=".ignore"
              @sort="onSort"
              @choose="onChoose"
            >
              <template v-for="(item, index) in pointList" :key="item.point">
                <div :class="item.point === 0 ? 'ignore' : ''">
                  <div class="point">
                    <b>{{
                      item.point === 0 || item.point === undefined
                        ? "s"
                        : index === pointList.length - 1
                        ? "f"
                        : index
                    }}</b>
                  </div>
                  <div>
                    {{ item.lat }}
                  </div>
                  <div>
                    {{ item.lng }}
                  </div>
                  <div class="btn">
                    <button
                      v-if="index !== 0"
                      type="button"
                      @click="removePoint(index)"
                    >
                      <font-awesome-icon :icon="['far', 'circle-xmark']" />
                    </button>
                  </div>
                </div>
              </template>
            </VueDraggable>
          </div>
          <div class="map_area">
            <MapController
              v-if="pointList.length > 0"
              :otherProp="'mission'"
              :mapType="'normal'"
              :pointList="pointList"
              @updatePoint="chgPoint"
              ref="pointMap"
            />
          </div>
        </div>
      </div>
      <div class="check_wrap">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(3)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="missionAssign">
          완료
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(3)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_04" style="display: none">
    <div class="radiusbox modal_contents message_modal">
      <div class="message_box">아래 Device의 Recording을 Stop하시겠습니까?</div>
      <div class="input_box">
        <input type="text" disabled :value="selectedDevice.id" />
      </div>
      <div class="message_box">
        확인을 누르시면 위 디아비스의 Recording이 중지됩니다.
      </div>
      <div class="button_bottom_box">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(4)"
        >
          취소
        </button>
        <button
          type="button"
          class="btn_check mcbtn"
          @click="recordStop(selectedDevice.id)"
        >
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(4)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_05" style="display: none">
    <div class="radiusbox modal_contents record_modal">
      <div class="record_device_wrap_title">
        {{
          isRecordDetail ? "Recording - Detail" : "Start Recording - Setting"
        }}
      </div>
      <div
        class="record_device_wrap"
        v-for="(item, index) in topicSelectdDataList"
        :key="item.edge_id"
      >
        <button
          v-if="topicSelectdDataList.length > 1"
          type="button"
          class="record_device_remove"
          @click="recordItemRemove(index)"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" />
        </button>
        <div class="record_device">
          <span>{{ item.edge_id }}</span>
        </div>
        <div class="record_info">
          <div class="title">Rosbag Name</div>
          <div class="input">
            <input
              type="text"
              maxlength="35"
              :value="item.default_rosbag"
              @input="onInput($event, item)"
              @keyup="topictextLength($event)"
              :disabled="isRecordDetail ? true : false"
            />
          </div>
          <div class="btn" v-if="!isRecordDetail">
            <span class="length"
              ><b class="len">{{ item.bag_name.length }}</b
              >/35</span
            >
          </div>
        </div>
        <div class="record_info" v-if="!isRecordDetail">
          <div class="title">Load Topic Set</div>
          <div class="input">
            <input type="text" disabled :value="item.topic_set" />
          </div>
          <div class="btn">
            <button
              type="button"
              class="mcbtn"
              @click="showModal(6, item.edge_id)"
            >
              불러오기
            </button>
          </div>
        </div>
        <div class="record_topic">
          <div class="row row_title">
            <div class="left">Connect</div>
            <div class="right" v-if="!isRecordDetail">
              <span>Topic Name</span>
              <button
                type="button"
                class="btn_clear"
                @click="clearSelectedTopicItem(item)"
              >
                Clear
              </button>
              <button
                type="button"
                class="btn_add"
                @click="addNewTopicItem(item, index)"
              >
                + Add
              </button>
            </div>
          </div>
          <div
            class="row row_content"
            v-for="(itemTopic, i) in item.topicItemList"
            :key="i"
          >
            <div class="left all_chk">
              <input
                type="checkbox"
                :id="'topic_item_' + index + '_' + i"
                :checked="item.chkList.includes(i)"
                @change="chkTopicItem(item, i)"
                :disabled="isRecordDetail ? true : false"
              /><label :for="'topic_item_' + index + '_' + i"></label>
            </div>
            <div class="right">
              <input
                type="text"
                class="topic_box"
                @input="onInputTopic($event, itemTopic)"
                :value="itemTopic.topics_list"
                :disabled="isRecordDetail ? true : false"
              />
              <button
                v-if="item.topicItemList.length > 1 && !isRecordDetail"
                type="button"
                class="topic_remove"
                @click="removeTopicItem(item, i)"
              >
                <font-awesome-icon :icon="['fas', 'xmark']" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="check_wrap" v-if="!isRecordDetail">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(5)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="recordStart">
          완료
        </button>
      </div>
      <button type="button" class="btn_record_close" @click="closeModal(5)">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_06" style="display: none">
    <div class="radiusbox modal_contents signal_modal recording_topic_modal">
      <strong class="modal_title">Load Topic Set</strong>
      <div class="table_list">
        <div class="float_box" style="margin: 0; height: 100%">
          <div class="float_box table_list_common">
            <div class="filter_box">
              <input
                type="text"
                name="topicKeyword"
                ref="topicKeyword"
                placeholder="Topic Title 검색"
              />
              <button
                type="button"
                class="btn_search"
                @click="searchTopic"
              ></button>
            </div>
            <div class="select_box mando_table_wrap">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <div class="all_chk">
                        <input
                          type="checkbox"
                          id="allChkTopic"
                          v-model="isAllChkTopic"
                          @change="allChkTopic"
                        /><label for="allChkTopic"></label><span>title</span>
                      </div>
                    </th>
                    <th>등록자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in topicSetList" :key="index">
                    <td>
                      <div class="chk_item">
                        <input
                          type="checkbox"
                          :id="'topic_' + index"
                          :checked="selectedTopicIds.includes(item.topic_id)"
                          @change="chkTopic(item.topic_id)"
                        /><label :for="'topic_' + index"></label
                        ><span>{{ item.topic_name }}</span>
                      </div>
                    </td>
                    <td>{{ item.register_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="check_wrap">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(6)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="addTopicSet">
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(6)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_07" style="display: none">
    <div class="radiusbox modal_contents message_modal">
      <div class="message_box">수행 중인 미션을 취소 하시겠습니까?</div>
      <div class="input_box">
        <div class="input_text">
          <p>- Dvc id : {{ selectedDevice.id }}</p>
          <p>- Mission id : {{ selectedDevice.missionId }}</p>
          <p>- Progress : {{ selectedDevice.progress }}%</p>
        </div>
      </div>
      <div class="message_box">Cancel Action을 선택 후 확인을 눌러주세요.</div>
      <div class="input_box">
        <select v-model="missionCancelSelect" required>
          <option value="" selected>*Cancel Action 명</option>
          <option
            v-for="item in missionCancelList"
            :key="item.item_id"
            :value="item.item_id"
          >
            {{ item.item_name }}
          </option>
        </select>
      </div>
      <div class="button_bottom_box">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(7)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="cancelAction">
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(7)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_08" style="display: none">
    <div class="radiusbox modal_contents signal_modal camera_modal">
      <strong class="modal_title">Channel 항목 선택</strong>
      <div class="table_list">
        <div class="float_box" style="margin: 0; height: 100%">
          <div class="float_box table_list_common">
            <div class="count_box" style="margin-top: 42px">Device 목록</div>
            <div class="select_box mando_table_wrap">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <!-- <div class="all_chk">
                        <input
                          type="checkbox"
                          id="allChkCameraEdgeDevice"
                          v-model="isAllChkCameraEdgeDevice"
                          @change="allChkCameraEdgeDevice"
                        /><label for="allChkCameraEdgeDevice"></label
                        ><span>Device ID</span>
                      </div> -->
                      <div class="all_chk">
                        <span>Device ID</span>
                      </div>
                    </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in cameraEdgeDeviceList"
                    :key="item.id"
                  >
                    <td>
                      <!-- <div class="chk_item">
                        <input
                          v-model="cameraDeviceId"
                          type="radio"
                          :checked="index === 0 ? true : false"
                          :id="'camera_device_' + item.edge_device_id"
                          :value="item.edge_device_id"
                          @change="chkCameraEdgeDevice(item.edge_device_id)"
                        /><label
                          :for="'camera_device_' + item.edge_device_id"
                        ></label
                        ><span>{{ item.edge_device_id }}</span>
                      </div> -->
                      <div class="chk_item">
                        <input
                          v-model="cameraDeviceId"
                          type="radio"
                          :checked="index === 0 ? true : false"
                          :id="'camera_device_' + item.edge_device_id"
                          :value="item.edge_device_id"
                          @change="chkCameraEdgeDevice($event)"
                        /><label
                          :for="'camera_device_' + item.edge_device_id"
                        ></label
                        ><span>{{ item.edge_device_id }}</span>
                      </div>
                    </td>
                    <td>{{ item.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="choose_box">
            <div class="btn_wrap">
              <button type="button" class="btn_add" @click="getCameraTopicList">
                추가
              </button>
              <!-- <button type="button" class="btn_add_del">삭제</button> -->
            </div>
          </div>

          <div
            class="float_box table_list_common"
            style="float: right; width: calc(45% - 150px)"
          >
            <div class="filter_box"></div>
            <div class="count_box">Device > Channel 선택</div>
            <div class="select_box mando_table_wrap" style="width: 100%">
              <table class="mando_table">
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>Device ID</th>
                    <th>Channel Name</th>
                    <th>Channel Camera Topic</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="cameraTopicList.length > 0">
                    <tr v-for="(item, index) in cameraTopicList" :key="index">
                      <td>
                        <div class="chk_item">
                          <input
                            v-model="channel"
                            type="radio"
                            :id="'cam0era_1' + index"
                            :value="item"
                          /><label :for="'camera_1' + index"></label
                          ><span>{{ item.device_id }}</span>
                        </div>
                      </td>
                      <td>{{ item.channel_name }}</td>
                      <td>{{ item.channel_camera_topic }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="3">데이터가 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="check_wrap">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(8)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="selectChannel">
          완료
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(8)"></button>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap_09" style="display: none">
    <div class="radiusbox modal_contents message_modal">
      <div class="message_box">
        현재 수행 중인 Project의 선택하신 Device의<br />
        <span class="txt txt_b">개별 관제 화면</span>으로 이동하시겠습니까?
      </div>
      <div class="input_box">
        <p class="title">대상 Device 선택</p>
        <div class="input_select_button">
          <select v-model="singlePageDeviceId">
            <option value="0" selected>디바이스 ID</option>
            <option
              v-for="item in edgeDeviceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.edge_device_id }}
            </option>
          </select>
          <button type="button" class="mcbtn" @click="selectSingleDevice">
            선택
          </button>
        </div>
        <p class="title">Task 선택 (정렬 : 생성일 순 | Task 명 순)</p>
        <div class="input_list">
          <template v-if="singlePageTasks.length > 0">
            <ul class="task_list">
              <li v-for="(item, index) in singlePageTasks" :key="item.task_id">
                <input
                  v-model="selectTaskId"
                  type="radio"
                  :id="'task_' + index"
                  :value="item.task_id"
                />
                <label :for="'task_' + index"
                  >{{ item.create_date }} | {{ item.task_name }}</label
                >
              </li>
            </ul>
          </template>
          <template v-else>
            <p class="no_task">Task가 없습니다.</p>
          </template>
        </div>
      </div>
      <div class="button_bottom_box">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal(9)"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="goSingleTask">
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal(9)"></button>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import apiWebSocket from "@/assets/js/apiWebSocket";
//import ChartController from "@/components/ChartController.vue";
import ApexChartController from "@/components/ApexChartController.vue";
import MapController from "@/components/MapController.vue";
//import VideoController from "@/components/VideoController.vue";
import apiRobot from "@/assets/js/apiRobot";
import { mapGetters } from "vuex";
import { VueDraggable } from "vue-draggable-plus";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
  name: "MultiAgentMonitoringView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    //ChartController,
    ApexChartController,
    MapController,
    VueDraggable,
    //VideoController,
    ColorPicker,
  },
  data() {
    return {
      // project , task 정보
      taskData: {
        projectId: null,
        projectName: null,
        awsId: null,
        taskName: null,
        creatorName: null,
        type: null,
        teamName: null,
        description: null,
      },

      //DTC Map

      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      prjData: {
        project_name: "",
        aws_id: "",
        pm: "",
        project_type: "",
        team_name: "",
        last_activity: "",
        project_description: "",
      },

      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      labelsArray: [],
      datasArray: [],
      chartType: "line",
      mapType: "both",

      status: null,
      mapPage: "robot",
      robotData: [],
      recordData: [],
      maxLogDataCnt: 50,
      logData: [],
      device: {
        selected: {
          id: "",
          status: "",
        },
        status: {
          cnt: {
            idle: 0,
            onMission: 0,
            offLine: 0,
            fail: 0,
          },
        },
      },
      selectedDevice: {
        id: "",
        status: "",
        missionId: 0,
        progress: 0,
        mission: {},
        fail: {},
      },
      map: {
        currentPose: [],
        path: [],
        init: true,
      },

      pointList: [],
      tempPointList: [],
      childItem: [],
      videoSrc: require(`@/assets/video/vector.mp4`),
      videoControl: true,

      missionCancelList: [],
      missionCancelSelect: "",
      missionGoalPoint: [],
      mission: [],
      missionDetail: {},
      missionType: "",
      missionItem: "",
      missionItemId: 0,
      failAction: "",
      failList: [],
      edgeDeviceList: [],
      cameraEdgeDeviceList: [],
      isAllChkDevice: false,
      isAllChkSgl: false,
      isAllChkCameraEdgeDevice: false,
      isAllChkSglRemove: false,
      selectedDeviceList: [],
      selectedEdgeDeviceList: [],
      signalList: [],
      selectedSignalList: [],
      selectedSignalParam: [],
      selectedGraphList: [],
      selectedGraphRemoveList: [],
      cameraTopicList: [],
      selectedCameraEdgeDeviceList: [],
      topicSetList: [],
      topicItemList: [],

      isAllChkTopic: false,
      selectedTopicIds: [],
      selectedTopicItem: [],
      topicDataList: [],
      topicSelectdDataList: [],

      signalLabels: [],
      signalData: [],
      signalColor: [],
      signalCnt: 61,
      robotDataGraph: [],
      robotDataLabel: [],
      robotDataDevice: [],
      colorList: [],
      isUpdateChart: true,

      selectedRecordIds: [],
      selectedRecordDevice: [],
      signalResponse: false,
      signalChange: false,
      timer: [],

      isRecordDetail: false,
      viewer: {},
      AWS_Region: "ap-northeast-2",
      AWS_ACCESS_KEY_ID: "AKIA3V3KHBOJX7FBRGP2",
      AWS_SECRET_ACCESS_KEY: "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",
      Channel_Name: "",
      channel: "",
      cameraDeviceId: "",

      singlePageDeviceId: 0,
      singlePageTasks: [],
      selectTaskId: 0,
    };
  },

  computed: {
    ...mapGetters(["getUserData"]),
  },

  async mounted() {
    // Role
    await this.setRole();

    // prj info
    const prjId = this.$route.query.prjId;
    const prjData = await this.getPrjData(prjId);
    if (typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
      this.prjData = prjData.project;
    }

    try {
      // monitoring-task 정보
      await common
        .apiGet(
          "/monitoring-task/" +
            this.$route.query.taskId +
            "?viewRole=" +
            this.viewRole
        )
        .then((r) => {
          console.log("0. monitoring-task 정보");
          console.log(r.data.data);
          this.taskData.projectId = r.data.data.projectId;
          this.taskData.projectName = r.data.data.projectName;
          this.taskData.awsId = r.data.data.awsId;
          this.taskData.taskName = r.data.data.taskName;
          this.taskData.creatorName = r.data.data.creatorName;
          this.taskData.type = r.data.data.type;
          this.taskData.teamName = r.data.data.teamName;
          this.taskData.lastVisit = r.data.data.lastVisit;
          this.taskData.description = r.data.data.description;
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      // monitoring-task 에 속한 edge-device 정보
      await common
        .apiGet(
          "/edge-device/monitoring-task/edge-device-group/" +
            this.$route.query.taskId
        )
        .then((r) => {
          console.log("0. monitoring-task 에 속한 edge-device 정보");
          console.log(r.data.data);
          const o = [];
          r.data.data.forEach((e) => {
            if (!o.some((item) => item.id === e.id)) {
              o.push({
                id: e.id,
                value: e.edge_device_id,
                oem_id: e.oem_id,
                vehicle_id: e.vehicle_id,
              });
            }
          });
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // mando api call

    //projectId
    this.projectId = this.$route.query.prjId;
    // await this.allowAccess();
    /*
    this.labelsArray = ["가나다", "마바사", "자차"];
    console.log("///////////// :: ", this.labelsArray);
    this.datasArray = [{ 가나다: 100 }, { 마바사: 32 }, { 자차: 45 }];
    */

    apiWebSocket.onopen = function (event) {
      event.returnValue;
    };

    const vm = this;
    apiWebSocket.onmessage = async function (event) {
      const loading = vm.$refs.lodingWrap;
      let data = null;
      //if (vm.robotData.length < 1) loading.style.display = "block";
      return new Promise((resolve) => {
        setTimeout(() => {
          data = JSON.parse(event.data);
          console.log("data!!!!!!!! :: ", data);
          resolve();
          loading.style.display = "none";
        }, 1000);
      }).then(async function () {
        console.log("로봇 데이터!!! :: ", vm.robotData);
        if (data.type === "status" && vm.robotData.length > 0) {
          console.log(data.data.device_id);
          let isSameDevice = false;
          for (let i = 0; i < vm.robotData.length; i++) {
            if (vm.robotData[i].device_id === data.data.device_id) {
              isSameDevice = true;
              console.log("data.data :: ", data.data);
              vm.robotData[i] = data.data;
            }
          }
          if (!isSameDevice) {
            vm.robotData.push(data.data);
            console.log(data.data.mission_status);
          }
        } else if (data.type === "status") {
          vm.robotData.push(data.data);
        }

        if (data.type === "status") {
          vm.map.path = [];
          vm.map.currentPose = [];
          for (let item of vm.robotData) {
            vm.map.path.push(item.path);
            vm.map.currentPose.push(item.current_pose);
          }
          vm.calcStatusCnt();
        }

        if (data.type === "signal") {
          const dataObj = data.data;

          //const keys = Object.keys(data.data);

          const dataSets = Object.entries(dataObj);

          if (vm.signalLabels.length >= vm.signalCnt) {
            vm.signalLabels.shift();
          }
          vm.signalLabels.push(dataSets[0][1].split(" ")[1]);

          dataSets.shift();
          //let cnt = 0;
          let deviceId = "";
          let dataName = "";
          let dataValue = 0;
          let array = [];
          for (let i = 0; i < dataSets.length; i++) {
            for (let j = 0; j < dataSets[i].length; j++) {
              deviceId = dataSets[i][0];
              console.log(
                "(((((((((((((((::)))))))))))))))deviceId  :: ",
                deviceId
              );
              for (let k = 0; k < dataSets[i][j].length; k++) {
                if (Object.keys(dataSets[i][j][k]).includes("data_spec")) {
                  dataName = dataSets[i][j][k].data_spec;
                  dataValue = dataSets[i][j][k].value.toFixed(5);
                  console.log("dataValuedataValuedataValue :: ", dataName);
                  console.log("dataValuedataValuedataValue :: ", dataValue);
                  array.push([deviceId + " - " + dataName, dataValue]);
                }
              }
            }
          }
          console.log("Array !!!!!!!!!!!!!! :: ", array);

          for (let j = 0; j < array.length; j++) {
            let isSameSignal = false;
            for (let i = 0; i < vm.signalData.length; i++) {
              if (array[j][0] === vm.signalData[i].name) {
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
                isSameSignal = true;

                if (vm.signalData[i].data.length >= vm.signalCnt) {
                  console.log("!!!!!!!!!!!! :: ", vm.signalLabels.length);
                  console.log("!!!!!!!!!!!!22222222222222 :: ", vm.signalCnt);
                  vm.signalData[i].data.shift();
                }
                vm.signalData[i].data.push(array[j][1]);
              }
            }
            if (!isSameSignal) {
              const param = {
                name: array[j][0],
                data: [array[j][1]],
              };
              vm.signalData.push(param);
            }
            //console.log("vm.signalData!!! :: ", vm.signalData);
          }
          //console.log("9999999999999!!!!!!!!!!! :: ", vm.selectedGraphList);

          console.log("vm.signalData22!!! :: ", vm.signalLabels);
          console.log("vm.signalData !!! :: ", vm.signalData);

          for (let i = 0; i < vm.signalData.length; i++) {
            let isChartItem = false;
            if (vm.selectedGraphList.length > 0) {
              for (let j = 0; j < vm.selectedGraphList.length; j++) {
                if (
                  vm.selectedGraphList[j].device_id +
                    " - " +
                    vm.selectedGraphList[j].data_spec ===
                  vm.signalData[i].name
                ) {
                  isChartItem = true;
                }
              }
              if (!isChartItem) {
                vm.signalData.splice(i, 1);
              }
              console.log(
                "vm.signalData[i]vm.signalData[i]vm.signalData[i]vm.signalData[i]vm.signalData[i]vm.signalData[i]::   ",
                vm.signalData[i]
              );
              if (
                vm.signalData[i] != undefined &&
                vm.signalData[i].data.length > 0
              ) {
                const labelLength = vm.signalLabels.length;
                const dataLength = vm.signalData[i].data.length;
                if (dataLength < labelLength) {
                  const cnt = labelLength - dataLength;
                  for (let k = 0; k < cnt; k++) {
                    vm.signalData[i].data.unshift(null);
                  }
                }
              }
            }
          }

          vm.$refs.chartWrap.updateChart(vm.signalData, vm.signalLabels);
        }
        if (data.type === "log") {
          if (vm.logData.length > vm.maxLogDataCnt) {
            vm.logData.pop();
          }
          vm.logData.unshift(data.data);
        }
        if (data.type === "recording") {
          return new Promise((resolve) => {
            setTimeout(() => {
              vm.getTopicWrapList();
              resolve();
            }, 1000);
          }).then(function () {
            vm.countRecord(vm.topicSelectdDataList);
          });
        }
      });
    };
    apiWebSocket.onclose = function () {
      alert("close");
    };

    await this.getEdgeDeviceList();
    await this.socketSendEdgeids();
    await this.getTopicWrapList();
    this.countRecord(this.topicDataList);

    //await this.getSignalLists();
    //this.missionView();

    // Create Signaling Client

    //Channel_Name: "c-apne2-rs01-kvs-00",
    setInterval(() => this.callSocket(), 60000);
  },
  created() {
    /*
    this.pointList.push({
      point: 0,
      lat: 37.409345,
      lon: 127.114304,
    });
    */
  },
  watch: {
    datasArray() {},
  },
  methods: {
    goSingleTask() {
      const taskId = this.selectTaskId;
      const prjId = this.$route.query.prjId;
      const taskTypeId = 11;
      const query = {
        prjId: prjId,
        taskId: taskId,
        taskTypeId: taskTypeId,
      };
      // console.log(taskId);
      try {
        this.$router.push({
          name: "SingleAgentMonitoring",
          query,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async getTasks(projectId) {
      try {
        await common
          .apiGet(
            "/monitoring-task?" +
              "project-id=" +
              projectId +
              "&project-type-id=4"
          )
          .then((r) => {
            this.singlePageTasks = [];
            for (let item of r.data.data) {
              if (item.monitoring_task_type_id == 11) {
                let isExist = false;
                for (let item_02 of this.singlePageTasks) {
                  if (item_02.task_id === item.task_id) {
                    isExist = true;
                  }
                }
                if (!isExist) {
                  this.singlePageTasks = [
                    ...this.singlePageTasks,
                    ...r.data.data,
                  ];
                }
              }
            }
            this.singlePageTasks = this.singlePageTasks.filter(
              (e) => e.monitoring_task_type_id === 11
            );

            console.log("싱글 테스크스 :: ", this.singlePageTasks);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async selectSingleDevice() {
      if (this.singlePageDeviceId === 0) {
        alert("디바이스를 선택해 주세요.");
        return false;
      }
     
      try {
        await common
          .apiGet("/project/edge-device/" + this.singlePageDeviceId)
          .then((r) => {
            const data = r.data.data;

            for (let i = 0; i < data.length; i++) {
              const projectId = data[i].project_id;
              {
                this.getTasks(projectId);
              }
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    callSocket() {
      const edgeIds = [];
      for (let i = 0; i < this.edgeDeviceList.length; i++) {
        edgeIds.push(this.edgeDeviceList[i].edge_device_id);
      }
      const data = {
        action: "set_edgeids",
        data: {
          edgeids: edgeIds,
        },
      };
      apiWebSocket.send(JSON.stringify(data));
    },
    // 비디오 랜텀 ID 생성
    getRandomClientId() {
      return Math.random().toString(36).substring(2).toUpperCase();
    },
    wirteLog(d, d2) {
      if (d2 == undefined) d2 = "";

      document.getElementById("remote_log").innerHTML =
        document.getElementById("remote_log").innerHTML + "<br />" + d + d2;
      document.getElementById("remote_log").scrollTop =
        document.getElementById("remote_log").scrollHeight;
      console.log(d, d2);
    },

    async connect() {
      this.viewer.remoteView = document.getElementById("remote_view");
      const region = this.AWS_Region;
      const accessKeyId = this.AWS_ACCESS_KEY_ID;
      const secretAccessKey = this.AWS_SECRET_ACCESS_KEY;

      // eslint-disable-next-line no-undef
      const kinesisVideoClient = new AWS.KinesisVideo({
        region,
        accessKeyId,
        secretAccessKey,
      });
      const channel = this.Channel_Name;
      const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
          ChannelName: channel,
        })
        .promise();
      const channelARN =
        describeSignalingChannelResponse.ChannelInfo.ChannelARN;
      //this.wirteLog("[VIEWER] Channel ARN: ", channelARN);

      const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
          ChannelARN: channelARN,
          SingleMasterChannelEndpointConfiguration: {
            Protocols: ["WSS", "HTTPS"],

            // eslint-disable-next-line no-undef
            Role: KVSWebRTC.Role.VIEWER,
          },
        })
        .promise();
      const endpointsByProtocol =
        getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
          (endpoints, endpoint) => {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            return endpoints;
          },
          {}
        );
      //this.wirteLog("[VIEWER] Endpoints: ", endpointsByProtocol);

      const kinesisVideoSignalingChannelsClient =
        // eslint-disable-next-line no-undef
        new AWS.KinesisVideoSignalingChannels({
          region: region,
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          endpoint: endpointsByProtocol.HTTPS,
          correctClockSkew: true,
        });

      // Get ICE server configuration
      const getIceServerConfigResponse =
        await kinesisVideoSignalingChannelsClient
          .getIceServerConfig({
            ChannelARN: channelARN,
          })
          .promise();
      const iceServers = [];
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
      });
      getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
      //this.wirteLog("[VIEWER] ICE servers: ", iceServers);

      // eslint-disable-next-line no-undef
      this.viewer.signalingClient = new KVSWebRTC.SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        clientId: this.getRandomClientId(),
        // eslint-disable-next-line no-undef
        role: KVSWebRTC.Role.VIEWER,
        region: region,
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
        },
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
      });

      const configuration = {
        iceServers,
        iceTransportPolicy: "all",
      };
      this.viewer.peerConnection = new RTCPeerConnection(configuration);
      this.viewer.dataChannel =
        this.viewer.peerConnection.createDataChannel("kvsDataChannel");
      this.viewer.dataChannel.onopen = () => {
        //this.wirteLog("[VIEWER] Data channel opend");
        //viewer.controlInterval = setInterval(() => joyControl(), 100);
      };

      this.viewer.signalingClient.on("open", async () => {
        //this.wirteLog("[VIEWER] Connected to signaling service");

        // Create an SDP offer to send to the master
        //this.wirteLog("[VIEWER] Creating SDP offer");
        await this.viewer.peerConnection.setLocalDescription(
          await this.viewer.peerConnection.createOffer({
            offerToReceiveAudio: false,
            offerToReceiveVideo: true,
          })
        );

        // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        //this.wirteLog("[VIEWER] Sending SDP offer");
        this.viewer.signalingClient.sendSdpOffer(
          this.viewer.peerConnection.localDescription
        );
        //this.wirteLog("[VIEWER] Generating ICE candidates");
      });

      this.viewer.signalingClient.on("sdpAnswer", async (answer) => {
        // Add the SDP answer to the peer connection
        //this.wirteLog("[VIEWER] Received SDP answer");
        await this.viewer.peerConnection.setRemoteDescription(answer);
      });

      this.viewer.signalingClient.on("iceCandidate", (candidate) => {
        // Add the ICE candidate received from the MASTER to the peer connection
        //this.wirteLog("[VIEWER] Received ICE candidate");
        this.viewer.peerConnection.addIceCandidate(candidate);
      });

      this.viewer.signalingClient.on("close", () => {
        //this.wirteLog("[VIEWER] Disconnected from signaling channel");
      });

      this.viewer.signalingClient.on("error", (error) => {
        console.error("[VIEWER] Signaling client error: ", error);
      });

      // Send any ICE candidates to the other peer
      this.viewer.peerConnection.addEventListener(
        "icecandidate",
        ({ candidate }) => {
          if (candidate) {
            //this.wirteLog("[VIEWER] Generated ICE candidate");

            // When trickle ICE is enabled, send the ICE candidates as they are generated.
            //this.wirteLog("[VIEWER] Sending ICE candidate");
            this.viewer.signalingClient.sendIceCandidate(candidate);
          } else {
            //this.wirteLog("[VIEWER] All ICE candidates have been generated");
          }
        }
      );

      // As remote tracks are received, add them to the remote view
      this.viewer.peerConnection.addEventListener("track", (event) => {
        //this.wirteLog("[VIEWER] Received remote track");
        if (this.viewer.remoteView.srcObject) {
          return;
        }
        this.viewer.remoteStream = event.streams[0];
        this.viewer.remoteView.srcObject = this.viewer.remoteStream;
        this.viewer.remoteView.play().catch((err) => console.error(err));
      });

      //this.wirteLog("[VIEWER] Connection this.viewer");
    },

    connection_open() {
      //this.wirteLog("[VIEWER] Starting this.viewer connection");
      this.viewer.signalingClient.open();
    },

    async disconnect() {
      //this.wirteLog("[VIEWER] Stopping this.viewer connection");
      if (this.viewer.signalingClient) {
        this.viewer.signalingClient.close();
        this.viewer.signalingClient = null;
      }

      if (this.viewer.peerConnection) {
        this.viewer.peerConnection.close();
        this.viewer.peerConnection = null;
      }

      if (this.viewer.remoteStream) {
        this.viewer.remoteStream.getTracks().forEach((track) => track.stop());
        this.viewer.remoteStream = null;
      }

      if (this.viewer.controlInterval) {
        clearInterval(this.viewer.controlInterval);
        this.viewer.controlInterval = null;
      }

      if (this.viewer.remoteView) {
        this.viewer.remoteView.srcObject = null;
      }

      if (this.viewer.dataChannel) {
        this.viewer.dataChannel = null;
      }
    },

    // 채널 선택
    async selectChannel(action) {
      if (action !== "stop") {
        const deviceId = this.channel.device_id;
        //const channelName = this.channel.channel_name;
        const channelCameraTopic = this.channel.channel_camera_topic;
        if (this.channel === "") {
          alert("채널을 선택해 주세요.");
          return false;
        } else {
          try {
            const param = {
              device_id: deviceId,
              channel_camera_topic: channelCameraTopic,
            };
            const response = await apiRobot.post("/camera/topic_select", param);
            if (response.status === 200 || response.status === "200") {
              console.log("비디오!!! :: ", response.data.item);
              const data = response.data.item;
              alert("Channel 선택이 완료 되었습니다.");
              this.Channel_Name = data.channel_name;
              this.AWS_Region = data.channel_region;
              this.AWS_ACCESS_KEY_ID = data.channel_cred_id;
              this.AWS_SECRET_ACCESS_KEY = data.channel_cred_pw;
              this.closeModal(8);
              await this.disconnect();
              await this.connect();
              this.connection_open();
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } else {
        try {
          const response = await apiRobot.post("/camera/topic_list");
          if (response.status === 200 || response.status === "200") {
            alert("영상 송출이 중지 되었습니다.");
            this.closeModal(8);
            await this.disconnect();
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    // 토픽 Rosbag Name 디폴트
    dateFormat(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;
      second = second >= 10 ? second : "0" + second;

      return (
        date.getFullYear().toString() +
        month.toString() +
        day.toString() +
        "_" +
        hour +
        "_" +
        minute +
        "_" +
        second
      );
    },
    async recordStop(edgeId) {
      try {
        let param = {};
        let response = null;
        console.log("((((((((( :))))))))) :: ", this.topicSelectdDataList);
        const length = this.topicSelectdDataList.length;
        if (length > 0) {
          for (let i = 0; i < this.topicSelectdDataList.length; i++) {
            let topic_name = [];
            for (
              let j = 0;
              j < this.topicSelectdDataList[i].topicItemList.length;
              j++
            ) {
              let topic =
                this.topicSelectdDataList[i].topicItemList[j].topics_list;
              topic_name.push(topic);
            }
            console.log("$$$$$$$$$$ :: ", topic_name);
            param = {
              request: [
                {
                  device_id: this.topicSelectdDataList[i].edge_id,
                  type: "stop",
                  bag_name: this.topicSelectdDataList[i].bag_name,
                  topics: topic_name,
                },
              ],
            };
            console.log("param :: ", param);
            response = await apiRobot.post(
              "/recording/recording_action",
              param
            );
          }
        } else {
          for (let i = 0; i < this.topicDataList.length; i++) {
            if (this.topicDataList[i].edge_id === edgeId) {
              this.topicDataList[i].stopWatchTime = null;
              let topic_name = [];
              for (
                let j = 0;
                j < this.topicDataList[i].topicItemList.length;
                j++
              ) {
                let topic = this.topicDataList[i].topicItemList[j].topics_list;
                topic_name.push(topic);
              }
              console.log("$$$$$$$$$$ :: ", topic_name);
              param = {
                request: [
                  {
                    device_id: this.topicDataList[i].edge_id,
                    type: "stop",
                    bag_name: this.topicDataList[i].bag_name,
                    topics: topic_name,
                  },
                ],
              };
              console.log("param :: ", param);
              response = await apiRobot.post(
                "/recording/recording_action",
                param
              );
            }
          }
        }

        if (response.status === 200 || response.status === "200") {
          alert("레코딩이 중단 되었습니다.");
          for (let item of this.timer) {
            if (item.edge_id === edgeId) {
              item.target.innerHTML = "-:-:-";
              clearInterval(item.timer);
            }
          }
          for (let item of this.topicDataList) {
            if (item.edge_id === edgeId) {
              item.recording_on = "false";
            }
          }
          this.closeModal(4);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async recordStart() {
      try {
        let param = {};
        let response = null;
        console.log("((((((((( :))))))))) :: ", this.topicSelectdDataList);
        for (let i = 0; i < this.topicSelectdDataList.length; i++) {
          if (this.topicSelectdDataList[i].default_rosbag === "") {
            alert("Rosbag Name을 입력해 주세요.");
            return false;
          }
          this.selectedRecordIds = this.selectedRecordIds.filter(
            (e) => e != this.topicSelectdDataList[i].edge_id
          );
          console.log("this.selectedRecordIds :: ", this.selectedRecordIds);
          if (this.topicSelectdDataList[i].chkList.length < 1) {
            alert("최소 1개 이상의 Topic을 체크해 주세요.");
            return false;
          }
          console.log(
            "체크리스트 !!! : ",
            this.topicSelectdDataList[i].chkList
          );
          let topic_name = [];
          for (
            let j = 0;
            j < this.topicSelectdDataList[i].topicItemList.length;
            j++
          ) {
            let topic = "";
            for (
              let k = 0;
              k < this.topicSelectdDataList[i].chkList.length;
              k++
            ) {
              if (this.topicSelectdDataList[i].chkList[k] === j) {
                if (
                  this.topicSelectdDataList[i].topicItemList[j].topics_list ===
                  ""
                ) {
                  alert("체크된 Topic은 공백으로 입력할 수 없습니다.");
                  return false;
                }

                topic =
                  this.topicSelectdDataList[i].topicItemList[j].topics_list;
                topic_name.push(topic);
              }
            }
          }
          console.log("$$$$$$$$$$ :: ", topic_name);
          param = {
            request: [
              {
                device_id: this.topicSelectdDataList[i].edge_id,
                type: "start",
                bag_name: this.topicSelectdDataList[i].bag_name,
                topics: topic_name,
              },
            ],
          };
          console.log("param :: ", param);
          response = await apiRobot.post("/recording/recording_action", param);
        }

        if (response.status === 200 || response.status === "200") {
          alert("레코딩이 시작되었습니다.");
          // 밀리초시계 카운트 호출
          //this.countRecord(this.topicSelectdDataList);
          this.closeModal(5);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    convertMillisecondsToTime(milliseconds) {
      //  milliseconds > HH:MM:SS:ss
      const seconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedMilliseconds = (milliseconds % 1000)
        .toString()
        .padStart(2, "0");

      const forMilliseconds = formattedMilliseconds.slice(0, 2);

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}:${forMilliseconds}`;

      return formattedTime;
    },
    onInput(e, i) {
      i.default_rosbag = e.target.value;
    },
    onInputTopic(e, i) {
      i.topics_list = e.target.value;
    },
    chkTopicItem(item, index) {
      if (item.chkList.includes(index)) {
        item.chkList = item.chkList.filter((e) => e != index);
      } else {
        item.chkList.push(index);
      }
      console.log("!!! :: ", item.chkList);
    },
    chkRecord(deviceId) {
      //this.selectedRecordIds.push(deviceId)
      if (this.selectedRecordIds.includes(deviceId)) {
        this.selectedRecordIds = this.selectedRecordIds.filter(
          (e) => e !== deviceId
        );
      } else {
        this.selectedRecordIds.push(deviceId);
      }
    },
    // Signal 차트에서 컬러 선택
    colorPick(e, item) {
      item.color = e;
    },

    selectMissionType(e) {
      const selectedId = e.target.value;
      const missionList = this.mission.mission;
      for (let item of missionList) {
        if (selectedId == item.mission_type) {
          this.missionItem = item.item_name;
          this.missionItemId = item.item_id;
        }
      }
    },
    selectFailType(e) {
      const selectedId = e.target.value;
      const failList = this.mission.fail_action;
      for (let item of failList) {
        if (selectedId == item.item_id) {
          this.fail = item.item_id;
        }
      }
    },
    topictextLength(e) {
      e.preventDefault();
      const len = e.target.value.length;

      e.target.parentElement.nextElementSibling.querySelector(
        ".len"
      ).innerHTML = len;
    },
    // 토픽 네임 작성 Add
    addNewTopicItem(item, index) {
      const param = {
        topics_list: "",
      };
      this.topicSelectdDataList[index].topicItemList.push(param);
      //item.topicItemList = [];
    },
    // 토픽 remve 버튼 클릭
    removeTopicItem(item, index) {
      console.log("itemitem!!!@@@ :: ", item);
      item.topicItemList.splice(index, 1);
    },
    clearSelectedTopicItem(item) {
      console.log("item :; ", item);
      item.chkList = [];
    },
    async getTopicSetItemList() {
      const deviceId = this.selectedDevice.id;
      try {
        let topicItemName = [];
        for (let item of this.topicSetList) {
          const id = item.topic_id;
          const param = {
            topic_id: id,
          };
          const response = await apiRobot.post(
            "/recording/topic_set_item_list",
            param
          );

          topicItemName = [...topicItemName, ...response.data.item];
        }
        console.log("topicItemName!!! : ", topicItemName);
        for (let item of this.topicSelectdDataList) {
          for (let topic of topicItemName) {
            let param = {
              topics_list: topic.topic_item_name,
            };
            if (item.edge_id === deviceId) {
              item.topicItemList.push(param);
            }
          }
        }
        console.log("%%%%%%%%%%% :: ", topicItemName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 토픽셋에서 확인 버튼
    async addTopicSet() {
      this.selectedTopicItem = [];
      for (let item of this.selectedTopicIds) {
        this.selectedTopicItem.push(
          this.topicSetList.filter((e) => e.topic_id === item)
        );
      }
      console.log("this.selectedTopicItem :: ", this.selectedTopicItem);
      let a = [];
      this.selectedTopicItem.forEach((e) => {
        a = [...a, ...e.map((i) => i.topic_name)];
      });
      console.log(a);

      for (let item of this.topicSelectdDataList) {
        if (item.edge_id === this.selectedDevice.id) {
          item.topic_set = [...a];
        }
      }
      this.getTopicSetItemList();
      this.closeModal(6);
    },
    recordItemRemove(index) {
      this.topicSelectdDataList.splice(index, 1);
    },
    async getCameraTopicList() {
      //console.log("selecte :: ", this.selectedCameraEdgeDeviceList);
      //const deviceId = this.selectedCameraEdgeDeviceList;
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      try {
        const param = {
          device_id: this.cameraDeviceId,
        };
        const response = await apiRobot.post("/camera/topic_list", param);
        console.log("43434 토픽!!! :: ", response);
        this.cameraTopicList = response.data.item;
        loading.style.display = "none";
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async chgDeviceStatus() {
      const status = this.selectedDevice.status
        ?.replaceAll("_", "")
        ?.replaceAll("-", "")
        ?.replaceAll(" ", "")
        .toUpperCase();
      const deviceId = this.selectedDevice.id;

      if (status === "IDLE" || status === "ONMISSION" || status === "OFFLINE") {
        try {
          const param = {
            device_id: deviceId,
          };
          const response = await apiRobot.post("/mission/manual_fail", param);
          if (response.status === 200 || response.status === "200") {
            alert("변경되었습니다.");
            this.closeModal(1);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (status === "FAILED") {
        try {
          const param = {
            device_id: deviceId,
          };
          const response = await apiRobot.post(
            "/mission/manual_fail_recover",
            param
          );
          if (response.status === 200 || response.status === "200") {
            alert("변경되었습니다.");
            this.closeModal(1);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },

    async signalGraph(txt) {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";

      let param = {};
      const vm = this;
      if (txt && txt === "stop") {
        param = {
          signal_list: [],
        };
        try {
          const response = await apiRobot.post("/signal/signal", param);
          if (response.status === 200 || response.status === "200") {
            alert("Signal 수신이 중지 되었습니다.");
            this.closeModal(2);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const param = {
          signal_list: [...this.selectedGraphList],
        };

        try {
          console.log("%%%%%%%%%%%%% :: ", param.signal_list);
          const color = [];
          for (let item of param.signal_list) {
            color.push(item.color);
          }
          vm.$refs.chartWrap.updateChartColor(color);
          const response = await apiRobot.post("/signal/signal", param);
          console.log("^^^^^^^^^^^^^^^^^^^^^^ ::: ", response.data);
          if (response.status === 200 || response.status === "200") {
            alert("Signal 선택이 완료 되었습니다.");
            this.signalChange = true;
            this.closeModal(2);
          }
          console.log(
            "신규 API 결과 ::----------------------------- ",
            response
          );
          loading.style.display = "none";
          this.signalLabels = [];
          this.signalData = [];
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    addSignal() {
      if (this.isAllChkSgl) {
        this.isAllChkSgl = false;
      }
      this.selectedGraphList = [
        ...this.selectedGraphList,
        ...this.selectedSignalParam,
      ];
      this.selectedSignalParam = [];
      for (let item_01 of this.signalList) {
        for (let item_02 of this.selectedGraphList) {
          if (item_02.data_spec === item_01.signal_id) {
            this.signalList = this.signalList.filter(
              (e) => e.signal_id !== item_01.signal_id
            );
          }
        }
      }
      console.log("&&&&&&&&&&&&&&& :: ", this.selectedGraphList);
      const graphLength = this.selectedGraphList.length;
      this.signalColor[graphLength];
    },
    /*
    removeTopicItem(id) {
      console.log("this.topicItemListTemp :: ", this.topicItemList);
      for (let i = 0; i < this.topicItemList.length; i++) {
        console.log("dasfasdfiosdfjo");
        console.log(this.topicItemList[i]);

        if (this.topicItemList[i].topic_item_id == id) {
          this.topicItemList.splice(i, 1);
        }
      }
    },
    */

    allChkTopic() {
      this.selectedTopicIds = [];
      if (this.isAllChkTopic) {
        console.log("444444444444 :: ", this.topicSetList);
        for (let item of this.topicSetList) {
          this.selectedTopicIds.push(item.topic_id);
        }
      }
    },
    chkTopic(id) {
      if (this.selectedTopicIds.includes(id)) {
        this.selectedTopicIds = this.selectedTopicIds.filter((e) => e !== id);
      } else {
        this.selectedTopicIds.push(id);
      }
    },
    allChkCameraEdgeDevice() {
      this.selectedCameraEdgeDeviceList = [];
      if (this.isAllChkCameraEdgeDevice) {
        for (let item of this.cameraEdgeDeviceList) {
          this.selectedCameraEdgeDeviceList.push(item.edge_device_id);
        }
      }
      console.log(
        "selectedCameraEdgeDeviceList ::: ",
        this.selectedCameraEdgeDeviceList
      );
    },
    /*
    chkCameraEdgeDevice(id) {
      if (this.selectedCameraEdgeDeviceList.includes(id)) {
        this.selectedCameraEdgeDeviceList =
          this.selectedCameraEdgeDeviceList.filter((e) => e !== id);
      } else {
        this.selectedCameraEdgeDeviceList.push(id);
      }
      console.log("/////////////// :: ", this.selectedCameraEdgeDeviceList);
    },
    */
    chkCameraEdgeDevice(e) {
      this.cameraDeviceId = e.target.value;
    },

    allChkSgl() {
      this.selectedSignalList = [];
      this.selectedSignalParam = [];
      if (this.isAllChkSgl) {
        for (let item of this.signalList) {
          const param = {};
          param.device_id = item.device_id;
          param.data_type = item.action_type;
          param.data_spec = item.signal_id;
          param.color = "#00B4ED";

          this.selectedSignalList.push(item.signal_id);
          this.selectedSignalParam.push(param);
        }
      }
      console.log("selectedSignalList ::: ", this.selectedSignalList);
      console.log("selectedSignalParam ::: ", this.selectedSignalParam);
    },
    chkSignal(deviceId, signalId, actionType) {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      if (this.selectedSignalList.includes(signalId)) {
        this.selectedSignalList = this.selectedSignalList.filter(
          (e) => e !== signalId
        );
        this.selectedSignalParam = this.selectedSignalParam.filter(
          (e) => e.data_spec !== signalId
        );
      } else {
        const param = {};

        param.device_id = deviceId;
        param.data_type = actionType;
        param.data_spec = signalId;
        param.color = "#00B4ED";

        this.selectedSignalList.push(signalId);
        this.selectedSignalParam.push(param);
      }
      loading.style.display = "none";
      console.log("/////////////// :: ", this.selectedSignalParam);
    },
    allChkDevice() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      this.selectedDeviceList = [];
      this.selectedEdgeDeviceList = [];
      if (this.isAllChkDevice) {
        for (let item of this.edgeDeviceList) {
          this.selectedDeviceList.push(item.id);
          this.selectedEdgeDeviceList.push(item.edge_device_id);
        }
        console.log("gggggggg :: ", this.selectedEdgeDeviceList);
        this.getSignalList();
      } else {
        // 전체 체크박스 해제 시 디바이스 선택 비우기
        this.selectedEdgeDeviceList = [];
        // 선택된 엣지 디바이스의 시그널 목록 비우기
        this.signalList = [];
      }

      loading.style.display = "none";
    },
    chkEdgeDevice(id, edgeId) {
      if (this.selectedDeviceList.includes(id)) {
        this.selectedDeviceList = this.selectedDeviceList.filter(
          (e) => e !== id
        );
        this.selectedEdgeDeviceList = this.selectedEdgeDeviceList.filter(
          (e) => e !== edgeId
        );
        this.signalList = this.signalList.filter((e) => e.device_id != edgeId);
      } else {
        this.selectedDeviceList.push(id);
        this.selectedEdgeDeviceList.push(edgeId);
        this.getSignalList();
      }
      console.log("this.signalList ::1!!!! ,, L::", this.signalList);

      console.log("/////////////// :: ", this.selectedDeviceList);
    },
    allChkSglRemove() {
      if (this.isAllChkSglRemove) {
        this.selectedGraphRemoveList = [];
        for (let item of this.selectedGraphList) {
          this.selectedGraphRemoveList.push(item.data_spec);
        }
      } else {
        this.selectedGraphRemoveList = [];
      }
    },
    chkSglRemove(dataSpec) {
      if (this.selectedGraphRemoveList.includes(dataSpec)) {
        this.selectedGraphRemoveList = this.selectedGraphRemoveList.filter(
          (e) => e !== dataSpec
        );
      } else {
        this.selectedGraphRemoveList.push(dataSpec);
      }
    },
    removeSignal() {
      if (this.isAllChkSglRemove) {
        this.isAllChkSglRemove = false;
      }
      if (this.selectedEdgeDeviceList.length > 0) {
        const selectedItem = this.selectedGraphList.filter((e) =>
          this.selectedGraphRemoveList.includes(e.data_spec)
        );
        for (let item of selectedItem) {
          const param = {};
          param.device_id = item.device_id;
          param.action_type = item.data_type;
          param.signal_id = item.data_spec;

          //this.selectedSignalList.push(item.signal_id);
          //this.selectedSignalParam.push(param);
          this.signalList.push(param);

          this.selectedSignalList = this.selectedSignalList.filter(
            (e) => e !== param.signal_id
          );
        }
      } else {
        console.log("!!328927394 :: ", this.selectedSignalList);
        this.selectedSignalList = [];
      }
      this.selectedGraphList = this.selectedGraphList.filter(
        (e) => !this.selectedGraphRemoveList.includes(e.data_spec)
      );
      this.selectedGraphRemoveList = [];
      const graphLength = this.selectedGraphList.length;
      this.signalColor[graphLength];
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];
            this.cameraEdgeDeviceList = [...r.data.data];

            this.cameraDeviceId = this.cameraEdgeDeviceList[0].edge_device_id;

            console.log("엣지 디바이스 !!! :: ", this.edgeDeviceList);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getSignalList() {
      try {
        //this.selectedEdgeDeviceList.push("wm_robot1");
        //this.selectedEdgeDeviceList.push("robot2");
        const param = {
          device_list: this.selectedEdgeDeviceList,
          //device_list: ["robot1", "robot2"],
        };
        const response = await apiRobot.post(
          "/signal/signal_select_list",
          param
        );
        console.log(
          "3333333333333 signal 결과 ::----------------------------- ",
          response
        );
        this.signalList = response.data.item;
        for (let i = 0; i < this.signalList.length; i++) {
          let isNonAddItem = false;
          for (let j = 0; j < this.selectedGraphList.length; j++) {
            if (
              this.selectedGraphList[j].data_spec ==
              this.signalList[i].signal_id
            ) {
              isNonAddItem = true;
            }
            if (isNonAddItem) this.signalList.splice(i, 1);
          }
        }
        //this.selectedEdgeDeviceList = [];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async missionAssign() {
      const getCKUser = this.getUserData;
      const getUser = this.$store.state.user;
      let userId = "";
      let userData = null;
      if (getCKUser == undefined) {
        userData = getUser;
      } else {
        userData = getCKUser;
      }

      try {
        const response = await common.apiGet("/member/info");

        userId = response.data.data.memberId;
        console.log("member_id =  ::: ", response);
        console.log("member_id =  ::: ", userId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      const registMission = {};
      const registFailAction = {};

      for (let item of this.mission.mission) {
        if (this.missionItemId === item.item_id) {
          registMission.item_id = item.item_id;
          registMission.mission_type = item.mission_type;
          registMission.item_name = item.item_name;
          registMission.item_type = item.item_type;
          registMission.action_type = item.action_type;
          registMission.item_definition = item.item_definition;
        }
      }

      for (let item of this.mission.fail_action) {
        if (this.fail === item.item_id) {
          registFailAction.item_id = item.item_id;
          registFailAction.item_name = item.item_name;
          registFailAction.item_type = item.item_type;
          registFailAction.action_type = item.action_type;
          registFailAction.item_definition = item.item_definition;
        }
      }
      console.log("123123 :: ", this.pointList);
      const array = [...this.pointList];
      const newArr = [];
      array.map((a) => {
        let b = {};
        //b["point"] = a["point_order"];
        b["goal_pose_x"] = a["lat"];
        b["goal_pose_y"] = a["lng"];
        b.goal_pose_x = b.goal_pose_x.toString();
        b.goal_pose_y = b.goal_pose_y.toString();
        b.goal_pose_theta = "0.0";
        // 세타 값 강제 주입
        newArr.push(b);
      });

      try {
        const param = {
          device_id: this.selectedDevice.id,
          //register_id: userData.email,
          register_id: userId,
          register_name: userData.name,
          mission: {
            item_id: registMission.item_id,
            mission_type: registMission.mission_type,
            item_name: registMission.item_name,
            item_type: registMission.item_type,
            action_type: registMission.action_type,
            item_definition: registMission.item_definition,
          },
          fail_action: {
            item_id: registFailAction.item_id,
            item_name: registFailAction.item_name,
            item_type: registFailAction.item_type,
            action_type: registFailAction.action_type,
            item_definition: registFailAction.item_definition,
          },
          goal_poses: newArr,
        };
        console.log("param ::----------------------------- ", param);
        const response = await apiRobot.post("/mission/mission_assign", param);
        console.log(
          "mission/mission_assign API 결과 ::----------------------------- ",
          response
        );
        if (response.status === 200 || response.status === "200") {
          alert("미션이 성공적으로 등록 되었습니다.");
          this.closeModal(3);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      this.closeModal(3);
    },
    async cancelAction() {
      if (this.missionCancelSelect === "") {
        alert("Cancel Action을 지정해 주세요.");
        return false;
      } else {
        try {
          const param = {
            device_id: this.selectedDevice.id,
            mission_item_id: this.selectedDevice.missionId,

            item_definition: {
              return_to_base: true,
              signal_lamp: true,
              head_lamp: true,
            },
          };
          console.log("미션캔슬!!! :: ", param);
          const response = await apiRobot.post(
            "/mission/mission_cancel",
            param
          );
          console.log(
            "신규 API 결과 ::----------------------------- ",
            response
          );
          if (response.status === 200 || response.status === "200") {
            alert("Mission Cancel 되었습니다.");
            this.closeModal(7);
            this.closeModal(3);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    onSort() {
      console.log("포인트리스트!!!! :: ", this.pointList);
      if (
        this.pointList[0].point !== 0 &&
        this.pointList[0].point !== undefined
      ) {
        this.pointList = [...this.tempPointList];
      }
      this.$refs.pointMap.rearrangeMarker(this.pointList);
    },
    chgPoint(item) {
      console.log("맵 전달받은 것 :: ", item);
      this.childItem = item;
      this.pointList = [...this.childItem];

      this.childItem = [];
    },
    onChoose() {
      this.tempPointList = [...this.pointList];
    },

    removePoint(index) {
      this.pointList.splice(index, 1);
      this.$refs.pointMap.rearrangeMarker(this.pointList);
    },
    // Data Recording 스톱워치
    countRecord(startList) {
      console.log("startList :: ", startList);
      console.log("startList !!!:: ", this.topicDataList);
      //스톱워치 이벤트
      let mins = 0;
      let seconds = 0;
      let milliseconds = 0;
      let target = [];
      /*
      for (let i = 0; i < this.topicDataList.length; i++) {
        for (let j = 0; j < startList.length; j++) {
          if (this.topicDataList[i].edge_id === startList[j].edge_id) {
            this.topicDataList[i].recording_on = "true";
            const param = {
              edge_id: this.topicDataList[i].edge_id,
              target: document.getElementById("record_" + i),
            };
            target.push(param);

            const stopTime = this.topicDataList[i].stopWatchTime;

            alert(stopTime);
            console.log("target !!!!!!!!! !2222222222222; :: ", target);
            mins = parseInt(stopTime.split(":")[1]);
            seconds = parseInt(stopTime.split(":")[2]);
            milliseconds = 0;
          }
        }
      }
      */
      for (let i = 0; i < this.topicDataList.length; i++) {
        if (this.topicDataList[i].recording_on == "true") {
          const param = {
            edge_id: this.topicDataList[i].edge_id,
            target: document.getElementById("record_" + i),
          };
          target.push(param);

          const stopTime = this.topicDataList[i].stopWatchTime;

          console.log("target !!!!!!!!! !2222222222222; :: ", target);
          mins = parseInt(stopTime.split(":")[1]);
          seconds = parseInt(stopTime.split(":")[2]);
          milliseconds = 0;
        }
      }

      for (let item of target) {
        let setTime = setInterval(function () {
          startWatch(item.target);
        }, 10);
        //console.log("target !!!!!!!!! ; :: ", target);
        const param = {
          timer: setTime,
          edge_id: item.edge_id,
          target: item.target,
        };
        this.timer.push(param);
        console.log("timertimertimer :: ", this.timer);
      }
      // start 버튼 이벤트

      // 시간 계산
      function startWatch(target) {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
        milliseconds++;

        if (milliseconds >= 100) {
          milliseconds = 0;
          seconds++;
        }
        if (seconds >= 60) {
          seconds = 0;
          mins++;
        }
        //for (let item of target) {
        console.log("target !!!!!!!!! ; :: ", target);
        target.innerHTML = `${mins < 10 ? "0" + mins : mins} : ${
          seconds < 10 ? "0" + seconds : seconds
        } : ${milliseconds < 10 ? "0" + milliseconds : milliseconds}`;
        //}
      }
    },
    // 디바이스 Status 카운트 계산
    calcStatusCnt() {
      const cnt = this.device.status.cnt;
      cnt.idle = 0;
      cnt.onMission = 0;
      cnt.offLine = 0;
      cnt.fail = 0;
      for (let item of this.robotData) {
        this.device.status.cnt;
        const status = item.mission_status
          ?.replaceAll("_", "")
          ?.replaceAll("-", "")
          ?.replaceAll(" ", "")
          .toUpperCase();

        if (status === "IDLE") {
          cnt.idle++;
        } else if (status === "ONMISSION") {
          cnt.onMission++;
        } else if (status === "OFFLINE") {
          cnt.offLine++;
        } else if (status === "FAILED") {
          cnt.fail++;
        }
      }
    },
    async socketOpen() {
      apiWebSocket.onopen = function (event) {
        alert(event.returnValue);
        return event.returnValue;
      };
    },
    socketClose() {
      apiWebSocket.close();
    },

    async socketSendEdgeids() {
      const deviceIds = [];
      for (let item of this.edgeDeviceList) {
        deviceIds.push(item.edge_device_id);
      }
      const data = {
        action: "set_edgeids",
        data: {
          edgeids: deviceIds,
        },
      };
      apiWebSocket.send(JSON.stringify(data));
    },
    chgMapType(type) {
      this.mapType = type;
    },
    carDetail(e, carNumber) {
      document.querySelectorAll(".data_list").forEach((e) => {
        e.classList.remove("active");
      });
      e.target.classList.add("active");

      this.carDataDetailList = [];
      this.carDataDetail = {};
      this.carDataList.forEach((e) => {
        if (e.car_number == carNumber) {
          this.carDataDetail.car_number = e.car_number;
          this.carDataDetail.car_type = e.car_type;
          this.carDataDetail.driver = e.driver;
          this.carDataDetail.logging_date = e.logging_date;

          this.carDataDetailList = e.items;
        }
      });
      console.log(".//////////// :: ", this.carDataDetailList);
    },

    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },

    /**************
     * select box
     **************/
    async getOemSelect() {
      try {
        const response = await common.apiGet(
          "/edge-device-group/domain/oem/vehicle/1"
        );
        const o = [];
        response.data.data.forEach((e) => {
          o.push({ id: e.id, value: e.name });
        });
        this.oemOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/1");
      }
    },
    async getVehicleSelect(id) {
      try {
        const o = [];
        if (id != 0) {
          const response = await common.apiGet(
            "/edge-device-group/domain/oem/vehicle/" + id
          );
          response.data.data.forEach((e) => {
            o.push({ id: e.id, value: e.name });
          });
        }
        this.vehicleOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/" + id);
      }
    },

    // 프로젝트정보 , 부가기능
    async hideProjectInfo() {
      // 프로젝트 정보 - 접기 , 펼치기
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },
    hideUtils() {
      // 부가기능 - 보이기 숨기기
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },
    async clipCoppy() {
      // 공유하기
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.error(error);
      }
    },
    async taskModify() {
      // 수정
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async getPrjData(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
    // 미션 캔슬
    async missionCancel() {
      try {
        const response = await apiRobot.post("/mission/mission_cancel_list");
        this.missionCancelList = response.data.cancel_action;
        console.log(
          "미션캔슬 결과 ::----------------------------- ",
          this.missionCancelList
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 전체 Mission & Fail 목록
    async missionAssignView() {
      const param = {
        //device_id: "robot1",
        device_id: this.selectedDevice.id,
      };
      try {
        const response = await apiRobot.post(
          "/mission/mission_assign_view",
          param
        );

        this.mission = response.data;
        console.log("this.missionthis.mission :: ", this.mission);

        if (this.missionDetail.mission && this.missionDetail.mission != null) {
          this.missionType = this.missionDetail.mission.mission_type;
          this.missionItem = this.missionDetail.mission.item_name;
        } else {
          this.missionItemId = this.mission.mission[0].item_id;
          this.missionType = this.mission.mission[0].mission_type;
          this.missionItem = this.mission.mission[0].item_name;
        }

        if (
          this.missionDetail.fail_action &&
          this.missionDetail.fail_action != null
        ) {
          this.fail = this.missionDetail.fail_action.item_id;
        } else {
          this.fail = this.mission.fail_action[0].item_id;
        }
        console.log("111sssddd ::----------------------------- ", this.mission);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 등록된 미션보기
    async missionView() {
      const param = {
        //device_id: "robot1",
        mission_item_id: this.selectedDevice.missionId,
      };
      try {
        const response = await apiRobot.post("/mission/mission_view", param);
        this.missionDetail = response.data;
        const array = response.data.goal_point;
        const newArr = [];
        array.map((a) => {
          let b = {};
          b["point"] = a["point_order"];
          b["lat"] = a["point_lat"];
          b["lng"] = a["point_lng"];
          newArr.push(b);
        });
        this.pointList = [...newArr];
        this.$refs.pointMap.viewMap();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTopicSetList() {
      try {
        const response = await apiRobot.post("/recording/topic_set_list");
        this.topicSetList = response.data.item;
        console.log("topicSetList :: ", this.topicSetList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTopicWrapList() {
      const deviceIds = [];
      for (let item of this.edgeDeviceList) {
        deviceIds.push(item.edge_device_id);
      }
      const param = {
        thing_list: deviceIds,
        //thing_list: ["wm_robot1", "wm_robot2"],
      };

      try {
        const response = await apiRobot.post(
          "/recording/recording_shadow",
          param
        );
        // 레코드 아이템 받아옴
        this.topicDataList = response.data.item;
        this.topicSelectdDataList = [];
        for (let item of this.topicDataList) {
          if (item.recording_on === "true") {
            this.topicSelectdDataList.push(item);
            const topicItem = item.topics.split(",");
            item.topicItemList = [];
            item.chkList = [];
            for (let i = 0; i < topicItem.length; i++) {
              const param = {
                topics_list: topicItem[i],
              };
              item.topicItemList.push(param);
              item.chkList.push(i);
              //item.topicItemList.chkList.push(topic);
            }

            const dateB = new Date(item.recording_start_time);
            const dateA = new Date(item.recording_stop_time);
            let diffMSec = null;
            if (
              item.recording_start_time !== "" &&
              item.recording_stop_time !== ""
            ) {
              diffMSec = dateA.getTime() - dateB.getTime();
            } else {
              diffMSec = 0;
            }

            //const diffHour = this.convertMillisecondsToTime(diffMSec);
            const diffHour = this.convertMillisecondsToTime(diffMSec);
            console.log(`시간의 차이는 ${diffMSec}시간 입니다.`);
            console.log(`시간의 차이는 ${diffHour}시간 입니다.`);
            item.stopWatchTime = diffHour;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getSitePoint() {
      try {
        const response = await apiRobot.post("/map/site_point");

        console.log("site_point :: ", response.data);
        return response.data.item;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async showModal(
      num,
      deviceId,
      status,
      missionId,
      progress,
      recordItem,
      selectedRecordIds,
      view
    ) {
      const loading = this.$refs.lodingWrap;
      const vm = this;
      let $modal = null;
      view === "record_derail"
        ? (this.isRecordDetail = true)
        : (this.isRecordDetail = false);

      this.selectedDevice.id = deviceId;
      this.selectedDevice.missionId = missionId;
      this.selectedDevice.progress = progress;
      this.selectedDevice.status = status
        ?.replaceAll("_", "")
        ?.replaceAll("-", "")
        ?.replaceAll(" ", "")
        ?.toUpperCase();
      if (num === 1) {
        $modal = this.$refs.modalWrap_01;
        $modal.style.display = "block";
      } else if (num === 2) {
        $modal = this.$refs.modalWrap_02;
        $modal.style.display = "block";
      } else if (num === 3) {
        loading.style.display = "block";
        $modal = this.$refs.modalWrap_03;
        await this.missionAssignView();
        if (missionId != null) {
          await this.missionView();
          vm.$refs.pointMap.rearrangeMarker(this.pointList);
        } else {
          //console.log("포인트 리스트!!! :: ", this.map.currentPose);
          const deviceId = this.selectedDevice.id;
          for (let item of this.robotData) {
            if (item.device_id == deviceId) {
              console.log("444444444444444 :: ", item);
              const newMapParam = [
                {
                  lat:
                    "latitude" in item.current_pose
                      ? item.current_pose.latitude
                      : item.current_pose.x,
                  lng:
                    "longitude" in item.current_pose
                      ? item.current_pose.longitude
                      : item.current_pose.y,
                },
              ];

              this.pointList = [...newMapParam];
            }
          }
        }
        $modal.style.display = "block";
        loading.style.display = "none";
      } else if (num === 4) {
        $modal = this.$refs.modalWrap_04;
        $modal.style.display = "block";
      } else if (num === 5) {
        // Data Recording에서 Start 버튼 클릭 시

        // 데이터 레코딩 다중 스타트 버튼 클릭 시 아무것도 선택되지 않았을 때 유효성 검사
        if (recordItem == null && selectedRecordIds.length < 1) {
          alert("최소 1개 이상의 Device를 선택해 주세요.");
          return false;
        }
        loading.style.display = "block";
        $modal = this.$refs.modalWrap_05;

        let topics = null;
        let length = null;
        // 데이터 레코딩 개별 스타트 버튼 클릭 시
        if (recordItem != null) {
          topics = recordItem.topics;
          length = topics.split(",").length;
          console.log("length :: ", length);
          recordItem.chkList =
            recordItem.chkList == undefined ? [] : recordItem.chkList;
          recordItem.default_rosbag =
            recordItem.default_rosbag === "" ||
            recordItem.default_rosbag === undefined
              ? recordItem.edge_id + "_" + this.dateFormat(new Date())
              : recordItem.default_rosbag;
          recordItem.stopWatchTime = recordItem.stopWatchTime
            ? recordItem.stopWatchTime
            : null;
          if (recordItem.topicItemList == undefined) {
            recordItem.topicItemList = [];
            const param = { topics_list: "" };
            recordItem.topicItemList.push(param);
          }
          //if (this.topicSelectdDataList.length < 1) {
          this.topicSelectdDataList = [];
          this.topicSelectdDataList.push(recordItem);
          //}

          // 데이터 레코딩 다중 스타트 버튼 클릭 시
        } else if (selectedRecordIds != null) {
          console.log("selectedRecordIds :: ", selectedRecordIds);
          console.log("selectedRecordIds :: ", this.topicDataList);

          this.topicSelectdDataList = [];
          for (let i = 0; i < this.topicDataList.length; i++) {
            if (selectedRecordIds.includes(this.topicDataList[i].edge_id)) {
              topics = this.topicDataList[i].topics;
              length = topics.split(",").length;
              this.topicDataList[i].chkList =
                this.topicDataList[i].chkList == undefined
                  ? []
                  : this.topicDataList[i].chkList;
              this.topicDataList[i].default_rosbag =
                this.topicDataList[i].default_rosbag === "" ||
                this.topicDataList[i].default_rosbag === undefined
                  ? this.topicDataList[i].edge_id +
                    "_" +
                    this.dateFormat(new Date())
                  : this.topicDataList[i].default_rosbag;
              this.topicDataList[i].stopWatchTime = this.topicDataList[i]
                .stopWatchTime
                ? this.topicDataList[i].stopWatchTime
                : null;

              if (this.topicDataList[i].topicItemList == undefined) {
                this.topicDataList[i].topicItemList = [];
                const param = { topics_list: "" };
                this.topicDataList[i].topicItemList.push(param);
              }

              console.log("this.topicDataList[i] :: ", this.topicDataList[i]);

              this.topicSelectdDataList.push(this.topicDataList[i]);
            }
          }
        }
        console.log("length :: ", length);
        //this.topicItemList
        console.log("########## :: ", recordItem);

        $modal.style.display = "block";
        //await this.getTopicWrapList();
        loading.style.display = "none";
      } else if (num === 6) {
        // 토픽셋 불러오기
        loading.style.display = "block";
        $modal = this.$refs.modalWrap_06;
        await this.getTopicSetList();
        $modal.style.display = "block";
        loading.style.display = "none";
      } else if (num === 7) {
        loading.style.display = "block";
        $modal = this.$refs.modalWrap_07;
        await this.missionCancel();
        $modal.style.display = "block";
        loading.style.display = "none";
      } else if (num === 8) {
        $modal = this.$refs.modalWrap_08;
        $modal.style.display = "block";
      } else if (num === 9) {
        $modal = this.$refs.modalWrap_09;
        $modal.style.display = "block";
      }
    },
    closeModal(num) {
      let $modal = null;

      if (num === 1) {
        $modal = this.$refs.modalWrap_01;
      } else if (num === 2) {
        $modal = this.$refs.modalWrap_02;
      } else if (num === 3) {
        $modal = this.$refs.modalWrap_03;
      } else if (num === 4) {
        $modal = this.$refs.modalWrap_04;
      } else if (num === 5) {
        $modal = this.$refs.modalWrap_05;
      } else if (num === 6) {
        $modal = this.$refs.modalWrap_06;
      } else if (num === 7) {
        $modal = this.$refs.modalWrap_07;
      } else if (num === 8) {
        $modal = this.$refs.modalWrap_08;
      } else if (num === 9) {
        $modal = this.$refs.modalWrap_09;
      }

      $modal.style.display = "none";
    },
  },
};
</script>
<style scoped lang="scss">
#modalWrap {
  .signal_modal {
    width: 90%;
    height: 80%;
    padding: 20px;
    &.camera_modal {
      padding-bottom: 52px;
      .table_list_common {
        width: calc(50% - 75px) !important;
        .select_box {
          width: 100% !important;
          input[type="radio"] {
            position: relative;
            top: 3px;
            cursor: pointer;
          }
        }
      }
      .check_wrap {
        margin-top: 65px;
      }
    }
    .modal_title {
      margin: 0;
    }
    .table_list {
      height: calc(100% - 100px);
      .table_list_common {
        float: left;
        width: 55%;
        height: 100%;
        .count_box {
          padding: 15px 10px;
          font-size: 18px;
          font-weight: 700;
        }
        .filter_box {
          width: calc(100% - 70px);
          select {
            width: calc(100% - 10px);
            height: 100%;
            margin: 0;
            background-position: center right 15px;
          }
          .btn_option {
            border-radius: 10px;
          }
        }
        .select_box {
          width: calc(50% - 10px);
          //height: calc(100% - 120px);
          height: calc(100% - 70px);
          padding: 0;
          .all_chk {
            position: relative;
            label {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
      .choose_box {
        height: calc(100% - 120px);
        margin-top: 112px;
      }
    }
    &.recording_topic_modal {
      width: 30%;
      height: 60%;
      .table_list_common {
        width: 100%;
        .select_box {
          width: 100% !important;
          height: calc(100% - 85px);
          margin-top: 20px;
        }
      }
    }
  }
  .message_modal {
    width: 500px;
    height: auto;
    margin-top: 20px;
    .message_box {
      position: static;
      margin-bottom: 20px;
      padding-top: 20px;
      transform: translate(0);
      .txt {
        &.txt_b {
          color: #12a1d5;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
    .input_box {
      margin-bottom: 12px;
      .title {
        font-size: 16px;
        margin-bottom: 8px;
        text-align: left;
      }
      .input_list {
        width: 100%;
        height: 150px;
        overflow: auto;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        .task_list {
          li {
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            gap: 5px;
            width: 100%;
            height: 40px;
            text-align: left;
            padding: 0 15px;
            box-sizing: border-box;
            label {
              font-size: 16px;
              cursor: pointer;
            }
            input {
              width: 13px;
              height: 13px;
              margin: 0;
            }
            &:hover {
              background-color: #00b4ed;
              label {
                color: #fff;
              }
            }
          }
        }
        .no_task {
          display: inline-flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      .input_select_button {
        display: flex;
        gap: 10px;
        padding-bottom: 20px;
        button {
          width: 90px;
          height: 45px;
          border-radius: 10px;
        }
      }
      input,
      .input_text {
        width: 100%;
        height: 40px;
        padding: 0 20px;
        border-radius: 10px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        box-sizing: border-box;
      }
      select {
        width: 100%;
        background-position: center right 15px;
      }
      .input_text {
        height: auto;
        padding: 20px;
        text-align: left;
        p {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .button_box {
      margin-bottom: 30px;
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: space-between;
        li {
          width: calc((100% - 15px) / 4);
          height: 40px;
          div {
            display: inline-flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            border: 1px solid #d5dae3;
            background-color: #eee;
            border-radius: 10px;
            &.active_idle {
              background: #ffa800;
              color: #fff;
            }
            &.active_fail {
              background: #f15046;
              color: #fff;
            }
          }
        }
      }
    }
    .button_bottom_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
      button {
        position: static;
        transform: translate(0);
      }
    }
  }
  .record_modal {
    width: 50%;
    height: 70%;
    overflow-y: auto;
    .record_device_wrap_title {
      display: inline-flex;
      width: 100%;
      height: 50px;
      align-items: center;
      background-color: #405261;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 500;
    }
    .record_device_wrap {
      position: relative;
      border: 1px solid #d5dae3;
      padding-bottom: 30px;
      margin: 30px 25px 0;
      border-radius: 10px;
      .record_device_remove {
        position: absolute;
        top: 7px;
        right: 8px;
        width: 30px;
        height: 30px;
        margin-bottom: 30px;
        color: #fff;
        font-size: 24px;
      }
      .record_device {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 50px;
        margin-bottom: 25px;
        padding: 0 20px;
        background: #00b4ed;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-sizing: border-box;
        span {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .record_info {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        .title {
          width: 200px;
          font-size: 18px;
          font-weight: 500;
        }
        .input {
          flex: 1;
          margin-right: 12px;
          input {
            width: 100%;
            height: 45px;
            font-size: 18px;
            padding: 0 10px;
            box-sizing: border-box;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            background: #fff;
          }
        }
        .btn {
          width: 90px;
          height: 45px;
          button {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .length {
            display: inline-flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            .len {
              font-size: 18px;
            }
          }
        }
      }
      .record_topic {
        padding: 0 20px;
        .row {
          display: flex;
          align-items: center;
          margin-top: 20px;
          .left {
            width: 85px;
            font-size: 18px;
            font-weight: 500;
          }
          .right {
            position: relative;
            flex: 1;
            span {
              font-size: 20px;
              font-weight: 500;
            }
            button {
              width: 90px;
              height: 45px;
              font-size: 15px;
              font-weight: 400;
              border-radius: 10px;
              color: #fff;
              &.btn_clear {
                background: #f15046;
              }
              &.btn_add {
                background: #00b4ed;
              }
            }
            .topic_box {
              display: inline-flex;
              height: 35px;
              width: 100%;
              align-items: center;
              padding: 0 45px 0 20px;
              box-sizing: border-box;
              border: 1px solid #d5dae3;
              font-size: 18px;
              border-radius: 10px;
            }
            .topic_remove {
              position: absolute;
              display: inline-flex;
              top: 50%;
              right: 7px;
              width: 30px;
              height: 30px;
              transform: translateY(-50%);
              justify-content: center;
              align-items: center;
              background-color: #405261;
              border-radius: 50%;
            }
          }
          &.row_title {
            .left {
              text-align: center;
            }
            .right {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 10px;
              span {
                padding-right: 10px;
              }
            }
          }
          &.row_content {
            .left {
              text-align: center;
            }
          }
        }
      }
      .btn_record_close {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 10px;
        top: 8px;
        font-size: 30px;
        color: #fff;
      }
    }
    .check_wrap {
      padding: 0 20px 30px;
    }
    .btn_record_close {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 26px;
      color: #fff;
    }
  }
  .mission_modal {
    width: 90%;
    height: 90%;
    padding: 20px;
    overflow: auto;
    h2 {
      font-size: 20px;
      font-weight: 700;
    }
    .info_box {
      padding: 30px 0 20px;
      .row {
        display: flex;
        align-items: center;
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
        .title {
          width: 20%;
          max-width: 200px;
        }
        .info {
          width: 30%;
          flex: 1;

          margin-right: 20px;

          select {
            width: 100%;
            background-position: center right 15px;
          }
          input[type="text"] {
            width: 100%;
            border: 1px solid #d5dae3;
            background-color: #fff;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 10px 15px;
            font-size: 15px;
            color: #9ea5b2;
          }
        }
        &.row_02 {
          .title {
            width: 30%;
            max-width: 300px;
          }
          .tbl {
            width: 70%;
            flex: 1;
            border: 1px solid #d5dae3;
            .mando_table_wrap {
              padding: 0;
              max-height: 139px;
              overflow-y: auto;
              tbody {
                tr {
                  div {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    label {
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
        }
        &.row_03 {
          .btn {
            width: 30%;
            max-width: 300px;
          }
          .btn_mission {
            font-weight: 500;
            padding: 10px 15px;
            border-radius: 10px;
            cursor: default;
            &.btn_mission_cancel {
              background-color: #f15046;
              color: #fff;
              cursor: pointer;
            }
          }

          .title {
            width: 25%;
            max-width: 170px;
          }
          .tbl {
            width: 60%;
            flex: 1;
          }
        }
      }
    }
    .goal_point_box {
      border-top: 1px solid #d5dae3;
      .ico_info {
        vertical-align: middle;
        font-size: 24px;
        color: #405261;
      }
      h3 {
        padding: 15px 0;
        font-size: 18px;
        font-weight: 600;
      }
      .goal_point_wrap {
        display: flex;
        height: 400px;
        gap: 20px;
        padding: 10px;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        .goal_point_inner {
          width: 460px;
          .title {
            display: flex;
            align-items: center;
            height: 35px;
            & > div {
              font-size: 14px;
              font-weight: 600;
              text-align: center;
              &:first-child,
              &:last-child {
                width: 50px;
              }
              &:nth-child(2),
              &:nth-child(3) {
                width: calc((100% - 100px) / 2);
              }
            }
          }
          .goal_point {
            display: flex;
            flex-direction: column;
            height: calc(100% - 33px);
            gap: 10px;
            overflow-y: auto;
            & > div {
              display: flex;
              align-items: center;
              margin-right: 10px;
              padding: 10px 0;
              border: 1px solid #d5dae3;
              border-radius: 10px;
              cursor: move;
              & > div {
                text-align: center;
                &:first-child,
                &:last-child {
                  width: 50px;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  width: calc((100% - 100px) / 2);
                }
              }
            }
            .point {
              b {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                font-weight: 600;
                border: 2px solid #020a0a;
                border-radius: 50%;
              }
            }
            .btn {
              button {
                color: #020a0a;
                font-size: 18px;
              }
            }
          }
        }
        .map_area {
          flex: 1;
        }
      }
    }
  }
}
.moa_dete {
  overflow: hidden;
}
.mid_btn_area {
  text-align: right;
  .btn_control {
    padding: 10px 20px;
    border-radius: 10px;
  }
}
.data_wrap {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  & > div {
    width: calc(50% - 10px);
  }
}
.title_box {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  strong {
    font-size: 22px;
    font-weight: 700;
    line-height: 38px;
  }
}
.status_wrap {
  .status_top {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    padding: 20px 20px 0;
    &.status_top_02 {
      justify-content: space-between;
      .status_inner {
        display: flex;
        align-items: center;
        .status {
          padding: 0 10px;
          .btn_start_stop {
            padding: 10px 15px;
            color: #fff;
            font-weight: 600;
            border-radius: 10px;
            &.btn_start {
              background-color: #12a1d5;
            }
            &.btn_stop {
              background-color: #f15046;
            }
          }
        }
      }
    }
    .title {
      font-size: 22px;
      font-weight: 700;
      i {
        margin-right: 5px;
        font-size: 22px;
        color: #12a1d5;
      }
    }
    .status {
      padding: 16px 16px 16px 30px;
      border-radius: 10px;
      &.status_01 {
        background-color: #fff2d9;
        .item {
          &::before {
            background-color: #ffa800;
          }
        }
      }
      &.status_02 {
        background-color: #d9f2e3;
        .item {
          &::before {
            background-color: #02a744;
          }
        }
      }
      &.status_03 {
        background-color: #ececec;
        .item {
          &::before {
            background-color: #bbb;
          }
        }
      }
      &.status_04 {
        background-color: #fde5e3;
        .item {
          &::before {
            background-color: #f15046;
          }
        }
      }
      & > span {
        font-size: 16px;
        font-weight: 700;
      }
      .item {
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.mando_table_wrap {
  padding: 0 20px;
  overflow-y: auto;
  box-sizing: border-box;
}
.mando_table {
  position: static !important;
  &.log_table {
    white-space: normal;
    td {
      text-align: left;
      .log_title {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        .log_level {
          font-weight: 600;
        }
      }
      .log_content {
        margin-top: 5px;
        line-height: 1.5;
      }
    }
  }
  th {
    & > div {
      font-weight: 600;
    }
  }
  td {
    label {
      margin-right: 5px;
    }
    .record_on_off_wrap {
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;

      .record_on_off {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-sizing: border-box;
        &.record_on {
          background-color: #02a744;
        }
        &.record_off {
          border: 2px solid #02a744;
        }
      }
      .record_txt {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 34px;
        font-weight: 600;
        border: 1px solid #d5dae3;
        border-radius: 10px;
      }
    }
    .txt_on_off {
      padding: 8px 20px;
      font-weight: 600;
      color: #fff;
      border-radius: 10px;
      &.txt_on {
        background-color: #02a744;
      }
      &.txt_off,
      &.txt_off_detail {
        background-color: #bbb;
      }
      &.txt_on_detail,
      &.txt_on_detail {
        cursor: pointer;
      }
      &.txt_on_detail {
        background-color: #405261;
      }
      &.txt_review {
        color: #405261;
        background-color: #fff;
        border: 2px solid #405261;
      }
    }
    .btn_start_stop {
      padding: 10px 15px;
      color: #fff;
      font-weight: 600;
      border-radius: 10px;
      &.btn_start {
        background-color: #12a1d5;
      }
      &.btn_stop {
        background-color: #f15046;
      }
    }
  }
  .btn_status {
    width: 100%;
    margin-top: 6px;
    padding: 8px 0;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
    &.btn_status_01 {
      background-color: #ffa800;
    }
    &.btn_status_02 {
      background-color: #02a744;
    }
    &.btn_status_03 {
      background-color: #bbb;
    }
    &.btn_status_04 {
      background-color: #f15046;
    }
    &.btn_status_05 {
      background-color: #405261;
    }
    &.btn_status_06 {
      background-color: #12a1d5;
    }
  }
}
.video_wrap {
  position: relative;
  width: calc(100% - 20px);
  aspect-ratio: 16 / 9;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #000;
  border-radius: 0;
  .btn_area {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 35px;
    top: 15px;
    right: 15px;

    button {
      padding: 0 15px;
      height: 100%;
      font-size: 16px;
      font-weight: 600;
      border-radius: 10px;
    }
  }
  video {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
  }
}
.chart_box {
  position: relative;
  width: 100%;
  height: 400px;
  .btn_area {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 10px;
    .btn_stop {
      height: 38px;
      font-size: 16px;
      padding: 0 15px;
      border-radius: 10px;
      font-weight: 600;
    }
    .btn_more {
      width: 30px;
    }
    button {
      height: 30px;
      border-radius: 50%;
      font-size: 5px;
    }
  }
}
.check_wrap {
  .btn_cancel {
    width: 90px;
    height: 45px;
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 10px;
    margin-right: 15px;
  }
}
</style>
