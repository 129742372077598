<template>
  <div class="component">
    <div class = "componentType">
     <div class = "first">
        Component Type
     </div>
     <div class = "second">
        Calendar
     </div>
    </div>
    
    <div class = "componentType">
     <div class = "first">
        Label Text
     </div>
     <div class = "second">
            <input
            type="text"
            v-model="labelText"
            @input="labelTextChange"
          />
     </div>
    </div>
    
    <div id="datesize">
     <div class="first">
        D - Day
     </div>
     <div >
          <Datepicker v-model="picked" /> ~ <Datepicker v-model="picked2"   />
     </div>
    </div>
    <div class="componentType">
        <div class="first">기간</div>
        <div class="second">
        <select v-model="periodType" @change="periodChange">
            <option value="">기간 선택</option>
            <option value="1d">1일</option>
            <option value="1w">1주</option>
            <option value="2w">2주</option>
            <option value="1m">1달</option>
        </select>
        </div>
    </div>
  </div>
</template>

<script>

import Datepicker from "vue3-datepicker";

export default {
    props: {
      interface: {
        type: Object,
        required: true,
      },
      otherProp: {
        type: Object,
        required: true,
      }
    }
    ,
    components:{
        Datepicker
    },
   data(){
    return {
      picked: new Date(),
      picked2: new Date(),
      periodType :'',
      labelText  : ''
    }
   },
    mounted() {
      console.log(this.otherProp)
      this.picked = this.otherProp.valueStart;
      this.picked2 = this.otherProp.valueEnd;
      this.periodType = this.otherProp.periodType;
      this.labelText = this.otherProp.labelText;
  },

  methods: {
    periodChange(){
        const today = new Date();
        
        // 오늘부터 내일까지 (1일)
        const oneDay = new Date(today);
        oneDay.setDate(today.getDate() + 1);

        // 오늘부터 1주일 (7일)
        const oneWeek = new Date(today);
        oneWeek.setDate(today.getDate() + 7);

        // 오늘부터 2주일 (14일)
        const twoWeeks = new Date(today);
        twoWeeks.setDate(today.getDate() + 14);

        // 오늘부터 1개월 (30일, 월 단위로 맞추려면 .setMonth 사용 가능)
        const oneMonth = new Date(today);
        oneMonth.setMonth(today.getMonth() + 1); // 월 단위 계산
        switch(this.periodType){
            case '1d':
                this.picked = today
                this.picked2 = oneDay
                break;
            case '1w':
                this.picked = today
                this.picked2 = oneWeek
                break;
            case '2w':
                this.picked = today
                this.picked2 = twoWeeks
                break;
            case '1m':
                this.picked = today
                this.picked2 = oneMonth
                break;
        }
        //위의 값을 또 상위 부모에게 보내야함....
        const item  = this.otherProp;
        item.valueStart = this.picked;
        item.valueEnd   = this.picked2;
        this.$emit('updateValue', item); //
    },
    labelTextChange(event){
      this.labelText =  event.target.value;
      const item  = this.otherProp;
      item.labelText = this.labelText;
      this.$emit('updateValue', item); //
    }
  },
  watch:{
    picked(){
        const item  = this.otherProp;
        item.valueStart = this.picked;
        item.valueEnd   = this.picked2;
        this.$emit('updateValue', item); //
    },
    picked2(){
        const item  = this.otherProp;
        item.valueStart = this.picked;
        item.valueEnd   = this.picked2;
        this.$emit('updateValue', item); //
    }
  }
}
</script>

<style>
.componentType{
     display:flex;
     justify-content: center; /* 수평 중앙 정렬 */
     align-items: center;     /* 수직 중앙 정렬 */
}
.first{
    width: 25%;
}

#datesize{
    display:flex;
    height: 300px;
    width: 75%;
}
.second{
    width: 75%;
}
</style>