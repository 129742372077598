<template>
<!--    <div style="padding: 5px">
      <button @click="drawMDTextTest" border="1">drawMDTextTest@DataParsePdfChunk</button>
    </div>-->
  <div
      class="drop-zone"
      @click="openFileDialog"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
      :class="{ 'drag-over': isDragging }"
  >
    <div v-if="!files.length">Drag & Drop files here or click to upload</div>
    <div v-else>
      <p v-for="(file, index) in files" :key="index">
        {{ file.name }} ({{ (file.size / 1024).toFixed(2) }} KB)
      </p>
    </div>
    <input type="file" ref="fileInput" multiple @change="onFileSelect" hidden />

    <!-- 💡 drop-zone 내부에서만 모달로 띄우는 Spinner -->
    <div v-if="isLoading" class="spinner-modal">
      <div class="spinner"></div>
    </div>

  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import axios from "axios";
import conf from "@/assets/js/conf";

export default {
  name: "DataParseCompForPdfChunk",
  emits: ['callDrawMDText', 'callClearMdText'],
  props: ['comp_type'],
  data() {
    return {
    }
  },
  setup(props, { emit }) {
    const files = ref([]);
    const isDragging = ref(false);
    const fileInput = ref(null);

    const isLoading = ref(false);

    onMounted(() => {
      fileInput.value = fileInput.value || document.querySelector('input[type="file"]');
    });

    const onDragOver = () => {
      isDragging.value = true;
    };

    const onDragLeave = () => {
      isDragging.value = false;
    };

    const onDrop = (event) => {
      isDragging.value = false;
      handleFiles(event.dataTransfer.files);
    };

    const onFileSelect = (event) => {
      handleFiles(event.target.files);
    };

    const openFileDialog = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleFiles = (fileList) => {
      files.value = [...fileList];
      const file = files.value[0];
      // emit('files-added', files.value);
      pdfFileToMdText(file);
    };

    const pdfFileToMdText = async (file) => {
      // 기존 MarkDownView
      callClearMdText();
      // PDF to MD API call
      const baseURL = conf.getPdfToMd_baseURL();
      // const url = baseURL + "/pdf_to_md";
      const url = baseURL + "/pdf/titles";

      const headerParams = {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "x-api-key": "pdf_key_1"
      }
      const formData = new FormData();
      formData.append("file", file);
      let mdText = "";
      // let mdTextArr = [];
      let statements = [];

      isLoading.value = true;
      try{
        const response = await axios.post(url, formData, { headers: headerParams });
        // mdTextArr = response.data.content;
        statements = response.data.content.statements;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
      // mdText = mdTextArr.join("  \n");

      statements.forEach((each_row) => {
        // console.log(each_row);
        // check type : title, image, table
        // each_row.type
        mdText += each_row.statement + "  \n";
      })

      drawMDText(mdText)
    }

    const drawMDText = (mdText) => {
      emit('callDrawMDText', mdText);
    }

    const drawMDTextTest = () => {
      const mdTextArr = [
          "# Markdown Sample",
          "- List 1",
          "- **bold** *Italic*",
          "- [link Sample](https://vuejs.org)",
          "Plain Text",
          "line feed",
          "# Markdown Table Example",
          "| Name  | Age | City |",
          "|-------|-----|------|",
          "| Alice | 25  | NY   |",
          "| Bob   | 30  | LA   |",
          "| Eve   | 28  | SF   |",
      ]
      let mdText = mdTextArr.join("  \n");
      emit('callDrawMDText', mdText);
    }

    const callClearMdText = () => {
      emit("callClearMdText");
    }

    return {
      files,
      isDragging,
      fileInput,
      onDragOver,
      onDragLeave,
      onDrop,
      onFileSelect,
      openFileDialog,
      pdfFileToMdText,
      drawMDText,
      drawMDTextTest,
      callClearMdText,
      isLoading
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.drop-zone {
  width: 1210px;
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}

.drag-over {
  background: #f0f0f0;
}

/* 🛠️ drop-zone 내부에서만 띄우는 Spinner 모달 */
.spinner-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fafafa;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 회전하는 스피너 */
.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 255, 0.3);
  border-top-color: blue;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* 회전 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
