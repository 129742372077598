<template>
  <div class="dashboard">
    <div class="stats">
      <div class="stat-item">
        <label>Paragraphs</label>
        <span>{{ paragraphs }}</span>
      </div>
      <div class="stat-item">
        <label>LLM Categorize</label>
        <span>{{ state.llmCategorize }}</span>
      </div>
      <div class="stat-item">
        <label>Software</label>
        <span>{{ state.software }}</span>
      </div>
      <div class="stat-item">
        <label>A-SPICE</label>
        <span>{{ state.aSpice }}</span>
      </div>
      <div class="stat-item">
        <label>Cyber Security</label>
        <span>{{ state.cyberSecurity }}</span>
      </div>
      <div class="stat-item">
        <label>Hardware</label>
        <span>{{ state.hardware }}</span>
      </div>
      <div class="stat-item">
        <label>Electrical & Electronic</label>
        <span>{{ state.electricalElectronic }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "DashboardComp",
  data() {
    let paragraphs = 0;
    return {
      paragraphs,
    }
  },
  setup() {
    const state = reactive({
      llmCategorize: 0,
      software: 0,
      aSpice: 0,
      cyberSecurity: 0,
      hardware: 0,
      electricalElectronic: 0
    });

    const set_llmCategorize = () => {
      state.llmCategorize += 1;
    }
    const set_software = () => {
      state.software += 1;
    }
    const set_aSpice = () => {
      state.aSpice += 1;
    }
    const set_cyberSecurity = () => {
      state.cyberSecurity += 1;
    }
    const set_hardware = () => {
      state.hardware += 1;
    }
    const set_electricalElectronic = () => {
      state.electricalElectronic += 1;
    }

    const clearStateData = () => {
      state.llmCategorize = 0;
      state.software = 0;
      state.aSpice = 0;
      state.cyberSecurity = 0;
      state.hardware = 0;
      state.electricalElectronic = 0;
    }

    return {
      state,
      set_llmCategorize,
      set_software,
      set_aSpice,
      set_cyberSecurity,
      set_hardware,
      set_electricalElectronic,
      clearStateData
    }
  },
  methods: {
    set_paragraphs_length(dataLength){
      this.paragraphs = dataLength;
    },
    set_paragraphs_length_reset(){
      this.paragraphs = 0;
    }
  }
};
</script>

<style scoped>
.dashboard {
  max-width: 1240px;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;  /* 각 항목 너비 조정 */
}

label {
  font-weight: bold;
  font-size: 12px;
  color: #333;
}

span {
  display: block;
  padding: 2px;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  margin-top: 2px;
}

.stat-item span {
}
</style>
