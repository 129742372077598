 <template>
    <div class="filter_box">  
        <!-- <div v-if="readOnlyProp===false" class="date_filter">                                                    -->
            <!-- <div class="date_period_first"> Handler ID</div>
            <div class="date_period_second" ref="handlerKey" > {{handlerKey}}  </div>
        </div> -->
        <div v-if="readOnlyProp===false" class="date_filter" style="width:100%">                                                   
            <div class="first"> Label Type </div>
            <div class="second"> label </div>
        </div>
        <!-- <div class="date_filter">                                                   
            <div class="date_period_first"> 제어 Handler </div>
            <div class="date_period_second"> 
              <select v-model="parentHandlerKey" @change="updateParent($event)">
                <option value=""  selected>None</option>
                <option v-for="(item) in handlerList.filter((ele)=>ele.handlerKey!==otherProp.handlerKey)"  :key="item.handlerKey"  :value="item.handlerKey">
                  handlerKey : {{item.handlerKey}}
                </option>
              </select>
            </div>
        </div> -->

        <div class="date_filter">
        <div class="first">API</div>
        <div class="second">
          <select name="" id="" @change="labelDataSetting($event)">
            <option value="">None</option>
            <option value="dataSet" >/api/test</option>
          </select>     
        </div>     
        </div>
        <div  class="date_filter" style="width:100%;display:block;">
        <QuillEditor ref="quillEditor" @input="onEditorChange" v-model="content" :options="editorOptions" style="display:block;height:500px"
        />
        </div>

    </div>
    <!-- ColorPicker를 조건부로 렌더링 -->

</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@/assets/js/customBlocked'

export default {
    components: {
      QuillEditor
     },
     props: {
      interface: {
        type: Object,
        required: true,
      },
      otherProp: {
        type: Object,
        required: true,
      },
      readOnlyProp:{
        type: Boolean,
        require:true,
      },
      handlerList:{
        type:Object,
        require:true
      },
      componentIndex:{
        type:Number
      },
      keyValueList:{
        type:Object
      },
      keyValueType:{
        type:String
      },
      labelHtml:{
        type:String
      }
    },

    data() {
        return {
            content:"",
            labelText:'',
            handlerKey :'',
            color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
            picStartDate: null,
            picEndDate: null,
            start_cal_key:0,
            end_cal_key:0,
            picStartDateCalendar: null,
            picEndDateCalendar: null,
            picColor: {
                startStatus: false,
                endStatus: false,
                active: "#00B4ED",
                default: "#020A0A",
            },
            onlyRead : false,
            remove : false,
            DataMapCateGory:'Data',
            parentHandlerKey : '',
            testData:[
              
            ],
            components:[
              {selectSectionYn:null}
            ],
     editorOptions: {
        theme: 'snow', // 'bubble', 'snow', 'core' 중 선택
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
              [{ color: [] }, { background: [] }], 
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image'],
          ],
        },
      },
        };
    },
    mounted(){
        const vm = this;
        const prop = this.otherProp;
        console.log(vm)
        vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
        vm.handlerKey = prop.handlerKey; //handlerKey
        if(vm.parentHandlerKey!==undefined){
          vm.parentHandlerKey = prop.parentHandlerKey; // 부모키 
        }
        if(vm.labelHtml!==undefined){
          vm.content = vm.labelHtml;
        }
        vm.labelText = prop.labelText;
        vm.parameter = prop.parameter
        const  quillInstance = this.$refs.quillEditor.getQuill();
        if(this.otherProp.labelHtml!==undefined){
          this.content = prop.labelHtml;
          quillInstance.clipboard.dangerouslyPasteHTML(prop.labelHtml);
        }

        quillInstance.on("text-change", () => {
        this.$emit('changeTextHtml',{index:this.componentIndex, value:quillInstance.root.innerHTML})
        quillInstance.on("selection-change", (range) => {
                  if (range) {
                   this.$emit('changeTextHtml',{index:this.componentIndex, value:quillInstance.root.innerHTML})
                  } else {
                    console.log("Editor out of focus");
                  }
                });
          // 여기서 원하는 함수를 호출하거나 상태를 업데이트
        });

        this.$emit('changeTextHtml',{index:this.componentIndex, value:prop.labelHtml})
    },
    methods:{
      updateParent(event){
        this.$emit('updateParent',{index:this.componentIndex, value:event.target.value})
     },
      labelTextChange(event){
      this.labelText =  event.target.value;
      const item  = this.otherProp;
      item.labelText = this.labelText;
      },
      parameterTextChange(event){
        this.parameter =  event.target.value;
        const item  = this.otherProp;
        item.parameter = this.parameter;
      },
      removeItem(){
        this.$emit('removeItem');
      },
      labelDataSetting(){

      },
      onEditorChange(value) {
        this.content = value.target.outerHTML; // content에 강제 반영
        this.$emit('changeTextHtml',{index:this.componentIndex, value:this.content})
      },
    }
    ,
    watch:{
    }
}
</script>

<style scoped lang="scss">
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height:100%;
  width: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    margin-bottom:15px;
    width: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
  }
}


.labelText{
    width: 50%;
    height: 100%;
    line-height: 45px;
    box-sizing: border-box;
    border: 1px solid #D5DAE3;
    border-radius: 10px;
    background-color: #fff;
    font-size: 15px;
    padding: 10px 15px;
}
// #quillEditor{
//   ::v-deep(.ql-editor){
//       /* 기본적인 bold 스타일 강제 적용 */
//       strong {
//         font-weight: bold !important; /* 강제적으로 굵게 표시 */
//       }
      
//       /* 기본적인 italic 스타일 강제 적용 */
//       em {
//         font-style: italic !important; /* 강제적으로 기울임 표시 */
//       }
//       strong em, em strong {
//       font-weight: bold !important;
//       font-style: italic !important;
//     }
   
//   }
// }

</style>

