import axios from "axios";
// Vector Logger API
const axiosInstanceTemplate = axios.create({
  baseURL: "https://ml5bmeaed6.execute-api.ap-northeast-2.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    "X-API-Key": "mcspkey1",
  },
});

const apiTemplate = {
  get(url, params = {}) {
    return axiosInstanceTemplate.get(url, { params });
  },
  post(url, data = {}) {
    return axiosInstanceTemplate.post(url, data);
  },
  patch(url, data = {}) {
    return axiosInstanceTemplate.patch(url, data);
  },
  delete(url) {
    return axiosInstanceTemplate.delete(url);
  },
  put(url, data = {}) {
    return axiosInstanceTemplate.put(url, data);
  },
};

export default apiTemplate;
