<template>
  <div v-if="readOnlyProp === false">
    <input type="hidden" v-model="remove" />
    <button class="close" @click="removeItem">
      <font-awesome-icon :icon="['fas', 'xmark']" />
    </button>
  </div>
  <div class="filter_box">
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="date_period_first">Handler ID</div>
      <div class="date_period_second" ref="handlerKey">{{ handlerKey }}</div>
    </div>
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="date_period_first">Filter Type</div>
      <div class="date_period_second">Range</div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">제어 Handler</div>
      <div class="date_period_second">
        <select v-model="parentHandlerKey" @change="updateParent($event)">
          <option value="" selected>None</option>
          <option
            v-for="item in handlerList.filter(
              (ele) => ele.handlerKey !== otherProp.handlerKey
            )"
            :key="item.handlerKey"
            :value="item.handlerKey"
          >
            handlerKey : {{ item.handlerKey }}
          </option>
        </select>
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Default Text</div>
      <div class="date_period_second">
        <input
          type="text"
          class="labelText"
          v-model="labelText"
          @input="labelTextChange"
        />
      </div>
    </div>
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="date_period_first">Range</div>
      <div class="date_period_second" style="gap: 10px">
        <input
          class="range"
          type="number"
          v-model="startRange"
          @input="updateRange"
        />
        <input
          class="range"
          type="number"
          v-model="endRange"
          @input="updateRange"
        />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Unit</div>
      <div class="date_period_second">
        <input
          class="labelText"
          type="number"
          v-model="step"
          @input="updateRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interface: {
      type: Object,
      required: true,
    },
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
    keyValueList: {
      type: Object,
    },
    keyValueType: {
      type: String,
    },
  },
  data() {
    return {
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      DataMapCateGory: "Data",
      parentHandlerKey: "",
      barKeyValueType: "",
      inputKey: "",
      inputValue: "",
      barKeyValueList: [],
      startRange: 0,
      endRange: 0,
      step: 0,
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    const range = prop.range;
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    console.log(range);
    vm.labelText = prop.labelText;
    vm.startRange = range.startRange;
    vm.endRange = range.endRange;
    vm.step = range.step;
  },
  methods: {
    updateParent(event) {
      this.$emit("updateParent", {
        index: this.componentIndex,
        value: event.target.value,
      });
      console.log(this.handlerList);
    },
    labelTextChange(event) {
      this.labelText = event.target.value;
      const item = this.otherProp;
      item.labelText = this.labelText;
    },

    removeItem() {
      this.$emit("removeItem");
    },
    updateRange() {
      const range = {
        startRange: this.startRange,
        endRange: this.endRange,
        step: this.step,
      };
      this.$emit("updateRange", { index: this.componentIndex, value: range });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    &:first-child {
      .date_period_second {
        border-top: 1px solid #d5dae3;
      }
    }
    .date_period {
      width: 240px;
      display: flex;
      justify-content: flex-end; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      display: flex;
      height: 100%;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      background-color: #00b4ed;
      color: #fff;
      font-weight: 600;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-right: 1px solid #d5dae3;
      border-bottom: 1px solid #d5dae3;
      box-sizing: border-box;
      select {
        background-position: center right 15px;
      }
    }
  }
}
.labelText {
  width: 50% !important;
  height: 40px !important;
  line-height: 45px;
  box-sizing: border-box !important;
  border: 1px solid #d5dae3 !important;
  border-radius: 10px !important;
  background-color: #fff;
  font-size: 15px !important;
  padding: 10px 15px !important;
}

.range {
  width: 25%;
  height: 40px;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}
.close {
  font-size: 20px;
  color: #bbb;
}
</style>
