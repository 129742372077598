<template>
  <div class="chart">
    <canvas id="myChart" ref="myChart" width="100%" height="100%"> </canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomoPlugin from "chartjs-plugin-zoom";
import { toRaw } from "vue";

Chart.register(zoomoPlugin);
export default {
  name: "ChartController",
  computed: {},
  props: {
    labelsArray: {
      type: Array,
      default: null,
    },
    datasArray: {
      type: Array,
      default: null,
    },
    chartTitle: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "line",
    },
    displayLegend: {
      type: String,
    },
    customTooltip: {
      type: String,
    },
    chartTooltip: {
      type: Array,
    },
  },
  data() {
    return {
      chartApiCall: null,
      chart: null,
    };
  },
  watch: {
    datasArray() {
      try {
        const existing_chart = this.chartApiCall;
        console.log("ddddddddddddddd :: ", this.labelsArray);
        console.log("mmmmmmmmmmmmm :: ", this.datasArray);
        console.log("!!!!!!!!!!!!!!!! :: ", this.chartTooltip);

        //existing_chart.destroy();
        toRaw(existing_chart).destroy();
        //this.renderChart()
        console.log("trying to destory then redraw chart");
        this.createChart();
      } catch (e) {
        console.log("chart does not exist yet to destroy");
      }
    },
  },
  created() {},
  mounted() {
    this.createChart();
  },
  methods: {
    transNumberFormat(targetNumber) {
      const number = targetNumber;
      let transNumber = number;
      const splitNum = number.toString().split(".")[1];
      if (splitNum != undefined && splitNum.length > 4) {
        transNumber = number.toFixed(5);
      }
      return transNumber;
    },
    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#000";
        tooltipEl.style.borderRadius = "3px";
        tooltipEl.style.color = "white";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    },
    externalTooltipHandler(context) {
      // Tooltip Element
      const vm = this;
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const targetTime = tooltip.title;
        console.log(targetTime);
        //const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        /*
        const tableHead = document.createElement("thead");
        titleLines.forEach((title) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = 0;

          const th = document.createElement("th");
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });
        */

        const tableBody = document.createElement("tbody");
        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement("span");
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = "2px";
          span.style.marginRight = "10px";
          span.style.height = "10px";
          span.style.width = "10px";
          span.style.display = "inline-block";

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = 0;

          const td = document.createElement("td");
          td.style.borderWidth = 0;
          td.style.color = "#fff";
          for (let item of this.chartTooltip) {
            if (targetTime == item.time)
              td.innerHTML +=
                "<div style='display:flex; align-items:center; gap:10px;'><span style='width:15px; color:#fff; text-align:center;'>" +
                item.point +
                "</span><span style='flex:1; color:#fff; text-align:left;'>:&nbsp;&nbsp;" +
                vm.transNumberFormat(item.fpy * 100) +
                "%</span></div>";
          }

          //td.innerHTML = "TEST";

          //const text = document.createTextNode(body);

          //td.appendChild(span);
          //td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        //tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + "px " + tooltip.options.padding + "px";
    },
    createChart() {
      const vm = this;
      vm.chart = this.$refs.myChart;
      /*
      console.log("ddddddddddddddd :: ", vm.labelsArray);
      console.log("mmmmmmmmmmmmm :: ", vm.datasArray);
      */
      this.chartApiCall = new Chart(vm.chart, {
        type: vm.chartType,
        data: {
          labels: vm.labelsArray,
          datasets: vm.datasArray,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          plugins: {
            legend: {
              position: "top",
              display: vm.displayLegend === "N" ? false : true,
            },
            tooltip: {
              enabled: vm.customTooltip === "Y" ? false : true,
              position: "nearest",
              external:
                vm.customTooltip === "Y" ? vm.externalTooltipHandler : null,
            },
            title: {
              display: true,
              text: this.chartTitle,
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "xy",
              },
              pan: {
                enabled: true,
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    addData(chart, label, newData) {
      chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(newData);
      });
      chart.update();
    },
    removeData(chart) {
      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      chart.update();
    },
  },
};
</script>
<style scoped lang="scss">
.chart {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
