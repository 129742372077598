<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="sysOps_sourceManagement"
        :checkRole="manageRole"
      />
      <div class="title_btn">
        <button
          type="button"
          class="add_project bgicon"
          @click="openModal('regist')"
        >
          신규 Source 등록
        </button>
      </div>
      <div class="list_wrap float_box">
        <div class="list_filter_wrap float_box">
          <div class="filter_list_box">
            <select style="min-width: 150px" v-model="sourceType">
              <option value="">Type</option>
              <option value="2">ml</option>
              <option value="1">inference</option>
            </select>
            <!-- <select style="min-width: 150px" v-model="useYn">
              <option value="">UseYn</option>
              <option value="y">Y</option>
              <option value="n">N</option>
            </select> -->
            <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input_text">
                <input
                  type="text"
                  placeholder="Source Name"
                  v-model="sourceNm"
                />
              </div>
              <div class="search_form_box">
                <button
                  type="button"
                  class="btn_send mcbtn"
                  @click="clickSearch()"
                >
                  조회
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ sourceList.length }}</span> Site
            </div>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <colgroup></colgroup>
              <thead>
                <tr>
                  <th>
                    <div class="col_name">Source Name</div>
                  </th>
                  <th>
                    <div class="col_name">Last Version</div>
                  </th>
                  <th>
                    <div class="col_name">Type</div>
                  </th>
                  <th>
                    <div class="col_name">Date</div>
                  </th>
                  <th>
                    <div class="col_name">Use</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="m_pointer"
                  v-for="(item, index) in sourceListSliced"
                  :key="index"
                  @click="openModal(item)"
                >
                  <td>{{ item.sourceNm }}</td>
                  <td>{{ item.sourceVersion }}</td>
                  <td>{{ item.sourceType === 1 ? "inference" : "ml" }}</td>
                  <td>{{ item.createDate }}</td>
                  <td>{{ item.useYn.toUpperCase() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap" style="display: none">
    <div class="radiusbox modal_contents message_modal modal">
      <h2>{{ isRegistSource ? "소스 등록" : "상세보기 및 수정" }}</h2>
      <div class="row row_01">
        <select
          v-model="sourceVersion"
          v-if="!isRegistSource"
          style="width: 150px"
          @change="chgVersion"
        >
          <option value="">Version</option>
          <option
            v-for="item in sourceVersionOrigin"
            :key="sourceVersionOrigin - (item - 1)"
          >
            {{ sourceVersionOrigin - (item - 1) }}
          </option>
        </select>
        <button type="button" class="mcbtn" @click="registSource">
          {{ isRegistSource ? "등록" : "수정" }}
        </button>
        <button
          type="button"
          class="mcbtn"
          @click="deleteSource(sourceInfo.sourceId)"
          v-if="!isRegistSource"
        >
          삭제
        </button>
      </div>
      <div class="row row_02">
        <div class="title">Name</div>
        <div class="content">
          <template v-if="!isRegistSource">
            {{ sourceInfo.sourceNm }}
          </template>
          <template v-else>
            <input type="text" v-model="sourceInfo.sourceNm" />
          </template>
        </div>
        <template v-if="!isRegistSource">
          <div class="title">Version</div>
          <div class="content">
            {{ sourceInfo.sourceVersion }}
          </div>
        </template>
        <div class="title">Type</div>
        <div class="content">
          <template v-if="!isRegistSource">
            {{ sourceInfo.sourceType === 1 ? "inference" : "ml" }}
          </template>
          <template v-else>
            <select v-model="sourceInfo.sourceType">
              <option value="">Type</option>
              <option value="2">ml</option>
              <option value="1">inference</option>
            </select>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="title">Uri</div>
        <div class="content txt-left">
          <template v-if="!isRegistSource">
            {{ sourceInfo.sourceUri }}
          </template>
          <template v-else>
            <input type="text" v-model="sourceInfo.sourceUri" />
          </template>
        </div>
      </div>
      <div class="row row_txt">
        <div class="title">Config</div>
        <div class="content">
          <textarea v-model="sourceInfo.sourceConfig"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="title">Description</div>
        <div class="content">
          <input type="text" v-model="sourceInfo.sourceDesc" />
        </div>
      </div>
      <template v-if="!isRegistSource">
        <div class="row">
          <div class="title">Create user</div>
          <div class="content">{{ sourceInfo.createUser }}</div>
          <div class="title">Create date</div>
          <div class="content">{{ sourceInfo.createDate }}</div>
        </div>
        <!-- <div class="row">
          <div class="title">Update user</div>
          <div class="content">
            {{ sourceInfo.updateUser }}
          </div>
          <div class="title">Update date</div>
          <div class="content">
            {{ sourceInfo.updateDate }}
          </div>
        </div> -->
      </template>
      <div class="row">
        <div class="title">Order</div>
        <div class="content">
          <input
            type="number"
            v-model="sourceInfo.order"
            onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
          />
        </div>
        <div class="title">Use</div>
        <div class="content">
          <select v-model="sourceInfo.useYn">
            <option value="y">Y</option>
            <option value="n">N</option>
          </select>
        </div>
      </div>
      <button type="button" class="btn_modal_close" @click="closeModal()">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>

  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import "@/assets/js/filter";
import common from "@/assets/js/common";
import { mapGetters } from "vuex";

export default {
  name: "topicSetList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    PaginationUi,
    BreadcrumbLayout,
    VCalendarUi,
  },
  data() {
    return {
      filterList: [],
      filterRowData: [],
      perPageList: [],
      perPage: 0,

      searchKeyword: "",

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      sourceList: [],
      sourceListSliced: [],
      sourceVersionOrigin: "",
      sourceVersion: "",
      isfilter: true,
      filtersourceList: {
        origin: {},
      },

      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      selectDuration: 1825,

      today: "",

      sourceNm: null,
      sourceType: "",
      useYn: "",

      sourceInfo: {
        // sourceNm: "",
        // sourceVersion: 1,
        sourceType: "",
        // sourceUri: "",
        // sourceConfig: "",
        // sourceDesc: "",
        // createUser: "",
        // createDate: "",
        // updateUser: "",
        // updateDate: "",
        order: 1,
        useYn: "y",
      },
      isRegistSource: false,
    };
  },
  created() {},
  async mounted() {
    this.setRole();
    this.setDate(this.selectDuration);
    this.$refs.lodingWrap.style.display = "block";

    await this.getSourceList();
    this.$refs.lodingWrap.style.display = "none";
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    ...mapGetters(["getUserData"]),
  },
  methods: {
    inputOnlyNumber(e) {
      console.log(e);
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "none";
      this.resetSourceInfo();
    },
    resetSourceInfo() {
      this.sourceInfo = {
        sourceType: "",
        order: 1,
        useYn: "y",
      };
    },
    async clickSearch() {
      await this.getSourceList();
    },
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    datePicToggle() {
      const vm = this;
      const _display = vm.$refs.calenderBox.style.display;
      const _startDateBox = vm.$refs.startDateBox.style.display;
      // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
      // console.log(_display);

      if (_display == "block") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "none";
        vm.picColor.startStatus = false;
        vm.picColor.endStatus = false;
      } else if (_display == "none") {
        vm.$refs.datePicToggle.nextElementSibling.style.display = "block";
        console.log(_startDateBox);
        if (_startDateBox == "block") {
          vm.picColor.startStatus = true;
          vm.picColor.endStatus = false;
        } else if (_startDateBox == "none") {
          vm.picColor.startStatus = false;
          vm.picColor.endStatus = true;
        }
      }
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },
    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.today = vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },

    async getSourceList() {
      const sourceNm = this.sourceNm === "" ? null : this.sourceNm;
      const sourceType = this.sourceType;
      // const useYn = this.useYn === "" ? null : this.useYn;

      const dateFrom = this.picStartDate.replaceAll("-", "");
      const dateTo = this.picEndDate.replaceAll("-", "");

      const param = {};

      param.sourceNm = sourceNm;
      param.sourceType = sourceType;
      // param.useYn = useYn;
      param.dateFrom = dateFrom;
      param.dateTo = dateTo;

      try {
        const response = await common.apiGet("/source", param);
        console.log("response :: ", response);
        this.sourceList = response.data.data;
        this.currentPage = 1;
        this.totalItems = this.sourceList.length;
        this.displayDataPerPage(this.sourceList);
      } catch (error) {
        console.error("error", error);
      }
    },

    async getSourceOne(sourceId, sourceVersion, chgSelect) {
      const param = {
        sourceVersion: sourceVersion,
      };
      try {
        const response = await common.apiGet("/source/" + sourceId, param);
        console.log("response 1111:: ", response);
        this.sourceInfo = response.data.data;
        if (!chgSelect) {
          this.sourceVersionOrigin = this.sourceVersion =
            response.data.data.lastSourceVersion;
        }

        //this.sourceList = response.data.data;
      } catch (error) {
        console.error("error", error);
      }
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getSourceList();
    },
    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.sourceListSliced = rowData.slice(startIndex, endIndex);
    },

    async openModal(item) {
      if (item === "regist") {
        this.isRegistSource = true;
      } else {
        this.isRegistSource = false;
        await this.getSourceOne(item.sourceId, item.sourceVersion, false);
      }

      const $modal = this.$refs.modalWrap;

      $modal.style.display = "block";
    },
    isValidURL(url) {
      const urlRegex =
        // eslint-disable-next-line no-useless-escape
        /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;
      return urlRegex.test(url);
    },
    async registSource() {
      const sourceId =
        this.sourceInfo.sourceId === undefined
          ? null
          : this.sourceInfo.sourceId;
      const sourceNm = this.sourceInfo.sourceNm;
      const sourceUri = this.sourceInfo.sourceUri;
      const sourceType = this.sourceInfo.sourceType;
      const sourceConfig = this.sourceInfo.sourceConfig;
      // const sourceVersion = this.sourceInfo.sourceVersion;
      const sourceDesc = this.sourceInfo.sourceDesc;
      const order = this.sourceInfo.order;
      const useYn = this.sourceInfo.useYn;
      const validUri = this.isValidURL(sourceUri);

      if (sourceNm === "" || sourceNm === null || sourceNm === undefined) {
        alert("소스명을 입력해 주세요.");
        return false;
      } else if (
        sourceType === "" ||
        sourceType === null ||
        sourceType === undefined
      ) {
        alert("Type을 선택해 주세요.");
        return false;
      } else if (
        sourceUri !== "" &&
        sourceUri !== null &&
        sourceUri !== undefined &&
        !validUri
      ) {
        alert("올바른 URI를 입력해 주세요.");
        return false;
      } else if (
        sourceConfig === "" ||
        sourceConfig === null ||
        sourceConfig === undefined
      ) {
        alert("SourceConfig를 작성해 주세요.");
        return false;
      }
      const param = {
        sourceId: sourceId,
        sourceNm: sourceNm,
        sourceType: sourceType,
        sourceUri: sourceUri,
        sourceConfig: sourceConfig,
        sourceDesc: sourceDesc,
        order: order,
        useYn: useYn,
      };
      try {
        const response = await common.apiPost("/source", param);
        console.log("response 222:: ", response);
        if (response.status === 200 || response.status === "200") {
          alert("등록되었습니다.");
          this.resetSourceInfo();
          this.closeModal();
          await this.getSourceList();
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    async deleteSource(sourceId) {
      if (confirm("해당 소스를 삭제하시겠습니까?")) {
        try {
          const response = await common.apiDelete("/source/" + sourceId);
          if (response.status === 200 || response.status === "200") {
            alert("삭제되었습니다.");
            this.resetSourceInfo();
            this.closeModal();
            await this.getSourceList();
          }
        } catch (error) {
          console.error("error", error);
        }
      }
    },
    async chgVersion() {
      await this.getSourceOne(
        this.sourceInfo.sourceId,
        this.sourceVersion,
        true
      );
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  .list_filter_wrap {
    overflow: visible;
    .filter_list_box {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 15px;
      box-sizing: border-box;
      .filter_box {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        height: 100%;
        box-sizing: border-box;
        .date_filter {
          position: relative;
          .date_period {
            margin: 0;
          }
          &::after {
            display: none;
          }
        }
        .input_text {
          input {
            width: 220px;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #d5dae3;
            border-radius: 10px;
            box-sizing: border-box;
          }
        }
        .search_form_box {
          .btn_send {
            height: 40px;
            padding: 0 15px;
            border-radius: 10px;
            line-height: 37px;
            font-size: 15px;
          }
        }
      }
      select {
        background-position: center right 15px;
      }
      &::after {
        display: none;
      }
    }
  }

  .table_list {
    margin-top: 20px;
    height: 715px;
  }
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
.modal {
  width: 40%;
  min-width: 500px;
  min-height: 300px;
  height: auto;
  padding: 50px 0 28px;
  overflow: hidden;
  h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #405261;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  textarea {
    width: 100%;
    height: 80%;
    resize: none;
    border: 1px solid #d5dae3;
    //border-radius: 10px;
    word-break: break-all;
    box-sizing: border-box;
    padding: 0 10px;
  }
  input[type="text"],
  input[type="number"],
  .input {
    width: 100%;
    height: 40px;
    border: 1px solid #d5dae3;
    //border-radius: 10px;
    box-sizing: border-box;
    padding: 0 10px;
  }
  select {
    width: 100%;
    padding-right: 45px;
    background-position: center right 15px;
  }
  .input {
    display: inline-flex;
    align-items: center;
    padding-right: 35px;
  }
  .btn_modal_close {
    position: absolute;
    top: 8px;
    right: 10px;
    color: #fff;
    font-size: 28px;
  }
  .row {
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #d5dae3;
    &.row_txt {
      height: 100px;
    }
    &.row_01 {
      justify-content: flex-end;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px;
      button {
        width: 60px;
        height: 40px;
        border-radius: 10px;
      }
    }
    .title {
      width: 105px;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
      font-weight: 600;
      span {
        display: inline-flex;
        align-items: center;
      }
    }
    .content {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 10px;
      height: 100%;
      box-sizing: border-box;
    }
    .txt-left {
      justify-content: flex-start;
    }
  }
}
.title_btn {
  position: absolute;
  top: 30px;
  right: 42px;
  display: flex;
  gap: 15px;
  button {
    border-radius: 10px;
    font-weight: $bold;
    background-repeat: no-repeat;
    &.bgicon {
      padding: 13px 15px 13px 35px;
      color: $whiteColor;
      background-color: $mainColor;
      background-position-y: center;
      background-position-x: 10px;
      &.add_project {
        background-image: url($baseURL + "common/add_15_wh.svg");
      }
      &.setting_project {
        background-image: url($baseURL + "common/contents_btnicon_settings.svg");
      }
    }
    &.btn_view_log {
      background-color: $whiteColor;
      border: 1px solid #dedede;
      border-radius: 10px;
      padding: 13px 17px;
      font-weight: $regular;
    }
    &:disabled {
      background-color: $line02Color;
    }
  }
}
</style>
